import { Stack } from '@mui/material'
import { myPageUrl } from '../../containers/common/constant/appUrl'
import { OperationId } from '../../containers/common/constant/operationLog'
import { tabValue, useAction } from '../../containers/member/memberService'
import { getClassificationValueLabels, translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { GContainer, GItem, GItemContainer, MiddleGItem } from '../components/common/grids'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { ChildContent } from '../components/common/member/childContent'
import { UserContent } from '../components/common/member/userContent'
import { TabBar } from '../components/common/tabBar'
import { ReductionsDialog } from '../components/member/reductionsDialog'
import { yesNo } from '../../containers/common/constant/classification'

export const Member = () => {
  const {
    isVerifiedAll,
    activeTabValue,
    activeChildId,
    childs,
    user,
    child,
    isOpenReductionsHistory,
    reductionHistoryState,
    changeTab,
    changeChild,
    goModify,
    goAddChild,
    onClickReductionHistories,
    onCloseReductionsHistory,
    sysCtrl,
  } = useAction()
  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Stack spacing={4}>
            <TabBar
              value={activeTabValue}
              valueLabels={getClassificationValueLabels('member.classification.tabValue', Object.values(tabValue))}
              onChange={changeTab}
            />

            <div>
              <GContainer rowSpacing={1}>
                {activeTabValue === tabValue.child && childs && (
                  <GItemContainer xs={12} rowSpacing={1}>
                    <MiddleGItem xs={5} sm={3}>
                      {translate('member.label.selectionChild')}　
                    </MiddleGItem>
                    <GItem xs={7} sm={9}>
                      <SelectNoBind fullWidth valueLabels={childs} onChange={changeChild} value={activeChildId} />
                    </GItem>
                    {isVerifiedAll && (
                      <>
                        <MiddleGItem xs={5} sm={3}></MiddleGItem>
                        <GItem xs={7} sm={9}>
                          <Link
                            onClick={goAddChild}
                            operationLog={{ operationId: OperationId.OP_00000056 }}
                            underline="none"
                          >
                            ＋ {translate('member.text.addChild')}
                          </Link>
                        </GItem>
                      </>
                    )}
                  </GItemContainer>
                )}
                <GItem xs={12}>
                  {activeTabValue === tabValue.user
                    ? user && (
                        <UserContent
                          user={user}
                          displayType="reference"
                          onClickReductionHistories={onClickReductionHistories}
                        />
                      )
                    : child && (
                        <ChildContent
                          child={child}
                          isShowUseEnd={
                            childs
                            && childs.length > 1
                            && sysCtrl.childUseEndAcceptFlag === yesNo.yes
                          }
                          displayType="reference"
                        />
                      )
                  }
                </GItem>
              </GContainer>
            </div>
          </Stack>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>
      <BottomButtonGroup>
        {isVerifiedAll && (
          <ButtonOutlinedWarningL fullWidth onClick={goModify}>
            {translate('member.button.modify')}
          </ButtonOutlinedWarningL>
        )}
        <ButtonOutlinedResponsive to={myPageUrl.url()}>
          {translate('member.button.backToMypage')}
        </ButtonOutlinedResponsive>
      </BottomButtonGroup>
      {reductionHistoryState && (
        <ReductionsDialog
          isOpen={isOpenReductionsHistory}
          onClose={onCloseReductionsHistory}
          reductionName={reductionHistoryState.reductionName}
          reductionInfos={reductionHistoryState.reductionInfos}
        />
      )}
    </Stack>
  )
}
