import { Analytics } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { showLoading } from '../../containers/common/store/slices/application'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { ElapsedMillisecond } from '../../utils/dateUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'

interface UrlParams {
  facilityId: string
}

interface LocationState {
  /** 空き状況基準日 */
  availabilityBaseDate?: ElapsedMillisecond
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const { facilityId } = useParams<UrlParams>()
  const currentHistory = useHistory<LocationState | undefined>()
  const locationState = currentHistory.location.state

  const [facility, setFacility] = useState<NullPropsToUndefinedType<GetFacilityDto>>()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      showLoading(
        errorHandle(async () => {
          const resultDto = await getFacility(facilityId)
          setFacility(resultDto)
        })
      )
    )
    // AmazonPinpointへ追加情報送信。
    sendAnalyticsRecord(facilityId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    facilityId,
    facility,
    availabilityBaseDate: locationState?.availabilityBaseDate,
  }
}

/**
 * AmazonPinpointへ追加情報送信
 * 呼び出し完了を待つ必要は無くエラーのハンドリング不要、awaitしないこと
 * @param facilityId 表示している施設ID
 */
const sendAnalyticsRecord = (facilityId: string) => {
  Analytics.record({
    name: 'facilityDetail',
    attributes: { facilityId },
  })
}
