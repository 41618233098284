import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePostInterview } from '../../dataAccess/webApi/dao/interviewsDao'
import { toApiYmd } from '../../utils/dateUtil'
import { undefinedPropsToNull } from '../../utils/objectUtil'
import { blankToNull } from '../../utils/stringUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { getInterviewReservationSteps } from '../common/codeMaster'
import { interviewReservationCompletionUrl } from '../common/constant/appUrl'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getLatestAddOperationDatetime, useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import {
  InterviewReserveEntry,
  clearInterviewReserve,
  selectInterviewReserveEntry,
  selectInterviewReserveUpdateDatetimes,
} from '../common/store/slices/interviewReserve'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const [facilityName, setFacilityName] = useState<string>()
  const [interviewResultCode, setInterviewResultCode] = useState<number>()
  const { addOperationLog } = useOperationLog()

  const reserveEntry = useSelector(selectInterviewReserveEntry)
  const updateDatetimes = useSelector(selectInterviewReserveUpdateDatetimes)

  const steps = useMemo(getInterviewReservationSteps, [])

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          reserveEntry && setFacilityName((await getFacility(reserveEntry.facilityId)).facilityName)
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const decide = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000026 })
    const operationLogDatetime = castNonNullable(getLatestAddOperationDatetime())

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          if (reserveEntry && updateDatetimes) {
            const response = await postInterview(
              reserveEntry,
              updateDatetimes.userUpdateDatetime,
              updateDatetimes.childUpdateDatetime,
              operationLogDatetime
            )
            if (response.resultCode) {
              // 登録失敗時
              setInterviewResultCode(response.resultCode)
            } else {
              // 登録成功時
              history.push(interviewReservationCompletionUrl.url())
              dispatch(clearInterviewReserve())
            }
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [addOperationLog, dispatch, errorHandle, history, reserveEntry, updateDatetimes])

  return {
    reserveEntry,
    facilityName,
    steps,
    interviewResultCode,
    decide,
  }
}

const postInterview = (
  entry: InterviewReserveEntry,
  userUpdateDatetime: string,
  childUpdateDatetime: string,
  operationLogDatetime: string
) => {
  return executePostInterview(
    undefinedPropsToNull({
      facilityId: entry.facilityId,
      childId: entry.childId,
      parentName: entry.parentName,
      parentKana: entry.parentKana,
      postalCode: entry.postalCode,
      address1: entry.address1,
      address2: entry.address2,
      buildingNameRoomNumber: blankToNull(entry.buildingNameRoomNumber),
      residenceCategory: entry.residenceCategory,
      relationship: blankToNull(entry.relationship),
      childName: entry.childName,
      childKana: entry.childKana,
      childGender: entry.childGender,
      childBirthday: toApiYmd(entry.childBirthday),
      childMedicalHistoryFlag: entry.childMedicalHistoryFlag,
      childMedicalHistory: blankToNull(entry.childMedicalHistory),
      childAllergyFlag: entry.childAllergyFlag,
      childAllergy: blankToNull(entry.childAllergy),
      maternalHandbookNo: entry.maternalHandbookNo,
      note: blankToNull(entry.note),
      facilityNumber: blankToNull(entry.facilityNumber),
      userUpdateDatetime,
      childUpdateDatetime,
      operationLogDatetime,
    })
  )
}
