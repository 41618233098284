import { ReactNode } from 'react'
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'

interface FormProps<FormInputs extends FieldValues, TContext extends object> {
  /** 子要素 */
  children: ReactNode
  /**
   * submit時に呼び出される関数。
   * コントロールのバリデーションでエラーになった場合は呼び出されません。
   */
  onSubmit?: SubmitHandler<FormInputs>
  /**
   * useForm関数の戻り値を設定する
   */
  formMethods: UseFormReturn<FormInputs, TContext>
  id?: string
}

/** フォームコントロール */
export const Form = <FormInputs extends FieldValues, TContext extends object>({
  children,
  onSubmit,
  formMethods,
  id,
}: FormProps<FormInputs, TContext>) => {
  return (
    <FormProvider {...formMethods}>
      <form
        id={id}
        noValidate
        {...(onSubmit && {
          onSubmit: formMethods.handleSubmit(onSubmit),
        })}
      >
        {children}
      </form>
    </FormProvider>
  )
}
