import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useErrorHandle } from '../../containers/common/error/errorHandler'
import { selectFacilityReserveEntry, setFacilityReserve } from '../../containers/common/store/slices/facilityReserve'
import { executeGetChild } from '../../dataAccess/webApi/dao/childrenDao'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { fromApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined, undefinedPropsToOptional } from '../../utils/objectUtil'
import { ReactBatchUpdater } from '../../utils/reactUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { facilityReservationConfirmationUrl } from '../common/constant/appUrl'
import { availabilityStatus, yesNo } from '../common/constant/classification'
import { getFacility } from '../common/facility'
import { showLoading } from '../common/store/slices/application'
import { getUser } from '../common/user'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

interface Inputs {
  purposeOfUse: string
  lunchFlag: string
  snackFlag: string
  postponeCancelWaitFlag: string
  note: string
}

interface PageState {
  /** 施設情報 */
  facility?: NullPropsToUndefinedType<GetFacilityDto>
}

//------------------------------------------------------------------------------------------
export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const reserveEntry = useSelector(selectFacilityReserveEntry)
  const entryInput = reserveEntry?.input
  const { addOperationLog } = useOperationLog()

  const [state, setState] = useState<PageState>()

  const formMethods = useForm<Inputs>()

  const history = useHistory()

  const reservationReference = useMemo(() => {
    if (reserveEntry?.facility && reserveEntry?.parentChild) {
      const { facility, parentChild } = reserveEntry
      return {
        ...parentChild,
        facilityName: facility.facilityName,
      }
    }
  }, [reserveEntry])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000025 })

      dispatch(
        reserveEntry &&
          setFacilityReserve({
            ...reserveEntry,
            input: {
              useReasonCategory: data.purposeOfUse,
              lunchFlag: data.lunchFlag,
              snackFlag: data.snackFlag,
              postponeCancelWaitFlag: data.postponeCancelWaitFlag,
              citizenNote: data.note,
            },
          })
      )
      history.push(facilityReservationConfirmationUrl.url()) //値の送り先を設定
    },
    [reserveEntry, addOperationLog]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          if (reserveEntry == null) {
            // 前画面入力情報が無い場合はエラーにするので何もしない
            return
          }
          const { facility, user, child } = await getUserWithChild(
            reserveEntry.facilityId,
            reserveEntry.childId,
            new Date(reserveEntry.usageDatetimes[0].usageDate)
          )

          /** 給食注文 */
          let lunchFlag: string = yesNo.no
          if (facility.lunchAcceptFlag === yesNo.yes) {
            lunchFlag = entryInput?.lunchFlag ?? facility.lunchInitialSelect ?? yesNo.yes
          }
          /** おやつ注文 */
          let snackFlag: string = yesNo.no
          if (facility.snackAcceptFlag === yesNo.yes) {
            snackFlag = entryInput?.snackFlag ?? facility.snackInitialSelect ?? yesNo.yes
          }
          /** 予約が取れなかった場合項目 */
          let postponeCancelWaitFlag: string = yesNo.no
          if (isShownPostponeCancelWaitItem(facility)) {
            if (entryInput) {
              postponeCancelWaitFlag = entryInput.postponeCancelWaitFlag
            } else if (
              reserveEntry.reservationNo == null &&
              reserveEntry.usageDatetimes[0].status === availabilityStatus.wait
            ) {
              // 入力項目が表示されていて 新規キャンセル待ち予約なら
              // 「キャンセル待ちする」を初期選択
              postponeCancelWaitFlag = yesNo.yes
            }
          }

          const batchUpdater = new ReactBatchUpdater()

          batchUpdater.add(() =>
            formMethods.reset({
              ...formMethods.getValues(),
              ...undefinedPropsToOptional({
                purposeOfUse: entryInput?.useReasonCategory,
                lunchFlag,
                snackFlag,
                postponeCancelWaitFlag,
                note: entryInput?.citizenNote,
              }),
            })
          )
          batchUpdater.add(() =>
            dispatch(
              setFacilityReserve({
                ...reserveEntry,
                // 施設情報
                facility: {
                  facilityName: facility.facilityName,
                  immediatelyReservationFlag: facility.immediatelyReservationFlag,
                  lunchAcceptFlag: facility.lunchAcceptFlag,
                  snackAcceptFlag: facility.snackAcceptFlag,
                  postponeCancelWaitAcceptFlag: facility.postponeCancelWaitAcceptFlag,
                },
                // 保護者お子さま情報
                parentChild: {
                  name: user.name,
                  kana: user.kana,
                  tel: user.tel,
                  email: user.email,
                  childName: child.name,
                  childKana: child.kana,
                  childGender: child.gender,
                  childBirth: fromApiYmd(child.birthday).getTime(),
                  childAllergyFlag: child.allergyFlag,
                  residenceCategory: user.residenceCategory,
                },
              })
            )
          )
          batchUpdater.addSetter(setState, { facility })

          batchUpdater.execute()
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    reserveEntry,
    reservationReference,
    facility: state?.facility,
    isShownPostponeCancelWaitItem: isShownPostponeCancelWaitItem(state?.facility),
    formMethods,
    onSubmit,
  }
}

//------------------------------------------------------------------------------------------
const isShownPostponeCancelWaitItem = (facility: NullPropsToUndefinedType<GetFacilityDto> | undefined) => {
  if (facility) {
    return facility.immediatelyReservationFlag === yesNo.no && facility.postponeCancelWaitAcceptFlag === yesNo.yes
  } else {
    return false
  }
}

async function getUserWithChild(facilityId: string, childId: string, targetDate: Date) {
  const [facility, user, childResponse] = await Promise.all([
    getFacility(facilityId, targetDate),
    getUser(),
    executeGetChild(childId),
  ])
  return {
    facility,
    user,
    child: nullPropsToUndefined(childResponse.result),
  }
}
