import { Box, Stack, styled } from '@mui/material'
import { facilityDetailUrl } from '../../containers/common/constant/appUrl'
import { reservationChangeRestriction, yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityReservationSelection/facilityReservationSelectionService'
import { translate } from '../../i18n'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { GContainer, GItem, MiddleGItem, RowSpacerGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import {
  AvailabilityCalendar,
  calendarChangeRestriction,
  CalendarChangeRestrictionType,
} from '../components/common/inputs/availabilityCalendar'
import { DatePickerNoBind } from '../components/common/inputs/datePickerNoBind'
import { Form } from '../components/common/inputs/form'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { StepBar } from '../components/common/stepBar'
import { TitleLabel } from '../components/common/titleLabel'
import { OperationId } from '../../containers/common/constant/operationLog'

const FacilityNoteCard = styled(InfoCard)({
  whiteSpace: 'pre-wrap',
})

/** 施設の予約変更制限値とカレンダーコントロールの変更制限値のマッピング */
const reservationChangeRestrictionToCalendarChangeableMap: Readonly<Record<string, CalendarChangeRestrictionType>> = {
  [reservationChangeRestriction.allowChangeDatetime]: calendarChangeRestriction.datetime,
  [reservationChangeRestriction.allowChangeTimeOnly]: calendarChangeRestriction.time,
  [reservationChangeRestriction.notAllowChangeDatetime]: calendarChangeRestriction.none,
} as const

export const FacilityReservationSelection = () => {
  const {
    facilityId,
    facility,
    childs,
    activeChildId,
    activeDate,
    availabilities,
    initialValidationAvailabilities,
    previousRange,
    formMethods,
    onSubmit,
    onNextPrevExec,
    changeChild,
    changeDate,
  } = useAction()

  if (facilityId == null || facility == null) {
    return null
  }

  let usageGuideStr: string
  if (facility.immediatelyReservationFlag === yesNo.yes) {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.1'
    } else {
      usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.2'
    }
  } else {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.3'
      } else {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.5'
      }
    } else {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.4'
      } else {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.6'
      }
    }
  }

  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Box>
            <GContainer rowSpacing={1}>
              <GItem xs={12}>
                <StepBar
                  activeStepValue="①"
                  stepValueLabels={[
                    { value: '①', label: '利用希望日' },
                    { value: '②', label: '必要事項入力' },
                    { value: '③', label: '入力内容確認' },
                    { value: '④', label: '完了' },
                  ]}
                />
              </GItem>

              <GItem xs={12}>
                <Link to={facilityDetailUrl.url(facilityId)} operationLog={{ operationId: OperationId.OP_00000027 }}>
                  <TitleLabel>{facility.facilityName}</TitleLabel>
                </Link>
              </GItem>

              <GItem xs={12}>
                <GContainer>
                  <MiddleGItem xs={5} md={4}>
                    {translate('facilityReservationSelection.label.selectionChild')}
                  </MiddleGItem>
                  <MiddleGItem xs={7} md={8}>
                    <SelectNoBind
                      fullWidth
                      name="childId"
                      onChange={changeChild}
                      valueLabels={childs}
                      value={activeChildId}
                    />
                  </MiddleGItem>
                </GContainer>

                <GContainer>
                  <MiddleGItem xs={5} md={4}>
                    {translate('facilityReservationSelection.label.selectTheDesiredDateOfUse')}
                  </MiddleGItem>
                  <MiddleGItem xs={7} md={8}>
                    <DatePickerNoBind fullWidth value={activeDate} onChange={changeDate} />
                  </MiddleGItem>
                  <RowSpacerGItem />
                </GContainer>
              </GItem>

              <GItem xs={12}>
                <Stack spacing={2}>
                  <AnnotationLabel>{translate(usageGuideStr)}</AnnotationLabel>

                  <AvailabilityCalendar
                    name="usageDesiredDatetimes"
                    label={translate('facilityReservationSelection.label.availabilityCalendar')}
                    required
                    baseDate={activeDate}
                    previousRange={previousRange}
                    receptionTimePattern={facility.receptionTimePattern}
                    availabilities={availabilities ?? []}
                    initialValidationAvailabilities={initialValidationAvailabilities}
                    isMultiple={previousRange == null && facility.citizenBulkReservationAcceptFlag === yesNo.yes}
                    onChangeBaseDate={onNextPrevExec}
                    control={formMethods.control}
                    changeRestriction={
                      reservationChangeRestrictionToCalendarChangeableMap[facility.reservationChangeRestriction]
                    }
                  />
                </Stack>
              </GItem>
              <GItem xs={12}>
                <FacilityNoteCard>{facility.note}</FacilityNoteCard>
              </GItem>
            </GContainer>
          </Box>
          <div>{translate('facilityReservationSelection.label.annotation')}</div>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>

      <Form onSubmit={onSubmit} formMethods={formMethods}>
        <BottomButtonGroup>
          <ButtonOutlinedWarningL type="submit" fullWidth>
            {translate('facilityReservationSelection.button.applyOnTheSelectedSchedule')}
          </ButtonOutlinedWarningL>
          <BackButton />
        </BottomButtonGroup>
      </Form>
    </Stack>
  )
}
