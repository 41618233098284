import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ValueType } from '../../../../utils/typeUtil'
import { accountChangeVerificationCategory } from '../../constant/classification'

interface AccountChangeEntryBase {
  category: ValueType<typeof accountChangeVerificationCategory>
}
interface AccountEmailChangeEntry extends AccountChangeEntryBase {
  category: '1'
  email: string
}
interface AccountTelChangeEntry extends AccountChangeEntryBase {
  category: '2'
  tel: string
}
interface AccountPasswordChangeEntry extends AccountChangeEntryBase {
  category: '3'
  password: string
  newPassword: string
}

type AccountChangeEntry = AccountEmailChangeEntry | AccountTelChangeEntry | AccountPasswordChangeEntry

interface AccountChangeState {
  entry?: AccountChangeEntry
}

const initialState: AccountChangeState = {}

const accountChangeSlice = createSlice({
  name: 'accountChange',
  initialState,
  reducers: {
    setAccountChange: (state, action: PayloadAction<AccountChangeEntry>) => {
      state.entry = action.payload
    },
    clearAccountChange: (state) => {
      delete state.entry
    },
  },
})

export const { setAccountChange, clearAccountChange } = accountChangeSlice.actions
export const selectAccountChangeEntry = (state: RootState) => state.accountChange.entry
export default accountChangeSlice.reducer
