import { Redirect } from 'react-router'

interface IdentityRedirectProps {
  from?: Location
  toPathname: string
}
export const IdentityRedirect = (props: IdentityRedirectProps) => {
  const { from, toPathname } = props

  return from?.pathname === toPathname ? (
    <Redirect to={from} />
  ) : (
    <Redirect
      to={{
        pathname: props.toPathname,
        state: { from },
      }}
    />
  )
}
