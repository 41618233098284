import { Stack } from '@mui/material'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { informationDetailUrl } from '../../../containers/common/constant/appUrl'
import { formatYmdHmWeek } from '../../../utils/dateUtil'
import { AnnotationLabel } from './annotationLabel'
import { Card } from './card'

interface CityFacilityInformationCardProps {
  informaionNo: string
  publicationStartDatetime: Date
  facilityName: string
  title: string
}

/**
 * 市、施設からのお知らせ概要を表示するカードコントロール。
 * HOME、お知らせ一覧画面で使うためcommonに配置している
 */
export const CityFacilityInformationCard = (props: CityFacilityInformationCardProps) => {
  const history = useHistory()
  const onClick = useCallback(() => history.push(informationDetailUrl.url(props.informaionNo)), [props.informaionNo])
  return (
    <Card onClick={onClick}>
      <Stack>
        <div>
          <AnnotationLabel>{formatYmdHmWeek(props.publicationStartDatetime)}</AnnotationLabel>
        </div>
        <div>【{props.facilityName}】</div>
        <div>{props.title}</div>
      </Stack>
    </Card>
  )
}
