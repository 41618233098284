// 画面メインコンテンツエリア
//

import { styled } from '@mui/material'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import {
  selectBottomActionContainerHeight,
  selectIsHiddenMain,
  selectPxPerVh,
} from '../../../containers/common/store/slices/application'

interface MainProps {
  children: ReactNode
  className?: string
}

const Root = styled('main', {
  shouldForwardProp: (prop) => prop !== 'pxPerVh' && prop !== 'bottomContainerHeight' && prop !== 'isHidden',
})<{
  pxPerVh: number
  bottomContainerHeight: number
  isHidden: boolean
}>(({ theme, pxPerVh, bottomContainerHeight, isHidden }) => ({
  position: 'relative',
  flexGrow: 1,
  padding: theme.appMain.padding,
  paddingBottom: theme.appMain.padding + bottomContainerHeight,
  minHeight: `calc(${pxPerVh}px * 100 - ${theme.typography.pxToRem(theme.appFooter.height('xs'))})`,
  [theme.breakpoints.up('sm')]: {
    minHeight: `calc(${pxPerVh}px * 100 - ${theme.typography.pxToRem(theme.appFooter.height('sm'))})`,
  },
  ...(isHidden && { visibility: 'hidden' }),
}))

/**
 * 画面メインコンテンツエリア。
 * このコントロールの子に各画面のページコントロールが配置される。
 *
 * ■ minHeight を指定する目的
 * ページコンテンツの高さが画面一杯より遥かに低い場合、
 * 画面共通フッター(AppFooter)がページコンテンツにくっついて表示されることを防ぐため
 *
 * ■ paddingBottomにbottomContainerHeightを追加している目的
 * メインコンテンツエリア下部に表示する「登録」「戻る」などのアクションコントロールを格納する部分は
 * このメインコンテンツエリアに対して絶対位置で下部に固定されて表示する為、
 * 下部アクションコンテナ分のパディングを追加している。
 *
 * ※positionを以下のように設定して下部固定
 * <AppMain> *(position: 'relative')
 *   <BottomActionContainer> *(position: 'absolute', bottom:<mainの本来のpadding>)
 */
export const AppMain = (props: MainProps) => {
  const { className } = props
  const pxPerVh = useSelector(selectPxPerVh)
  const bottomContainerHeight = useSelector(selectBottomActionContainerHeight)
  const isHidden = useSelector(selectIsHiddenMain)

  return (
    <Root
      {...{
        className,
        pxPerVh,
        bottomContainerHeight,
        isHidden,
      }}
    >
      {props.children}
    </Root>
  )
}
