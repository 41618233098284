import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/accountPasswordChange/accountPasswordChangeService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'

/**
 * TODO: 2022/02/25 日夏 パスワード変更にてSMS認証OK後に新パスワード変更を適用するフローがcogniteで実現出来ないため、一旦変更機能を凍結
 * ログイン画面のパスワードリセットを案内して入り口をふさぐ仮対応とする
 */
export const AccountPasswordChange = () => {
  const { formMethods, onSubmit } = useAction()
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={4}>
        <Grid container>
          <Grid item md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <AttentionLabel>*</AttentionLabel>
                  {translate('accountPasswordChange.label.currentPassword')}：
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    name="currentPassword"
                    label={translate('accountPasswordChange.label.currentPassword')}
                    textType="password"
                    maxLength={20}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <AttentionLabel>*</AttentionLabel>
                  {translate('accountPasswordChange.label.newPassword')}：
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    name="newPassword"
                    label={translate('accountPasswordChange.label.newPassword')}
                    textType="password"
                    maxLength={20}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <AttentionLabel>*</AttentionLabel>
                  {translate('accountPasswordChange.label.newPasswordConfirmation')}：
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    name="newPasswordConfirmation"
                    label={translate('accountPasswordChange.label.newPasswordConfirmation')}
                    textType="password"
                    maxLength={20}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {translate('accountPasswordChange.text.passwordPattern')}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>

        <BottomButtonGroup>
          <ButtonOutlinedWarningL fullWidth type="submit">
            {translate('accountPasswordChange.button.change')}
          </ButtonOutlinedWarningL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
