import React from 'react'
import { BreakpointSwitch } from '../breakpointSwitch'
import { ButtonProps } from './buttonBase'
import { ButtonOutlinedL } from './buttonOutlinedL'
import { ButtonOutlinedS } from './buttonOutlinedS'

/**
 * whiteカラーのボタンコントロールをxs,mdでS,Lサイズの切り替えを行うコントロール。
 */
export const ButtonOutlinedResponsive = (props: ButtonProps) => {
  const { children, ...through } = props
  return (
    <BreakpointSwitch
      xs={<ButtonOutlinedS {...through}>{children}</ButtonOutlinedS>}
      md={
        <ButtonOutlinedL {...through} fullWidth>
          {children}
        </ButtonOutlinedL>
      }
    />
  )
}
