import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/interviewReservationDetail/interviewReservationDetailService'
import { translate } from '../../i18n'
import { AlertCard } from '../components/common/alertCard'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { InfoCard } from '../components/common/infoCard'
import { InterviewDetailContent } from '../components/common/reservationDetail/interviewDetailContent'

export const InterviewReservationDetail = () => {
  const { interviewDetail } = useAction()
  if (interviewDetail == null) {
    return null
  }
  return (
    <Stack spacing={2}>
      <Grid container rowSpacing={2}>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <AlertCard>・{translate('interviewReservationDetail.card.inquiry')}</AlertCard>

            <InfoCard>
              <Grid container>
                <InterviewDetailContent interview={interviewDetail} displayType="reference" />
              </Grid>
            </InfoCard>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
