import React from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { IconProps } from './iconBase'

/**
 * プラスマークアイコン
 */
export const PlusCircleOutlineIcon = (props: IconProps) => {
  return <AddCircleOutlineIcon {...props} />
}
