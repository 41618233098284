import { styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface SizeLabelBaseProps {
  children: ReactNode
  className?: string
  isFontWeightRegular?: boolean
}

const Root = styled('span', { shouldForwardProp: (prop) => prop !== 'isFontWeightRegular' })<{
  isFontWeightRegular?: boolean
}>(({ theme, isFontWeightRegular }) => ({
  ...(isFontWeightRegular && {
    fontWeight: theme.typography.fontWeightRegular,
  }),
}))

export const SizeLabelBase = (props: SizeLabelBaseProps) => {
  return (
    <Root isFontWeightRegular={props.isFontWeightRegular} className={props.className}>
      {props.children}
    </Root>
  )
}
