import { Alert, Snackbar, styled } from '@mui/material'
import { memo, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMessage } from '../../../containers/common/store/slices/application'

const Root = styled(Snackbar)(({ theme }) => ({
  top: '64px',
  [theme.breakpoints.up('sm')]: {
    '&.MuiSnackbar-root': {
      top: '70px',
    },
  },
}))

/** デフォルト自動非表示期間 */
const defaultAutoHideDuration = 3000

export const ToastNotification = memo(function ToastNotification() {
  const message = useSelector(selectMessage)
  const [isOpen, setIsOpen] = useState(false)

  // messageを空にするとhideのtransition中に幅が変わって見栄えが悪い為
  // messageはそのままにして非表示化を行う。
  useEffect(() => {
    setIsOpen(!!message)
  }, [message?.timestamp])

  const closeMessage = useCallback((event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      // 何処かのボタンクリックでメッセージを閉じない
      return
    }
    setIsOpen(false)
  }, [])

  return (
    <Root
      key={message?.timestamp}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={message?.autoHideDuration ?? defaultAutoHideDuration}
      onClose={closeMessage}
    >
      <Alert onClose={closeMessage}>{message?.content}</Alert>
    </Root>
  )
})
