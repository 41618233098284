import { AuthState, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { translate } from '../../i18n'
import { verifyTel } from '../../utils/authUtil'
import { isTupleValue } from '../../utils/typeUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { notifyMessage } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import {
  selectIsLoggedIn,
  selectIsVerifiedEmail,
  selectIsVerifiedTel,
  setLogin,
} from '../common/store/slices/authority'
import { Hub } from 'aws-amplify'


interface LocationState {
  from: Location
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const { ssoUseFlag, ssoUseName } = useSelector(selectSystemControl)

  const from = location.state?.from

  useEffect(() => {
    return Hub.listen("auth", async ({ payload: { event } }) => {
      if (event === "forgotPasswordSubmit") {
        dispatch(notifyMessage(translate('login.success.paswordUpdate')))
      }
    })
  }, [])

  useEffect(() => {
    // iOSの場合、onAuthUIStateChangeがSignedInで２回呼び出されてしまう。
    // 参照：
    // https://github.com/aws-amplify/amplify-js/issues/7635
    // https://github.com/aws-amplify/amplify-js/issues/6330
    //
    // 根本的な原因はwebpack4内部の話にまで波及しているようなので、根本解決は困難。
    // よって、2重の検証コード送信を防ぐためにアプリ側でフラグ対処
    let isCalldVerify = false

    return onAuthUIStateChange((nextAuthState, authData) => {
      const user = authData as CognitoUserInterface | undefined
      if (isTupleValue([AuthState.SignedIn, AuthState.VerifyContact], nextAuthState) && user && user.username) {
        const isVerifiedEmail = !!user.attributes.email_verified
        const isVerifiedTel = !!user.attributes.phone_number_verified
        dispatch(
          setLogin({
            loginId: user.attributes.email,
            verifications: { tel: isVerifiedTel, email: isVerifiedEmail },
          })
        )
        if (!isCalldVerify && isVerifiedEmail && !isVerifiedTel) {
          // メールアドレスの検証が実施済み かつ
          // 電話番号の検証が未実施の場合はワンタイムパスワードを発行し、
          // ワンタイムパスワード確認画面へ遷移
          errorHandle(async () => {
            isCalldVerify = true
            await verifyTel()
            dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
          })()
        }
      } else {
        dispatch(setLogin())
      }
    })
  }, [])

  return {
    isLoggedIn,
    isVerifiedEmail,
    isVerifiedTel,
    from,
    ssoUseFlag,
    ssoUseName,
  }
}
