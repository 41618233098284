import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { executeGetAccount, executePutAccountSsoLinkUnlink } from '../../dataAccess/webApi/dao/accountDao'
import { GetAccountDto } from '../../dataAccess/webApi/dto/accountDto'
import { signOut } from '../../utils/authUtil'
import { loginUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { reset, showLoading } from '../common/store/slices/application'
import { selectIsVerifiedAll, selectIsVerifiedEmail, selectIsVerifiedTel } from '../common/store/slices/authority'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { translate } from '../../i18n'

interface DialogParam {
  isOpen: boolean
  onClose: () => void,
  message?: string,
}

const DIALOG_PARAM_DEFAULT_VALUE: DialogParam = {
  isOpen: false,
  onClose: () => {},
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()
  const sysCtrl = useSelector(selectSystemControl)

  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedAll = useSelector(selectIsVerifiedAll)
  const { ssoUseFlag, ssoUseName } = useSelector(selectSystemControl)

  const [account, setAccount] = useState<GetAccountDto>()
  const [dialog, setDialog] = useState<DialogParam>(DIALOG_PARAM_DEFAULT_VALUE)

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes }] })
    
    dispatch(showLoading(errorHandle(() => initialize())))

    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [])
  
  const initialize = useCallback(
    async () => {
      const response = await  executeGetAccount()
      setAccount(response.result)
    },
    [account]
  )
    
  const logout = useCallback(() => {
    dispatch(
      showLoading(
        errorHandle(async () => {
          await signOut()
          dispatch(reset({ isKeepAuthority: false }))
          history.push(loginUrl.url())
        })
      )
    )
  }, [dispatch, errorHandle, history])
  
  //SSO連携解除
  const unlink = useCallback(async() => {
    
    try {
      await executePutAccountSsoLinkUnlink()
      
      setDialog({
        isOpen: true,
        message: translate('account.success.unlinkCompletionMessage'),
        onClose: () => {onCloseComplateDialog()},
      })
      
    } catch {
      setDialog({
        isOpen: true,
        message: translate('account.error.unlinkErrorMessage'),
        onClose: () => {setDialog(DIALOG_PARAM_DEFAULT_VALUE)},
      })
    }
  }, [])
  
  const onCloseComplateDialog = useCallback(async() => {
    setDialog(DIALOG_PARAM_DEFAULT_VALUE)
    // 連携解除後ログアウトしないと再連携でエラーになる為、ログアウトする
    logout()
  }, [])

  return { sysCtrl, isVerifiedTel, isVerifiedEmail, isVerifiedAll, account, logout, ssoUseFlag, ssoUseName, unlink, dialog }
}
