import { v4 as uuidV4 } from 'uuid'

/** WindowIDのsessionストレージ保存キー */
const WINDOW_ID_STORE_KEY_NAME = 'operation_log_window_id'
/** SSOで連携、ログイン後に遷移する先のsessionストレージ保存キー */
const SSO_LOGIN_REDIRECT_TO_KEY_NAME = 'sso_login_redirect_to'
/** ログイン済でSSOで連携後に遷移する先のsessionストレージ保存キー */
const SSO_LINK_REDIRECT_TO_KEY_NAME = 'sso_link_redirect_to'

let windowId: string | undefined = undefined

export const getWindowId = () => {
  if (windowId == null) {
    windowId = uuidV4()
  }
  return windowId
}

export const backupWindowId = () => {
  if (windowId != null) {
    window.sessionStorage.setItem(WINDOW_ID_STORE_KEY_NAME, windowId)
  }
}

export const restoreWindowId = () => {
  const id = window.sessionStorage.getItem(WINDOW_ID_STORE_KEY_NAME)
  if (id != null) {
    windowId = id
    // タブ複製、新しいタブで開く等のsessionStorageコピーで引き継がれないよう
    // 復元後は削除
    window.sessionStorage.removeItem(WINDOW_ID_STORE_KEY_NAME)
  }
}


export const saveSsoLoginRedirectTo = (to: string) => {
  window.sessionStorage.setItem(SSO_LOGIN_REDIRECT_TO_KEY_NAME, to)
}

export const loadDeleteSsoLoginRedirectTo = () => {
  const to = window.sessionStorage.getItem(SSO_LOGIN_REDIRECT_TO_KEY_NAME)
  window.sessionStorage.removeItem(SSO_LOGIN_REDIRECT_TO_KEY_NAME)
  return to
}

export const saveSsoLinkRedirectTo = (to: string) => {
  window.sessionStorage.setItem(SSO_LINK_REDIRECT_TO_KEY_NAME, to)
}

export const loadDeleteSsoLinkRedirectTo = () => {
  const to = window.sessionStorage.getItem(SSO_LINK_REDIRECT_TO_KEY_NAME)
  window.sessionStorage.removeItem(SSO_LINK_REDIRECT_TO_KEY_NAME)
  return to
}