import { Stack, styled } from '@mui/material'
import React, { ReactNode } from 'react'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { GContainer, GItem, GItemContainer, LabelGItemBase, ValueGItemBase } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'


// import { changeIsLoading } from 'containers/common/isLoading'
// import { executeApi } from '../../dataAccess/webApi/dao/commonDao'
// import { ContactData, ContactDto } from '../../dataAccess/webApi/dto/contactDto'
// import { GuideTextDto, GuideTextData } from '../../dataAccess/webApi/dto/guideTextDto'
// import { useEffect, useRef, useState } from 'react'
// import { useDispatch } from 'react-redux'
// import { useHistory, useParams } from 'react-router'
import { useAction } from '../../containers/contact/contactService'

const ContentBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

const LabelGItem = (props: { children?: ReactNode }) => (
  <LabelGItemBase xs={4} md={2}>
    {props.children}
  </LabelGItemBase>
)
const ValueGItem = (props: { children: ReactNode }) => (
  <ValueGItemBase xs={8} md={10}>
    {props.children}
  </ValueGItemBase>
)

export const Contact = () => {
  const { contactList, guideText } = useAction()
  
  if(contactList == null){
    
  }
  if(guideText == null){
    
  }
  console.log("contactList", contactList);
  console.log("guideText", guideText);
  
  
  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Stack spacing={2}>
            {/*
            <div>{translate('contact.text.mainMessage')}</div>

            <ContentBox>{translate('contact.text.subMessage')}</ContentBox>
            */}
            <ContentBox>{guideText?.guideTextContent}</ContentBox>
            
            {/*
            <InfoCard>
              <GContainer>
                <GItem xs={12}>＜{translate('contact.label.contact')}＞</GItem>
                <GItem xs={12}>{translate('contact.label.division')}</GItem>
                <GItemContainer xs={12}>
                  <LabelGItem>{translate('contact.label.tel')}</LabelGItem>
                  <ValueGItem>045-671-3564</ValueGItem>
                </GItemContainer>
                <GItemContainer xs={12}>
                  <LabelGItem>{translate('contact.label.fax')}</LabelGItem>
                  <ValueGItem>045-664-5479</ValueGItem>
                </GItemContainer>
                <GItemContainer xs={12}>
                  <LabelGItem>{translate('contact.label.email')}</LabelGItem>
                  <ValueGItem>kd-ichijiyoyaku@city.yokohama.jp</ValueGItem>
                </GItemContainer>
              </GContainer>
            </InfoCard>
            */}
            
            {contactList && contactList.map((contact) => (
            
              <InfoCard key={contact.contactId}>
                <GContainer>
                  <GItem xs={12}>＜{translate('contact.label.contact')}＞</GItem>
                  <GItem xs={12}>{contact.contactName}</GItem>
                  
                  {contact.tel && (contact.tel !== "") &&
                    <GItemContainer xs={12}>
                      <LabelGItem>{translate('contact.label.tel')}</LabelGItem>
                      <ValueGItem><a href={"tel:" + contact.tel}>{contact.tel}</a></ValueGItem>
                    </GItemContainer>
                  }
                  
                  {contact.fax && (contact.fax !== "") &&
                    <GItemContainer xs={12}>
                      <LabelGItem>{translate('contact.label.fax')}</LabelGItem>
                      <ValueGItem>{contact.fax}</ValueGItem>
                    </GItemContainer>
                  }
                  
                  {contact.email && (contact.email !== "") &&
                    <GItemContainer xs={12}>
                      <LabelGItem>{translate('contact.label.email')}</LabelGItem>
                      <ValueGItem><a href={"mailto:" + contact.email + "?subject=" + encodeURIComponent(contact.subject || "") + "&body=" + encodeURIComponent(contact.body || "")}>{contact.email}</a></ValueGItem>
                    </GItemContainer>
                  }
                  
                  {((contact.subject && (contact.subject !== "")) || (contact.body && (contact.body !== ""))) &&
                    <details style={{"marginTop": "10px", "marginLeft": "120px"}}>
                      <summary>お問い合わせメールの例</summary>
                      <div style={{"border": "1px solid silver", "padding":"10px"}}>
                        {contact.subject && (contact.subject !== "") &&
                          <div style={{"marginBottom": "20px"}}>件名：{contact.subject}</div>
                        }
                        {contact.body && (contact.body !== "") &&
                          <div style={{"whiteSpace": "pre-wrap"}}>本文：<br />{contact.body}</div>
                        }
                      </div>
                    </details>
                  }
                </GContainer>
              </InfoCard>
            /*
              <Grid key={link.linkId} item xs={12}>
                <Link href={link.linkUrl || ""} target="_blank">
                  {link.linkName}
                </Link>
              </Grid>
            */
            ))}
            
            
          </Stack>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
