import { Dialog } from '../components/common/dialog'
import { ButtonS } from '../components/common/buttons/buttonS'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { useAction } from '../../containers/sso/ssoService'
import { styled } from '@mui/material'

const DialogContentText = styled('div')({
  whiteSpace: 'pre-line',
  textAlign: 'center',
})

export const Sso = () => {
  const {
    dialog
  } = useAction()
  
  return (
    <Dialog
      isOpen={dialog.isOpen}
      onClose={dialog.onClose}
      buttons={
        dialog.isButtonOutlined
          ? <ButtonOutlinedS onClick={dialog.onClose}>{dialog.buttonLabel}</ButtonOutlinedS>
          : <ButtonS onClick={dialog.onClose}>{dialog.buttonLabel}</ButtonS>
      }
      maxWidth={dialog.isButtonOutlined ? 'xs' : 'sm'}
      fullWidth
    >
      <DialogContentText>
        {dialog.message}
      </DialogContentText>
    </Dialog>
  )
}