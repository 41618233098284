import { DateRange, isIncludeDate } from '../../utils/dateUtil'
import { numberPropsToDate } from '../../utils/objectUtil'
import { yesNo } from './constant/classification'
import { SystemControl } from './store/slices/systemControl'

export const canUseCouponFunctionFromSysCtrl = (sysCtrl: SystemControl, baseDate: Date) => {
  const { useCouponFunctionFlag, usageCouponFromDate, usageCouponToDate } = sysCtrl

  if (useCouponFunctionFlag !== yesNo.yes) {
    // クーポン機能オフなら利用不可
    return false
  }
  if (usageCouponFromDate != null && usageCouponToDate != null) {
    const usageCouponRange: DateRange = numberPropsToDate({ from: usageCouponFromDate, to: usageCouponToDate }, [
      'from',
      'to',
    ])
    if (!isIncludeDate(baseDate, usageCouponRange)) {
      // クーポン利用期間外なら利用不可
      return false
    }
  }

  return true
}
