import { SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeGetCouponsIssues } from '../../dataAccess/webApi/dao/couponsDao'
import { executeGetReservations } from '../../dataAccess/webApi/dao/reservationsDao'
import { executeGetUsageRecords } from '../../dataAccess/webApi/dao/usageRecordsDao'
import { formatYmd, fromApiYmd, fromApiYmdHms, getNowTrimedTime } from '../../utils/dateUtil'
import { nullPropsToUndefined, undefinedPropsToNull } from '../../utils/objectUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { ChildrenChoice, getChildrenChoices } from '../common/child'
import { yesNo } from '../common/constant/classification'
import { canUseCouponFunctionFromSysCtrl } from '../common/coupon'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

interface LocationState {
  /** アクティブなお子さまIDの値 */
  childId?: string
  /** アクティブなタブの値 */
  tabValue?: string
}

interface Reservation {
  reservationNo: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  facilityName: string
  childName: string
  status: string
}
interface UsageHistory {
  reservationNo: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  facilityName: string
  childName: string
  status: string
  usageMinute?: string
}
interface CouponsIssues {
  couponsIssuedFlag: string
  totalLimitUsageMinute: number
  totalTotalBalanceMinute: number
  couponlimitList: CouponLimit[]
}
interface CouponLimit {
  limitDate: string
  totalBalanceMinute: string
}
interface PageState {
  activeChildId?: string
  activeTabValue: string
  /**
   * 利用予約。
   * 予約側表示時のみ設定。
   */
  reservations?: Reservation[]
  /**
   * 利用履歴。
   * 実績側表示時のみ設定。
   */
  usageHistorys?: UsageHistory[]
  childs: ChildrenChoice[]
  couponsIssues?: CouponsIssues
}

/** タブの選択値 */
export const tabValue = {
  reservation: '1',
  usageRecord: '2',
} as const

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { addOperationLog } = useOperationLog()

  const locationState = history.location.state
  const sysCtrl = useSelector(selectSystemControl)
  const [state, setState] = useState<PageState>({
    activeChildId: locationState?.childId,
    activeTabValue: locationState?.tabValue ?? tabValue.reservation,
    childs: [],
  })
  const [showCouponFunctionFlag, setShowCouponFunctionFlag] = useState<boolean>(false)

  const load = useCallback(
    (
      isInitialize: boolean,
      loadedChilds: { value: string; label: string }[],
      activeTabValue: string,
      activeChildId?: string
    ) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            let callGetChildren
            let callGetReservations
            let callGetUsageHistorys
            if (isInitialize) {
              callGetChildren = getChildrenChoices()
            }
            if (activeTabValue === tabValue.reservation) {
              // クーポン利用期間外なら利用対象予約なしとする
              callGetReservations = canUseCouponFunctionFromSysCtrl(sysCtrl, getNowTrimedTime())
                ? getReservations(activeChildId)
                : []
            } else {
              callGetUsageHistorys = getUsageHistorys(activeChildId)
            }
            const callGetCouponsIssues = getCouponsIssue()

            const [childs, reservations, usageHistorys, couponsIssues] = await Promise.all([
              callGetChildren ?? loadedChilds,
              callGetReservations,
              callGetUsageHistorys,
              callGetCouponsIssues,
            ])

            if (sysCtrl.useCouponFunctionFlag === yesNo.yes && couponsIssues.couponsIssuedFlag === yesNo.yes) {
              //クーポン画面表示が可能
              setShowCouponFunctionFlag(true)
            }

            setState({
              activeChildId,
              activeTabValue,
              reservations,
              usageHistorys,
              childs,
              couponsIssues,
            })
          }),
          isHiddenMain: isInitialize,
        })
      )
    },
    [dispatch, errorHandle, sysCtrl]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    load(true, state.childs, state.activeTabValue, state.activeChildId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 戻るで表示した際にアクティブなタブなどを復元する為に履歴に保管
    history.replace({ ...history.location, state: { childId: state.activeChildId, tabValue: state.activeTabValue } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeChildId, state.activeTabValue])

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      load(false, state.childs, state.activeTabValue, event.target.value)
    },
    [state, load, addOperationLog]
  )

  const changeTab = useCallback(
    (value: string) => {
      if (value === tabValue.reservation) {
        addOperationLog({ operationId: OperationId.OP_00000062 })
      } else if (value === tabValue.usageRecord) {
        addOperationLog({ operationId: OperationId.OP_00000063 })
      }

      load(false, state.childs, value, state.activeChildId)
    },
    [state, load, addOperationLog]
  )

  return {
    ...state,
    changeChild,
    changeTab,
    showCouponFunctionFlag,
  }
}

const getReservations = async (childId?: string) => {
  const response = await executeGetReservations(undefinedPropsToNull({ 
      childId: childId,
      couponUsageTargetFlag: yesNo.yes,
      confirmedOnlyGetFlag: yesNo.yes,
  }))
  return response.result.map((reservation) => {
    const { usageDate, useFromDatetime, useToDatetime, ...other } = reservation
    return nullPropsToUndefined({
      ...other,
      usageDate: fromApiYmd(usageDate),
      useFromDatetime: fromApiYmdHms(useFromDatetime),
      useToDatetime: fromApiYmdHms(useToDatetime),
    })
  })
}
const getUsageHistorys = async (childId?: string) => {
  const response = await executeGetUsageRecords(
    undefinedPropsToNull({ yyyymm: null, childId: childId, couponUsageTargetFlag: yesNo.yes })
  )
  return response.result.map((usageHistory) => {
    const { usageDate, useFromDatetime, useToDatetime, usageMinute, ...other } = usageHistory
    return nullPropsToUndefined({
      ...other,
      usageDate: fromApiYmd(usageDate),
      useFromDatetime: fromApiYmdHms(useFromDatetime),
      useToDatetime: fromApiYmdHms(useToDatetime),
      usageMinute: formatLocaleTimeAmountHm(usageMinute),
    })
  })
}
const getCouponsIssue = async () => {
  const response = await executeGetCouponsIssues()
  let totalLimitUsageMinute = 0
  let totalTotalBalanceMinute = 0
  response.result.forEach((coupon) => {
    totalLimitUsageMinute += coupon.limitUsageMinute
    totalTotalBalanceMinute += coupon.totalBalanceMinute
  })
  const couponlimitList = response.result.map((coupon) => {
    return {
      limitDate: formatYmd(fromApiYmd(coupon.limitDate)),
      totalBalanceMinute: formatLocaleTimeAmountHm(coupon.totalBalanceMinute),
    }
  })
  const couponsIssuedFlag = response.result.length > 0 ? yesNo.yes : yesNo.no
  return {
    couponsIssuedFlag,
    totalLimitUsageMinute: totalLimitUsageMinute,
    totalTotalBalanceMinute,
    couponlimitList,
  }
}
