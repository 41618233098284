import { Box, styled } from '@mui/material'
import { ReactNode } from 'react'

interface SubTitleProps {
  title: ReactNode
}

const Root = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
  letterSpacing: '0.2em',
  color: theme.palette.secondary.main,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
}))

export const SubTitle = (props: SubTitleProps) => {
  return <Root>{props.title}</Root>
}
