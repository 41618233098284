import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { executePostOperationLog, executePostOperationLogAccessData } from '../../dataAccess/webApi/dao/operationLogDao'
import { AccessData } from '../../dataAccess/webApi/dto/operationLogDto'
import { getNow, toApiYmdHmsS } from '../../utils/dateUtil'
import { getWindowId } from './sessionStore'
import { selectIsLoggedIn } from './store/slices/authority'

const getCurrentUrl = () => {
  const locationPath = window.location.pathname
  return locationPath.split('/', 2).join('/')
}

export interface OperationLogInput {
  operationId: string
  accessData?: AccessData[]
}

export interface OperationLogRecord extends OperationLogInput {
  windowId: string
  url: string
  operationDatetime: string
}

const sendingQueue: OperationLogRecord[] = []
let isSending = false
let latestAddOperationLogRecord: OperationLogRecord | undefined

const postOperationLog = async () => {
  const operations = sendingQueue.splice(0)
  if (operations.length === 0) {
    return
  }
  try {
    await executePostOperationLog({ operations })
  } catch (e) {
    // エラーでも処理続行
    console.error(e)
  }
}

const sendOperationLog = async () => {
  if (isSending) {
    return
  }
  isSending = true
  while (sendingQueue.length) {
    await postOperationLog()
  }
  isSending = false
}

/**
 * @returns 最後にaddOperationLogしたレコードの操作日時
 */
export const getLatestAddOperationDatetime = () => {
  return latestAddOperationLogRecord?.operationDatetime
}

export const useOperationLog = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const addOperationLog = useCallback(
    (logInput: OperationLogInput) => {
      if (!isLoggedIn) {
        // ログインしていない場合は記録しない
        return
      }

      const record: OperationLogRecord = {
        ...logInput,
        windowId: getWindowId(),
        url: getCurrentUrl(),
        operationDatetime: toApiYmdHmsS(getNow()),
      }
      // アクセスデータログ登録をその他APIで行う際に
      // 操作ログ紐づけ用のキー情報を取り出す目的に設定
      latestAddOperationLogRecord = record

      sendingQueue.push(record)
      return sendOperationLog()
    },
    [isLoggedIn]
  )

  const getOnClickAddOperationLog = useCallback(
    (logInput: OperationLogInput) => {
      return () => {
        addOperationLog(logInput)
      }
    },
    [addOperationLog]
  )

  const flushOperationLog = useCallback(() => {
    return postOperationLog()
  }, [])

  const attachAccessData = useCallback(
    async (accessInput: { operationDatetime?: string; accessData?: AccessData[] }) => {
      try {
        const operationDatetime = accessInput.operationDatetime ?? getLatestAddOperationDatetime()
        if (!operationDatetime) {
          console.error('operationDatetime none')
          return
        }
        await executePostOperationLogAccessData({ operationDatetime, accessData: accessInput.accessData })
      } catch (e) {
        // エラーでも処理続行
        console.error(e)
      }
    },
    []
  )

  return { addOperationLog, getOnClickAddOperationLog, flushOperationLog, attachAccessData }
}
