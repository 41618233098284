import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getNow } from '../../utils/dateUtil'
import { datePropsToNumber, numberPropsToDate, undefinedPropsToOptional } from '../../utils/objectUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import {
  getChild,
  ChildrenChoice,
  getChildrenChoices,
  getInterviewedChildrenChoices
} from '../common/child'
import { getFacility } from '../common/facility'
import { getInterviewStatusLabel } from '../common/codeMaster'
import { interviewReservationConfirmationUrl, facilityDetailUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { interviewStatus, permitFlag } from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { getAddressStringByPostalCode } from '../common/location'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import {
  selectInterviewReserveEntry,
  setInterviewReserve,
  setInterviewReserveUpdateDatetime,
} from '../common/store/slices/interviewReserve'
import { getUser } from '../common/user'

interface UrlParams {
  facilityId: string
  childId: string
}

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}

interface PageState {
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  uninterviewedChilds: ChildrenChoice[]
  childs: ChildrenChoice[]
  interviewedChildsAndStatus: ChildrenChoice[]
}

interface Inputs {
  parentName: string
  parentKana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber: string
  residenceCategory: string
  relationship: string
  childName: string
  childKana: string
  childGender: string
  childBirthday: Date
  childMedicalHistoryFlag: string
  childMedicalHistory: string
  childAllergyFlag: string
  childAllergy: string
  maternalHandbookNo: string
  note: string
  facilityNumber: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { facilityId } = useParams<UrlParams>()
  const { addOperationLog } = useOperationLog()

  const isKeep = !!history.location.state?.isKeep
  const reserveEntry = useSelector(selectInterviewReserveEntry)
  const childBirthdayLimitDate = getNow() // 誕生日入力の上限値

  const formMethods = useForm<Inputs>({
    defaultValues: {
      ...(isKeep && reserveEntry && numberPropsToDate(reserveEntry, ['childBirthday'])),
    },
  })

  const [userInterviewPermitCount, setUserInterviewPermitCount] = useState<number>(0)
  const [childInterviewPermitCount, setChildInterviewPermitCount] = useState<number>(0)
  const [isOpenInterviewChildSelect, setIsOpenInterviewChildSelect] = useState(false)
  const [state, setState] = useState<PageState>({ uninterviewedChilds: [], childs: [], interviewedChildsAndStatus: [] })
  const [childId, setChildId] = useState('')

  // 初期表示
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes, childId }] })

    if (!(isKeep && reserveEntry)) {
      dispatch(
        showLoading(
          errorHandle(async () => {
            // お子さま選択ダイアログの情報取得＆表示
            const facilityWithChilds = await getFacilityWithChilds(facilityId)
            setState(facilityWithChilds)
            setIsOpenInterviewChildSelect(true)
          })
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 初期設定
  const initialize = useCallback(
    (childId: string) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const {
              initialInputs,
              userInterviewPermitCount,
              childInterviewPermitCount,
              userUpdateDatetime,
              childUpdateDatetime,
            } = await getInitialInputs(childId)
            formMethods.reset({
              ...formMethods.getValues(),
              ...initialInputs,
            })
            dispatch(setInterviewReserveUpdateDatetime({ userUpdateDatetime, childUpdateDatetime }))
            setUserInterviewPermitCount(userInterviewPermitCount)
            setChildInterviewPermitCount(childInterviewPermitCount)
            setChildId(childId)
          }),
          isHiddenMain: false,
        })
      )
  },[dispatch, errorHandle, formMethods])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000025 })

      dispatch(
        setInterviewReserve({
          ...datePropsToNumber(data),
          facilityId,
          childId,
        })
      )
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      history.replace({ ...history.location, state: { isKeep: true } })
      history.push(interviewReservationConfirmationUrl.url())
    },
    [dispatch, history, facilityId, childId, addOperationLog]
  )

  const autoCompleteAddress = useCallback(async () => {
    const { postalCode } = formMethods.getValues()
    try {
      const address = await getAddressStringByPostalCode(postalCode)
      if (address) {
        formMethods.setValue('address1', address)
      }
    } catch {
      // 取得できなかった場合は何もしない
    }
  }, [formMethods])

  // お子さま選択ダイアログのボタン押下
  const onCloseInterviewChildSelect = useCallback(
    (isCancel: boolean, childId?: string) => {
      if (isCancel || !childId) {
        // 選択をキャンセルした場合
        setIsOpenInterviewChildSelect(false)
        history.push(facilityDetailUrl.url(facilityId))
      } else {
        // お子さまを選択した場合
        setIsOpenInterviewChildSelect(false)
        initialize(childId)
      }
    },
    [history, initialize]
  )

  return {
    formMethods,
    autoCompleteAddress,
    onSubmit,
    userInterviewPermitCount,
    childInterviewPermitCount,
    childBirthdayLimitDate,
    isOpenInterviewChildSelect,
    onCloseInterviewChildSelect,
    ...state,
  }
}

const getInitialInputs = async (childId: string) => {
  const [user, child] = await Promise.all([getUser(), getChild(childId)])
  return {
    initialInputs: undefinedPropsToOptional({
      parentName: user.name,
      parentKana: user.kana,
      postalCode: user.postalCode,
      address1: user.address1,
      address2: user.address2,
      buildingNameRoomNumber: user.buildingNameRoomNumber,
      residenceCategory: user.residenceCategory,
      relationship: user.relationship,

      childName: child.name,
      childKana: child.kana,
      childGender: child.gender,
      childBirthday: child.birthday,
      maternalHandbookNo: child.maternalHandbookNo,
    }),
    userInterviewPermitCount: user.interviewPermitCount,
    childInterviewPermitCount: child.interviewPermitCount,
    userUpdateDatetime: user.updateDatetime,
    childUpdateDatetime: child.updateDatetime,
  }
}

/**
 * お子さま一覧取得（お子さま選択ダイアログで使用）
 */
async function getFacilityWithChilds(facilityId: string) {
  const [facility, allChilds, interviewedChilds] = await Promise.all([
    getFacility(facilityId),
    getChildrenChoices(),
    getInterviewedChildrenChoices(facilityId),
  ])

  //A761にて、キャンセル済お子様にラジオボタンを表示して再登録を可能にする対応を行いました。
  //本来SQLで修正をするところですが、お子様選択一覧画面に表示されるキャンセル済お子様のラベルに
  //「（キャンセル済）」のステータスを表示する対応が今後発生する可能性があり、その場合SQLの修正は不要になってしまうため画面での対応としました。

  //interviewedChilds（面談済みお子様）から状態：キャンセル済を除外する
  const exceptForChildsWithCanceledInterview = interviewedChilds.filter((v) => v.status !== interviewStatus.canceled)
  const interviewedChildIdSet = new Set(exceptForChildsWithCanceledInterview.map(({ value }) => value))
  const uninterviewedChilds = allChilds.filter(({ value }) => !interviewedChildIdSet.has(value))
  const childs = interviewedChilds.filter((v) => v.permitFlag === permitFlag.permitted)
  const interviewedChildsAndStatus = exceptForChildsWithCanceledInterview.map((v) => {
    let label = v.label
    if (v.status) {
      label = v.label + '（' + getInterviewStatusLabel(v.status) + '）'
    }
    return { value: v.value, label: label }
  })

  return {
    facility,
    uninterviewedChilds,
    childs,
    interviewedChildsAndStatus,
  }
}
