/** キーを削除して値だけ取り出す */
export type ValueType<T> = T extends { [_ in keyof T]: infer R } ? R : never

/** T | null を T | undefined に変換する  */
export type NullToUndefinedType<T> = T extends null ? undefined : T

/** T | null のプロパティを T | undefined に変換する  */
export type NullPropsToUndefinedType<T> = {
  [P in keyof T]: NullToUndefinedType<T[P]>
}

/** T | undefined を T | null に変換する  */
export type UndefinedToNullType<T> = T extends undefined ? null : T

/** T | undefined のプロパティを T | null に変換する  */
export type UndefinedPropsToNullType<T> = {
  [P in keyof T]: UndefinedToNullType<T[P]>
}

/** T | null のプロパティを T に変換する  */
export type NullPropsToNonNullType<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

/** Date を number に変換する  */
export type DateToNumberType<T> = T extends Date ? number : T

/** Date のプロパティを number に変換する  */
export type DatePropsToNumberType<T> = {
  [P in keyof T]: DateToNumberType<T[P]>
}

/** number を Date に変換する  */
export type NumberToDateType<T> = T extends number ? Date : T

/** number の指定したプロパティを Date に変換する  */
export type NumberPropsToDateType<T, K extends KeysInclude<T, number>> = Omit<T, K> & {
  [P in K]: NumberToDateType<T[P]>
}

/** TからVの型にマッチするプロパティ名をUnion型で取り出す */
export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}[keyof T]

/** TからVの型を含むプロパティ名をUnion型で取り出す */
export type KeysInclude<T, V> = {
  [K in keyof T]-?: V extends T[K] ? K : never
}[keyof T]

/** undefined を含むプロパティを オプショナル型(?付き) に変換する  */
export type UndefinedPropsToOptionalType<T> = Omit<T, KeysInclude<T, undefined>> &
  Partial<Pick<T, KeysInclude<T, undefined>>>

type Nullable<T> = T | undefined

type NullableRecord<T> = {
  [K in keyof T]: Nullable<T[K]>
}

/**  Tuple の先頭を取り出す */
type Head<Types> = Types extends [any, ...any[]]
  ? ((...args: Types) => any) extends (head: infer H, ...args: any) => any
    ? H
    : never
  : never

/** Tuple の先頭以外を Tuple として取り出す */
type Tail<Types> = Types extends [any, any, ...any[]]
  ? ((...args: Types) => any) extends (head: any, ...args: infer T) => any
    ? T
    : never
  : never

/** 省略可能Tuple */
export type OptionalTuple<Types extends unknown[]> =
  | NullableRecord<Types>
  | {
      0: Nullable<Head<Types>> | []
      1: OptionalTuple<Tail<Types>>
    }[Tail<Types> extends never ? 0 : 1]

/** 最低N個の必須指定がある省略可能Tuple */
export type OptionalTupleMin<Types extends unknown[], OptionTypes extends unknown[]> = OptionTypes extends never[]
  ? Types
  : Types extends never[]
  ? NullableRecord<OptionTypes>
  :
      | [...Types, ...NullableRecord<OptionTypes>]
      | {
          0: [...Types, Head<Types>] | Types
          1: OptionalTupleMin<Types, Tail<OptionTypes>>
        }[Tail<OptionTypes> extends never ? 0 : 1]

/**
 * 値の型からnullおよびundefinedを除外して型にキャストする。
 * ※値の変換は行いません。型情報のキャストのみ
 * @param value 値
 * @returns nullおよびundefinedを除外した型
 */
export const castNonNullable = <T,>(value: T) => value as NonNullable<T>

/**
 * 値の型にnullおよびundefinedをUnionした型にキャストする。
 * ※値の変換は行いません。型情報のキャストのみ
 * @param value 値
 * @returns 値の型にnullおよびundefinedをUnionした型
 */
export const castNullable = <T,>(value: T) => value as Nullable<T>

/**
 * 渡された値のタプルを生成する
 *
 * @param ts タプルの値
 * @returns タプル
 */
export const tuple = <T extends unknown[]>(...ts: T): T => ts

/**
 * キー値が対象オブジェクトのプロパティであるかを判定する。
 * また、型ガード関数としても機能するのでキー値が対象オブジェクトのプロパティでない可能性がある
 * というコンパイルエラーが出た場合にこのチェックで対象のプロパティであることを証明できる
 *
 * @param key キー値
 * @param value 対象オブジェクト
 * @returns キー値が対象オブジェクトのプロパティである場合true
 */
export const isKeyOf = <T extends Object>(key: any, value: T): key is keyof T => {
  return key != null && key in value
}

/**
 * 渡された値がタプルの値かを判定する型ガード関数
 *
 * @param tuple タプル
 * @param value 値
 * @returns タプルの値ならtrue
 */
export const isTupleValue = <T extends Readonly<unknown[]>>(tuple: T, value: any): value is T[number] => {
  if (tuple == null) {
    return false
  }
  return tuple.includes(value)
}
