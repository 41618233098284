import { Stack, styled } from '@mui/material'
import { getBloodTypes, getCovereds, getGenders, getYesNos } from '../../containers/common/codeMaster'
import { useAction } from '../../containers/memberAddChild/memberAddChildService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { DatePicker } from '../components/common/inputs/datePicker'
import { Form } from '../components/common/inputs/form'
import { RadioButtonGroup } from '../components/common/inputs/radioButtonGroup'
import { TextBox } from '../components/common/inputs/textBox'
import { SubTitle } from '../components/common/subTitle'

const ChildContentAttribute = styled(GContainer)({
  padding: '0 0 0 3rem',
})

export const MemberAddChild = () => {
  const { formMethods, onSubmit, childBirthdayLimitDate } = useAction()
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <Stack spacing={2}>
              {/* 基本 */}
              <SubTitle title={translate('memberAddChild.title.basic')} />
              <div>
                <ChildContentAttribute rowSpacing={3}>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.name')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox name="name" label={translate('memberAddChild.label.name')} maxLength={100} required />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.kana')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="kana"
                        label={translate('memberAddChild.label.kana')}
                        maxLength={100}
                        textType="katakana"
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.birthday')}
                    </GItem>
                    <GItem xs={12}>
                      <DatePicker name="birthday" label={translate('memberAddChild.label.birthday')} maxDate={childBirthdayLimitDate} required />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.hasBrothersSisters')}
                    </GItem>
                    <GItem xs={12}>
                      <RadioButtonGroup
                        name="brothersSistersFlag"
                        label={translate('memberAddChild.label.hasBrothersSisters')}
                        buttonValueLabels={getYesNos().reverse()}
                        row
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.isMultipleBirths')}
                    </GItem>
                    <GItem xs={12}>
                      <RadioButtonGroup
                        name="multipleBirthsFlag"
                        label={translate('memberAddChild.label.isMultipleBirths')}
                        buttonValueLabels={getCovereds().reverse()}
                        row
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.gender')}
                    </GItem>
                    <GItem xs={12}>
                      <RadioButtonGroup
                        name="gender"
                        label={translate('memberAddChild.label.gender')}
                        buttonValueLabels={getGenders()}
                        row
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.bloodType')}
                    </GItem>
                    <GItem xs={12}>
                      <RadioButtonGroup
                        name="bloodType"
                        label={translate('memberAddChild.label.bloodType')}
                        buttonValueLabels={getBloodTypes()}
                        row
                        required
                      />
                    </GItem>
                  </GItemContainer>
                  <GItemContainer xs={12}>
                    <GItem xs={12}>
                      <AttentionLabel>*</AttentionLabel>
                      {translate('memberAddChild.label.maternalHandbookNo')}
                    </GItem>
                    <GItem xs={12}>
                      <TextBox
                        name="maternalHandbookNo"
                        label={translate('memberAddChild.label.maternalHandbookNo')}
                        maxLength={20}
                        required
                      />
                    </GItem>
                  </GItemContainer>
                </ChildContentAttribute>
              </div>
            </Stack>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>

        <BottomButtonGroup>
          <ButtonL fullWidth type="submit">
            {translate('memberAddChild.button.confirmation')}
          </ButtonL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
