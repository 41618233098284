import { useCallback, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { undefinedPropsToOptional } from '../../utils/objectUtil'
import { memberModifyConfirmationUserUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { getAddressStringByPostalCode } from '../common/location'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import {
  selectMemberModifyUserEntry,
  setMemberModifyUpdateDatetime,
  setMemberModifyUser,
} from '../common/store/slices/memberModify'
import { getUser } from '../common/user'

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}

interface Inputs {
  name: string
  kana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber: string
  residenceCategory: string
  relationship: string

  emergencyContactName1: string
  emergencyContactKana1: string
  emergencyContactRelationship1: string
  emergencyContactCompany1: string
  emergencyContactTel1: string
  emergencyContactEmail1: string

  emergencyContactName2: string
  emergencyContactKana2: string
  emergencyContactRelationship2: string
  emergencyContactCompany2: string
  emergencyContactTel2: string
  emergencyContactEmail2: string

  emergencyContactName3: string
  emergencyContactKana3: string
  emergencyContactRelationship3: string
  emergencyContactCompany3: string
  emergencyContactTel3: string
  emergencyContactEmail3: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { addOperationLog } = useOperationLog()
  const [interviewPermitCount, setInterviewPermitCount] = useState<number>(0)
  const isKeep = !!history.location.state?.isKeep

  // ログイン必須の画面なので必ずNonNullable
  const modifyEntry = useSelector(selectMemberModifyUserEntry)

  const formMethods = useForm<Inputs>({
    defaultValues: {
      ...(isKeep && modifyEntry),
    },
  })

  // ちらつき防止のためにuseLayoutEffect使用
  useLayoutEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes }] })

    if (!(isKeep && modifyEntry)) {
      dispatch(
        showLoading(
          errorHandle(async () => {
            const { updateDatetime, interviewPermitCount, ...initialInputs } = await getInitialInputs()
            formMethods.reset({
              ...formMethods.getValues(),
              ...initialInputs,
            })
            dispatch(setMemberModifyUpdateDatetime(updateDatetime))
            setInterviewPermitCount(interviewPermitCount)
          })
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000058 })

      dispatch(setMemberModifyUser(data))
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      history.replace({ ...history.location, state: { isKeep: true } })
      history.push(memberModifyConfirmationUserUrl.url())
    },
    [addOperationLog]
  )

  const autoCompleteAddress = useCallback(async () => {
    const { postalCode } = formMethods.getValues()
    try {
      const address = await getAddressStringByPostalCode(postalCode)
      if (address) {
        formMethods.setValue('address1', address)
      }
    } catch {
      // 取得できなかった場合は何もしない
    }
  }, [])

  return {
    formMethods,
    interviewPermitCount,
    autoCompleteAddress,
    onSubmit,
  }
}

const getInitialInputs = async () => {
  const user = await getUser()
  const {
    name,
    kana,
    tel,
    email,
    postalCode,
    address1,
    address2,
    buildingNameRoomNumber,
    residenceCategory,
    relationship,
    emergencyContacts: [emergencyContact1, emergencyContact2, emergencyContact3],
    interviewPermitCount,
    updateDatetime,
  } = user
  // formMethods.resetで propaty: undefined が含まれると
  // 対象コントロールの値が undefined となりエラーが発生してしまう
  // その為、undefinedPropsToOptionalでundefinedを含む型を持つプロパティを
  // オプショナルに変換する。※値がundefinedのプロパティは除去されたオブジェクトになる
  return undefinedPropsToOptional({
    name,
    kana,
    tel,
    email,
    postalCode,
    address1,
    address2,
    buildingNameRoomNumber,
    residenceCategory,
    relationship,

    emergencyContactName1: emergencyContact1.name,
    emergencyContactKana1: emergencyContact1.kana,
    emergencyContactRelationship1: emergencyContact1.relationship,
    emergencyContactCompany1: emergencyContact1.company,
    emergencyContactTel1: emergencyContact1.tel,
    emergencyContactEmail1: emergencyContact1.email,

    emergencyContactName2: emergencyContact2.name,
    emergencyContactKana2: emergencyContact2.kana,
    emergencyContactRelationship2: emergencyContact2.relationship,
    emergencyContactCompany2: emergencyContact2.company,
    emergencyContactTel2: emergencyContact2.tel,
    emergencyContactEmail2: emergencyContact2.email,

    emergencyContactName3: emergencyContact3.name,
    emergencyContactKana3: emergencyContact3.kana,
    emergencyContactRelationship3: emergencyContact3.relationship,
    emergencyContactCompany3: emergencyContact3.company,
    emergencyContactTel3: emergencyContact3.tel,
    emergencyContactEmail3: emergencyContact3.email,

    updateDatetime,
    interviewPermitCount,
  })
}
