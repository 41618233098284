// テキストフォームコントロール
//

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment } from '@mui/material'
import React, { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useMemo, useState } from 'react'
import { Control, Validate, useController } from 'react-hook-form'
import {
  emailValidator,
  katakanaValidator,
  passwordValidator,
  postalCodeValidator,
  requiredValidator,
} from '../../../../containers/common/validator'
import { translate } from '../../../../i18n'
import { formatPostalCode } from '../../../../utils/itemFormatUtil'
import { getValueByNameStr } from '../../../../utils/objectUtil'
import { TextBoxBaseStyled } from './textBoxBaseStyled'

type TextType = 'postalCode' | 'email' | 'katakana' | 'oneTimeCode' | 'password'

interface TextBoxProps {
  /** 入力値を紐づける(バインドする)インプットオブジェクトのプロパティ名  */
  name: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 入力必須とする場合true */
  required?: boolean
  /** 最大入力文字数 */
  maxLength: number
  /** width: 100% にする場合true */
  fullWidth?: boolean
  /** プレースホルダ */
  placeholder?: string
  /** 入力欄左に表示するアイコン */
  startIcon?: React.ReactNode
  /** 複数行入力する場合true */
  multiline?: boolean
  /** 複数行入力する場合の表示行数 */
  rows?: number
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
  /**
   * テキストのタイプに応じて入力値のバリデーションチェックを行います
   */
  textType?: TextType
  /** autocomplete属性 */
  autoComplete?: string
}

const getValidators = (label: string, required?: boolean, textType?: TextType) => {
  const validator: Record<string, Validate<any>> = {}
  if (required) {
    validator.required = requiredValidator(label)
  }
  switch (textType) {
    case 'postalCode':
      validator.postalCode = postalCodeValidator()
      break
    case 'email':
      validator.email = emailValidator()
      break
    case 'katakana':
      validator.katakana = katakanaValidator()
      break
    case 'password':
      validator.password = passwordValidator()
      break
    default:
      break
  }
  return validator
}

const formatters: Record<string, (value: string) => string> = {
  postalCode: (value) => formatPostalCode(value),
}

/**
 * プロパティtextTypeに対応するinputタグのtype属性値のマップ。
 * このマップに存在しない場合は、type属性の指定なし。
 * 数値のみ項目に tel を指定しているのは、numberの使い勝手が非常に悪い為、敢えて tel を指定しています。
 * 参考：https://qiita.com/Avocado/items/2148d3af400823f91ea5
 */
const textTypeToInputType: Record<string, string> = {
  postalCode: 'tel',
  email: 'email',
  oneTimeCode: 'tel',
  password: 'password',
}

/** テキストフォームコントロール */
export const TextBox = (props: TextBoxProps) => {
  const { name, label, required, maxLength, startIcon, control, textType, ...through } = props

  const [inputType, setInputType] = useState(textType && textTypeToInputType[textType])

  const switchPasswordVisible = useCallback(() => {
    setInputType((old) => {
      if (old === textTypeToInputType.password) {
        return 'text'
      } else {
        return textTypeToInputType.password
      }
    })
  }, [])

  /**
   * password入力ではトリム処理は行わない
   * @param value 文字列
   * @returns 各行で末尾スペース(半角全角)を除去した文字列
   */
  const rtrimSpaceByLine = useCallback(
    (value: string) => {
      if (textType === 'password') {
        return value
      }
      return value.replaceAll(/[ 　]+$/gm, '')
    },
    [textType]
  )

  const validate = useMemo(() => getValidators(label, required, textType), [label, required, textType])
  const {
    field: { ref, onBlur, onChange, ...ctrlProps },
    formState: { errors },
  } = useController({
    name,
    rules: {
      maxLength: {
        value: maxLength,
        message: translate('system.error.charactersCountOver'),
      },
      validate,
    },
    defaultValue: '',
    control,
  })
  const onChangeFormat = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string) => {
      let value = typeof event === 'string' ? event : event.target.value
      const formatter = formatters[textType ?? '']
      if (formatter) {
        value = formatter(value)
      }
      onChange(value)
    },
    [onChange, textType]
  )
  const onBlurTrimEnd = useCallback(
    (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const rtrimedValue = rtrimSpaceByLine(event.target.value)
      onChangeFormat(rtrimedValue)
      onBlur()
    },
    [onBlur, onChangeFormat, rtrimSpaceByLine]
  )
  const error = getValueByNameStr(errors, name)
  return (
    <TextBoxBaseStyled
      {...ctrlProps}
      {...through}
      {...(inputType && { type: inputType })}
      onChange={onChangeFormat}
      onBlur={onBlurTrimEnd}
      onKeyPress={(event: KeyboardEvent) => {
        if (!through.multiline && event.key === 'Enter') {
          // Enterでサブミットされた場合い末尾トリムを実施
          const rtrimedValue = rtrimSpaceByLine(ctrlProps.value)
          if (rtrimedValue !== ctrlProps.value) {
            onChangeFormat(rtrimedValue)
          }
        }
      }}
      maxLength={maxLength}
      inputProps={{
        maxLength,
      }}
      InputProps={{
        ...(startIcon && {
          startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
        }),
        ...(textType === 'password' && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={switchPasswordVisible}>
                {inputType === textTypeToInputType.password ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
      // *を表示する為にlabelプロパティにのみrequired適用 ※ブラウザ固有必須動作をさせないため
      // https://stackoverflow.com/questions/52067514/material-ui-replicating-the-required-text-field-error-message/52067539
      InputLabelProps={{ required }}
      inputRef={ref}
      error={!!error}
      helperText={error?.message}
    />
  )
}
