import { CircularProgress, Modal, styled } from '@mui/material'
import { memo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsLoading } from '../../../containers/common/store/slices/application'

const Root = styled(Modal)({
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
  },
})
const Circle = styled(CircularProgress)({
  position: 'absolute',
  // Circleの幅40px
  top: 'calc(50% - 20px)',
  left: 'calc(50% - 20px)',
})

export const LoadingProgress = memo(function LoadingProgress() {
  const modalContentRef = useRef<HTMLDivElement>(null)
  const [isShownCircle, setIsShownCircle] = useState(false)
  const isLoading = useSelector(selectIsLoading)

  useEffect(() => {
    if (isLoading) {
      // onChangeでloading表示する場合、フォーカスが残ったままだと
      // そのまま変更出来てしまうのでモーダル側にフォーカスを移す
      modalContentRef.current?.focus()
      setIsShownCircle(false)
      const timeoutId = setTimeout(() => setIsShownCircle(true), 500)
      return () => clearTimeout(timeoutId)
    }
  }, [isLoading])
  return (
    <Root open={isLoading}>
      <div ref={modalContentRef}>{isShownCircle && <Circle />}</div>
    </Root>
  )
})
