import { Box, Collapse, Grid, Stack, styled } from '@mui/material'
import { useCallback, useState } from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { tabValue, useAction } from '../../containers/coupon/couponService'
import { getClassificationValueLabels, translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { CenterGItem, GContainer, GItem, MiddleGItem, RowSpacerGItem } from '../components/common/grids'
import { MinusCircleOutlineIcon } from '../components/common/icons/minusCircleOutlineIcon'
import { PlusCircleOutlineIcon } from '../components/common/icons/plusCircleOutlineIcon'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { PageTitle } from '../components/common/pageTitle'
import { ReservationCard } from '../components/common/reservation/reservationCard'
import { UsageHistoryCard } from '../components/common/reservation/usageHistoryCard'
import { TabBar } from '../components/common/tabBar'
import { TimeAmountContent } from '../components/common/timeAmountContent'
import { TransitionCheck } from '../components/common/transitionCheck'
import { OperationId } from '../../containers/common/constant/operationLog'

const ExistResultMessageCard = styled('div')({
  marginBottom: '20px',
  textAlign: 'center',
})
const NoResultMessageCard = styled('div')({
  padding: '20px 0',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
})
const CouponCenterGItem = styled(CenterGItem)({
  margin: '30px 0 15px 0',
})
const CouponlimitDateListCenterGItem = styled(CenterGItem)({
  margin: '15px 0 30px 0',
  display: 'flex',
})

const MinusCircleOutlineBlackIcon = styled(MinusCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.5rem',
  margin: '2px 10px 0 0',
}))
const PlusCircleOutlineBlackIcon = styled(PlusCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.5rem',
  margin: '2px 10px 0 0',
}))
const CouponLimitDateListGItem = styled(GItem)({
  marginRight: '10px',
})
const CouponLimitDateHeaderGItem = styled(GItem)({
  margin: '0 15px 5px 0',
})
export const Coupon = () => {
  const {
    activeChildId,
    childs,
    changeChild,
    activeTabValue,
    changeTab,
    reservations,
    usageHistorys,
    couponsIssues,
    showCouponFunctionFlag,
  } = useAction()
  const [isShownCouponLimitDateList, setIsShownCouponLimitDateList] = useState(false)
  const toggleDetail = useCallback(
    () => setIsShownCouponLimitDateList(!isShownCouponLimitDateList),
    [isShownCouponLimitDateList]
  )
  let contents
  if (reservations) {
    contents = reservations.map((reservation) => (
      <ReservationCard key={reservation.reservationNo} {...reservation} isCpuponFlag={true} />
    ))
  } else if (usageHistorys) {
    contents = usageHistorys.map((usageHistory) => (
      <UsageHistoryCard key={usageHistory.reservationNo} operationId={OperationId.OP_00000065} {...usageHistory} />
    ))
  }
  if (contents == null || couponsIssues == null) {
    return null
  }
  if (!showCouponFunctionFlag) {
    return <TransitionCheck check={false} />
  }

  const limitDateList = couponsIssues.couponlimitList.map((data, index) => {
    return (
      <GContainer key={index}>
        <CouponLimitDateListGItem>{data.limitDate}</CouponLimitDateListGItem>
        <CouponLimitDateListGItem>{data.totalBalanceMinute}</CouponLimitDateListGItem>
      </GContainer>
    )
  })

  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={4}></GItem>
        <GItem xs={12} md={4}>
          <PageTitle title={translate('coupon.title.remainingTimeOfTheFirstChildcareTicket')} />

          <CouponCenterGItem>
            <div>
              <TimeAmountContent
                value={couponsIssues?.totalTotalBalanceMinute}
                subValue={couponsIssues?.totalLimitUsageMinute}
              />
            </div>
          </CouponCenterGItem>
          <CouponlimitDateListCenterGItem>
            <Box>
              <Link onClick={toggleDetail}>
                {isShownCouponLimitDateList ? <MinusCircleOutlineBlackIcon /> : <PlusCircleOutlineBlackIcon />}
              </Link>
            </Box>
            <Box>
              <GContainer>
                <CouponLimitDateHeaderGItem>{translate('coupon.text.byLimitDate')}</CouponLimitDateHeaderGItem>
                <CouponLimitDateHeaderGItem>{translate('coupon.text.remainingTime')}</CouponLimitDateHeaderGItem>
              </GContainer>
              <Collapse in={isShownCouponLimitDateList}>{limitDateList}</Collapse>
            </Box>
          </CouponlimitDateListCenterGItem>
          <GContainer>
            <MiddleGItem xs={5} md={4}>
              {translate('coupon.label.selectionChild')}
            </MiddleGItem>
            <MiddleGItem xs={7} md={8}>
              <SelectNoBind
                fullWidth
                includeNone="always"
                noneLable={translate('system.pulldown.allChildren')}
                valueLabels={childs}
                onChange={changeChild}
                value={activeChildId}
              />
            </MiddleGItem>
          </GContainer>
          <RowSpacerGItem />
          <TabBar
            value={activeTabValue}
            valueLabels={getClassificationValueLabels('coupon.classification.tabValue', Object.values(tabValue))}
            onChange={changeTab}
          />
          <RowSpacerGItem />
          <Grid item xs={12}>
            {contents.length ? (
              <>
                {activeTabValue === tabValue.reservation && (
                  <ExistResultMessageCard>{translate('coupon.text.existReservations')}</ExistResultMessageCard>
                )}
                <Stack spacing={1}>{contents}</Stack>
              </>
            ) : (
              <NoResultMessageCard>
                {translate(
                  activeTabValue === tabValue.reservation ? 'coupon.text.noReservation' : 'coupon.text.noUsageHistory'
                )}
              </NoResultMessageCard>
            )}
          </Grid>
        </GItem>
        <GItem md={4}></GItem>
      </GContainer>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
