import { Grid, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
// import { systemManualPdfUrl } from '../../containers/common/constant/externalUrl'
import { selectSystemControl } from '../../containers/common/store/slices/systemControl'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { GContainer, GItem } from '../components/common/grids'
import { DownloadIcon } from '../components/common/icons/downloadIcon'
import { SubTitle } from '../components/common/subTitle'
import { TitleLabel } from '../components/common/titleLabel'
import awsmobile from '../../aws-exports'

export const Manual = () => {
  const serviceTitle = useSelector(selectSystemControl).serviceTitle
  const bucketName = useSelector(selectSystemControl).s3BucketNameManual
  const region = awsmobile.aws_project_region
  const manualFileName = '横浜市_一時預かり保育予約システム_市民向けマニュアル.pdf'
  const systemManualPdfUrl = `https://${bucketName}.s3.${region}.amazonaws.com/${encodeURIComponent(manualFileName)}`
  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <GContainer>
              <GItem>
                <TitleLabel>{serviceTitle}</TitleLabel>
              </GItem>
              <GItem xs={12}>
                <SubTitle title={'操作マニュアル'} />
              </GItem>
            </GContainer>
            <div>
              {/* 
              マニュアルはダウンロードを強制しています。
              download属性は同一オリジンの URL と、 blob:、 data: の各スキームでのみ動作するので、
              download属性は使用せず
              S3側でpdfのコンテンツタイプをapplication/octet-streamに設定してダウンロードを強制させるようにした
              https://developer.mozilla.org/ja/docs/Web/HTML/Element/a
              */}
              <ButtonL fullWidth href={systemManualPdfUrl} startIcon={<DownloadIcon />}>
                {translate('manual.button.download')}
              </ButtonL>
            </div>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
