import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface AccordionCardProps {
  /** アコーディオンのコンテンツ部(展開して表示される中身) */
  children: ReactNode
  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string
  /** アコーディオンタイトル部(開閉の際にクリックする部分) */
  title?: string
  /** 展開時はtrue */
  expanded?: boolean
  /** 開閉の状態が変更される時呼び出される関数 */
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void
}

const Root = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
}))
const Summary = styled(AccordionSummary)({
  minHeight: 0,
  whiteSpace: 'pre-wrap',
  '&.Mui-expanded': {
    minHeight: 0,
    '& .MuiAccordionSummary-content': {
      margin: '8px 0',
    },
  },
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
})
const Detail = styled(AccordionDetails)({
  whiteSpace: 'pre-wrap',
  paddingTop: 0,
})

/** アコーディオン型のカードコントロール */
export const AccordionCard = (props: AccordionCardProps) => {
  return (
    <Root expanded={props.expanded} onChange={props.onChange} elevation={0} className={props.className}>
      <Summary expandIcon={<ExpandMoreIcon />}>{props.title}</Summary>
      <Detail>{props.children}</Detail>
    </Root>
  )
}
