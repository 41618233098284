import { styled } from '@mui/material'
import { ButtonBase } from './buttonBase'

/**
 * infoカラーのボタンコントロール基本部分。
 */
export const ButtonOutlinedInfoBase = styled(ButtonBase)(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  backgroundColor: theme.palette.info.main,
  '&:hover': {
    backgroundColor: theme.palette.info.dark,
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: theme.palette.info.main,
    },
  },
}))
