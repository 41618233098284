import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

export interface InterviewReserveEntry {
  facilityId: string
  childId: string

  parentName: string
  parentKana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber: string
  residenceCategory: string
  relationship: string
  childName: string
  childKana: string
  childGender: string
  childBirthday: ElapsedMillisecond
  childMedicalHistoryFlag: string
  childMedicalHistory: string
  childAllergyFlag: string
  childAllergy: string
  maternalHandbookNo: string
  note: string
  facilityNumber: string
}

interface InterviewReserveState {
  entry?: InterviewReserveEntry
  /** 排他制御用更新日時 */
  updateDatetimes?: {
    userUpdateDatetime: string
    childUpdateDatetime: string
  }
}

const initialState: InterviewReserveState = {}

export const interviewReserveSlice = createSlice({
  name: 'interviewReserve',
  initialState,
  reducers: {
    setInterviewReserve: (state, action: PayloadAction<InterviewReserveEntry>) => {
      state.entry = action.payload
    },
    setInterviewReserveUpdateDatetime: (
      state,
      action: PayloadAction<{ userUpdateDatetime: string; childUpdateDatetime: string }>
    ) => {
      state.updateDatetimes = action.payload
    },
    clearInterviewReserve: (state) => {
      delete state.entry
    },
  },
})
export const { setInterviewReserve, setInterviewReserveUpdateDatetime, clearInterviewReserve } =
  interviewReserveSlice.actions
export const selectInterviewReserveEntry = (state: RootState) => state.interviewReserve.entry
export const selectInterviewReserveUpdateDatetimes = (state: RootState) => state.interviewReserve.updateDatetimes
export default interviewReserveSlice.reducer
