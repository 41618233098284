import { executeGet, executePost } from '../common/apiCaller'
import { GetCouponsIssuesDto, GetCouponsIssuesInputDto, PostCouponsUsageInputDto } from '../dto/couponsDto'

const apiName = 'citizen'
const apiPath = '/coupons'

export const executeGetCouponsIssues = (input?: GetCouponsIssuesInputDto) => {
  return executeGet<GetCouponsIssuesDto[]>(apiName, `${apiPath}/issue`, input)
}

export const resultCodePostCouponsUsage = {
  /** 利用日当日ではない */
  notUsageDate: 1,
  /** 有効なクーポンが無い */
  notExistsValidCoupon: 2,
  /** 既に登録済み */
  alreadyRegistered: 3,
  /** 残高が不足 */
  insufficientBalance: 4,
} as const

export const executePostCouponsUsage = (reservationNo: string, input: PostCouponsUsageInputDto) => {
  return executePost<null>(apiName, `${apiPath}/usage/${reservationNo}`, input)
}
