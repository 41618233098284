import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import theme from './theme'
import { store } from './containers/common/store'
import { App } from './app'
import { ScrollToTop } from './views/components/common/scrollToTop'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
