import { executeDelete, executeGet, executeGetNoResultError, executePost, executePut } from '../common/apiCaller'
import {
  DeleteReservationInputDto,
  GetReservationDto,
  GetReservationsDto,
  GetReservationsInputDto,
  PostReservationInputDto,
  PutReservationInputDto,
} from '../dto/reservationsDto'

const apiName = 'citizen'
const apiPath = '/reservations'

export const executeGetReservations = async (input?: GetReservationsInputDto) => {
  return executeGet<GetReservationsDto[]>(apiName, apiPath, input)
}

export const executeGetReservation = (id: string) => {
  return executeGetNoResultError<GetReservationDto>(apiName, `${apiPath}/${id}`)
}

export const executeDeleteReservation = (id: string, input: DeleteReservationInputDto) => {
  return executeDelete<null>(apiName, `${apiPath}/${id}`, input)
}

export const executePostReservation = (input: PostReservationInputDto) => {
  return executePost<null>(apiName, apiPath, input)
}

export const executePutReservation = (id: string, input: PutReservationInputDto) => {
  return executePut<null>(apiName, `${apiPath}/${id}`, input)
}
