import { useSelector } from 'react-redux'
import { selectIsExistsBackHistory } from '../../../../containers/common/store/slices/application'
import { BackButton } from './backButton'
import { WindowCloseButton } from './windowCloseButton'

interface BackOrWindowCloseButtonProps {
  className?: string
}

/**
 * 戻る(またはウィンドウを閉じる)ボタン。
 * 履歴スタック数から戻り先が無いと判断したら閉じるボタンを表示する
 */
export const BackOrWindowCloseButton = (props: BackOrWindowCloseButtonProps) => {
  const isExistsBackHistory = useSelector(selectIsExistsBackHistory)

  return isExistsBackHistory ? <BackButton {...props} /> : <WindowCloseButton {...props} />
}
