import { FormControlLabel, Radio, RadioGroup, styled, SelectChangeEvent } from '@mui/material'
import React from 'react'

interface RadioButtonNoBindProps {
  /** name属性 */
  name?: string
  /** 選択状態の選択肢の値 */
  value?: string
  /** 横に並べて表示する場合true */
  row?: boolean
  /** 選択肢の値と表示文言配列 */
  buttonValueLabels: { value: string; label: string }[]
  /** 選択が変更された時に呼び出される関数 */
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
}

const Root = styled(RadioGroup)(() => ({
  '& .MuiRadio-root': {
    padding: '0 9px',
  },
}))

/** ラジオボタンフォームコントロール*/
/** react-hook-formを使って入力値の紐づけを実施しないバージョン*/
export const RadioButtonGroupNoBind = (props: RadioButtonNoBindProps) => {
  const { name, value, buttonValueLabels, onChange, ...through } = props

  return (
    <Root 
      name={name}
      value={value}
      onChange={onChange}
      {...through}
    >
      {buttonValueLabels.map(({ value, label }) => (
        <FormControlLabel
          key={value}
          {...{ value, label }}
          control={<Radio />}
        />
      ))}
    </Root>
  )
}

