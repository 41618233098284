import { styled, Theme } from '@mui/material'
import React from 'react'

interface StepBarProps {
  activeStepValue?: string
  stepValueLabels: { value: string; label: string }[]
}

// 三角のボーダーは高さを固定で指定する必要があるため、関連するサイズは全てremで記載した。
const Root = styled('ul')(({ theme }) => ({
  display: 'flex',
  listStyle: 'none',
  overflow: 'hidden',
  padding: '0 0.8rem 0 0',
  fontSize: theme.typography.font.sizeS,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.font.sizeM,
  },
  color: theme.palette.primary.contrastText,
}))
const stepBorderStyles = {
  content: '""',
  position: 'absolute',
  top: '-2.5rem',
  borderWidth: '5rem 0 5rem 2.2rem',
  borderStyle: 'solid',
  zIndex: 10,
}
const getActiveColor = (theme: Theme, isActive: boolean) =>
  isActive ? theme.palette.primary.main : theme.palette.primary.light

const StepItem = styled('li', { shouldForwardProp: (prop) => prop !== 'isFirst' && prop !== 'isActive' })<{
  isFirst: boolean
  isActive: boolean
}>(({ theme, isFirst, isActive }) => ({
  position: 'relative',
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem 1.6rem',
  paddingRight: '0.4rem',
  ...(isFirst && {
    paddingLeft: '0.6rem',
  }),
  height: '5rem',
  backgroundColor: getActiveColor(theme, isActive),
  '&::before': {
    right: '-1.1rem',
    borderColor: `transparent transparent transparent ${theme.palette.white.light}`,
    ...stepBorderStyles,
  },
  '&::after': {
    right: '-0.7rem',
    borderColor: `transparent transparent transparent ${getActiveColor(theme, isActive)}`,
    ...stepBorderStyles,
  },
}))
const ValueLabel = styled('div')({
  zIndex: 11,
})
const ValueLabelSpace = styled('div')({
  width: '5px',
})

export const StepBar = (props: StepBarProps) => {
  return (
    <Root>
      {props.stepValueLabels.map(({ value, label }, index) => (
        <StepItem key={value} isFirst={index === 0} isActive={value === props.activeStepValue}>
          <ValueLabel>{value}</ValueLabel>
          <ValueLabelSpace />
          <ValueLabel>{label}</ValueLabel>
        </StepItem>
      ))}
    </Root>
  )
}
