import { Stack, styled } from '@mui/material'
import { useAction } from '../../containers/accountConfirmation/accountConfirmationService'
import { preinterviewAccept, yesNo } from '../../containers/common/constant/classification'
import { translate } from '../../i18n'
import { ChildSelectDialog } from '../components/accountConfirmation/childSelectDialog'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedInfoL } from '../components/common/buttons/buttonOutlinedInfoL'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { GContainer, GItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { Form } from '../components/common/inputs/form'
import { Select } from '../components/common/inputs/select'
import { SubTitle } from '../components/common/subTitle'
import { TitleLabel } from '../components/common/titleLabel'

const ContentBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

const preinterviewAcceptWebs: readonly string[] = [preinterviewAccept.telWeb, preinterviewAccept.web] as const
const preinterviewAcceptTels: readonly string[] = [preinterviewAccept.telWeb, preinterviewAccept.tel] as const

export const AccountConfirmation = () => {
  const {
    facility,
    uninterviewedChilds,
    childs,
    interviewedChildsAndStatus,
    formMethods,
    isOpenInterviewChildSelect,
    onSubmit,
    onClickTel,
    goInterview,
    onCloseInterviewChildSelect,
  } = useAction()

  if (facility == null) {
    return null
  }
  const isPreinterviewAcceptWeb = preinterviewAcceptWebs.includes(facility.preinterviewAcceptFlag)
  const isPreinterviewAcceptTel = preinterviewAcceptTels.includes(facility.preinterviewAcceptFlag)
  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Stack spacing={2}>
            <div>
              <AttentionLabel>
                <TitleLabel>{facility.facilityName}</TitleLabel>
              </AttentionLabel>
            </div>

            {!!childs.length && (
              <>
                <SubTitle title={translate('accountConfirmation.title.finishedInterview')} />
                <ContentBox>{translate('accountConfirmation.text.finishedInterviewContent')}</ContentBox>
                <Form onSubmit={onSubmit} formMethods={formMethods}>
                  <InfoCard>
                    <Stack spacing={2}>
                      <Select
                        name="childId"
                        label={translate('accountConfirmation.pulldown.child')}
                        valueLabels={childs}
                      />
                      {facility.reservationAcceptFlag === yesNo.yes ? (
                        <ButtonOutlinedWarningL type="submit">
                          {translate('accountConfirmation.button.facilityReservationSelection')}
                        </ButtonOutlinedWarningL>
                      ) : (
                        <ButtonL onClick={onClickTel}>
                          <Stack>
                            <div>{translate('accountConfirmation.button.telReservation')}</div>
                            <div>
                              <AnnotationLabel>{`(${facility.tel})`}</AnnotationLabel>
                            </div>
                          </Stack>
                        </ButtonL>
                      )}
                    </Stack>
                  </InfoCard>
                </Form>
              </>
            )}
            <SubTitle title={translate('accountConfirmation.title.notFinishedInterview')} />
            <ContentBox>{translate('accountConfirmation.text.notFinishedInterviewContent')}</ContentBox>
            <InfoCard>
              <Stack spacing={2}>
                {isPreinterviewAcceptWeb && (
                  <ButtonOutlinedInfoL onClick={goInterview}>
                    <Stack>
                      <div>{translate('accountConfirmation.button.webInterview')}</div>
                      <div>
                        <AttentionLabel>
                          {translate('accountConfirmation.button.webInterviewAnnotation')}
                        </AttentionLabel>
                      </div>
                    </Stack>
                  </ButtonOutlinedInfoL>
                )}
                {isPreinterviewAcceptTel && (
                  <ButtonL onClick={onClickTel}>
                    <Stack>
                      <div>{translate('accountConfirmation.button.telInterview')}</div>
                      <div>
                        <AnnotationLabel>{`(${facility.tel})`}</AnnotationLabel>
                      </div>
                    </Stack>
                  </ButtonL>
                )}
              </Stack>
            </InfoCard>
          </Stack>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
      <ChildSelectDialog
        isOpen={isOpenInterviewChildSelect}
        onClose={onCloseInterviewChildSelect}
        childs={uninterviewedChilds}
        interviewedChildsAndStatus={interviewedChildsAndStatus}
        isShowToNextButton={!!uninterviewedChilds.length}
      />
    </Stack>
  )
}
