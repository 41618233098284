import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { executeGetInformation } from '../../dataAccess/webApi/dao/informationsDao'
import { fromApiYmdHms } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { selectIsLoggedIn, selectIsVerifiedEmail, selectIsVerifiedTel } from '../common/store/slices/authority'

interface UrlParams {
  informaionNo: string
}

interface Information {
  informaionNo: string
  publicationStartDatetime: Date
  facilityName: string
  title: string
  body: string
  url?: string
  attachment1FileName?: string
  attachment1Key?: string
  attachment2FileName?: string
  attachment2Key?: string
  attachment3FileName?: string
  attachment3Key?: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const { informaionNo } = useParams<UrlParams>()
  const [information, setInformation] = useState<Information>()

  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)

  useEffect(() => {
    const initialize = async () => {
      const result = await getInformation(isLoggedIn, isVerifiedEmail, isVerifiedTel, informaionNo)
      setInformation(result)
    }
    dispatch(showLoading(errorHandle(initialize)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    information,
  }
}

const getInformation = async (
  isLoggedIn: boolean,
  isVerifiedEmail: boolean,
  isVerifiedTel: boolean,
  informaionNo: string
) => {
  const response = await executeGetInformation(isLoggedIn && isVerifiedEmail && isVerifiedTel, informaionNo)
  const { publicationStartDatetime, ...other } = response.result
  return nullPropsToUndefined({
    ...other,
    publicationStartDatetime: fromApiYmdHms(publicationStartDatetime),
  })
}
