import { styled } from '@mui/material'
import React from 'react'

interface CompletionMessageLabelProps {
  children?: string
}

const Root = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.font.sizeL2,
}))

/** 予約などの処理完了画面で表示する見出しのメッセージに使用するコントロール */
export const CompletionMessageLabel = (props: CompletionMessageLabelProps) => {
  return <Root>{props.children}</Root>
}
