import { Box, Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { httpStatusCode } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/systemError/systemErrorService'
import { translate } from '../../i18n'
import { ValueType } from '../../utils/typeUtil'
import { Link } from '../components/common/link'
import { PageTitle } from '../components/common/pageTitle'

interface SystemErrorProps {
  statusCode?: ValueType<typeof httpStatusCode>
}

const ErrorContentItem = styled(Grid)({
  whiteSpace: 'pre-wrap',
})

export const SystemError = (props: SystemErrorProps) => {
  const { subTitleContent } = useAction(props.statusCode)
  return (
    <Stack spacing={2}>
      <PageTitle title={translate('systemError.title.systemError')} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {subTitleContent && `(${subTitleContent})`}
          </Grid>
          <ErrorContentItem item xs={12}>
            {translate('systemError.text.content')}
          </ErrorContentItem>
          <Grid item xs={12}>
            {/* クライアントコードを最新化させるためにリロードを伴った遷移をさせる */}
            <Link href={rootUrl.url()}>{translate('system.text.backToHome')}</Link>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}
