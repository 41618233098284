// .envファイルに定義された値を定数として定義
// .envについて：https://qiita.com/geekduck/items/6f99a3da15dd39658fff

/** GoogleMapのAPIKey */
export const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY
/** メンテナンス中メッセージ情報パス */
export const maintenanceMessagePath = process.env.REACT_APP_MAINTENANCE_MESSAGE_PATH

/** Amplify.configure oauth.domain */
export const amplifyConfOauthDomain = process.env.REACT_APP_OAUTH_DOMAIN
/** Amplify.configure oauth.redirectSignIn */
export const amplifyConfOauthSignIn = process.env.REACT_APP_OAUTH_SIGN_IN
/** Amplify.configure oauth.redirectSignOut */
export const amplifyConfOauthSignOut = process.env.REACT_APP_OAUTH_SIGN_OUT
/** 認証 IDプロバイダー名 */
export const authIdProviderName = process.env.REACT_APP_AUTH_ID_PROVIDER_NAME
