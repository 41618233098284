import { Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/usageHistory/usageHistoryService'
import { translate } from '../../i18n'
import { formatLocaleYm } from '../../utils/dateUtil'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { CenterMiddleItem, LeftItem, MiddleItem, RightItem } from '../components/common/gridItems'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { UsageHistoryCard } from '../components/common/reservation/usageHistoryCard'
import { OperationId } from '../../containers/common/constant/operationLog'
import { RadioButtonGroupNoBind } from '../components/common/inputs/radioButtonGroupNoBind'
import { getUsageHistoryStatusSearchConditions } from '../../containers/common/codeMaster'

const NoResultMessageCard = styled('div')({
  padding: '20px 0',
  textAlign: 'center',
})

const MonthControl = (props: { yyyymm?: Date; previousMonth: () => void; nextMonth: () => void }) => {
  return (
    <>
      <LeftItem item xs={3}>
        <Link onClick={props.previousMonth}>{`<${translate('usageHistory.text.previousMonth')}`}</Link>
      </LeftItem>
      <CenterMiddleItem item xs={6}>
        {props.yyyymm && formatLocaleYm(props.yyyymm)}
      </CenterMiddleItem>
      <RightItem item xs={3}>
        <Link onClick={props.nextMonth}>{`${translate('usageHistory.text.nextMonth')}>`}</Link>
      </RightItem>
    </>
  )
}

export const UsageHistory = () => {
  const {
    activeYyyymm,
    activeChildId,
    usageHistories,
    childs,
    activeStatusSearchCondition,
    changeChild,
    previousMonth,
    nextMonth,
    statusSearchConditionChangeHandle,
  } = useAction()

  if (usageHistories == null) {
    return null
  }
  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Grid container>
              <MiddleItem item>{translate('usageHistory.label.selectionChild')}　</MiddleItem>
              <Grid item xs>
                <SelectNoBind
                  fullWidth
                  includeNone="always"
                  noneLable={translate('system.pulldown.allChildren')}
                  valueLabels={childs}
                  onChange={changeChild}
                  value={activeChildId}
                />
              </Grid>
            </Grid>
            
            <RadioButtonGroupNoBind
              name={'statusSearchCondition'}
              value={activeStatusSearchCondition}
              onChange={statusSearchConditionChangeHandle}
              buttonValueLabels={getUsageHistoryStatusSearchConditions()}
              row
            />

            <div>
              <Grid container rowSpacing={1}>
                <MonthControl previousMonth={previousMonth} nextMonth={nextMonth} yyyymm={activeYyyymm} />
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    {usageHistories.length ? (
                      usageHistories.map((usage) => (
                        <UsageHistoryCard key={usage.reservationNo} operationId={OperationId.OP_00000046} {...usage} />
                      ))
                    ) : (
                      <NoResultMessageCard>{translate('usageHistory.text.noUsageHistory')}</NoResultMessageCard>
                    )}
                  </Stack>
                </Grid>
                {!!usageHistories.length && <MonthControl previousMonth={previousMonth} nextMonth={nextMonth} />}
              </Grid>
            </div>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
