import { styled } from '@mui/material'
import { translate } from '../../../i18n'
import { AttentionLabel } from './attentionLabel'

type SizeType = 'sizeS' | 'sizeM'
const defaultSize = 'sizeS'

const Root = styled(AttentionLabel, { shouldForwardProp: (prop) => prop !== 'size' })<{ size: SizeType }>(
  ({ theme, size }) => ({
    fontSize: size === 'sizeM' ? theme.typography.font.sizeM : theme.typography.font.sizeS,
  })
)
interface RequiredLabelProps {
  /**
   * 文字の大きさ。
   * 省略じは、Sサイズ
   */
  size?: SizeType
}
/**
 * 入力項目の必須表示に使用するラベル。
 * TODO: 2023/02/02 日夏 既存入力項目で「*」と表現している個所は今後sizeM(省略)で項目名後ろにこの部品を配置するように変更予定
 *
 * @param props
 * @returns
 */
export const RequiredLabel = (props: RequiredLabelProps) => {
  const { size } = props
  return <Root size={size ?? defaultSize}>{translate('system.label.required')}</Root>
}
