import { useSelector } from 'react-redux'
import { selectSystemControl } from '../../../containers/common/store/slices/systemControl'
import { formatYmdHms } from '../../../utils/dateUtil'

export const SystemDatetimeLabel = (props: { className?: string }) => {
  const sysCtrl = useSelector(selectSystemControl)
  return sysCtrl.systemDatetime == null ? null : (
    <span className={props.className}>{formatYmdHms(sysCtrl.systemDatetime)}</span>
  )
}
