import { SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeGetInterviewedFacilities } from '../../dataAccess/webApi/dao/interviewedFacilitiesDao'
import { GetInterviewedFacilitiesDto } from '../../dataAccess/webApi/dto/interviewedFacilitiesDto'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { ChildrenChoice, getChildrenChoices } from '../common/child'
import { facilityDetailUrl, facilityDetailAvailabilityUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { facilityReservationSelectionCreateUrl } from '../../containers/common/constant/appUrl'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

interface LocationState {
  /** アクティブなお子さまID */
  childId?: string
}

interface PageState {
  activeChildId?: string
  myFacilities?: NullPropsToUndefinedType<GetInterviewedFacilitiesDto>[]
  childs: ChildrenChoice[]
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { addOperationLog } = useOperationLog()

  const locationState = history.location.state
  const [state, setState] = useState<PageState>({
    activeChildId: locationState?.childId,
    childs: [],
  })

  const load = useCallback(
    (isInitialize: boolean, loadedChilds: { value: string; label: string }[], activeChildId?: string) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const childs = isInitialize ? await getChildrenChoices() : loadedChilds
            const childId = activeChildId ?? childs[0]?.value
            const myFacilities = await getMyFacilities(childId)
            setState({
              activeChildId: childId,
              childs,
              myFacilities,
            })
          }),
          isHiddenMain: isInitialize,
        })
      )
    },
    []
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    load(true, state.childs, state.activeChildId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 戻るで表示した際にアクティブな選択を復元する為に履歴に保管
    history.replace({ ...history.location, state: { childId: state.activeChildId } })
  }, [state.activeChildId])

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      load(false, state.childs, event.target.value)
    },
    [state.childs, addOperationLog]
  )

  const onClickFacilityName = useCallback(
    (facilityId?: string) => {
      addOperationLog({ operationId: OperationId.OP_00000027 })

      if (facilityId) {
        // この画面では施設カードに施設IDを設定しているのでnullはあり得ない
        history.push(facilityDetailUrl.url(facilityId))
      }
    },
    [addOperationLog]
  )

  const callTel = useCallback((tel: string) => (document.location.href = `tel:${tel}`), [])

  // 空き状況（利用する）へ遷移
  const goFacilityReservationSelection = useCallback(
    (facilityId: string, activeChildId: string) => {
      addOperationLog({ operationId: OperationId.OP_00000040 })
      history.push(facilityReservationSelectionCreateUrl.url(facilityId, activeChildId))
    },
    [addOperationLog, history]
  )

  // 空き状況へ遷移
  const goFacilityDetailAvailability = useCallback(
    (facilityId: string, activeChildId: string) => {
      addOperationLog({ operationId: OperationId.OP_00000041 })
      history.push(facilityDetailAvailabilityUrl.url(facilityId, activeChildId))
    },
    [addOperationLog, history]
  )

  return {
    ...state,
    changeChild,
    onClickFacilityName,
    callTel,
    goFacilityReservationSelection,
    goFacilityDetailAvailability,
  }
}

const getMyFacilities = async (childId: string) => {
  const response = await executeGetInterviewedFacilities({ childId })
  return response.result.map((v) => nullPropsToUndefined(v))
}
