import { styled } from '@mui/material'
import { ButtonContainedBase } from './buttonContainedBase'

/**
 * primaryカラーのボタンコントロールSサイズ。
 */
export const ButtonS = styled(ButtonContainedBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeM,
  padding: '3px 13px',
  borderRadius: 8,
}))
