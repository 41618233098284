type PromiseFactoryReturnType<T> = T extends () => Promise<infer R> ? R : T extends () => infer R ? R : T
type AllConcurrencyReturnType<T> = { -readonly [P in keyof T]: PromiseFactoryReturnType<Awaited<T[P]>> }

/**
 * 同時実行最大数を指定して並列実行する。
 * Promise.allと同様に、何れかが失敗すればそこで処理終了
 *
 * @param promiseFactories 並列実行させるプロミスを返す関数
 * @param maxWorkerCount 同時実行最大数
 * @returns 実行結果
 */
export const promiseAllConcurrency = async <T extends readonly (() => unknown)[] | []>(
  promiseFactories: T,
  maxWorkerCount: number
): Promise<AllConcurrencyReturnType<T>> => {
  const tasks = Array.from(promiseFactories)

  /** 処理位置 */
  let workIdx = 0
  const results: unknown[] = []
  const workers: Promise<boolean>[] = []
  Array.from(Array(maxWorkerCount)).forEach(() => {
    const p = new Promise<boolean>(async (resolve, reject) => {
      while (workIdx < tasks.length) {
        const idx = workIdx
        const task = tasks[idx]
        // awaitの前にインクリメントする
        workIdx++
        try {
          const result = await task()
          results[idx] = result
        } catch (e) {
          // 失敗したらワーカー終了
          reject(e)
          return
        }
      }
      resolve(true)
    })
    workers.push(p)
  })

  await Promise.all(workers)

  // タイプ「unknown[]」からタイプ「AllConcurrencyReturnType<T>」への変換は、
  // どちらのタイプも他方と十分にオーバーラップしないため、
  // 間違いである可能性があります。
  // これが意図的なものである場合は、最初に式を「unknown」に変換してください。
  // とのコンパイルエラー指摘を受け一旦unknown
  const retValue: unknown = results
  return retValue as AllConcurrencyReturnType<T>
}
