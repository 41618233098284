import { Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { yokohamaCityTemporaryChildcareReservationServiceUrl } from '../../containers/common/constant/externalUrl'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { GContainer, GItem } from '../components/common/grids'
import { Link } from '../components/common/link'
import { SubTitle } from '../components/common/subTitle'
const AttributeGContainer = styled(GContainer)({
  padding: '1rem 2rem 2rem 3rem',
})
const PageTitleNoBoder = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL2,
  letterSpacing: '0.2em',
  color: theme.palette.secondary.main,
}))
const TableCellBace = styled(TableCell)(({ theme }) => ({
  borderTop: '0.5px solid' + theme.palette.grayDark.dark,
  borderRight: '0.5px solid' + theme.palette.grayDark.dark,
  borderLeft: '0.5px solid' + theme.palette.grayDark.dark,
  borderBottom: '0.5px solid' + theme.palette.grayDark.dark,
}))
const HeaderTableCell = styled(TableCellBace)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main,
}))

const BodyTableCell = styled(TableCellBace)({
  maxWidth: '250px',
})
const BodyTableCellS = styled(TableCellBace)({
  maxWidth: '10px',
})
const TextLeft = styled(Typography)({
  textAlign: 'left',
})
const TextLink = styled(Link)({
  textAlign: 'left',
})
const TextCenter = styled(Typography)({
  textAlign: 'center',
})

const list = [
  {
    no: '1.1.1',
    criterion: '非テキストコンテンツに関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  {
    no: '1.2.1',
    criterion: '音声のみ及び映像のみ (収録済) に関する達成基準',
    grade: 'A',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '1.2.2',
    criterion: 'キャプション (収録済) に関する達成基準',
    grade: 'A',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '1.2.3',
    criterion: '音声解説、又はメディアに対する代替 (収録済) に関する達成基準',
    grade: 'A',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '1.2.4',
    criterion: 'キャプション (ライブ) に関する達成基準',
    grade: 'AA',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '1.2.5',
    criterion: '音声解説 (収録済) に関する達成基準',
    grade: 'AA',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '1.3.1 ',
    criterion: '情報及び関係性に関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  { no: '1.3.2', criterion: '意味のある順序に関する達成基準', grade: 'A', apply: '〇', suitable: '〇', note: '' },
  {
    no: '1.3.3',
    criterion: '感覚的な特徴に関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  { no: '1.4.1', criterion: '色の使用に関する達成基準', grade: 'A', apply: '〇', suitable: '×', note: '一部非対応' },
  {
    no: '1.4.2',
    criterion: '音声の制御に関する達成基準',
    grade: 'A',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '1.4.3 ',
    criterion: 'コントラスト (最低限) に関する達成基準',
    grade: 'AA',
    apply: '〇',
    suitable: '〇',
    note: '',
  },
  {
    no: '1.4.4',
    criterion: 'テキストのサイズ変更に関する達成基準',
    grade: 'AA',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  { no: '1.4.5 ', criterion: '文字画像に関する達成基準', grade: 'AA', apply: '〇', suitable: '△', note: '' },
  { no: '2.1.1 ', criterion: 'キーボードに関する達成基準', grade: 'A', apply: '〇', suitable: '×', note: '一部非対応' },
  {
    no: '2.1.2',
    criterion: 'キーボードトラップなしに関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  {
    no: '2.2.1 ',
    criterion: 'タイミング調整可能に関する達成基準',
    grade: 'A',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '2.2.2',
    criterion: '一時停止、停止、非表示に関する達成基準',
    grade: 'A',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  {
    no: '2.3.1',
    criterion: '3 回の閃光、又は閾値以下に関する達成基準',
    grade: 'A',
    apply: 'ー',
    suitable: '〇',
    note: '該当するコンテンツ無し',
  },
  { no: '2.4.1', criterion: 'ブロックスキップに関する達成基準', grade: 'A', apply: '〇', suitable: '〇', note: '' },
  { no: '2.4.2', criterion: 'ページタイトルに関する達成基準', grade: 'A', apply: '〇', suitable: '〇', note: '' },
  {
    no: '2.4.3',
    criterion: 'フォーカス順序に関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  {
    no: '2.4.4 ',
    criterion: 'リンクの目的 (コンテキスト内) に関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '〇',
    note: '',
  },
  { no: '2.4.5', criterion: '複数の手段に関する達成基準', grade: 'AA', apply: '〇', suitable: '×', note: '一部非対応' },
  {
    no: '2.4.6',
    criterion: '見出し及びラベルに関する達成基準',
    grade: 'AA',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  { no: '2.4.7 ', criterion: 'フォーカスの可視化に関する達成基準', grade: 'AA', apply: '〇', suitable: '〇', note: '' },
  { no: '3.1.1', criterion: 'ページの言語に関する達成基準', grade: 'A', apply: '〇', suitable: '〇', note: '' },
  { no: '3.1.2', criterion: '一部分の言語に関する達成基準', grade: 'AA', apply: '〇', suitable: '〇', note: '' },
  { no: '3.2.1', criterion: 'フォーカス時に関する達成基準', grade: 'A', apply: '〇', suitable: '〇', note: '' },
  { no: '3.2.2', criterion: '入力時に関する達成基準', grade: 'A', apply: '〇', suitable: '〇', note: '' },
  {
    no: '3.2.3',
    criterion: '一貫したナビゲーション に関する達成基準',
    grade: 'AA',
    apply: '〇',
    suitable: '〇',
    note: '',
  },
  {
    no: '3.2.4',
    criterion: '一貫した識別性に関する達成基準',
    grade: 'AA',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  { no: '3.3.1 ', criterion: 'エラーの特定に関する達成基準', grade: 'A', apply: '〇', suitable: '〇', note: '' },
  {
    no: '3.3.2',
    criterion: 'ラベル又は説明に関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  { no: '3.3.3', criterion: 'エラー修正の提案に関する達成基準', grade: 'AA', apply: '〇', suitable: '〇', note: '' },
  {
    no: '3.3.4',
    criterion: 'エラー回避 (法的、金融、データ) に関する達成基準',
    grade: 'AA',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
  { no: '4.1.1', criterion: '構文解析に関する達成基準', grade: 'A', apply: '〇', suitable: '×', note: '一部非対応' },
  {
    no: '4.1.2',
    criterion: '名前 (name) ・役割 (role) 及び値 (value) に関する達成基準',
    grade: 'A',
    apply: '〇',
    suitable: '×',
    note: '一部非対応',
  },
]
export const WebAccessibilityPolicy = () => {
  return (
    <Stack>
      <GContainer rowSpacing={3}>
        <GItem md={1}></GItem>
        <GItem xs={12} md={10}>
          <PageTitleNoBoder> ウェブアクセシビリティ方針 </PageTitleNoBoder>
          <AttributeGContainer>
            <Typography>
              {
                '横浜市一時預かりWEB予約システムでは、ウェブアクセシビリティー向上のため、日本工業規格JIS X 8341-3:2016 のウェブコンテンツのアクセシビリティ達成等級 AAに準拠することを目指します。'
              }
            </Typography>
          </AttributeGContainer>
        </GItem>
        <GItem md={1}></GItem>
        <GItem md={1}></GItem>
        <GItem xs={12} md={10}>
          <SubTitle title={'対象範囲'} />
          <AttributeGContainer>
            <GItem xs={12} md={12}>
              <TextLeft>{'横浜市一時預かりWEB予約システム'}</TextLeft>
              <TextLink href={yokohamaCityTemporaryChildcareReservationServiceUrl} target="_blank">
                {`(${yokohamaCityTemporaryChildcareReservationServiceUrl})`}
              </TextLink>
            </GItem>
          </AttributeGContainer>
          <SubTitle title={'目標とする等級'} />
          <AttributeGContainer>
            <TextLeft>
              {'日本工業規格 JIS X 8341-3:2016のウェブアクセシビリティ達成等級AA を満たすことを目標とします。'}
            </TextLeft>
          </AttributeGContainer>
          <SubTitle title={'試験実施期間'} />
          <AttributeGContainer>
            <TextLeft>{'2022年12月'}</TextLeft>
          </AttributeGContainer>
          <SubTitle title={'達成基準チェックリスト'} />
        </GItem>
        <GItem md={1}></GItem>
      </GContainer>
      <GContainer>
        <GItem md={1}></GItem>
        <GItem xs={12} md={10}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <HeaderTableCell>
                  <TextCenter>{'細分箇条'}</TextCenter>
                </HeaderTableCell>
                <HeaderTableCell>
                  <TextCenter>{'基準'}</TextCenter>
                </HeaderTableCell>
                <HeaderTableCell>
                  <TextCenter>{'等級'}</TextCenter>
                </HeaderTableCell>
                <HeaderTableCell>
                  <TextCenter>{'適用'}</TextCenter>
                </HeaderTableCell>
                <HeaderTableCell>
                  <TextCenter>{'適合'}</TextCenter>
                </HeaderTableCell>
                <HeaderTableCell>
                  <TextCenter>{'備考'}</TextCenter>
                </HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <TableRow key={index}>
                  <BodyTableCellS>
                    <TextCenter>{row.no}</TextCenter>
                  </BodyTableCellS>
                  <BodyTableCell>
                    <TextLeft>{row.criterion}</TextLeft>
                  </BodyTableCell>
                  <BodyTableCellS>
                    <TextCenter>{row.grade}</TextCenter>
                  </BodyTableCellS>
                  <BodyTableCellS>
                    <TextCenter>{row.apply}</TextCenter>
                  </BodyTableCellS>
                  <BodyTableCellS>
                    <TextCenter>{row.suitable}</TextCenter>
                  </BodyTableCellS>
                  <BodyTableCell>
                    <TextLeft>{row.note}</TextLeft>
                  </BodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GItem>
        <GItem md={1}></GItem>
      </GContainer>
      <GContainer>
        <GItem md={1}></GItem>
        <GItem md={10} sm={12}>
          <Typography align={'left'}>{'記号の種類'}</Typography>
          <Typography align={'left'}>{'適用欄○：「適用」、－：「非適用」'}</Typography>
          <Typography align={'left'}>{'適合欄○：「適合」、×：「非適合」'}</Typography>
          <Typography align={'left'}>
            {'※達成基準項目に該当するコンテンツが無い場合(非適用)は適合「○」となります。'}
          </Typography>
        </GItem>
        <GItem md={1}></GItem>
      </GContainer>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
