// import { executePost, KANRI_API_NAME } from '../common/apiCaller'
import { executeGet, executePost } from '../common/apiCaller'
import { PostLinkSettingsDto } from '../dto/linkSettingsDto'

// const apiName = KANRI_API_NAME
const apiName = 'public'
const apiPath = '/link-settings'

/**
 * 当日分を全件取得
 */
export const executeGetLinkSettings = () => {
  return executeGet<PostLinkSettingsDto[]>(apiName, apiPath)
}

/**
 * 全件取得
 */
export const executePostLinkSettings = () => {
  return executePost<PostLinkSettingsDto[]>(apiName, apiPath)
}
