import { Box, styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface InfoCardProps {
  children: ReactNode
  className?: string
}

const Root = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.white.main,
  border: `6px solid ${theme.palette.info.main}`,
  width: '100%',
  padding: '20px',
}))

export const InfoCard = (props: InfoCardProps) => {
  return <Root className={props.className}>{props.children}</Root>
}
