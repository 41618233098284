import { MouseEvent, useState } from 'react'
import { executePostInformationAttachment } from '../../../dataAccess/webApi/dao/informationAttachmentDao'
import { translate } from '../../../i18n'
import { ErrorMessageCard } from '../../components/common/errorMessageCard'
import { Link } from '../../components/common/link'

export interface Attachment {
  fileName?: string
  key?: string
}

interface FileLinkProps {
  attachments: Attachment[]
}

export const AttachmentLinks = ({attachments}: FileLinkProps) => {
  const [errorFlg, setErrorFlg] = useState<boolean>(false)

  const onClick = (fileName: string, key: string) => async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    try{
      // S3バケット上のファイルへの一時アクセス用のURLを取得
      const res = await executePostInformationAttachment({ fileName, key })
  
      const signedUrl = res.result.url
      const ext = fileName.split('.').pop()?.toLowerCase()
  
      // Google Docs Viewer を利用してファイルを別タブでプレビュー
      // ただし、Google Docs Viewer は jpg, jpeg には対応していないため、jpg, jpeg は画像のURLをそのまま別タブで開く
      const url = ext === "jpg" || ext === "jpeg"
        ? signedUrl
        : `https://docs.google.com/viewer?url=${encodeURIComponent(signedUrl)}`
  
      window.open(url, '_blank');
      setErrorFlg(false)
    }catch(err){
      setErrorFlg(true)
    }
  }

  return (
    <>
      {attachments.map(({fileName, key}) => (
        fileName && key && <Link
            key={key}
            onClick={onClick(fileName, key)}
          >
            {fileName}
          </Link>
      ))}
      {errorFlg && <ErrorMessageCard messages={[translate('information.error.fileAcquisitionFailure')]} />}
    </>
  )
}