import { styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface AttentionLabelProps {
  children: ReactNode
  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string
}

const Root = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}))

/** 注目させたい文言に使用する赤字のラベルコントロール */
export const AttentionLabel = (props: AttentionLabelProps) => {
  return <Root className={props.className}>{props.children}</Root>
}
