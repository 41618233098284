import { Grid, Stack } from '@mui/material'
import React, { ReactNode } from 'react'
import { getReservationStatusLabel } from '../../../../containers/common/codeMaster'
import { facilityReservationDetailUrl } from '../../../../containers/common/constant/appUrl'
import { translate } from '../../../../i18n'
import { formatHmtoHmOver, formatYmdWeek } from '../../../../utils/dateUtil'
import { Card } from '../card'
import { LabelGItemBase, RightGItem, ValueGItemBase } from '../grids'
import { Link } from '../link'
import { OperationId } from '../../../../containers/common/constant/operationLog'

interface ReservationCardProps {
  reservationNo: string
  childName: string
  facilityName: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  status: string
  isCpuponFlag?: boolean
}

const LabelItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={4}>{props.children}</LabelGItemBase>
const ValueItem = (props: { children: ReactNode }) => <ValueGItemBase xs={8}>{props.children}</ValueGItemBase>

export const ReservationCard = (props: ReservationCardProps) => {
  return (
    <Card>
      <Grid container>
        <LabelItem>{translate('system.reservationCard.label.reservationDatetime')}</LabelItem>
        <ValueItem>
          <Stack>
            <div>{formatYmdWeek(props.usageDate)}</div>
            <div>{formatHmtoHmOver({ from: props.useFromDatetime, to: props.useToDatetime }, props.usageDate)}</div>
          </Stack>
        </ValueItem>
        <LabelItem>{translate('system.reservationCard.label.facilityName')}</LabelItem>
        <ValueItem>{props.facilityName}</ValueItem>
        <LabelItem>{translate('system.reservationCard.label.childName')}</LabelItem>
        <ValueItem>{props.childName}</ValueItem>
        <LabelItem>{translate('system.reservationCard.label.status')}</LabelItem>
        <ValueItem>{getReservationStatusLabel(props.status)}</ValueItem>

        <RightGItem xs={12}>
          {props.isCpuponFlag === true ? (
            <Link
              to={facilityReservationDetailUrl.url(props.reservationNo)}
              operationLog={{ operationId: OperationId.OP_00000064 }}
            >
              {`${translate('system.reservationCard.text.timeInput')}>`}
            </Link>
          ) : (
            <Link
              to={facilityReservationDetailUrl.url(props.reservationNo)}
              operationLog={{ operationId: OperationId.OP_00000044 }}
            >
              {`${translate('system.reservationCard.text.reservationDetail')}>`}
            </Link>
          )}
        </RightGItem>
      </Grid>
    </Card>
  )
}
