import CancelIcon from '@mui/icons-material/Cancel'
import {
  Breakpoint,
  DialogActions,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material'
import { ElementType, ReactNode, useCallback } from 'react'
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { ExclamationMarkIcon } from './icons/exclamationMarkIcon'
import { Form } from './inputs/form'

interface DialogProps<FormInputs extends FieldValues, TContext extends object> {
  /** ダイアログのコンテンツ部 */
  children: ReactNode
  /**
   * コンテンツ要素のコンポーネント。
   * 指定が無ければ {@link DialogContent} を使用する
   */
  contentComponent?: ElementType
  /** ダイアログを表示する場合true */
  isOpen: boolean
  /** ダイアログが閉じられる操作が行われたときに呼び出される関数 */
  onClose: () => void
  /**
   * ダイアログのタイトル。指定なしの場合はタイトルなしで表示
   */
  title?: string
  /**
   * ダイアログ下部に配置するボタン。
   * 指定なければそのエリアはなしで表示する
   */
  buttons?: ReactNode
  /** ダイアログの最大幅 */
  maxWidth?: Breakpoint | false
  /**
   * trueの場合、ダイアログはmaxWidthまで拡大されます。
   * ダイアログの幅の拡大は、デフォルトのマージンによって制限されていることに注意してください。
   */
  fullWidth?: boolean
  /** ダイアログの高さを100%にする場合true */
  fullHeight?: boolean
  /** ダイアログ右上に閉じるボタンを表示する場合true */
  isShownCloseButton?: boolean

  /**
   * submit時に呼び出される関数。
   * コントロールのバリデーションでエラーになった場合は呼び出されません。
   */
  onSubmit?: SubmitHandler<FormInputs>
  /**
   * useForm関数の戻り値を設定する
   */
  formMethods?: UseFormReturn<FormInputs, TContext>
}

const Root = styled(MuiDialog, { shouldForwardProp: (prop) => prop !== 'fullHeight' })<{ fullHeight?: boolean }>(
  ({ fullHeight }) => ({
    ...(fullHeight && {
      '.MuiDialog-paper': {
        height: '100%',
      },
    }),
  })
)
const CloseButton = styled(IconButton)({ position: 'absolute', top: 0, right: 0, zIndex: 10, padding: 4 })

const Title = styled(DialogTitle)(({ theme }) => ({
  fontSize: theme.typography.font.sizeM,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.error.main,
}))
const TitleIcon = styled('div')({
  textAlign: 'center',
  fontSize: '4rem',
  lineHeight: 0.6,
})
const TitleText = styled('div')({
  textAlign: 'center',
})
export const DialogContent = styled(MuiDialogContent)({
  paddingBottom: 8,
})

const Actions = styled(DialogActions)({
  justifyContent: 'center',
  paddingBottom: 16,
})

/**
 * モーダルダイアログコントロール
 */
export const Dialog = <FormInputs extends FieldValues, TContext extends object>(
  props: DialogProps<FormInputs, TContext>
) => {
  const {
    isOpen,
    onClose,
    children,
    contentComponent,
    buttons,
    title,
    fullHeight,
    isShownCloseButton,
    onSubmit,
    formMethods,
    ...through
  } = props
  const close = useCallback(() => onClose(), [onClose])

  const ContentComp = contentComponent ?? DialogContent
  const contentActions = (
    <>
      <ContentComp>{children}</ContentComp>
      {buttons && <Actions>{buttons}</Actions>}
    </>
  )
  return (
    <Root {...through} fullHeight={fullHeight} open={isOpen} onClose={close}>
      {isShownCloseButton && (
        <CloseButton onClick={close}>
          <CancelIcon />
        </CloseButton>
      )}
      {title && (
        <Title>
          <TitleIcon>
            <ExclamationMarkIcon fontSize="inherit" />
          </TitleIcon>
          <TitleText>{title}</TitleText>
        </Title>
      )}

      {onSubmit && formMethods ? (
        <Form onSubmit={onSubmit} formMethods={formMethods}>
          {contentActions}
        </Form>
      ) : (
        contentActions
      )}
    </Root>
  )
}
