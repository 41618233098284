import React from 'react'
import MuiHomeIcon from '@mui/icons-material/Home'
import { IconProps } from './iconBase'

/**
 * HOME画面アイコン。
 */
export const HomeIcon = (props: IconProps) => {
  return <MuiHomeIcon {...props} />
}
