import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'
import { yesNo } from '../../constant/classification'

/**
 * システム全体の制御情報
 * 市民機能で必要な情報があれば追加する
 */
export interface SystemControl {
  serviceTitle: string
  iconImageName: string | null
  s3BucketNameIcon: string
  projectSearchFlag: string
  facilityCategorySearchFlag: string
  systemDatetime?: ElapsedMillisecond
  /** S3バケット(操作マニュアル) */
  s3BucketNameManual: string
  useCouponFunctionFlag: string
  /** クーポン利用できる期限（開始日） */
  usageCouponFromDate?: ElapsedMillisecond
  /** クーポン利用できる期限（終了日） */
  usageCouponToDate?: ElapsedMillisecond
  /** アカウント退会受付可否フラグ */
  unsubscribeAcceptFlag: string
  /** お子さま利用終了受付可否フラグ */
  childUseEndAcceptFlag: string
  /** SSO利用フラグ */
  ssoUseFlag: string,
  /** SSOリンク先名称 */
  ssoUseName: string | null,
  /** SSO連携時メール認証要否 */
  ssoEmailCertificationFlag: string | null,
  /** 電話番号の認証要否 */ 
  phoneCertificationFlag: string,
}

const defaultSystemControl: SystemControl = {
  serviceTitle: '',
  iconImageName: '',
  s3BucketNameIcon: '',
  projectSearchFlag: yesNo.no,
  facilityCategorySearchFlag: yesNo.no,
  s3BucketNameManual: '',
  useCouponFunctionFlag: yesNo.no,
  unsubscribeAcceptFlag: yesNo.no,
  childUseEndAcceptFlag: yesNo.no,
  ssoUseFlag: yesNo.no,
  ssoUseName: null,
  ssoEmailCertificationFlag: null,
  phoneCertificationFlag: yesNo.no,
}

interface SystemControlState {
  systemControl?: SystemControl
}

const initialState: SystemControlState = {}

const sliceName = 'systemControl'

export const systemControlSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSystemControl: (state, action: PayloadAction<SystemControl | undefined>) => {
      state.systemControl = action.payload
    },
  },
})

export const { setSystemControl } = systemControlSlice.actions
export const selectSystemControl = (state: RootState) => state.systemControl.systemControl ?? defaultSystemControl
export default systemControlSlice.reducer
