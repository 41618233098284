import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Divider, Drawer, IconButton, styled } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeSideMenu, selectIsOpen } from '../../../containers/common/store/slices/application'
import { SideMenuContent } from './sideMenuContent'

const drawerWidth = 240
const StyledDrawer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-start',
}))

export const SideMenu = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsOpen)
  const close = useCallback(() => dispatch(closeSideMenu()), [])

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={close}>
      <DrawerHeader>
        <IconButton onClick={close}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <SideMenuContent />
    </StyledDrawer>
  )
}
