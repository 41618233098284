import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Action } from 'history'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

/**
 * ブラウザ履歴の最小の長さ。
 * ブラウザを表示するとそのページが履歴に入るので最小は1
 */
const historyLengthMin = 1
interface ApplicationState {
  /** サイドメニュー開閉状態 */
  isOpen: boolean
  /** ローディング表示状態 */
  isLoading: boolean
  /** mainのvisibleをhideにするか否か */
  isHiddenMain: boolean
  /** 1vhあたりのピクセル */
  pxPerVh: number
  /** 画面下アクションコンテナの高さ */
  bottomActionContainerHeight: number
  /** フッターが画面内に入ったか */
  isIntersectingFooter: boolean
  /** トースト表示メッセージ */
  message?: {
    content: string
    timestamp: ElapsedMillisecond
    autoHideDuration?: number
  }
  /** ブラウザの履歴スタック数 */
  historyStackLength: number
}

type LoadingProcess = () => void

const initialState: ApplicationState = {
  isOpen: false,
  isLoading: false,
  isHiddenMain: false,
  isIntersectingFooter: false,
  pxPerVh: 0,
  bottomActionContainerHeight: 0,
  historyStackLength: historyLengthMin,
}

const sliceName = 'application'

export const showLoading = createAsyncThunk(
  `${sliceName}/showLoading`,
  async (process: LoadingProcess | { process: LoadingProcess; isHiddenMain: boolean }, thunkAPI) => {
    if (typeof process === 'function') {
      thunkAPI.dispatch(setIsHiddenMain(true))
      await process()
    } else {
      thunkAPI.dispatch(setIsHiddenMain(process.isHiddenMain))
      await process.process()
    }
  }
)
export const applicationSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    openSideMenu: (state) => {
      state.isOpen = true
    },
    closeSideMenu: (state) => {
      state.isOpen = false
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsHiddenMain: (state, action: PayloadAction<boolean>) => {
      state.isHiddenMain = action.payload
    },
    setPxPerVh: (state, action: PayloadAction<number>) => {
      state.pxPerVh = action.payload
    },
    setBottomActionContainerHeight: (state, action: PayloadAction<number>) => {
      state.bottomActionContainerHeight = action.payload
    },
    setIsIntersectingFooter: (state, action: PayloadAction<boolean>) => {
      state.isIntersectingFooter = action.payload
    },
    notifyMessage: (state, action: PayloadAction<string | { content: string; autoHideDuration: number }>) => {
      // timestampは同じ文言でも別メッセージとして扱うために使用している為、JSTでの生成は不要
      const timestamp = Date.now()
      let content
      let autoHideDuration
      if (typeof action.payload === 'string') {
        content = action.payload
      } else {
        content = action.payload.content
        autoHideDuration = action.payload.autoHideDuration
      }

      state.message = { content, timestamp, autoHideDuration }
    },
    updateHistoryStackLength: (state, action: PayloadAction<Action>) => {
      switch (action.payload) {
        case 'POP':
          if (state.historyStackLength > historyLengthMin) {
            // メモリ管理のカウントである為、F5ボタンなどで画面リロードされるとクリアされてしまう
            // その場合最小数より減算しないようにする
            state.historyStackLength--
          }
          break
        case 'REPLACE':
          // スタック数変動なし
          break
        case 'PUSH':
          state.historyStackLength++
          break
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reset: (state, action: PayloadAction<{ isKeepAuthority: boolean }>) => {
      // RootStateの初期化が必要なのでstate初期化処理はRootRecucerで実装
      // そのため、引数未使用のeslintチェックも無効化している
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(showLoading.pending, (state) => {
        state.isLoading = true
      })
      .addCase(showLoading.fulfilled, (state) => {
        state.isLoading = false
        state.isHiddenMain = false
      })
      .addCase(showLoading.rejected, (state) => {
        state.isLoading = false
        state.isHiddenMain = false
      })
  },
})

export const {
  openSideMenu,
  closeSideMenu,
  setIsLoading,
  setPxPerVh,
  setBottomActionContainerHeight,
  setIsIntersectingFooter,
  setIsHiddenMain,
  notifyMessage,
  updateHistoryStackLength,
  reset,
} = applicationSlice.actions
export const selectIsOpen = (state: RootState) => state.application.isOpen
export const selectIsLoading = (state: RootState) => state.application.isLoading
export const selectIsHiddenMain = (state: RootState) => state.application.isHiddenMain
export const selectPxPerVh = (state: RootState) => state.application.pxPerVh
export const selectBottomActionContainerHeight = (state: RootState) => state.application.bottomActionContainerHeight
export const selectIsIntersectingFooter = (state: RootState) => state.application.isIntersectingFooter
export const selectMessage = (state: RootState) => state.application.message
export const selectIsExistsBackHistory = (state: RootState) => state.application.historyStackLength > historyLengthMin
export default applicationSlice.reducer
