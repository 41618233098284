import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { executeGetFacility } from '../../dataAccess/webApi/dao/facilitiesDao'
import { executeGetUsageRecord } from '../../dataAccess/webApi/dao/usageRecordsDao'
import { fromApiYmd, fromApiYmdHms, toApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { facilityReservationSelectionCreateUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'

interface UrlParams {
  reservationNo: string
}

interface UsageDetail {
  facilityId: string
  facilityName: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  status: string
  usageMinute?: string
  name: string
  kana: string
  tel: string
  email: string

  childId: string
  childName: string
  childKana: string

  useReasonCategory?: string
  lunchFlag: string
  snackFlag: string
  citizenNote?: string
  lunchCount?: number
  snackCount?: number
  
  amount?: number

  reservationCancelDatetime?: Date
}

interface FacilityInfo {
  lunchAcceptFlag: string
  snackAcceptFlag: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { reservationNo } = useParams<UrlParams>()
  const { addOperationLog, attachAccessData } = useOperationLog()

  const [usageDetail, setUsageDetail] = useState<UsageDetail>()
  const [facilityInfo, setFacilityInfo] = useState<FacilityInfo>()

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          const result = await getUsageDetail(reservationNo)
          attachAccessData({
            accessData: [
              {
                userIdRegFlag: yesNo.yes,
                childId: result.childId,
                usageDate: toApiYmd(result.usageDate),
                reservationNo,
              },
            ],
          })
          setUsageDetail(result)
          const facility = await getFacilityInfo(result.facilityId, toApiYmd(result.usageDate))
          setFacilityInfo(facility)
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goReservation = useCallback(
    () =>
      usageDetail &&
      history.push(facilityReservationSelectionCreateUrl.url(usageDetail.facilityId, usageDetail.childId)),
    [usageDetail]
  )

  return {
    usageDetail,
    facilityInfo,
    goReservation,
  }
}

const getUsageDetail = async (reservationNo: string) => {
  const response = await executeGetUsageRecord(reservationNo)
  const { usageDate, useFromDatetime, useToDatetime, reservationCancelDatetime, usageMinute, ...other } =
    response.result
  return nullPropsToUndefined({
    ...other,
    usageDate: fromApiYmd(usageDate),
    useFromDatetime: fromApiYmdHms(useFromDatetime),
    useToDatetime: fromApiYmdHms(useToDatetime),
    reservationCancelDatetime: fromApiYmdHms(reservationCancelDatetime),
    usageMinute: formatLocaleTimeAmountHm(usageMinute),
  })
}

const getFacilityInfo = async (facilityId: string, usageDate: string) => {
  const response = await executeGetFacility(facilityId, { targetDate: usageDate })
  const { lunchAcceptFlag, snackAcceptFlag } = response.result
  return { lunchAcceptFlag, snackAcceptFlag }
}
