import { Redirect } from 'react-router-dom'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/identityVerification/identityVerificationService'
import { translate } from '../../i18n'
import { OneTimePasswordVerificationContent } from '../components/common/account/oneTimePasswordVerificationContent'
import { Form } from '../components/common/inputs/form'

export const IdentityVerification = () => {
  const { formMethods, isVerifiedTel, identifier, from, resend, onSubmit } = useAction()
  if (isVerifiedTel) {
    return <Redirect to={from ? from : rootUrl.url()} />
  }
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <OneTimePasswordVerificationContent
        identifier={identifier}
        resend={resend}
        infoMessage={translate('identityVerification.text.infoMessage')}
      />
    </Form>
  )
}
