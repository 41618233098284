import { executeGetNoResultError, executePut } from '../common/apiCaller'
import { GetUserDto, PutUserInputDto } from '../dto/userDto'

const apiName = 'citizen'
const apiPath = '/user'

export const executeGetUser = () => {
  return executeGetNoResultError<GetUserDto>(apiName, apiPath)
}

export const executePutUser = (input: PutUserInputDto) => {
  return executePut<null>(apiName, apiPath, input)
}
