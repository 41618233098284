import { styled } from '@mui/material'
import { ButtonContainedBase } from './buttonContainedBase'

/**
 * errorカラーのボタンコントロールLサイズ。
 */
export const ButtonErrorL = styled(ButtonContainedBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
  padding: '18px 18px',
  backgroundColor: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
    '@media (hover: none)': {
      backgroundColor: theme.palette.error.main,
    },
  },
}))
