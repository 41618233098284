import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/memberModifyConfirmationChild/memberModifyConfirmationChildService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ChildContent } from '../components/common/member/childContent'
import { TransitionCheck } from '../components/common/transitionCheck'
import { ErrorMessageCard } from '../components/common/errorMessageCard'

export const MemberModifyConfirmationChild = () => {
  const { child, childrenResultCode, decide } = useAction()
  if (child == null) {
    return <TransitionCheck check={false} />
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <ChildContent child={child} />

            {!!childrenResultCode && (
              <ErrorMessageCard
                messages={[translate(`memberModifyConfirmationChild.error.childrenResultCode${childrenResultCode}`)]}
              />
            )}
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={decide}>
          {translate('memberModifyConfirmationChild.button.decision')}
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
