import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePutAccount, resultCodePutAccount } from '../../dataAccess/webApi/dao/accountDao'
import { translate } from '../../i18n'
import { getSessionAccessToken } from '../../utils/authUtil'
import { accountChangeVerificationUrl } from '../common/constant/appUrl'
import { accountChangeVerificationCategory, yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { setAccountChange } from '../common/store/slices/accountChange'
import { notifyMessage, showLoading } from '../common/store/slices/application'

interface Inputs {
  newEmail: string
  newEmailConfirmation: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const formMethods = useForm<Inputs>()
  const { addOperationLog } = useOperationLog()

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes }] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: Inputs) => {
    addOperationLog({ operationId: OperationId.OP_00000035, accessData: [{ userIdRegFlag: yesNo.yes }] })

    if (data.newEmail !== data.newEmailConfirmation) {
      formMethods.setError(
        'newEmailConfirmation',
        { message: translate('system.error.notSameEmail') },
        { shouldFocus: true }
      )
      return
    }
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          const response = await executePutAccount({
            accessToken: await getSessionAccessToken(),
            tel: null,
            email: data.newEmail,
            password: null,
            newPassword: null,
          })
          if (response.resultCode) {
            // 登録失敗時
            let newEmailErrorKey
            switch (response.resultCode) {
              case resultCodePutAccount.notChangedEmail:
                newEmailErrorKey = 'accountEmailChange.error.notChanged'
                break
              case resultCodePutAccount.alreadyRegisteredEmail:
                newEmailErrorKey = 'accountEmailChange.error.alreadyRegistered'
                break
            }
            if (newEmailErrorKey) {
              // ローディング表示中はsetErrorでメッセージ表示が反映されないので遅延させる
              const errorKey = newEmailErrorKey
              setTimeout(() => {
                formMethods.setError('newEmail', { message: translate(errorKey) }, { shouldFocus: true })
              }, 0)
            }
          } else {
            dispatch(
              setAccountChange({
                category: accountChangeVerificationCategory.email,
                email: data.newEmail,
              })
            )
            dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
            history.push(accountChangeVerificationUrl.url())
          }
        }),
        isHiddenMain: false,
      })
    )
  }

  return {
    formMethods,
    onSubmit,
  }
}
