import { FormHelperText, SelectChangeEvent, styled } from '@mui/material'
import { Ref, forwardRef, useCallback } from 'react'
import { Control, useController } from 'react-hook-form'
import { translate } from '../../../../i18n'
import { getValueByNameStr } from '../../../../utils/objectUtil'
import { fromNumber, toInt } from '../../../../utils/stringUtil'
import { castNonNullable } from '../../../../utils/typeUtil'
import { GContainer, MiddleGItem } from '../grids'
import { SizeLabelBase } from '../sizeLabelBase'
import { SelectItem, SelectNoBind } from './selectNoBind'

const BaseSelect = styled(SelectNoBind)(({ theme }) => ({
  fontSize: theme.typography.font.sizeLL1,
  minWidth: '7.4rem',
}))
const CustomItem = styled(SelectItem)(({ theme }) => ({
  fontSize: theme.typography.font.sizeLL1,
}))

type PartType = 'hour' | 'minute'

const splitAmount = (value: number | null) => {
  if (value == null) {
    return {
      hour: null,
      minute: null,
    }
  }
  const hour = Math.floor(value / 60)
  const minute = value % 60
  return {
    hour: hour,
    minute: minute,
  }
}
const spliceAmount = (currentValue: number | null, newValue: number | null, partType: PartType) => {
  let { hour, minute } = splitAmount(currentValue)
  if (partType === 'hour') {
    hour = newValue
  } else {
    minute = newValue
  }
  return hour == null && minute == null ? null : (hour ?? 0) * 60 + (minute ?? 0)
}

interface TimeSelectProps {
  partType: PartType
  /** 最大時間 */
  maxHour: number
  /** 入力可能な単位(分) */
  minuteStepUnit: number
  error: boolean
  value: number | null
  onChange: (...event: any[]) => void
  onBlur: (...event: any[]) => void
}

const getHourValueLabels = (maxHour: number) => {
  return [...Array(maxHour + 1)].map((_, i) => {
    const value = fromNumber(i)
    return { value, label: value }
  })
}
const getMinuteValueLabels = (minuteStepUnit: number) => {
  const unitCount = Math.floor(60 / minuteStepUnit)
  const valueLabels = []
  for (let i = 0; i < unitCount; i++) {
    const minute = i * minuteStepUnit
    const value = fromNumber(minute)
    valueLabels.push({ value, label: value })
  }
  return valueLabels
}

const TimeSelect = forwardRef(function TimeTextBox(props: TimeSelectProps, ref: Ref<HTMLSelectElement>) {
  const { onChange, value, partType, minuteStepUnit, maxHour, ...through } = props

  const valueLables = partType === 'hour' ? getHourValueLabels(maxHour) : getMinuteValueLabels(minuteStepUnit)

  const onChangeHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      const inputValue = event.target.value
      const numValue = inputValue ? toInt(inputValue) : null
      const newValue = spliceAmount(value, numValue, partType)
      onChange(newValue)
    },
    [onChange, value, partType]
  )

  const partValue = splitAmount(value)[partType]
  return (
    <BaseSelect
      {...through}
      itemComponent={CustomItem}
      ref={ref}
      valueLabels={valueLables}
      value={partValue == null ? '' : fromNumber(partValue)}
      onChange={onChangeHandler}
    />
  )
})

const Label = styled(SizeLabelBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeLL1,
  marginLeft: 8,
}))

interface TimeAmountSelectProps {
  /** 入力値を紐づける(バインドする)インプットオブジェクトのプロパティ名  */
  name: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 最大値(分) */
  maxMinute: number
  /** 入力可能な単位(分) */
  minuteStepUnit: number
  /** 入力必須とする場合true */
  required?: boolean

  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
}

export const TimeAmountSelect = (props: TimeAmountSelectProps) => {
  const { name, label, maxMinute, minuteStepUnit, required, control } = props

  const maxHour = castNonNullable(splitAmount(maxMinute).hour)

  const {
    field: { ref, value, onChange, onBlur },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: { value: !!required, message: translate('system.error.requiredInput', label) },
      validate: {
        invalidAmount: (value?: number) =>
          value == null ||
          value % minuteStepUnit === 0 ||
          translate('system.error.timeAmountStepUnit', fromNumber(minuteStepUnit)),
        minMinute: (value?: number) => {
          if (value == null || value >= minuteStepUnit) {
            return true
          }
          return translate('system.error.timeAmountMin', fromNumber(minuteStepUnit))
        },
        maxMinute: (value?: number) => {
          if (value == null || value <= maxMinute) {
            return true
          }
          const { hour, minute } = splitAmount(maxMinute)
          const hourStr = fromNumber(hour ?? 0)
          const minuteStr = fromNumber(minute ?? 0)
          return translate('system.error.timeAmountOver', hourStr, minuteStr)
        },
      },
    },
    defaultValue: null,
    control,
  })

  const error = getValueByNameStr(errors, props.name)
  const partProps = {
    error: !!error,
    onBlur,
    onChange,
    value,
    maxHour,
  }
  return (
    <div>
      <GContainer columnSpacing={1}>
        <MiddleGItem>
          <TimeSelect {...partProps} partType="hour" minuteStepUnit={minuteStepUnit} ref={ref} />
          <Label>{translate('system.label.hours')}</Label>
        </MiddleGItem>
        <MiddleGItem>
          <TimeSelect {...partProps} partType="minute" minuteStepUnit={minuteStepUnit} />
          <Label>{translate('system.label.minutes')}</Label>
        </MiddleGItem>
      </GContainer>
      {error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
    </div>
  )
}
