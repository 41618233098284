import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

export interface FacilityReserveEntry {
  facilityId: string
  childId: string
  reservationNo?: string

  /** 利用日時 */
  usageDatetimes: {
    usageDate: ElapsedMillisecond
    useFromDatetime: ElapsedMillisecond
    useToDatetime: ElapsedMillisecond
    status: string
  }[]

  /** 必要事項入力 */
  input?: {
    useReasonCategory?: string
    lunchFlag: string
    snackFlag: string
    postponeCancelWaitFlag: string
    citizenNote?: string
  }

  /** 施設情報 */
  facility?: {
    facilityName: string
    /** 利用予約即時予約フラグ 0:不可、1:可 */
    immediatelyReservationFlag: string
    /** 昼食受付可否フラグ 0:受け付けない、1:受け付ける */
    lunchAcceptFlag: string
    /** おやつ受付可否フラグ 0:受け付けない、1:受け付ける */
    snackAcceptFlag: string
    /** 見送り時のキャンセル待ち受付可否フラグ 0:受け付けない、1:受け付ける */
    postponeCancelWaitAcceptFlag: string
  }

  /** 保護者お子さま情報 */
  parentChild?: {
    name: string
    kana: string
    tel: string
    email: string
    childName: string
    childKana: string
    childGender: string
    childBirth: ElapsedMillisecond
    childAllergyFlag: string
    residenceCategory: string
  }
}

interface FacilityReserveState {
  entry?: FacilityReserveEntry
  updateDatetime?: string
}

const initialState: FacilityReserveState = {}

export const facilityReserveSlice = createSlice({
  name: 'facilityReserve',
  initialState,
  reducers: {
    setFacilityReserve: (state, action: PayloadAction<FacilityReserveEntry>) => {
      state.entry = action.payload
    },
    setReserveModifyUpdateDatetime: (state, action: PayloadAction<string>) => {
      state.updateDatetime = action.payload
    },
    clearFacilityReserve: (state) => {
      delete state.entry
      delete state.updateDatetime
    },
  },
})

export const { setFacilityReserve, setReserveModifyUpdateDatetime, clearFacilityReserve } = facilityReserveSlice.actions
export const selectFacilityReserveEntry = (state: RootState) => state.facilityReserve.entry
export const selectReserveModifyUpdateDatetime = (state: RootState) => state.facilityReserve.updateDatetime
export default facilityReserveSlice.reducer
