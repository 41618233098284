import { differenceInMinutes, isSameDay } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { executeGetCouponsIssues, executePostCouponsUsage } from '../../dataAccess/webApi/dao/couponsDao'
import { translate } from '../../i18n'
import { fromApiHms, getNowTrimedTime, toApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { hourToMinute } from '../../utils/timeUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { facilityReservationDeleteUrl, facilityReservationSelectionUpdateUrl } from '../common/constant/appUrl'
import { reservationStatus, yesNo } from '../common/constant/classification'
import { couponMinuteStepUnit } from '../common/constant/couponSetting'
import { OperationId } from '../common/constant/operationLog'
import { canUseCouponFunctionFromSysCtrl } from '../common/coupon'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { useOperationLog } from '../common/operationLog'
import { Reservation, getReservation } from '../common/reservation'
import { showLoading } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'

interface UrlParams {
  reservationNo: string
}

type ReservationDetail = {
  cancelLimit?: number
  cancelLimitTime?: Date
  cancelNote?: string
  cancelLimitHolidayOption?: string
  lunchAcceptFlag: string
  snackAcceptFlag: string
  postponeCancelWaitAcceptFlag: string
  immediatelyReservationFlag: string

  /** クーポン利用入力上限時間(分) */
  couponUsageInputLimitMinute?: number
} & Reservation

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { reservationNo } = useParams<UrlParams>()
  const { addOperationLog, attachAccessData } = useOperationLog()

  const sysCtrl = useSelector(selectSystemControl)

  const [reservationDetail, setReservationDetail] = useState<ReservationDetail>()

  const [couponTotalBalanceMinute, setCouponTotalBalanceMinute] = useState<number>()
  const [isOpenCouponUsageDialog, setIsOpenCouponUsageDialog] = useState(false)

  const reservationUsageMinute = useMemo(() => {
    if (reservationDetail) {
      const minute = differenceInMinutes(reservationDetail.useToDatetime, reservationDetail.useFromDatetime)
      if (minute % couponMinuteStepUnit) {
        // 入力単位以外の時は繰り上げ
        const unitCount = Math.floor(minute / couponMinuteStepUnit)
        return (unitCount + 1) * couponMinuteStepUnit
      } else {
        return minute
      }
    }
  }, [reservationDetail])

  const nowYmd = getNowTrimedTime()
  const isCanUseCouponSysCtrl = canUseCouponFunctionFromSysCtrl(sysCtrl, nowYmd)

  /** クーポン利用が可能か */
  const isCanUseCoupon =
    // クーポン機能有効
    isCanUseCouponSysCtrl &&
    reservationDetail != null &&
    // クーポン未利用
    reservationDetail.couponUsageMinute == null &&
    // 確定済み
    reservationDetail.status === reservationStatus.fixed &&
    // クーポン残高有
    !!couponTotalBalanceMinute &&
    // 利用日当日
    isSameDay(reservationDetail.usageDate, nowYmd)

  /** クーポン利用済 */
  const isUsedCoupon = reservationDetail?.couponUsageMinute != null

  const initialize = useCallback(
    async (isInitDisplay?: boolean) => {
      const result = await getReservationDetail(reservationNo)
      if (isInitDisplay) {
        attachAccessData({
          accessData: [
            { userIdRegFlag: yesNo.yes, childId: result.childId, usageDate: toApiYmd(result.usageDate), reservationNo },
          ],
        })
      }

      if (isCanUseCouponSysCtrl) {
        const couponTotalBalanceMinute = await getCouponTotalBalanceMinute(result.usageDate)
        setCouponTotalBalanceMinute(couponTotalBalanceMinute)
      }

      setReservationDetail(result)
    },
    [reservationNo, isCanUseCouponSysCtrl, attachAccessData]
  )

  const openCouponUsageDialog = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000033 })

    setIsOpenCouponUsageDialog(true)
  }, [addOperationLog])

  const onCloseCouponUsageDialog = useCallback(
    async (isDecision?: boolean) => {
      setIsOpenCouponUsageDialog(false)
      if (isDecision) {
        // 確定で閉じたとき再読み込み
        await initialize()
      }
    },
    [initialize]
  )

  const decisionCouponUsage = useCallback(
    async (usageMinute: number) => {
      const rsvDetail = castNonNullable(reservationDetail)
      addOperationLog({
        operationId: OperationId.OP_00000034,
        accessData: [
          {
            userIdRegFlag: yesNo.yes,
            childId: rsvDetail.childId,
            usageDate: toApiYmd(rsvDetail.usageDate),
            reservationNo,
          },
        ],
      })

      const res = await postCouponUsage(reservationNo, usageMinute)
      if (res.resultCode) {
        return { errorMessage: translate(`facilityReservationDetail.error.couponUsageResultCode${res.resultCode}`) }
      }
    },
    [reservationNo, addOperationLog, reservationDetail]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(showLoading(errorHandle(() => initialize(true))))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goCancel = useCallback(
    (status: string) => {
      if (status === reservationStatus.wait) {
        addOperationLog({ operationId: OperationId.OP_00000032 })
      } else {
        addOperationLog({ operationId: OperationId.OP_00000031 })
      }

      history.push(facilityReservationDeleteUrl.url(reservationNo))
    },
    [history, reservationNo, addOperationLog]
  )

  const goChange = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000035 })

    history.push(facilityReservationSelectionUpdateUrl.url(reservationNo))
  }, [history, reservationNo, addOperationLog])

  return {
    reservationDetail,
    reservationUsageMinute,
    isCanUseCoupon,
    isUsedCoupon,
    goCancel,
    goChange,
    couponTotalBalanceMinute,
    isOpenCouponUsageDialog,
    openCouponUsageDialog,
    onCloseCouponUsageDialog,
    decisionCouponUsage,
  }
}

const getReservationDetail = async (reservationNo: string) => {
  const reservation = await getReservation(reservationNo)
  const facility = await getFacility(reservation.facilityId, reservation.usageDate)

  return nullPropsToUndefined({
    ...reservation,
    cancelLimit: facility.cancelLimit,
    cancelLimitTime: fromApiHms(facility.cancelLimitTime),
    cancelNote: facility.cancelNote,
    cancelLimitHolidayOption: facility.cancelLimitHolidayOption,
    lunchAcceptFlag: facility.lunchAcceptFlag,
    snackAcceptFlag: facility.snackAcceptFlag,
    postponeCancelWaitAcceptFlag: facility.postponeCancelWaitAcceptFlag,
    immediatelyReservationFlag: facility.immediatelyReservationFlag,
    couponUsageInputLimitMinute: hourToMinute(facility.couponUsageInputLimitTime),
  })
}

const getCouponTotalBalanceMinute = async (usageDate: Date) => {
  const couponsRes = await executeGetCouponsIssues({ targetDate: toApiYmd(usageDate) })
  const coupons = couponsRes.result
  if (coupons.length) {
    // 有効なクーポンが存在する場合
    return coupons.reduce((sumVal, coupon) => sumVal + coupon.totalBalanceMinute, 0)
  }
}

const postCouponUsage = (reservationNo: string, usageMinute: number) => {
  return executePostCouponsUsage(reservationNo, { usageMinute })
}
