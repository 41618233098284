import { Stack, styled } from '@mui/material'
import { identityVerificationUrl, rootUrl, faqUrl } from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/identityEmailVerification/identityEmailVerificationService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { GContainer, GItem, GItemContainer, RowSpacerGItem } from '../components/common/grids'
import { IdentityRedirect } from '../components/common/identityRedirect'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'
import { TitleLabel } from '../components/common/titleLabel'
import { Link } from '../components/common/link'

const SubMessageGItem = styled(GItem)({
  whiteSpace: 'pre-wrap',
})

export const IdentityEmailVerification = () => {
  const { from, isVerifiedEmail, resultCode, formMethods, onSubmit } = useAction()

  if (isVerifiedEmail) {
    return <IdentityRedirect from={from} toPathname={identityVerificationUrl.url()} />
  }
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <GContainer>
              <GItem xs={12}>
                <TitleLabel>{translate('identityEmailVerification.text.mainMessage')}</TitleLabel>
              </GItem>
              <RowSpacerGItem />

              <SubMessageGItem xs={12}>{translate('identityEmailVerification.text.subMessageMail')}</SubMessageGItem>
              <RowSpacerGItem />
              <SubMessageGItem xs={12}>
                {translate('identityEmailVerification.text.subMessageQaLinkPrefix')}
                <Link to={faqUrl.url()} target="_blank">{translate('identityEmailVerification.text.subMessageQaLink')}</Link>
                {translate('identityEmailVerification.text.subMessageQaLinkSuffix')}
              </SubMessageGItem>
              <RowSpacerGItem />
              <GItemContainer xs={12}>
                <GItem xs={12}>
                  <AttentionLabel>*</AttentionLabel>
                  {translate('identityEmailVerification.label.email')}
                </GItem>
                <GItem xs={12}>
                  <TextBox
                    name="email"
                    label={translate('identityEmailVerification.label.email')}
                    maxLength={320}
                    textType="email"
                    required
                  />
                </GItem>
              </GItemContainer>
              <GItemContainer xs={12}>
                <GItem xs={12}>
                  <AttentionLabel>*</AttentionLabel>
                  {translate('identityEmailVerification.label.emailConfirmation')}
                </GItem>
                <GItem xs={12}>
                  <TextBox
                    name="emailConfirmation"
                    label={translate('identityEmailVerification.label.emailConfirmation')}
                    maxLength={320}
                    textType="email"
                    required
                    autoComplete="off"
                  />
                </GItem>
              </GItemContainer>

              {(!!resultCode?.verifySubmit || !!resultCode?.resendEmail) && (
                <>
                  <RowSpacerGItem />
                  <GItem xs={12}>
                    <ErrorMessageCard
                      messages={[
                        !!resultCode?.verifySubmit
                          ? translate(
                              `identityEmailVerification.error.verifySubmitResultCode${resultCode.verifySubmit}`
                            )
                          : translate(`identityEmailVerification.error.resendEmailResultCode${resultCode.resendEmail}`),
                      ]}
                    />
                  </GItem>
                </>
              )}
            </GContainer>
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>

        <BottomButtonGroup>
          <ButtonOutlinedWarningL fullWidth type="submit">
            {translate('accountCreateCompletion.button.resend')}
          </ButtonOutlinedWarningL>
          <ButtonOutlinedResponsive to={rootUrl.url()}>
            {translate('system.button.backToHome')}
          </ButtonOutlinedResponsive>
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
