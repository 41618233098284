import { Stack, styled } from '@mui/material'
import { useAction } from '../../containers/childUseEnd/childUseEndService'
import { yesNo } from '../../containers/common/constant/classification'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonErrorL } from '../components/common/buttons/ButtonErrorL'
import { BackButton } from '../components/common/buttons/backButton'
import {
  CenterGItem,
  GContainer,
  GItem,
  LabelGItemBase,
  ValueGItemBase,
} from '../components/common/grids'
import { CheckBox } from '../components/common/inputs/checkBox'
import { Form } from '../components/common/inputs/form'
import { TransitionCheck } from '../components/common/transitionCheck'
import { ErrorMessageCard } from '../components/common/errorMessageCard'

const GuideTextBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

export const ChildUseEnd = () => {
  const { sysCtrl, formMethods, onSubmit, state } = useAction()
  if (sysCtrl.childUseEndAcceptFlag !== yesNo.yes) {
    return <TransitionCheck check={false} />
  }
  if (state == null) {
    // 読み込み中
    return null
  }
  const { useEndGuideText, child, deleteResultCode } = state
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <GContainer>
          <GItem md={3}></GItem>
          <GItem xs={12} md={6}>
            <GContainer rowSpacing={1}>
              <LabelGItemBase xs="auto">{translate('childUseEnd.title.childName')}</LabelGItemBase>
              <ValueGItemBase xs>{child.name}</ValueGItemBase>
              
              <GItem xs={12}>{translate('childUseEnd.title.about')}</GItem>
              <GItem xs={12}>
                <GuideTextBox>{useEndGuideText}</GuideTextBox>
              </GItem>
              <CenterGItem xs={12}>
                <CheckBox
                  name="isConfirmation"
                  label={translate('childUseEnd.label.isConfirmation')}
                  valicationMessageKeys={{ required: 'childUseEnd.error.confirmation' }}
                  valueAtCheck={yesNo.yes}
                  required
                >
                  {translate('childUseEnd.checkbox.isConfirmation')}
                </CheckBox>
              </CenterGItem>
            </GContainer>
            {!!deleteResultCode && (
              <ErrorMessageCard
                messages={[translate(`childUseEnd.error.deleteResultCode${deleteResultCode}`)]}
              />
            )}
          </GItem>
          <GItem md={3}></GItem>
        </GContainer>
        <BottomButtonGroup>
          <ButtonErrorL fullWidth type="submit">
            {translate('childUseEnd.button.forward')}
          </ButtonErrorL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
