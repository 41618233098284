import { useMemo } from 'react'
import { getInterviewReservationSteps } from '../common/codeMaster'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

export const useAction = () => {
  const { addOperationLog } = useOperationLog()
  addOperationLog({ operationId: OperationId.OP_00000001 })

  const steps = useMemo(getInterviewReservationSteps, [])

  return {
    steps,
  }
}
