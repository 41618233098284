/** 個人情報の取り扱いについて */
export const handingOfPersonalDataUrl = 'https://www.densan-s.co.jp/privacy.html'
/** 乳幼児一時預かりについて */
export const aboutTemporaryCustodyOfInfantsUrl =
  'https://www.city.yokohama.lg.jp/kurashi/kosodate-kyoiku/kosodateshien/nyuyoji-ichiji/nyuyoji-ichiji.html'

/** 一時保育事業について */
export const aboutTemporaryChildcareBusinessUrl =
  'https://www.city.yokohama.lg.jp/kurashi/kosodate-kyoiku/hoiku-yoji/shisetsu/hoikuseido/ichiji/ichijiannai.html'

/** はじめてのお預かり券とは */
export const aboutFirstChildcareTicketUrl =
  'https://www.city.yokohama.lg.jp//kurashi/kosodate-kyoiku/kosodateshien/hajimetenoazukari.html'

/** GoogleMapへのリンクURL(Search) */
export const googleMapLinkSearchUrl = 'https://www.google.com/maps/search/?api=1&query='

/** 当システムのマニュアルPDF */ // バケットをシステムコントロールから取得するように変更
// export const systemManualPdfUrl =
//   'https://public-manual-bucket.s3.ap-northeast-1.amazonaws.com/%E6%A8%AA%E6%B5%9C%E5%B8%82_%E4%B8%80%E6%99%82%E9%A0%90%E3%81%8B%E3%82%8A%E4%BF%9D%E8%82%B2%E4%BA%88%E7%B4%84%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0_%E5%B8%82%E6%B0%91%E5%90%91%E3%81%91%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf'

/** 横浜市一時預かりWEB予約システム */
export const yokohamaCityTemporaryChildcareReservationServiceUrl = 'https://ichiji-yoyaku.city.yokohama.lg.jp/'

/** docomoメール設定画面 */
export const docomoMailSetupUrl = 'https://www.docomo.ne.jp/info/spam_mail/domain/'

/** auメール設定画面 */
export const auMailSetupUrl = 'https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/'

/** softbankメール設定画面 */
export const softbankMailSetupUrl = 'https://www.softbank.jp/mobile/support/mail/antispam/mms/whiteblack/'

/** ymobileメール設定画面 */
export const ymobileMailSetupUrl = 'https://www.ymobile.jp/support/relief/trouble_mail/'
