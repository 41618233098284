import { styled, Theme, useMediaQuery } from '@mui/material'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setBottomActionContainerHeight } from '../../../containers/common/store/slices/application'

interface BottomActionContainerProps {
  children: ReactNode
}

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.appMain.padding,
  width: `calc(100% - ${theme.appMain.padding * 2}px)`,
}))

/**
 * メインコンテンツエリア下部に表示する「登録」「戻る」などのアクションコントロールを格納するコントロール
 */
export const BottomActionContainer = (props: BottomActionContainerProps) => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const isUpMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  const reCalc = useCallback(
    (ele: HTMLDivElement) => {
      const height = ele.getBoundingClientRect().height + parseFloat(getComputedStyle(ele).marginTop)
      dispatch(setBottomActionContainerHeight(height))
    },
    [dispatch]
  )

  useEffect(() => {
    return () => {
      dispatch(setBottomActionContainerHeight(0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ref.current) {
      reCalc(ref.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpMd])

  useEffect(() => {
    let resizeObserver: ResizeObserver | undefined
    if (ref.current && window.ResizeObserver) {
      const ele = ref.current
      let preHeight: number | undefined
      resizeObserver = new ResizeObserver((entries) => {
        if (entries.length) {
          const height = entries[0].contentRect.height
          if (preHeight !== height) {
            preHeight = height
            reCalc(ele)
          }
        }
      })
      resizeObserver.observe(ele)
    }
    return () => resizeObserver && resizeObserver.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Root ref={ref}>{props.children}</Root>
}
