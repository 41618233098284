import { styled } from '@mui/system'
import { ButtonOutlinedBase } from './buttonOutlinedBase'

/**
 * buleカラーのボタンコントロールSサイズ。
 */
export const ButtonOutlinedBuleS = styled(ButtonOutlinedBase)(({ theme }) => ({
  borderColor: theme.palette.white.main,
  backgroundColor: theme.palette.primary.main,
  fontSize: theme.typography.font.sizeS,
  color: theme.palette.white.main,
  padding: '0 5px',
  borderWidth: 3,
  borderRadius: 8,
  minWidth: '30px',
  minHeight: '10px',
  display: 'inline-block',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    '@media (hover: none)': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))
