import { Stack, styled } from '@mui/material'
import React from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/myFacility/myFacilityService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { ButtonS } from '../components/common/buttons/buttonS'
import { FacilityCard } from '../components/common/facilityCard'
import { GContainer, GItem, MiddleGItem } from '../components/common/grids'
import { ReserveStatusCheckIcon } from '../components/common/icons/reserveStatusCheckIcon'
import { TelIcon } from '../components/common/icons/telIcon'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'

const NoResultMessageCard = styled('div')({
  padding: '20px 0',
  textAlign: 'center',
})

export const MyFacility = () => {
  const {
    activeChildId,
    myFacilities,
    childs,
    changeChild,
    onClickFacilityName,
    callTel,
    goFacilityReservationSelection,
    goFacilityDetailAvailability,
  } = useAction()
  if (myFacilities == null || activeChildId == null) {
    return null
  }
  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={2}></GItem>
        <GItem xs={12} md={8}>
          <GContainer rowSpacing={2}>
            <MiddleGItem>{translate('usageHistory.label.selectionChild')}：</MiddleGItem>
            <GItem xs>
              <SelectNoBind fullWidth valueLabels={childs} onChange={changeChild} value={activeChildId} />
            </GItem>

            <GItem xs={12}>
              <Stack spacing={1}>
                {myFacilities.length ? (
                  myFacilities.map((facility) => (
                    <FacilityCard
                      key={facility.facilityId}
                      {...facility}
                      onClickFacilityName={onClickFacilityName}
                      isDisabledImage
                    >
                      {facility.reservationAcceptFlag === yesNo.yes ? (
                        <ButtonS
                          startIcon={<ReserveStatusCheckIcon />}
                          onClick={() => goFacilityReservationSelection(facility.facilityId, activeChildId)}
                        >
                          {translate('myFacility.button.webReservation')}
                        </ButtonS>
                      ) : facility.notLoginCalendarDisplayFlag === yesNo.yes ? (
                        <ButtonS
                          startIcon={<ReserveStatusCheckIcon />}
                          onClick={() => goFacilityDetailAvailability(facility.facilityId, activeChildId)}
                        >
                          {translate('myFacility.button.availabilityCheck')}
                        </ButtonS>
                      ) : (
                        <ButtonS startIcon={<TelIcon />} onClick={() => callTel(facility.tel)}>
                          {translate('myFacility.button.telReservation')}
                        </ButtonS>
                      )}
                    </FacilityCard>
                  ))
                ) : (
                  <NoResultMessageCard>{translate('myFacility.text.noFacility')}</NoResultMessageCard>
                )}
              </Stack>
            </GItem>
          </GContainer>
        </GItem>
        <GItem md={2}></GItem>
      </GContainer>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
