import React, { memo } from 'react'
import { Redirect } from 'react-router'
import { errorUrls } from '../../../containers/common/constant/appUrl'

interface TransitionConsistencyCheckProps {
  check: (() => boolean) | boolean
}

export const TransitionCheck = memo(function TransitionConsistencyCheck(props: TransitionConsistencyCheckProps) {
  const checkResult = typeof props.check === 'function' ? props.check() : props.check
  return checkResult ? null : (
    <Redirect
      to={{
        pathname: errorUrls.transitionInconsistency.url(),
      }}
    />
  )
})
