import { useCallback, useEffect, useRef, useState } from 'react'
import { tuple } from '../../utils/typeUtil'

/**
 * unmountされたときに、trueを立てるRefHook
 * 参考：
 * https://qiita.com/macotok/items/87a260988376bcfa2ffd
 */
export const useUnmountRef = () => {
  const unmountRef = useRef(false)

  useEffect(
    () => () => {
      unmountRef.current = true
    },
    []
  )

  return unmountRef
}

type UseSafeStateT = {
  <T>(unmountRef: ReturnType<typeof useUnmountRef>, defaultValue: T): [T, (v: T) => void]
  <T = undefined>(unmountRef: ReturnType<typeof useUnmountRef>): [T | undefined, (v: T | undefined) => void]
}
/**
 * ReactでUnmountしたときにstateをメモリリークしない便利関数
 *
 * 参考：
 * https://qiita.com/macotok/items/87a260988376bcfa2ffd
 *
 * @param unmountRef useUnmountRefの値
 * @param defaultValue stateのデフォルト値
 * @returns [state, setState]
 */
export const useSafeState: UseSafeStateT = <T,>(unmountRef: ReturnType<typeof useUnmountRef>, defaultValue?: T) => {
  const [state, setStateOrig] = useState(defaultValue)
  const setState = useCallback(
    (v: T) => {
      if (!unmountRef.current) {
        setStateOrig(v)
      }
    },
    [setStateOrig, unmountRef]
  )
  return tuple(state, setState)
}
