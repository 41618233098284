import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/memberModifyConfirmationUser/memberModifyConfirmationUserService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { UserContent } from '../components/common/member/userContent'
import { TransitionCheck } from '../components/common/transitionCheck'

export const MemberModifyConfirmationUser = () => {
  const { user, usersResultCode, decide } = useAction()
  if (user == null) {
    return <TransitionCheck check={false} />
  }
  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <UserContent user={user} />
          {!!usersResultCode && (
            <ErrorMessageCard
              messages={[translate(`memberModifyConfirmationUser.error.usersResultCode${usersResultCode}`)]}
            />
          )}
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={decide}>
          {translate('memberModifyConfirmationUser.button.decision')}
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
