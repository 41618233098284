// お子さま情報に関する共通処理を定義
//

import { executeGetChild, executeGetChildren } from '../../dataAccess/webApi/dao/childrenDao'
import { fromApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { yesNo } from './constant/classification'

/**
 * お子さまの選択で使用する要素に必要な情報。
 * selectコントロールで使用
 */
export interface ChildrenChoice {
  value: string
  label: string
}

/**
 * お子さまの選択で使用する要素に必要な情報。
 * selectコントロールで使用
 */
export interface InterviewedChildrenChoice {
  value: string
  label: string
  status: string
  permitFlag: string
}

/**
 * お子さま情報
 */
export interface Child {
  childId: string
  name: string
  kana: string
  birthday: Date
  brothersSistersFlag: string
  multipleBirthsFlag: string
  gender: string
  bloodType?: string
  maternalHandbookNo?: string
  allergyFlag: string
  /** アレルギー１～３ */
  allergies: {
    causes?: string
    symptoms?: string
    treatments?: string
    concerns?: string
  }[]
  interviewPermitCount: number
  /** 排他チェック用の更新日時 */
  updateDatetime: string
}

/**
 * 選択対象のお子さまに関する情報を返す。
 *
 * @param facilityId 施設ID。面談済みのお子さまのみを取得したい場合に指定する
 * @returns お子さま要素情報の配列
 */
export const getChildrenChoices = async (facilityId?: string): Promise<ChildrenChoice[]> => {
  const response = await executeGetChildren(...(facilityId ? [{ facilityId }] : []))
  return response.result.map((v) => ({ value: v.childId, label: v.name }))
}

/**
 * 面談済みのお子さまのみに関する情報を返す。
 * 面談未実施状態のお子さまを含める
 *
 * @param facilityId 施設ID。
 * @returns お子さま要素情報の配列
 */
export const getInterviewedChildrenChoices = async (facilityId: string): Promise<InterviewedChildrenChoice[]> => {
  const response = await executeGetChildren({
    facilityId,
    includeNotImplementedFlag: yesNo.yes,
    getLatestInterviewedFlag: yesNo.yes,
  })

  // 施設ID指定した場合、statusとpermitFlgはnullおよびundefinedはありえない
  return response.result.map((v) => ({
    value: v.childId,
    label: v.name,
    status: castNonNullable(v.status),
    permitFlag: castNonNullable(v.permitFlag),
  }))
}

/**
 * 指定したお子さまIDのお子さま情報を返す。
 *
 * @param childId お子さまID
 * @returns お子さま情報
 */
export const getChild = async (childId: string): Promise<Child> => {
  const response = await executeGetChild(childId)
  const child = response.result
  const allergies = [
    {
      causes: child.allergyCauses1,
      symptoms: child.allergySymptoms1,
      treatments: child.allergyTreatments1,
      concerns: child.allergyConcerns1,
    },
    {
      causes: child.allergyCauses2,
      symptoms: child.allergySymptoms2,
      treatments: child.allergyTreatments2,
      concerns: child.allergyConcerns2,
    },
    {
      causes: child.allergyCauses3,
      symptoms: child.allergySymptoms3,
      treatments: child.allergyTreatments3,
      concerns: child.allergyConcerns3,
    },
  ].map((v) => nullPropsToUndefined(v))

  return nullPropsToUndefined({
    childId,
    name: child.name,
    kana: child.kana,
    birthday: fromApiYmd(child.birthday),
    brothersSistersFlag: child.brothersSistersFlag,
    multipleBirthsFlag: child.multipleBirthsFlag,
    gender: child.gender,
    bloodType: child.bloodType,
    maternalHandbookNo: child.maternalHandbookNo,
    allergyFlag: child.allergyFlag,
    allergies,
    updateDatetime: child.updateDatetime,
    interviewPermitCount: child.interviewPermitCount,
  })
}
