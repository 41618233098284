import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { NullPropsToUndefinedType, castNonNullable } from '../../utils/typeUtil'
import { ChildrenChoice, getChildrenChoices, getInterviewedChildrenChoices } from '../common/child'
import { facilityReservationSelectionCreateUrl, interviewReservationFormUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { showLoading } from '../common/store/slices/application'
import { getInterviewStatusLabel } from '../common/codeMaster'
import { interviewStatus, permitFlag } from '../common/constant/classification'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

interface UrlParams {
  facilityId: string
}

interface LocationState {
  /** 選択した子どもID */
  childId?: string
}

interface Inputs {
  childId: string
}

interface PageState {
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  uninterviewedChilds: ChildrenChoice[]
  childs: ChildrenChoice[]
  interviewedChildsAndStatus: ChildrenChoice[]
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { facilityId } = useParams<UrlParams>()
  const { addOperationLog } = useOperationLog()

  const [isOpenInterviewChildSelect, setIsOpenInterviewChildSelect] = useState(false)
  const [state, setState] = useState<PageState>({ uninterviewedChilds: [], childs: [], interviewedChildsAndStatus: [] })

  const currentLocation = history.location
  const locationState = currentLocation.state

  const formMethods = useForm<Inputs>({
    ...(locationState?.childId && {
      defaultValues: {
        childId: locationState.childId,
      },
    }),
  })

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          const facilityWithChilds = await getFacilityWithChilds(facilityId)
          setState(facilityWithChilds)
          if (locationState?.childId == null && facilityWithChilds.childs.length) {
            formMethods.setValue('childId', facilityWithChilds.childs[0].value)
          }
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback((data: Inputs) => {
    const { childId } = data
    // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
    history.replace({ ...currentLocation, state: { childId } })
    history.push(facilityReservationSelectionCreateUrl.url(facilityId, childId))
  }, [])

  const goInterview = useCallback(() => {
    setIsOpenInterviewChildSelect(true)
  }, [])
  const onCloseInterviewChildSelect = useCallback(
    (isCancel: boolean, childId?: string) => {
      if (isCancel) {
        // 選択をキャンセルした場合
        setIsOpenInterviewChildSelect(false)
      } else {
        console.log(castNonNullable(childId))
        history.push(interviewReservationFormUrl.url(facilityId))
      }
    },
    [history, facilityId]
  )

  const onClickTel = useCallback(() => (document.location.href = `tel:${state.facility?.tel}`), [state.facility])

  return {
    ...state,
    currentLocation,
    formMethods,
    isOpenInterviewChildSelect,
    onSubmit,
    onClickTel,
    goInterview,
    onCloseInterviewChildSelect,
  }
}

async function getFacilityWithChilds(facilityId: string) {
  const [facility, allChilds, interviewedChilds] = await Promise.all([
    getFacility(facilityId),
    getChildrenChoices(),
    getInterviewedChildrenChoices(facilityId),
  ])

  //A761にて、キャンセル済お子様にラジオボタンを表示して再登録を可能にする対応を行いました。
  //本来SQLで修正をするところですが、お子様選択一覧画面に表示されるキャンセル済お子様のラベルに
  //「（キャンセル済）」のステータスを表示する対応が今後発生する可能性があり、その場合SQLの修正は不要になってしまうため画面での対応としました。

  //interviewedChilds（面談済みお子様）から状態：キャンセル済を除外する
  const exceptForChildsWithCanceledInterview = interviewedChilds.filter((v) => v.status !== interviewStatus.canceled)
  const interviewedChildIdSet = new Set(exceptForChildsWithCanceledInterview.map(({ value }) => value))
  const uninterviewedChilds = allChilds.filter(({ value }) => !interviewedChildIdSet.has(value))
  const childs = interviewedChilds.filter((v) => v.permitFlag === permitFlag.permitted)
  const interviewedChildsAndStatus = exceptForChildsWithCanceledInterview.map((v) => {
    let label = v.label
    if (v.status) {
      label = v.label + '（' + getInterviewStatusLabel(v.status) + '）'
    }
    return { value: v.value, label: label }
  })

  return {
    facility,
    uninterviewedChilds,
    childs,
    interviewedChildsAndStatus,
  }
}
