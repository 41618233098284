import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showLoading } from '../../containers/common/store/slices/application'
import { executePostContacts } from '../../dataAccess/webApi/dao/contactDao'
import { executePostGuideText, guideTextId } from '../../dataAccess/webApi/dao/guideTextDao'
import { ContactData } from '../../dataAccess/webApi/dto/contactDto'
import { GuideTextData } from '../../dataAccess/webApi/dto/guideTextDto'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
// import { executeApi } from 'dataAccess/webApi/dao/commonDao'

// interface Information {
//   informaionNo: string
//   publicationStartDatetime: Date
//   facilityName: string
//   title: string
// }
// interface LinkSettings {
//   link_id: number
//   link_name: string
//   link_url: string
// }
export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  // const sysCtrl = useSelector(selectSystemControl)
  // const isLoggedIn = useSelector(selectIsLoggedIn)
  // const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  // const isVerifiedTel = useSelector(selectIsVerifiedTel)
  // const history = useHistory()
  const { addOperationLog } = useOperationLog()
  
  const [contactList, setContactList] = useState<ContactData[]>()
  // const [guideTextList, setGuideTextList] = useState<GuideTextData[]>()
  const [guideText, setGuideText] = useState<GuideTextData>()
  
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    const initialize = async () => {
      // 問合せ先を取得
      { 
        const contactList = await getContacts()
        setContactList(contactList)
        console.log("contactList", contactList)
      }
      
      // 案内文を取得
      { 
        // const guideTextList = await getContactCommonGuideText()
        // setGuideTextList(guideTextList)
        // console.log("guideTextList", guideTextList)
        const guideText = await getContactCommonGuideText()
        setGuideText(guideText)
        console.log("guideText", guideText)
      }
      
    }
    dispatch(showLoading(errorHandle(initialize)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // // My施設へ遷移
  // const goMyFacility = useCallback(() => {
  //   addOperationLog({ operationId: OperationId.OP_00000019 })
  //   history.push(myFacilityUrl.url())
  // }, [addOperationLog, history])

  // // 予約状況へ遷移
  // const goReservationStatusList = useCallback(() => {
  //   addOperationLog({ operationId: OperationId.OP_00000020 })
  //   history.push(reservationStatusListUrl.url())
  // }, [addOperationLog, history])

  // // 利用履歴へ遷移
  // const goUsageHistory = useCallback(() => {
  //   addOperationLog({ operationId: OperationId.OP_00000021 })
  //   history.push(usageHistoryUrl.url())
  // }, [addOperationLog, history])

  // // はじめてのおあずかり券へ遷移
  // const goCoupon = useCallback(() => {
  //   addOperationLog({ operationId: OperationId.OP_00000022 })
  //   history.push(couponUrl.url())
  // }, [addOperationLog, history])

  return {
    contactList,
    // guideTextList,
    guideText,
  }
}

/**
 * 問合せ先を一覧取得して返す
 * @return {Array<Object>} - linkSettingsのリスト
 */
const getContacts = async () => {
  // const response = await executePostLinkSettings()
  const response = await executePostContacts()
  return response.result.map((entry) => {
    const { ...other } = entry
    return {
      ...other,
    }
  })
}

/**
 * 案内文を取得して返す
 * @return {Array<Object>} - linkSettingsのリスト
 */
const getContactCommonGuideText = async () => {
  const response = await executePostGuideText( guideTextId.contactCommonGuideText )
  return response.result;
}

