import { API } from 'aws-amplify'
import axios from 'axios'
import http from 'http'
import https from 'https'
import { getSessionIdToken } from '../../../utils/authUtil'

const keepAliveHttpAgent = new http.Agent({ keepAlive: true })
const keepAliveHttpsAgent = new https.Agent({ keepAlive: true })

/**
 * GETでAPI呼び出し。
 * 結果無し(ApiResponse.result == null)時に例外としない
 * @param apiName
 * @param path
 * @param parameter
 * @returns レスポンス
 * @throws {ApiError} httpStatusCodeが2xx以外、resultCode,resultがbodyに含まれない場合
 */
export const executeGet = <T,>(apiName: string, path: string, parameter?: Record<string, any>) => {
  return execute<T>(apiName, path, API.get.bind(API), { queryParameter: parameter })
}

/**
 * GETでAPI呼び出し。
 * 結果無し(ApiResponse.result == null)時に例外とする
 * @param apiName
 * @param path
 * @param parameter
 * @returns レスポンス
 * @throws {NoResultError} result == nullの場合
 * @throws {ApiError} httpStatusCodeが2xx以外、resultCode,resultがbodyに含まれない場合
 */
export const executeGetNoResultError = <T,>(apiName: string, path: string, parameter?: Record<string, any>) => {
  return execute<T>(apiName, path, API.get.bind(API), { queryParameter: parameter }, { isNoResultError: true })
}

export const executeDelete = <T,>(apiName: string, path: string, parameter?: Record<string, any>) => {
  return execute<T>(apiName, path, API.del.bind(API), { bodyParameter: parameter })
}

export const executePost = <T,>(apiName: string, path: string, parameter?: Record<string, any>) => {
  return execute<T>(apiName, path, API.post.bind(API), { bodyParameter: parameter })
}

export const executePut = <T,>(apiName: string, path: string, parameter?: Record<string, any>) => {
  return execute<T>(apiName, path, API.put.bind(API), { bodyParameter: parameter })
}

/**
 * POSTでAPI呼び出し。
 * keepAlive: true で実行する
 * @param apiName
 * @param path
 * @param parameter
 * @returns レスポンス
 * @throws {ApiError} httpStatusCodeが2xx以外
 */
export const executePostKeepAlive = <T,>(apiName: string, path: string, parameter?: Record<string, any>) => {
  return execute<T>(apiName, path, API.post.bind(API), { bodyParameter: parameter }, { isKeepAlive: true })
}

const execute = async <T,>(
  apiName: string,
  path: string,
  api: (apiName: any, path: any, init: any) => Promise<any>,
  parameter: {
    queryParameter?: Record<string, any>
    bodyParameter?: Record<string, any>
  },
  { isNoResultError = false, isKeepAlive = false } = {}
) => {
  const { queryParameter, bodyParameter } = parameter
  let token
  try {
    token = await getSessionIdToken()
  } catch {
    // 未ログイン時はトークン無し
  }
  const option = {
    ...(isKeepAlive && {
      httpAgent: keepAliveHttpAgent,
      httpsAgent: keepAliveHttpsAgent,
    }),
    ...(token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    ...(queryParameter && {
      queryStringParameters: {
        q: JSON.stringify(queryParameter),
      },
    }),
    ...(bodyParameter && {
      body: bodyParameter,
    }),
  }
  try {
    const rawResponse = await api(apiName, path, option)
    if ('resultCode' in rawResponse && 'result' in rawResponse) {
      if (isNoResultError && rawResponse.result == null) {
        throw new NoResultError(apiName, path, parameter, 'NoResult', 200, rawResponse)
      }
      return rawResponse as ApiResponse<T>
    } else {
      throw TypeError(JSON.stringify(rawResponse))
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      throw e
    } else if (axios.isAxiosError(e)) {
      if (e.response == null) {
        console.error('apiCallerレスポンス取得不可', e)
      }
      let apiResponse
      const responseHeaders: Record<string, string> | undefined = e.response?.headers
      if (e.response && e.response.data.resultCode != null) {
        const body = e.response.data
        apiResponse = {
          resultCode: body.resultCode as number,
          ...(body.result != null && {
            result: body.result as NonNullable<T>,
          }),
        }
      }
      throw new ApiError(apiName, path, parameter, e.message, e.response?.status, apiResponse, responseHeaders)
    } else {
      // 想定されないエラー
      console.error('apiCaller想定されないエラー', e)
      throw new ApiError(apiName, path, parameter)
    }
  }
}

export interface ApiResponse<T> {
  resultCode: number
  result: T
}

export interface ApiResponseError<T> {
  resultCode: number
  result?: NonNullable<T>
}

export class ApiError<T> extends Error {
  apiName: string
  path: string
  parameter: {
    queryParameter?: Record<string, any>
    bodyParameter?: Record<string, any>
  }
  statusCode?: number
  response?: ApiResponseError<T>
  responseHeaders?: Record<string, string>

  constructor(
    apiName: string,
    path: string,
    parameter: {
      queryParameter?: Record<string, any>
      bodyParameter?: Record<string, any>
    },
    message?: string,
    statusCode?: number,
    response?: ApiResponseError<T>,
    responseHeaders?: Record<string, string>
  ) {
    super(message)
    this.name = new.target.name
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype)

    this.apiName = apiName
    this.path = path
    this.parameter = parameter
    this.statusCode = statusCode
    this.response = response
    this.responseHeaders = responseHeaders
  }
}

/**
 * 結果無し(ApiResponse.result === null)時に例外とするモードで呼び出した際、
 * スローされる例外
 */
export class NoResultError<T> extends ApiError<T> {}
