import React from 'react'
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined'
import { IconProps } from './iconBase'

/**
 * マイナスマークアイコン
 */
export const MinusCircleOutlineIcon = (props: IconProps) => {
  return <DoNotDisturbOnOutlinedIcon {...props} />
}
