/**
 * 操作ID
 * 市民機能のみに存在する操作も含めた連番定義
 * ※市民側は施設で定義した定数のコピーとする。その都合で命名も施設基準になっている
 */
export const OperationId = {
  /** 初期表示 */
  OP_00000001: '00000001',
  /** 利用者選択 */
  OP_00000002: '00000002',
  /** 保護者一覧：検索 */
  OP_00000003: '00000003',
  /** 予約サマリ */
  OP_00000004: '00000004',
  /** 仮予約 */
  OP_00000005: '00000005',
  /** 予約確定 */
  OP_00000006: '00000006',
  /** 予約見送り */
  OP_00000007: '00000007',
  /** キャンセル待ち */
  OP_00000008: '00000008',
  /** 予約キャンセル */
  OP_00000009: '00000009',
  /** 実績確定 */
  OP_00000010: '00000010',
  /** 未利用 */
  OP_00000011: '00000011',
  /** 領収書 */
  OP_00000012: '00000012',
  /** 保護者氏名 */
  OP_00000013: '00000013',
  /** お子さま氏名 */
  OP_00000014: '00000014',
  /** 検索 */
  OP_00000015: '00000015',
  /** Excelデータダウンロード（一覧情報を出力） */
  OP_00000016: '00000016',
  /** Excelデータダウンロード（詳細情報を出力） */
  OP_00000017: '00000017',
  /** 新規登録 */
  OP_00000018: '00000018',
  /** My施設 */
  OP_00000019: '00000019',
  /** 予約状況を確認する */
  OP_00000020: '00000020',
  /** 利用履歴 */
  OP_00000021: '00000021',
  /** はじめてのおあずかり券 */
  OP_00000022: '00000022',
  /** マイページ */
  OP_00000023: '00000023',
  /** ログアウト */
  OP_00000024: '00000024',
  /** 申込内容の確認 */
  OP_00000025: '00000025',
  /** 上記の内容で申し込み */
  OP_00000026: '00000026',
  /** 施設名 */
  OP_00000027: '00000027',
  /** 対象のお子さまを選択 */
  OP_00000028: '00000028',
  /** 利用希望日を選択 */
  OP_00000029: '00000029',
  /** 選択した日程で申し込み */
  OP_00000030: '00000030',
  /** 予約をキャンセル */
  OP_00000031: '00000031',
  /** キャンセル待ちを取消 */
  OP_00000032: '00000032',
  /** 利用する */
  OP_00000033: '00000033',
  /** クーポン利用：確定する */
  OP_00000034: '00000034',
  /** 変更 */
  OP_00000035: '00000035',
  /** キャンセル内容の確認 */
  OP_00000036: '00000036',
  /** 確定 */
  OP_00000037: '00000037',
  /** アカウント情報 */
  OP_00000038: '00000038',
  /** 会員情報 */
  OP_00000039: '00000039',
  /** 予約する */
  OP_00000040: '00000040',
  /** 空き状況確認 */
  OP_00000041: '00000041',
  /** タブ：利用予約 */
  OP_00000042: '00000042',
  /** タブ：面談予約 */
  OP_00000043: '00000043',
  /** 利用予約詳細 */
  OP_00000044: '00000044',
  /** 面談予約詳細 */
  OP_00000045: '00000045',
  /** 詳細 */
  OP_00000046: '00000046',
  /** 電話番号変更 */
  OP_00000047: '00000047',
  /** メールアドレス変更 */
  OP_00000048: '00000048',
  /** パスワード変更 */
  OP_00000049: '00000049',
  /** 確認 */
  OP_00000050: '00000050',
  /** ワンタイムパスワード再送信 */
  OP_00000051: '00000051',
  /** タブ：保護者 */
  OP_00000052: '00000052',
  /** タブ：お子さま */
  OP_00000053: '00000053',
  /** 減免詳細 */
  OP_00000054: '00000054',
  /** 減免詳細：閉じる */
  OP_00000055: '00000055',
  /** お子さまを追加 */
  OP_00000056: '00000056',
  /** 修正 */
  OP_00000057: '00000057',
  /** 修正内容の確認 */
  OP_00000058: '00000058',
  /** 再送信 */
  OP_00000059: '00000059',
  /** 登録内容の確認 */
  OP_00000060: '00000060',
  /** あずかり券を使う */
  OP_00000061: '00000061',
  /** タブ：おあずかり券を使う */
  OP_00000062: '00000062',
  /** タブ：利用履歴 */
  OP_00000063: '00000063',
  /** あずかり券を使う時間入力 */
  OP_00000064: '00000064',
  /** 利用履歴詳細 */
  OP_00000065: '00000065',
  /** 登録 */
  OP_00000066: '00000066',
  /** 更新 */
  OP_00000067: '00000067',
  /** 一括更新 */
  OP_00000068: '00000068',
  /** 追加 */
  OP_00000069: '00000069',
  /** 削除 */
  OP_00000070: '00000070',
  /** 登録確認：キャンセル */
  OP_00000071: '00000071',
  /** 登録確認：登録 */
  OP_00000072: '00000072',
  /** 登録エラー：閉じる */
  OP_00000073: '00000073',
  /** 登録完了：閉じる */
  OP_00000074: '00000074',
  /** エラー：閉じる */
  OP_00000075: '00000075',
  /** 削除確認：キャンセル */
  OP_00000076: '00000076',
  /** Excelデータダウンロード */
  OP_00000077: '00000077',
  /** ダウンロード */
  OP_00000078: '00000078',
  /** データ出力 */
  OP_00000079: '00000079',
  /** 詳細検索 */
  OP_00000080: '00000080',
  /** 本日の予約 */
  OP_00000081: '00000081',
  /** 翌営業日の予約 */
  OP_00000082: '00000082',
  /** 利用予約登録 */
  OP_00000083: '00000083',
  /** 面談予約登録 */
  OP_00000084: '00000084',
  /** TODO：未確定の面談予約 */
  OP_00000085: '00000085',
  /** TODO：未確定の利用予約 */
  OP_00000086: '00000086',
  /** TODO：本日の面談予約 */
  OP_00000087: '00000087',
  /** TODO：報告書 */
  OP_00000088: '00000088',
  /** 予約情報反映 */
  OP_00000089: '00000089',
  /** 実績利用日時 */
  OP_00000090: '00000090',
  /** はじめてのおあずかり券反映 */
  OP_00000091: '00000091',
  /** 残時間表示 */
  OP_00000092: '00000092',
  /** 予約反映確認：反映 */
  OP_00000093: '00000093',
  /** 予約反映確認：キャンセル */
  OP_00000094: '00000094',
  /** WEB予約枠（一括）：一律設定 */
  OP_00000095: '00000095',
  /** WEB予約枠（一括）：更新 */
  OP_00000096: '00000096',
  /** WEB予約枠（個別）：更新 */
  OP_00000097: '00000097',
  /** 初期パスワード再送 */
  OP_00000098: '00000098',
  /** 初期パスワード再送確認：キャンセル */
  OP_00000099: '00000099',
  /** 初期パスワード再送確認：送信 */
  OP_00000100: '00000100',
  /** 基本情報：お子様情報　明細 */
  OP_00000101: '00000101',
  /** 減免情報：新規登録 */
  OP_00000102: '00000102',
  /** 減免情報：減免情報リスト　明細 */
  OP_00000103: '00000103',
  /** 行動履歴：利用者削除 */
  OP_00000104: '00000104',
  /** 行動履歴：利用者削除確認：キャンセル */
  OP_00000105: '00000105',
  /** 行動履歴：利用者削除確認：削除 */
  OP_00000106: '00000106',
  /** はじめてのおあずかり券：利用停止 */
  OP_00000107: '00000107',
  /** はじめてのおあずかり券：利用再開 */
  OP_00000108: '00000108',
  /** はじめてのおあずかり券：利用停止/再開確認：キャンセル */
  OP_00000109: '00000109',
  /** はじめてのおあずかり券：利用停止/再開確認：OK */
  OP_00000110: '00000110',
  /** 報告書の出力 */
  OP_00000111: '00000111',
  /** 報告書の提出 */
  OP_00000112: '00000112',
  /** 報告書提出確認：キャンセル */
  OP_00000113: '00000113',
  /** 報告書提出確認：登録 */
  OP_00000114: '00000114',
  /** 確定確認：キャンセル */
  OP_00000115: '00000115',
  /** 確定確認：登録 */
  OP_00000116: '00000116',
  /** 差戻し */
  OP_00000117: '00000117',
  /** 差戻し確認：キャンセル */
  OP_00000118: '00000118',
  /** 差戻し確認：登録 */
  OP_00000119: '00000119',
  /** 障害情報：新規登録 */
  OP_00000120: '00000120',
  /** 導入編 */
  OP_00000121: '00000121',
  /** 運用編 */
  OP_00000122: '00000122',
  /** ①-1年齢＋曜日＋施設情報別利用時間数：ファイル作成 */
  OP_00000123: '00000123',
  /** ①-1年齢＋曜日＋施設情報別利用時間数：ダウンロード */
  OP_00000124: '00000124',
  /** ①-2年齢＋曜日＋施設情報別利用児童数：ファイル作成 */
  OP_00000125: '00000125',
  /** ①-2年齢＋曜日＋施設情報別利用児童数：ダウンロード */
  OP_00000126: '00000126',
  /** ②年齢＋利用方法・利用目的別実績：ファイル作成 */
  OP_00000127: '00000127',
  /** ②年齢＋利用方法・利用目的別実績：ダウンロード */
  OP_00000128: '00000128',
  /** ③利用目的＋曜日別実績：ファイル作成 */
  OP_00000129: '00000129',
  /** ③利用目的＋曜日別実績：ダウンロード */
  OP_00000130: '00000130',
  /** ④-1特定項目＋年齢＋施設情報別_利用時間数_施設別：ファイル作成 */
  OP_00000131: '00000131',
  /** ④-1特定項目＋年齢＋施設情報別_利用時間数_施設別：ダウンロード */
  OP_00000132: '00000132',
  /** ④-2特定項目＋年齢＋施設情報別_利用時間数_区・適用単価別：ファイル作成 */
  OP_00000133: '00000133',
  /** ④-2特定項目＋年齢＋施設情報別_利用時間数_区・適用単価別：ダウンロード */
  OP_00000134: '00000134',
  /** ④-3特定項目＋年齢＋施設情報別_利用児童数_施設別：ファイル作成 */
  OP_00000135: '00000135',
  /** ④-3特定項目＋年齢＋施設情報別_利用児童数_施設別：ダウンロード */
  OP_00000136: '00000136',
  /** ④-4特定項目＋年齢＋施設情報別_利用児童数_区・適用単価別：ファイル作成 */
  OP_00000137: '00000137',
  /** ④-4特定項目＋年齢＋施設情報別_利用児童数_区・適用単価別：ダウンロード */
  OP_00000138: '00000138',
  /** ⑤時間帯＋曜日＋施設情報別実績：ファイル作成 */
  OP_00000139: '00000139',
  /** ⑤時間帯＋曜日＋施設情報別実績：ダウンロード */
  OP_00000140: '00000140',
  /** ⑥利用時間加算対象時間：ファイル作成 */
  OP_00000141: '00000141',
  /** ⑥利用時間加算対象時間：ダウンロード */
  OP_00000142: '00000142',
  /** ⑦ステータス別利用予約数：ファイル作成 */
  OP_00000143: '00000143',
  /** ⑦ステータス別利用予約数：ダウンロード */
  OP_00000144: '00000144',
  /** ⑧上限利用率：ファイル作成 */
  OP_00000145: '00000145',
  /** ⑧上限利用率：ダウンロード */
  OP_00000146: '00000146',
  /** 導入編：アップロード */
  OP_00000147: '00000147',
  /** 導入編：ダウンロード */
  OP_00000148: '00000148',
  /** 運用編：アップロード */
  OP_00000149: '00000149',
  /** 運用編：ダウンロード */
  OP_00000150: '00000150',
  /** 利用者側：アップロード */
  OP_00000151: '00000151',
  /** 利用者側：ダウンロード */
  OP_00000152: '00000152',
  /** ⑨おあずかり券利用状況：ファイル作成 */
  OP_00000153: '00000153',
  /** ⑨おあずかり券利用状況：ダウンロード */
  OP_00000154: '00000154',
  /** 退会手続きはこちら */
  OP_00000155: '00000155',
  /** 退会する */
  OP_00000156: '00000156',
  /** 利用終了手続きはこちら */
  OP_00000159: '00000159',
  /** 利用終了する */
  OP_00000160: '00000160',
  /** SSO連携はこちら */
  OP_00000162: '00000162',
  /** SSO連携解除はこちら */
  OP_00000167: '00000167',
} as const
