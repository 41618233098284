import { executeGet, executeGetNoResultError } from '../common/apiCaller'
import { GetUsageRecordDto, GetUsageRecordsDto, GetUsageRecordsInputDto } from '../dto/usageRecordsDto'

const apiName = 'citizen'
const apiPath = '/usage-records'

export const executeGetUsageRecords = (input: GetUsageRecordsInputDto) => {
  return executeGet<GetUsageRecordsDto[]>(apiName, apiPath, input)
}

export const executeGetUsageRecord = (id: string) => {
  return executeGetNoResultError<GetUsageRecordDto>(apiName, `${apiPath}/${id}`)
}
