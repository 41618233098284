import { Fab, styled, Zoom } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsIntersectingFooter } from '../../../../containers/common/store/slices/application'
import { ArrowUpIcon } from '../icons/arrowUpIcon'

const Root = styled(Fab, { shouldForwardProp: (prop) => prop !== 'isIntersectingFooter' })<{
  isIntersectingFooter: boolean
}>(({ theme, isIntersectingFooter }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  position: isIntersectingFooter ? 'absolute' : 'fixed',
  bottom: 16,
  right: 16,
  zIndex: 100,
  opacity: 0.7,
}))

export const ScrollTopFab = () => {
  const [isShown, setIsShown] = useState(false)

  const isIntersectingFooter = useSelector(selectIsIntersectingFooter)

  useEffect(() => {
    const onScroll = () => {
      setIsShown(document.documentElement.scrollTop > 0)
    }
    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const onClick = useCallback(() => {
    window.scrollTo({
      top: 0,
    })
  }, [])
  return (
    <Zoom in={isShown}>
      <Root size="small" onClick={onClick} isIntersectingFooter={isIntersectingFooter}>
        <ArrowUpIcon />
      </Root>
    </Zoom>
  )
}
