import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { executeGetControl } from '../../dataAccess/webApi/dao/controlDao'
import { initializeI18n } from '../../i18n'
import { fromApiYmd, fromApiYmdHms, initializeDateUtil } from '../../utils/dateUtil'
import { datePropsToNumber, nullToUndefined } from '../../utils/objectUtil'
import { errorUrls, loginUrl } from '../common/constant/appUrl'
import { httpStatusCode } from '../common/constant/classification'
import { useOperationLog } from '../common/operationLog'
import { backupWindowId, restoreWindowId } from '../common/sessionStore'
import { setPxPerVh, updateHistoryStackLength } from '../common/store/slices/application'
import { detectLoginStatus } from '../common/store/slices/authority'
import { setSystemControl } from '../common/store/slices/systemControl'
import { Hub } from 'aws-amplify'

const getPxPerVh = () => window.innerHeight / 100

export const useAction = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isInitializedLoggedIn, setIsInitializedLoggedIn] = useState(false)
  const loginRouteMatch = useRouteMatch({
    path: loginUrl.routerPath,
    exact: true,
  })
  const underMaintenanceRouteMatch = useRouteMatch({
    path: errorUrls[httpStatusCode.serviceUnavailable].routerPath,
    exact: true,
  })

  const { flushOperationLog } = useOperationLog()

  useEffect(() => {
    return Hub.listen("auth", async ({ payload: { event } }) => {
      if (event === 'cognitoHostedUI') {
        await dispatch(detectLoginStatus())
      }
    })
  }, [])
  useEffect(() => {
    return history.listen((location, action) => {
      dispatch(updateHistoryStackLength(action))
    })
  }, [])
  useEffect(() => {
    const onResize = () => dispatch(setPxPerVh(getPxPerVh()))
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])
  useEffect(() => {
    const initializeLoggedIn = async () => {
      const [, , controlRes] = await Promise.all([
        (async () => {
          await initializeI18n()
        })(),
        dispatch(detectLoginStatus()),
        executeGetControl(),
      ])
      const systemDatetime = nullToUndefined(fromApiYmdHms(controlRes.result.systemDatetime))
      const serviceTitle = controlRes.result.serviceTitle
      document.title = serviceTitle
      document.querySelector('meta[name="description"]')?.setAttribute('content', serviceTitle)
      initializeDateUtil(systemDatetime)
      dispatch(
        setSystemControl(
          datePropsToNumber({
            serviceTitle,
            iconImageName: controlRes.result.iconImageName,
            s3BucketNameIcon: controlRes.result.s3BucketNameIcon,
            projectSearchFlag: controlRes.result.projectSearchFlag,
            facilityCategorySearchFlag: controlRes.result.facilityCategorySearchFlag,
            ssoUseFlag: controlRes.result.ssoUseFlag,
            ssoUseName: controlRes.result.ssoUseName,
            ssoEmailCertificationFlag: controlRes.result.ssoEmailCertificationFlag,
            phoneCertificationFlag: controlRes.result.phoneCertificationFlag,
            systemDatetime,
            s3BucketNameManual: controlRes.result.s3BucketNameManual,

            useCouponFunctionFlag: controlRes.result.useCouponFunctionFlag,
            ...(controlRes.result.usageCouponFromDate &&
              controlRes.result.usageCouponToDate && {
                usageCouponFromDate: fromApiYmd(controlRes.result.usageCouponFromDate),
                usageCouponToDate: fromApiYmd(controlRes.result.usageCouponToDate),
              }),

            unsubscribeAcceptFlag: controlRes.result.unsubscribeAcceptFlag,
            childUseEndAcceptFlag: controlRes.result.childUseEndAcceptFlag,
          })
        )
      )
      setIsInitializedLoggedIn(true)
    }
    initializeLoggedIn()
  }, [])

  useEffect(() => {
    const onBeforeunload = () => {
      flushOperationLog()
    }
    window.addEventListener('beforeunload', onBeforeunload)
    return () => window.removeEventListener('beforeunload', onBeforeunload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flushOperationLog])

  useEffect(() => {
    const onUnload = () => backupWindowId()
    window.addEventListener('unload', onUnload)
    return () => window.removeEventListener('unload', onUnload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const onDOMContentLoaded = () => restoreWindowId()
    window.addEventListener('DOMContentLoaded', onDOMContentLoaded)
    return () => window.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isInitializedLoggedIn,
    loginRouteMatch,
    underMaintenanceRouteMatch,
  }
}
