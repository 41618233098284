import { ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material'
import { LocationDescriptor } from 'history'
import React, { MouseEventHandler } from 'react'
import { Link } from './link'

const StyledItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '36px',
}))

interface ListItemLinkProps {
  icon?: React.ReactElement
  primary: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  to?: LocationDescriptor
}

export const ListItemLink = (props: ListItemLinkProps) => {
  const { icon, primary, to, onClick } = props

  return (
    <ListItemButton component={Link} to={to} onClick={onClick}>
      {icon && <StyledItemIcon>{icon}</StyledItemIcon>}
      <ListItemText primary={primary} />
    </ListItemButton>
  )
}
