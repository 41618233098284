import { SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { NoResultError } from '../../dataAccess/webApi/common/apiCaller'
import { executeGetChildren } from '../../dataAccess/webApi/dao/childrenDao'
import { executeGetFacilityReservationsWeekCitizen } from '../../dataAccess/webApi/dao/facilityReservationsWeekCitizenDao'
import { executeGetFacilityReservationsWeekPublic } from '../../dataAccess/webApi/dao/facilityReservationsWeekPublicDao'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetFacilityReservationsWeekPublicDto } from '../../dataAccess/webApi/dto/facilityReservationsWeekPublicDto'
import { translate } from '../../i18n'
import { DateRange, ElapsedMillisecond, getNowTrimedTime, isValidDate, toApiYmd } from '../../utils/dateUtil'
import { dateToNumber } from '../../utils/objectUtil'
import { castNonNullable, NullPropsToUndefinedType } from '../../utils/typeUtil'
import { getAgesTwoDigits } from '../common/codeMaster'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { selectIsLoggedIn } from '../common/store/slices/authority'
import { facilityReservationSelectionCreateUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'

interface UrlParams {
  facilityId: string
  childId?: string
}

interface Inputs {
  /** 利用希望日時 */
  usageDesiredDatetimes: {
    value: {
      /** 時間の基準となる日付 */
      baseDateOfTime: Date
      /** 日時範囲 */
      range: DateRange
      /** 空き状況ステータス */
      status: string
    }
  }[]
}

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep?: boolean
  activeChildId?: string
  activeDate: ElapsedMillisecond
}

interface PageState {
  activeChildId?: string
  activeDate: Date
  /** 施設情報 */
  facility?: NullPropsToUndefinedType<GetFacilityDto>

  childs: { value: string; label: string }[]
  availabilities?: GetFacilityReservationsWeekPublicDto[]
  isExistPermitChilds: boolean
}

type ChildsType = PageState['childs']

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const nextHistory = useHistory()
  const urlParams = useParams<UrlParams>()

  const isLoggedIn = useSelector(selectIsLoggedIn)
  const locationState = history.location.state
  const currentLocation = history.location

  const [state, setState] = useState<PageState>({
    ...(locationState?.isKeep
      ? {
          activeChildId: locationState.activeChildId,
          activeDate: new Date(locationState.activeDate),
        }
      : {
          activeChildId: urlParams.childId,
          activeDate: locationState?.activeDate ? new Date(locationState.activeDate) : getNowTrimedTime(),
        }),
    childs: [],
    isExistPermitChilds: false,
  })

  const notifyDateFetchErrorMessage = useCallback(
    () => dispatch(notifyMessage(translate('facilityDetailAvailability.error.noReservationReceptionSetting'))),
    [dispatch]
  )

  const formMethods = useForm<Inputs>()

  useEffect(() => {
    dispatch(
      showLoading(
        errorHandle(async () => {
          const facilityWithChilds = await getLoggedInJudgeFacilityAvailabilitiesWithChilds(
            urlParams.facilityId,
            state.activeChildId,
            state.activeDate,
            isLoggedIn
          )
          if (facilityWithChilds) {
            /** 正常 */
            setState({ ...facilityWithChilds })
            /** 施設の利用予約の受付可否：受け付ける、
             *  面談許可されたお子様　がいた場合は、施設施設予約（希望日選択）を表示 */
            facilityWithChilds.isExistPermitChilds &&
              facilityWithChilds.facility.reservationAcceptFlag === yesNo.yes &&
              nextHistory.replace({
                pathname: facilityReservationSelectionCreateUrl.url(
                  facilityWithChilds.facilityId,
                  facilityWithChilds.activeChildId
                ),
                state: { activeDate: state.activeDate.getTime() },
              })
          } else {
            /** 異常 */
            notifyDateFetchErrorMessage()
          }
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.activeChildId == null) {
      return
    }
    /** 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管 */
    history.replace({
      ...history.location,
      state: {
        isKeep: true,
        activeChildId: state.activeChildId,
        activeDate: dateToNumber(state.activeDate),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeChildId, state.activeDate])

  const onNextPrevExec = useCallback(
    (baseDate: Date) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const facilityAvailabilities = await getLoggedInJudgeFacilityAvailabilities(
              urlParams.facilityId,
              // childId未確定で呼び出されることはないのでNonNull
              castNonNullable(state.activeChildId),
              baseDate,
              state.isExistPermitChilds
            )
            if (facilityAvailabilities) {
              /** 正常 */
              setState((old) => ({ ...old, ...facilityAvailabilities }))
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [
      dispatch,
      errorHandle,
      notifyDateFetchErrorMessage,
      urlParams.facilityId,
      state.activeChildId,
      state.isExistPermitChilds,
    ]
  )

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const searchChildId = event.target.value
            const availabilities = await getLoggedInJudgeAvailabilities(
              urlParams.facilityId,
              searchChildId,
              state.activeDate,
              state.isExistPermitChilds
            )
            if (availabilities) {
              /** 正常 */
              setState((old) => ({ ...old, ...availabilities }))
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [
      dispatch,
      errorHandle,
      notifyDateFetchErrorMessage,
      urlParams.facilityId,
      state.activeDate,
      state.isExistPermitChilds,
    ]
  )

  const changeDate = useCallback(
    (date: Date | null) => {
      if (isValidDate(date)) {
        onNextPrevExec(date)
      }
    },
    [onNextPrevExec]
  )

  const callTel = useCallback((tel: string) => (document.location.href = `tel:${tel}`), [])

  return {
    facilityId: urlParams.facilityId,
    facility: state.facility,
    childs: state.childs,
    activeChildId: state.activeChildId,
    activeDate: state.activeDate,
    availabilities: state.availabilities,
    formMethods,
    isExistPermitChilds: state.isExistPermitChilds,
    currentLocation,
    isLoggedIn,
    onNextPrevExec,
    changeChild,
    changeDate,
    callTel,
  }
}

/**
 * 未ログイン、ログイン済みかを判定し、
 * 施設情報、空き情報、お子さまリストを取得
 * （getLoggedInJudgeFacilityAvailabilitiesWithChilds）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param isLoggedIn ログイン状態
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getLoggedInJudgeFacilityAvailabilitiesWithChilds = async (
  facilityId: string,
  childId: string | undefined,
  targetDate: Date,
  isLoggedIn: boolean
) => {
  if (isLoggedIn) {
    return getFacilityAvailabilitiesWithChildsLoggedIn(facilityId, childId, targetDate)
  } else {
    return getFacilityAvailabilitiesWithChildsNotLoggedIn(facilityId, childId, targetDate)
  }
}

/**
 * 施設許可お子さま存在かを判定し、
 * 施設情報、空き情報を取得
 * （getLoggedInJudgeFacilityAvailabilities）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param isExistPermitChilds 施設許可お子さま存在フラグ（true:存在 false:存在しない）
 * @returns 施設情報、空き情報
 */
const getLoggedInJudgeFacilityAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  isExistPermitChilds: boolean
) => {
  if (isExistPermitChilds) {
    return getFacilityAvailabilitiesLoggedIn(facilityId, childId, targetDate)
  } else {
    return getFacilityAvailabilitiesNotLoggedIn(facilityId, childId, targetDate)
  }
}

/**
 * 施設許可お子さま存在かを判定し、
 * 空き情報を取得
 * （getLoggedInJudgeAvailabilities）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param isExistPermitChilds 施設許可お子さま存在フラグ（true:存在 false:存在しない）
 * @returns 空き情報
 */
const getLoggedInJudgeAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  isExistPermitChilds: boolean
) => {
  if (isExistPermitChilds) {
    return getAvailabilitiesLoggedIn(facilityId, childId, targetDate)
  } else {
    return getAvailabilitiesNotLoggedIn(facilityId, childId, targetDate)
  }
}

/**
 * 空き情報を取得（未ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @returns 空き情報
 */
const getAvailabilitiesNotLoggedIn = async (facilityId: string, childId: string, targetDate: Date) => {
  const availabilitiesResponse = await executeGetFacilityReservationsWeekPublic(facilityId, {
    childId,
    targetDate: toApiYmd(targetDate),
  })
  if (availabilitiesResponse.result.length) {
    return {
      activeChildId: childId,
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 空き情報を取得（ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @returns 空き情報
 */
const getAvailabilitiesLoggedIn = async (facilityId: string, childId: string, targetDate: Date) => {
  const availabilitiesResponse = await executeGetFacilityReservationsWeekCitizen(facilityId, {
    childId,
    targetDate: toApiYmd(targetDate),
    reservationNo: null,
  })
  if (availabilitiesResponse.result.length) {
    return {
      activeChildId: childId,
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 施設情報、空き情報を取得（未ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @returns 施設情報、空き情報
 */
const getFacilityAvailabilitiesNotLoggedIn = async (facilityId: string, childId: string, targetDate: Date) => {
  try {
    const [facility, availabilities] = await Promise.all([
      getFacility(facilityId, targetDate),
      getAvailabilitiesNotLoggedIn(facilityId, childId, targetDate),
    ])
    if (availabilities) {
      return {
        ...availabilities,
        facility,
      }
    } else {
      return null
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      return null
    } else {
      throw e
    }
  }
}

/**
 * 施設情報、空き情報を取得（ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @returns 施設情報、空き情報
 */
const getFacilityAvailabilitiesLoggedIn = async (facilityId: string, childId: string, targetDate: Date) => {
  try {
    const [facility, availabilities] = await Promise.all([
      getFacility(facilityId, targetDate),
      getAvailabilitiesLoggedIn(facilityId, childId, targetDate),
    ])
    if (availabilities) {
      return {
        ...availabilities,
        facility,
      }
    } else {
      return null
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      return null
    } else {
      throw e
    }
  }
}

/**
 * 施設情報、空き情報、お子さまリストを取得（未ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getFacilityAvailabilitiesWithChildsNotLoggedIn = async (
  facilityId: string,
  childId: string | undefined,
  targetDate: Date
) => {
  const childs = getAgesTwoDigits()
  /*
     お子様IDが未設定（undefined）の場合（遷移直後）、
     未ログイン状態では、年齢リストの先頭(00:０歳)を選択値として設定する */
  const subjectChildId = getSubjectChildId(childs, childId)

  const facilityAvailabilities = await getFacilityAvailabilitiesNotLoggedIn(facilityId, subjectChildId, targetDate)
  if (facilityAvailabilities) {
    return {
      ...facilityAvailabilities,
      childs,
      isExistPermitChilds: false,
    }
  } else {
    return null
  }
}

/**
 * 施設情報、空き情報、お子さまリストを取得（ログイン時）
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getFacilityAvailabilitiesWithChildsLoggedIn = async (
  facilityId: string,
  childId: string | undefined,
  targetDate: Date
) => {
  const childrenResponse = await executeGetChildren({ facilityId })

  /* 
     お子様ID（childId）が未設定（undefined）の場合（遷移直後）、
     ログイン状態では、利用者に属するお子様で、施設に許可されたお子様リストの先頭を選択値として設定する。
     ただし、施設に許可したお子様IDが１つもない場合は、
     未ログイン時と同内容の表示を行う為、年齢リストの先頭を設定する。*/
  const isExistPermitChilds = childrenResponse.result.length !== 0

  const childs = isExistPermitChilds
    ? childrenResponse.result.map((child) => ({ value: child.childId, label: child.name }))
    : getAgesTwoDigits()

  const subjectChildId = getSubjectChildId(childs, childId)

  const facilityAvailabilities = isExistPermitChilds
    ? await getFacilityAvailabilitiesLoggedIn(facilityId, subjectChildId, targetDate)
    : await getFacilityAvailabilitiesNotLoggedIn(facilityId, subjectChildId, targetDate)

  if (facilityAvailabilities) {
    return {
      ...facilityAvailabilities,
      childs,
      isExistPermitChilds,
    }
  } else {
    return null
  }
}

const getSubjectChildId = (childs: ChildsType, childId: string | undefined) => {
  if (childId == null || !childs.some((v) => v.value === childId)) {
    // 戻る初期表示時に施設許可お子さま存在が変わっていた場合
    // システムエラーにならないように一致する選択の有無を判定しておく
    return childs[0].value
  } else {
    return childId
  }
}
