import { Divider, Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/informationDetail/informationDetailService'
import { formatYmdHmWeek } from '../../utils/dateUtil'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { InlineFlexbox } from '../components/common/flexboxes'
import { AccountIcon } from '../components/common/icons/accountIcon'
import { Link } from '../components/common/link'
import { TitleLabel } from '../components/common/titleLabel'
import { Attachment, AttachmentLinks } from '../components/information/attachmentLinks'


const IconBox = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.grayDark.main,
  borderRadius: 24,
  fontSize: '1.4rem',
  marginTop: '0.15rem',
  marginRight: 4,
}))

const BodyBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

const HeaderBodyDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
}))

export const InformationDetail = () => {
  const { information } = useAction()

  if (information == null) {
    return null
  }

  const attachments: Attachment[] = [
    {
      fileName: information.attachment1FileName,
      key: information.attachment1Key
    },
    {
      fileName: information.attachment2FileName,
      key: information.attachment2Key
    },
    {
      fileName: information.attachment3FileName,
      key: information.attachment3Key
    },
  ]

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <div>
              <AnnotationLabel>{formatYmdHmWeek(information.publicationStartDatetime)}</AnnotationLabel>
              <div>
                <IconBox>
                  <AccountIcon fontSize="inherit" />
                </IconBox>
                {information.facilityName}
              </div>
              <TitleLabel>{information.title}</TitleLabel>
            </div>

            <HeaderBodyDivider />

            <BodyBox>{information.body}</BodyBox>

            {information.url && (
              <Link href={information.url} target="_blank">
                {information.url}
              </Link>
            )}
            <AttachmentLinks attachments={attachments}/>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
