import { executeGet, executeGetNoResultError, executePost } from '../common/apiCaller'
import { GetInterviewDto, GetInterviewsDto, GetInterviewsInputDto, PostInterviewInputDto } from '../dto/interviewsDto'

const apiNameCitizen = 'citizen'
const apiPath = '/interviews'

export const executeGetInterviews = async (input: GetInterviewsInputDto) => {
  return executeGet<GetInterviewsDto[]>(apiNameCitizen, apiPath, input)
}

export const executeGetInterview = (id: string) => {
  return executeGetNoResultError<GetInterviewDto>(apiNameCitizen, `${apiPath}/${id}`)
}

export const executePostInterview = (input: PostInterviewInputDto) => {
  return executePost<null>(apiNameCitizen, apiPath, input)
}
