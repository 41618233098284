// 予約機能で共通する利用予約に関する表示部分を定義
// 情報照会、変更内容確認で共有
//

import { Stack } from '@mui/material'
import {
  getDoNotWaitlistLabel,
  getGenderLabel,
  getReservationStatusLabel,
  getUseReasonCategoryLabel,
  getYesNoLabel,
} from '../../../../containers/common/codeMaster'
import { yesNo } from '../../../../containers/common/constant/classification'
import { translate } from '../../../../i18n'
import { ElapsedMillisecond, formatHmtoHmOver, formatYmd, formatYmdWeek, toApiHms } from '../../../../utils/dateUtil'
import { RowSpacerGItem } from '../grids'
import { LabelGItem, ValueGItem } from './reservationDetailParts'

interface Reservation {
  facilityName: string

  name: string
  kana: string
  tel: string
  email: string

  childName: string
  childKana: string
  childBirth: Date | ElapsedMillisecond
  childGender: string
  childAllergyFlag: string
}

interface ReservationConfirmation extends Reservation {
  useReasonCategory?: string
  lunchFlag: string
  snackFlag: string
  postponeCancelWaitFlag: string
  citizenNote?: string
  lunchAcceptFlag: string
  snackAcceptFlag: string
  postponeCancelWaitAcceptFlag: string
  immediatelyReservationFlag: string
}

interface ReservationDelete extends ReservationConfirmation {
  usageDate: Date | ElapsedMillisecond
  useFromDatetime: Date | ElapsedMillisecond
  useToDatetime: Date | ElapsedMillisecond
  status: string
}

interface ReservationReference extends ReservationDelete {
  reservationDatetime: Date
  reservationChangeDatetime?: Date
}

/** 入力時に必要なプロパティ */
interface Input {
  reservation: Reservation
  displayType?: never
}

/** 入力内容確認時に必要なプロパティ */
interface Confirmation {
  reservation: ReservationConfirmation
  displayType: 'confirmation'
}

/** 利用予約情報削除時の詳細照会に必要なプロパティ */
interface Delete {
  reservation: ReservationDelete
  displayType: 'delete'
}

/** 詳細照会時に必要なプロパティ */
interface Reference {
  reservation: ReservationReference
  displayType: 'reference'
}

type ReservationDetailGItemsProps = Input | Confirmation | Delete | Reference

/** 予約機能の利用予約情報コンテンツ部分 */
export const ReservationDetailGItems = (props: ReservationDetailGItemsProps) => {
  const isShownReservationItem =
    props.displayType === 'confirmation' || props.displayType === 'delete' || props.displayType === 'reference'
  const isShownStatusItem = props.displayType === 'delete' || props.displayType === 'reference'
  const isShownReservationDatetimeItem = props.displayType === 'reference'

  return (
    <>
      <LabelGItem>{translate('system.reservationDetailGItems.label.facilityName')}</LabelGItem>
      <ValueGItem>{props.reservation.facilityName}</ValueGItem>
      {isShownStatusItem && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.reservationDatetime')}</LabelGItem>
          <ValueGItem>
            <Stack>
              <div>{formatYmdWeek(props.reservation.usageDate)}</div>
              <div>
                {formatHmtoHmOver(
                  { from: props.reservation.useFromDatetime, to: props.reservation.useToDatetime },
                  props.reservation.usageDate
                )}
              </div>
            </Stack>
          </ValueGItem>
          <LabelGItem>{translate('system.reservationDetailGItems.label.status')}</LabelGItem>
          <ValueGItem>{getReservationStatusLabel(props.reservation.status)}</ValueGItem>
        </>
      )}
      <RowSpacerGItem />

      <LabelGItem>{translate('system.reservationDetailGItems.label.userName')}</LabelGItem>
      <ValueGItem>{props.reservation.name}</ValueGItem>
      <LabelGItem>{translate('system.reservationDetailGItems.label.userNameKana')}</LabelGItem>
      <ValueGItem>{props.reservation.kana}</ValueGItem>
      <LabelGItem>{translate('system.reservationDetailGItems.label.tel')}</LabelGItem>
      <ValueGItem>{props.reservation.tel}</ValueGItem>
      <LabelGItem>{translate('system.reservationDetailGItems.label.email')}</LabelGItem>
      <ValueGItem>{props.reservation.email}</ValueGItem>
      <RowSpacerGItem />

      <LabelGItem>{translate('system.reservationDetailGItems.label.childName')}</LabelGItem>
      <ValueGItem>{props.reservation.childName}</ValueGItem>
      <LabelGItem>{translate('system.reservationDetailGItems.label.childNameKana')}</LabelGItem>
      <ValueGItem>{props.reservation.childKana}</ValueGItem>
      <LabelGItem>{translate('system.reservationDetailGItems.label.birthday')}</LabelGItem>
      <ValueGItem>{formatYmd(props.reservation.childBirth)}</ValueGItem>
      <LabelGItem>{translate('system.reservationDetailGItems.label.gender')}</LabelGItem>
      <ValueGItem>{getGenderLabel(props.reservation.childGender)}</ValueGItem>
      <LabelGItem>{translate('system.reservationDetailGItems.label.allergy')}</LabelGItem>
      <ValueGItem>{getYesNoLabel(props.reservation.childAllergyFlag)}</ValueGItem>
      {isShownReservationItem && (
        <>
          <LabelGItem>{translate('system.reservationDetailGItems.label.useReasonCategory')}</LabelGItem>
          <ValueGItem>{getUseReasonCategoryLabel(props.reservation.useReasonCategory)}</ValueGItem>
          {props.reservation.lunchAcceptFlag === yesNo.yes && (
            <>
              <LabelGItem>{translate('system.reservationDetailGItems.label.lunch')}</LabelGItem>
              <ValueGItem>{getYesNoLabel(props.reservation.lunchFlag)}</ValueGItem>
            </>
          )}
          {props.reservation.snackAcceptFlag === yesNo.yes && (
            <>
              <LabelGItem>{translate('system.reservationDetailGItems.label.snack')}</LabelGItem>
              <ValueGItem>{getYesNoLabel(props.reservation.snackFlag)}</ValueGItem>
            </>
          )}
          {props.reservation.immediatelyReservationFlag === yesNo.no &&
            props.reservation.postponeCancelWaitAcceptFlag === yesNo.yes && (
              <>
                <LabelGItem>{translate('system.reservationDetailGItems.label.postponeCancelWait')}</LabelGItem>
                <ValueGItem>{getDoNotWaitlistLabel(props.reservation.postponeCancelWaitFlag)}</ValueGItem>
              </>
            )}
          <LabelGItem>{translate('system.reservationDetailGItems.label.note')}</LabelGItem>
          <ValueGItem>{props.reservation.citizenNote}</ValueGItem>
        </>
      )}
      {isShownReservationDatetimeItem && (
        <>
          <RowSpacerGItem />
          <LabelGItem>{translate('system.reservationDetailGItems.label.reservationReceptionDatetime')}</LabelGItem>
          <ValueGItem>
            <Stack>
              <div>{formatYmdWeek(props.reservation.reservationDatetime)}</div>
              <div>{toApiHms(props.reservation.reservationDatetime)}</div>
            </Stack>
          </ValueGItem>
          {props.reservation.reservationChangeDatetime != null && (
            <>
              <LabelGItem>{translate('system.reservationDetailGItems.label.reservationChangeDatetime')}</LabelGItem>
              <ValueGItem>
                <Stack>
                  <div>{formatYmdWeek(props.reservation.reservationChangeDatetime)}</div>
                  <div>{toApiHms(props.reservation.reservationChangeDatetime)}</div>
                </Stack>
              </ValueGItem>
            </>
          )}
        </>
      )}
    </>
  )
}
