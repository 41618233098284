import React from 'react'
import { useAction } from '../../containers/accountChangeVerification/accountChangeVerificationService'
import { accountChangeVerificationCategory } from '../../containers/common/constant/classification'
import { OneTimePasswordVerificationContent } from '../components/common/account/oneTimePasswordVerificationContent'
import { Form } from '../components/common/inputs/form'
import { TransitionCheck } from '../components/common/transitionCheck'

export const AccountChangeVerification = () => {
  const { changeEntry, formMethods, identifier, resend, onSubmit } = useAction()
  if (changeEntry == null) {
    return <TransitionCheck check={false} />
  }
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <OneTimePasswordVerificationContent
        identifier={identifier}
        {...(changeEntry.category !== accountChangeVerificationCategory.email && {
          resend,
        })}
      />
    </Form>
  )
}
