// Material UI Gridコンポーネントをラップしたコントロールなどのユーティリティ部品
//

import { Grid, GridSize, styled } from '@mui/material'
import React, { forwardRef, ReactNode, Ref } from 'react'
import { ColonSeparator, InlineFlexbox } from './flexboxes'

/** Grid container */
export const GContainer = forwardRef(function GContainer(
  props: Omit<Parameters<typeof Grid>[0], 'item' | 'container' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
  ref: Ref<HTMLDivElement>
) {
  return <Grid ref={ref} {...{ ...props, container: true }} />
})
/** Grid item */
export const GItem = forwardRef(function GItem(
  props: Omit<
    Parameters<typeof Grid>[0],
    'item' | 'container' | 'direction' | 'spacing' | 'rowSpacing' | 'columnSpacing'
  >,
  ref: Ref<HTMLDivElement>
) {
  return <Grid ref={ref} {...{ ...props, item: true }} />
})
/** Grid item かつ container */
export const GItemContainer = (props: Omit<Parameters<typeof Grid>[0], 'item' | 'container'>) => {
  return <Grid {...{ ...props, item: true, container: true }} />
}

/** display:flexの Grid item */
export const FlexGItem = styled(GItem)({
  display: 'flex',
})

/** 水平左寄せ Grid item */
export const LeftGItem = styled(FlexGItem)({
  justifyContent: 'start',
})
/** 水平中央寄せ Grid item */
export const CenterGItem = styled(FlexGItem)({
  justifyContent: 'center',
})
/**
 * 水平右寄せ Grid item
 * iOSで end は機能しないので flex-end を使用
 */
export const RightGItem = styled(FlexGItem)({
  justifyContent: 'flex-end',
})

/** 垂直上寄せ Grid item */
export const TopGItem = styled(FlexGItem)({
  alignItems: 'start',
})
/** 垂直中央寄せ Grid item */
export const MiddleGItem = styled(FlexGItem)({
  alignItems: 'center',
})
/** 垂直下寄せ Grid item */
export const BottomGItem = styled(FlexGItem)({
  alignItems: 'flex-end',
})

/** 真ん中寄せ Grid item */
export const CenterMiddleGItem = styled(CenterGItem)({
  alignItems: 'center',
})

const WrapValue = styled('div')({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
})

interface LabelValueItemProps {
  children: ReactNode
  xs?: boolean | GridSize
  sm?: boolean | GridSize
  md?: boolean | GridSize
}

/**
 * 項目名：項目値
 * の列挙表示コンテンツで使用する
 * 項目名の Grid item 基本コントロール
 */
export const LabelGItemBase = GItem

/**
 * 項目名：項目値
 * の列挙表示コンテンツで使用する
 * 項目値の Grid item 基本コントロール
 */
export const ValueGItemBase = (props: LabelValueItemProps) => {
  const { children, ...through } = props
  return (
    <GItem {...through}>
      <InlineFlexbox>
        <ColonSeparator />
        <WrapValue>{children}</WrapValue>
      </InlineFlexbox>
    </GItem>
  )
}

/** １行分開ける為のGrid item */
export const RowSpacerGItem = () => <GItem xs={12}>&nbsp;</GItem>
