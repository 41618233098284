import { Grid, Stack } from '@mui/material'
import React, { ReactNode } from 'react'
import { getInterviewStatusLabel, getPermitFlagLabel } from '../../../containers/common/codeMaster'
import { interviewReservationDetailUrl } from '../../../containers/common/constant/appUrl'
import { interviewStatus, permitFlag } from '../../../containers/common/constant/classification'
import { translate } from '../../../i18n'
import { formatHmtoHmOver, formatYmdWeek } from '../../../utils/dateUtil'
import { Card } from '../common/card'
import { LabelItemBase, RightItem, ValueItemBase } from '../common/gridItems'
import { Link } from '../common/link'
import { OperationId } from '../../../containers/common/constant/operationLog'

interface InterviewCardProps {
  interviewNo: string
  interviewDate?: Date
  interviewStartDatetime?: Date
  interviewEndDatetime?: Date
  facilityName: string
  childName: string
  status: string
  permitFlag: string
}

const LabelItem = (props: { children?: ReactNode }) => <LabelItemBase xs={4}>{props.children}</LabelItemBase>
const ValueItem = (props: { children: ReactNode }) => <ValueItemBase xs={8}>{props.children}</ValueItemBase>

export const InterviewCard = (props: InterviewCardProps) => {
  return (
    <Card>
      <Grid container>
        <LabelItem>{translate('reservationStatusList.label.interviewDatetime')}</LabelItem>
        <ValueItem>
          {props.status !== interviewStatus.notFixed ? (
            <Stack>
              <div>{props.interviewDate && formatYmdWeek(props.interviewDate)}</div>
              {props.interviewDate && props.interviewStartDatetime && props.interviewEndDatetime && (
                <div>
                  {formatHmtoHmOver(
                    { from: props.interviewStartDatetime, to: props.interviewEndDatetime },
                    props.interviewDate
                  )}
                </div>
              )}
            </Stack>
          ) : (
            translate('reservationStatusList.text.notFixed')
          )}
        </ValueItem>
        <LabelItem>{translate('reservationStatusList.label.facilityName')}</LabelItem>
        <ValueItem>{props.facilityName}</ValueItem>
        <LabelItem>{translate('reservationStatusList.label.childName')}</LabelItem>
        <ValueItem>{props.childName}</ValueItem>
        <LabelItem>{translate('reservationStatusList.label.status')}</LabelItem>
        {props.permitFlag === permitFlag.shelved ? (
          <ValueItem>{getPermitFlagLabel(props.permitFlag)}</ValueItem>
        ) : (
          <ValueItem>{getInterviewStatusLabel(props.status)}</ValueItem>
        )}

        <RightItem item xs={12}>
          <Link
            to={interviewReservationDetailUrl.url(props.interviewNo)}
            operationLog={{ operationId: OperationId.OP_00000045 }}
          >
            {`${translate('reservationStatusList.text.reservationDetail')}>`}
          </Link>
        </RightItem>
      </Grid>
    </Card>
  )
}
