import { Dispatch, SetStateAction } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  SetValueConfig,
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form'
import { toInt } from './stringUtil'

type DipatchValue<T> = T extends Dispatch<SetStateAction<infer V>> ? V : never
type Task = () => void
export class ReactBatchUpdater {
  tasks = [] as Task[]

  add(task: Task) {
    this.tasks.push(task)
  }

  addSetter<T extends Dispatch<SetStateAction<any>>>(setter: T, value: DipatchValue<T>) {
    this.add(() => setter(value))
  }

  execute(lastTask?: Task) {
    unstable_batchedUpdates(() => {
      this.tasks.forEach((task) => task())
      if (lastTask) {
        lastTask()
      }
    })
  }
}

/**
 * 対象フィールドへの値の設定とエラークリアを行う
 * @param methods useFormの値
 * @param name 対象フィールド名
 * @param value 設定する値
 * @param options オプション
 */
export const setFormValueClearError = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>(
  methods: UseFormReturn<TFieldValues, object>,
  name: TFieldName,
  value: UnpackNestedValue<FieldPathValue<TFieldValues, TFieldName>>,
  options?: SetValueConfig
) => {
  methods.setValue(name, value, options)
  methods.clearErrors(name)
}

/**
 * 対象フィールドへのエラー設定を行う
 * @param methods useFormの値
 * @param errors 設定するフィールドエラー情報の配列
 */
export const setFormErrors = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>(
  methods: UseFormReturn<TFieldValues, object>,
  errors: { name: TFieldName; message: string }[]
) => {
  errors.forEach(({ name, message }, index) => methods.setError(name, { message }, { shouldFocus: index === 0 }))
}

/**
 * 対象フィールドへのエラー設定を遅延実行する
 * ※タブ画面などでタブがアクティブになってからフォーカス設定させるため
 * @param methods useFormの値
 * @param errors 設定するフィールドエラー情報の配列
 */
export const delayedSetFormErrors = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>(
  methods: UseFormReturn<TFieldValues, object>,
  errors: { name: TFieldName; message: string }[]
) => {
  setTimeout(() => {
    setFormErrors(methods, errors)
  }, 0)
}

/**
 * 指定したファイル(url)をダウンロードさせる。
 * @param fileUrl ファイルのURL
 * @param fileName 指定したファイル名でダウンロードさせたい場合に指定する
 */
export const downloadFile = (fileUrl: string, fileName?: string) => {
  const a = document.createElement('a')
  a.href = fileUrl
  if (fileName) {
    a.download = fileName
  }
  a.click()
  a.remove()
}

/**
 * 入力項目nameから該当要素が属するタブのタブValueを抽出し、配列にして返す
 * @param names 入力項目nameの配列
 * @returns タブValue配列(昇順)
 */
export const getTabValuesByName = (names: string[]) => {
  return names
    .map((name) => {
      const tabPanel = document.querySelector(`[name=${name}]`)?.closest('[role=tabpanel]')
      let tabValue
      if (tabPanel instanceof HTMLDivElement) {
        tabValue = toInt(tabPanel.dataset.tabValue)
      }
      return tabValue ?? Number.MAX_VALUE
    })
    .sort((a, b) => a - b)
}

/**
 * ウィンドウを開く
 * @param url URL
 * @param target openのtargetへ指定する値。デフォルト_blank
 */
export const openWindow = (url: string, target: string = '_blank') => {
  window.open(url, target)
}

/**
 * ウィンドウを閉じる
 */
export const closeWindow = () => {
  window.close()
}
