import { styled } from '@mui/material'
import { ButtonBase } from './buttonBase'

/**
 * primaryカラーのボタンコントロール基本部分。
 */
export const ButtonContainedBase = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&:active': {
    boxShadow: theme.shadows[8],
  },
}))
