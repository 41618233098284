/**
 * 分数をH時間m分形式にフォーマット
 *
 * @param value 分数
 * @returns フォーマットされた文字列
 */
type FormatTimeAmountString<T> = T extends number ? string : T
export const formatLocaleTimeAmountHm = <T extends number | undefined | null>(value: T): FormatTimeAmountString<T> => {
  let result
  if (value == null) {
    result = value
  } else {
    const num: number = value
    const hour = Math.floor(num / 60)
    const minute = num % 60
    result = hour + '時間' + minute + '分'
  }
  return result as FormatTimeAmountString<T>
}

/**
 * 時間を分に変換する。
 * 現時点では端数考慮無し。
 * 端数考慮する場合は端数処理方法を指定する引数を追加、bignumber.js を使って対処を想定
 *
 * @param value 時間(1, 2など)
 * @returns 分
 */
export const hourToMinute = <T extends number | undefined | null>(value: T): T => {
  let result
  if (value == null) {
    result = value
  } else {
    const num: number = value
    result = num * 60
  }
  return result as T
}
