import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'

export interface FacilityReserveDeleteEntry {
  reservationNo: string
  facilityId: string
  facilityName: string
  usageDate: ElapsedMillisecond
  useFromDatetime: ElapsedMillisecond
  useToDatetime: ElapsedMillisecond
  status: string

  name: string
  kana: string
  tel: string
  email: string

  childId: string
  childName: string
  childKana: string
  childBirth: ElapsedMillisecond
  childGender: string
  childAllergyFlag: string

  useReasonCategory?: string
  lunchFlag: string
  snackFlag: string
  postponeCancelWaitFlag: string
  citizenNote?: string

  cancelLimit?: number
  cancelLimitTime?: ElapsedMillisecond
  cancelNote?: string
  cancelLimitHolidayOption?: string

  cancelReason?: string

  immediatelyReservationFlag: string
  lunchAcceptFlag: string
  snackAcceptFlag: string
  postponeCancelWaitAcceptFlag: string
}

interface FacilityReserveDeleteState {
  entry?: FacilityReserveDeleteEntry
  /** 排他制御用更新日時 */
  updateDatetime?: string
}
const initialState: FacilityReserveDeleteState = {}

export const facilityReserveDeleteSlice = createSlice({
  name: 'facilityReserveDelete',
  initialState,
  reducers: {
    setFacilityReserveDeleteEntry: (state, action: PayloadAction<FacilityReserveDeleteEntry>) => {
      state.entry = action.payload
    },
    setFacilityReserveDeleteUpdateDatetime: (state, action: PayloadAction<string>) => {
      state.updateDatetime = action.payload
    },
    clearFacilityReserveDelete: (state) => {
      delete state.entry
      delete state.updateDatetime
    },
  },
})

export const { setFacilityReserveDeleteEntry, setFacilityReserveDeleteUpdateDatetime, clearFacilityReserveDelete } =
  facilityReserveDeleteSlice.actions
export const selectFacilityReserveDeleteEntry = (state: RootState) => state.facilityReserveDelete.entry
export const selectFacilityReserveDeleteUpdateDatetime = (state: RootState) =>
  state.facilityReserveDelete.updateDatetime
export default facilityReserveDeleteSlice.reducer
