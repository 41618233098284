import { Theme, useMediaQuery } from '@mui/material'
import React, { ReactNode } from 'react'

interface BreakpointSwitchProps {
  xs?: ReactNode
  sm?: ReactNode
  md?: ReactNode
}

/**
 * ブレイクポイントxs,sm,mdに応じた内容を切り分けたい場合に使用するコントロール。
 */
export const BreakpointSwitch = (props: BreakpointSwitchProps) => {
  const isMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  if (props.md && isMd) {
    return <>{props.md}</>
  }
  if (props.sm && isSm) {
    return <>{props.sm}</>
  }
  return <>{props.xs}</>
}
