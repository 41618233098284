import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePutAccount, resultCodePutAccount } from '../../dataAccess/webApi/dao/accountDao'
import { translate } from '../../i18n'
import { getSessionAccessToken } from '../../utils/authUtil'
import { accountChangeVerificationUrl } from '../common/constant/appUrl'
import { accountChangeVerificationCategory } from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { setAccountChange } from '../common/store/slices/accountChange'
import { notifyMessage, showLoading } from '../common/store/slices/application'

interface Inputs {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const formMethods = useForm<Inputs>()

  const onSubmit = (data: Inputs) => {
    if (data.newPassword !== data.newPasswordConfirmation) {
      formMethods.setError(
        'newPasswordConfirmation',
        { message: translate('system.error.notSamePassword') },
        { shouldFocus: true }
      )
      return
    }
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          const response = await executePutAccount({
            accessToken: await getSessionAccessToken(),
            tel: null,
            email: null,
            password: data.currentPassword,
            newPassword: data.newPassword,
          })
          if (response.resultCode) {
            // 登録失敗時
            // ローディング表示中はsetErrorでメッセージ表示が反映されないので遅延させる
            setTimeout(() => {
              switch (response.resultCode) {
                case resultCodePutAccount.notMatchedCurrentPassword:
                  formMethods.setError(
                    'currentPassword',
                    { message: translate('accountPasswordChange.error.notMatched') },
                    { shouldFocus: true }
                  )
                  break
                case resultCodePutAccount.violatePolicyNewPassword:
                  formMethods.setError(
                    'newPassword',
                    { message: translate('accountPasswordChange.error.violatePolicy') },
                    { shouldFocus: true }
                  )
                  break
              }
            }, 0)
          } else {
            dispatch(
              setAccountChange({
                category: accountChangeVerificationCategory.password,
                password: data.currentPassword,
                newPassword: data.newPassword,
              })
            )
            dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
            history.push(accountChangeVerificationUrl.url())
          }
        }),
        isHiddenMain: false,
      })
    )
  }

  return {
    formMethods,
    onSubmit,
  }
}
