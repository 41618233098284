import { useMemo, useEffect } from 'react'
import { getFacilityReservationSteps } from '../common/codeMaster'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

/** 表示モード（施設予約完了画面） */
export const dispMode = {
  /** 施設予約（即時予約）*/
  insertRealtime: 'ir',
  /** 施設予約（承認予約）*/
  insertApproval: 'ia',
  /** キャンセル待ち予約（即時予約）*/
  insertRealtimeCanselWait: 'irc',
  /** キャンセル待ち予約（承認予約）*/
  insertApprovalCanselWait: 'iac',
  /** 施設予約変更（即時予約）*/
  updateRealtime: 'ur',
  /** 施設予約変更（承認予約）*/
  updateApproval: 'ua',
  /** キャンセル待ち予約変更（即時予約）*/
  updateRealtimeCanselWait: 'urc',
  /** キャンセル待ち予約変更（承認予約）*/
  updateApprovalCanselWait: 'uac',
} as const

export const useAction = () => {
  const { addOperationLog } = useOperationLog()

  // 初期表示
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const steps = useMemo(getFacilityReservationSteps, [])

  return {
    steps,
  }
}
