import { styled } from '@mui/material'
import React, { Fragment } from 'react'

interface TabBarProps {
  value: string
  valueLabels: { value: string; label: string }[]
  onChange?: (value: string) => void
}

const Root = styled('div')({
  display: 'flex',
})
const Tab = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  ...(isActive
    ? {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: 'none ',
      }
    : {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        cursor: 'pointer',
        color: theme.palette.grayDark.main,
      }),
  padding: '5px 15px',
  borderRadius: '5px 5px 0 0',
}))
const Separator = styled('div')({
  width: '10px',
  borderBottom: '1px solid',
})
const Spacer = styled('div')({
  flexGrow: 1,
  borderBottom: '1px solid',
})

export const TabBar = (props: TabBarProps) => {
  return (
    <Root>
      {props.valueLabels.map(({ value, label }, index) => {
        const isActive = props.value === value
        return (
          <Fragment key={value}>
            <Tab isActive={isActive} {...(!isActive && props.onChange && { onClick: () => props.onChange?.(value) })}>
              {label}
            </Tab>
            {props.valueLabels[index + 1] && <Separator />}
          </Fragment>
        )
      })}
      <Spacer />
    </Root>
  )
}
