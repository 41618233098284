import { styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectSystemControl } from '../../../containers/common/store/slices/systemControl'

const fontSizeLv = {
  lv0: 0,
  lv1: 1,
  lv2: 2,
  lv3: 3,
} as const

const fontSizeNames = {
  [fontSizeLv.lv0]: 'sizeS',
  [fontSizeLv.lv1]: 'sizeM',
  [fontSizeLv.lv2]: 'sizeL1',
  [fontSizeLv.lv3]: 'sizeL2',
} as const
type fontSizeNameKey = keyof typeof fontSizeNames

const Root = styled('span', { shouldForwardProp: (prop) => prop !== 'lv' })<{ lv: fontSizeNameKey }>(
  ({ theme, lv }) => ({
    fontSize: theme.typography.font[fontSizeNames[lv]],
    letterSpacing: 0,
  })
)

export const SystemLogo = () => {
  const serviceTitle = useSelector(selectSystemControl).serviceTitle
  const serviceTitleLength = serviceTitle.length

  let lv: fontSizeNameKey = fontSizeLv.lv0
  if (serviceTitleLength < 14) {
    lv = fontSizeLv.lv3
  } else if (serviceTitleLength < 18) {
    lv = fontSizeLv.lv2
  } else if (serviceTitleLength < 23) {
    lv = fontSizeLv.lv1
  }

  return <Root lv={lv}>{serviceTitle}</Root>
}
