import { useLayoutEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { selectLoginUser } from '../common/store/slices/authority'
import { getUser } from '../common/user'
import { accountUrl, memberUrl, myFacilityUrl, usageHistoryUrl } from '../../containers/common/constant/appUrl'
import { useHistory } from 'react-router-dom'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const [userName, setUserName] = useState<string>()

  const loginUser = useSelector(selectLoginUser)

  // ちらつき防止のためにuseLayoutEffect使用
  useLayoutEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          setUserName(await getUserName())
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // アカウント情報へ遷移
  const goAccount = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000038 })
    history.push(accountUrl.url())
  }, [addOperationLog, history])

  // 会員情報へ遷移
  const goMember = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000039 })
    history.push(memberUrl.url())
  }, [addOperationLog, history])

  // My施設へ遷移
  const goMyFacility = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000019 })
    history.push(myFacilityUrl.url())
  }, [addOperationLog, history])

  // 利用履歴へ遷移
  const goUsageHistory = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000021 })
    history.push(usageHistoryUrl.url())
  }, [addOperationLog, history])

  return {
    loginId: loginUser?.loginId,
    userName,
    goAccount,
    goMember,
    goMyFacility,
    goUsageHistory,
  }
}

const getUserName = async () => {
  const user = await getUser()
  return user.name
}
