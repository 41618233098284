import { Box, Stack, styled } from '@mui/material'
import { useParams } from 'react-router'
import {
  facilityReservationSelectionCreateUrl,
  reservationStatusListUrl,
  rootUrl,
} from '../../containers/common/constant/appUrl'
import { facilityReservationStep } from '../../containers/common/constant/classification'
import {
  dispMode,
  useAction,
} from '../../containers/facilityReservationCompletion/facilityReservationCompletionService'
import { translate } from '../../i18n'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { CompletionMessageLabel } from '../components/common/completionMessageLabel'
import { CenterGItem, GContainer, GItem, RowSpacerGItem } from '../components/common/grids'
import { StepBar } from '../components/common/stepBar'

const StatementGItem = styled(GItem)({
  display: 'flex',
  justifyContent: 'center',
  margin: '38px',
})
const AnnotationBox = styled('div')({
  whiteSpace: 'pre-wrap',
  display: 'flex',
  justifyContent: 'center',
})
const ApprovalCanselWaitCompletionMessage = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: theme.typography.font.sizeL2,
  whiteSpace: 'pre-wrap',
}))

interface UrlParams {
  facilityId: string
  childId: string
  displayMode: string
}

export const FacilityReservationCompletion = () => {
  const { facilityId, childId, displayMode } = useParams<UrlParams>()
  const { steps } = useAction()

  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <StepBar activeStepValue={facilityReservationStep.step4} stepValueLabels={steps} />

          <Box>
            <GContainer>
              <StatementGItem xs={12}>
                {displayMode === dispMode.insertApprovalCanselWait ||
                displayMode === dispMode.updateApprovalCanselWait ? (
                  <ApprovalCanselWaitCompletionMessage>
                    {translate('facilityReservationCompletion.label.approvalCancelWaitCompletion')}
                  </ApprovalCanselWaitCompletionMessage>
                ) : (
                  <CompletionMessageLabel>
                    {translate('facilityReservationCompletion.label.completion')}
                  </CompletionMessageLabel>
                )}
              </StatementGItem>

              <GItem xs={1}></GItem>
              <GItem xs={10}>
                <GContainer>
                  {(displayMode === dispMode.insertRealtime || displayMode === dispMode.updateRealtime) && (
                    <CenterGItem xs={12}>
                      <span>
                        <AttentionLabel>
                          {translate('facilityReservationCompletion.text.realtimeMainAttentionMessage')}
                        </AttentionLabel>
                        {translate('facilityReservationCompletion.text.realtimeMainMessage')}
                      </span>
                    </CenterGItem>
                  )}
                  {(displayMode === dispMode.insertApprovalCanselWait ||
                    displayMode === dispMode.updateApprovalCanselWait) && (
                    <CenterGItem xs={12}>
                      {translate('facilityReservationCompletion.text.approvalCancelWaitSubMessage')}
                    </CenterGItem>
                  )}
                  {(displayMode === dispMode.insertApproval || displayMode === dispMode.updateApproval) && (
                    <CenterGItem xs={12}>
                      <AttentionLabel>
                        {translate('facilityReservationCompletion.text.approvalMainAttentionMessage')}
                      </AttentionLabel>
                    </CenterGItem>
                  )}
                  {(displayMode === dispMode.insertRealtimeCanselWait ||
                    displayMode === dispMode.updateRealtimeCanselWait) && (
                    <CenterGItem xs={12}>
                      <span>
                        <AttentionLabel>
                          {translate('facilityReservationCompletion.text.realtimeCancelWaitMainAttentionMessage')}
                        </AttentionLabel>
                        {translate('facilityReservationCompletion.text.realtimeCancelWaitMainMessage')}
                      </span>
                    </CenterGItem>
                  )}
                </GContainer>
                <RowSpacerGItem />
                <CenterGItem xs={12}>
                  {(displayMode === dispMode.insertRealtimeCanselWait ||
                    displayMode === dispMode.updateRealtimeCanselWait) && (
                    <AttentionLabel>
                      {translate('facilityReservationCompletion.text.realtimeCancelWaitSubAttentionMessage')}
                    </AttentionLabel>
                  )}
                </CenterGItem>
                <AnnotationBox>
                  <AnnotationLabel>{translate('facilityReservationCompletion.text.subMessage')}</AnnotationLabel>
                </AnnotationBox>
              </GItem>
              <GItem xs={1}></GItem>
            </GContainer>
          </Box>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>
      <BottomButtonGroup>
        {(displayMode === dispMode.insertApproval ||
          displayMode === dispMode.insertRealtimeCanselWait ||
          displayMode === dispMode.insertApprovalCanselWait ||
          displayMode === dispMode.insertRealtime) && (
          <ButtonL fullWidth to={facilityReservationSelectionCreateUrl.url(facilityId, childId)}>
            {translate('facilityReservationCompletion.button.nextReservation')}
          </ButtonL>
        )}
        <ButtonL fullWidth to={reservationStatusListUrl.url()}>
          {translate('facilityReservationCompletion.button.reserveStatusCheck')}
        </ButtonL>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
