import { Stack } from '@mui/material'
import { useAction } from '../../containers/facilityReservationConfirmation/facilityReservationConfirmationService'
import { translate } from '../../i18n'
import { formatHmtoHmOver, formatYmdWeek } from '../../utils/dateUtil'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { Card } from '../components/common/card'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { GContainer, GItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { ReservationDetailGItems } from '../components/common/reservationDetail/reservationDetailGItems'
import { StepBar } from '../components/common/stepBar'
import { TitleLabel } from '../components/common/titleLabel'
import { TransitionCheck } from '../components/common/transitionCheck'

export const FacilityReservationConfirmation = () => {
  const { reserveEntry, reservationReference, reservationResultCode, decide } = useAction()

  if (reserveEntry == null || reservationReference == null) {
    return <TransitionCheck check={false} />
  }

  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <StepBar
            activeStepValue="③"
            stepValueLabels={[
              { value: '①', label: '利用希望日' },
              { value: '②', label: '必要事項入力' },
              { value: '③', label: '入力内容確認' },
              { value: '④', label: '完了' },
            ]}
          />

          <Stack spacing={2}>
            <div>
              <GContainer spacing={1}>
                <GItem xs={12}>
                  <TitleLabel>利用希望日時</TitleLabel>
                </GItem>
                {reserveEntry.usageDatetimes.map((usageDatetime, index) => (
                  <GItem xs={6} key={index}>
                    <Card>
                      <GContainer columnSpacing={1}>
                        <GItem>
                          <TitleLabel>
                            <AttentionLabel>{formatYmdWeek(usageDatetime.usageDate)}</AttentionLabel>
                          </TitleLabel>
                        </GItem>
                        <GItem>
                          <TitleLabel>
                            <AttentionLabel>
                              {formatHmtoHmOver(
                                {
                                  from: usageDatetime.useFromDatetime,
                                  to: usageDatetime.useToDatetime,
                                },
                                usageDatetime.usageDate
                              )}
                            </AttentionLabel>
                          </TitleLabel>
                        </GItem>
                      </GContainer>
                    </Card>
                  </GItem>
                ))}
              </GContainer>
            </div>
            <InfoCard>
              <GContainer>
                <ReservationDetailGItems reservation={reservationReference} displayType="confirmation" />
              </GContainer>
            </InfoCard>

            {!!reservationResultCode && (
              <ErrorMessageCard
                messages={[
                  translate(`facilityReservationConfirmation.error.reservationResultCode${reservationResultCode}`),
                ]}
              />
            )}
          </Stack>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>

      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={decide}>
          上記の内容で申し込み
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
