import { styled } from '@mui/system'
import { ButtonOutlinedWarningBase } from './buttonOutlinedWarningBase'

/**
 * warningカラーのボタンコントロールLサイズ。
 */
export const ButtonOutlinedWarningL = styled(ButtonOutlinedWarningBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
  padding: '14px 14px',
  borderWidth: 4,
}))
