import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { executePostAccountIdentityVerifySubmit } from '../../dataAccess/webApi/dao/accountDao'
import { accountIdentityVerifySubmitCategory } from '../../dataAccess/webApi/dto/accountDto'
import { translate } from '../../i18n'
import { getSessionAccessToken, verifyTel } from '../../utils/authUtil'
import { getNow } from '../../utils/dateUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { detectLoginStatus, selectIsVerifiedTel } from '../common/store/slices/authority'

interface LocationState {
  from: Location
}

interface Inputs {
  oneTimePassword: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const isVerifiedTel = useSelector(selectIsVerifiedTel)

  const [identifier, setIdentifier] = useState<Date>(getNow())

  const formMethods = useForm<Inputs>()

  const from = location.state?.from

  const resend = useCallback(() => {
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          await verifyTel()
          dispatch(notifyMessage(translate('system.success.resendOneTimePassword')))
          setIdentifier(getNow())
        }),
        isHiddenMain: false,
      })
    )
  }, [dispatch, errorHandle])

  const onSubmit = useCallback(
    (data: Inputs) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const resp = await executePostAccountIdentityVerifySubmit({
              accessToken: await getSessionAccessToken(),
              category: accountIdentityVerifySubmitCategory.tel,
              passcode: data.oneTimePassword,
            })
            if (resp.resultCode) {
              // 検証失敗時
              // ローディング表示中はsetErrorでメッセージ表示が反映されないので遅延させる
              setTimeout(() => {
                formMethods.setError(
                  'oneTimePassword',
                  { message: translate(`identityVerification.error.verifySubmitResultCode${resp.resultCode}`) },
                  { shouldFocus: true }
                )
              }, 0)
            } else {
              // 検証成功時
              await dispatch(detectLoginStatus())
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [dispatch, errorHandle, formMethods]
  )

  return {
    formMethods,
    isVerifiedTel,
    identifier,
    from,
    resend,
    onSubmit,
  }
}
