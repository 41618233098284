import { addMonths, isAfter, isBefore } from 'date-fns'
import { useCallback, useLayoutEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { translate } from '../../i18n'
import { getNowTrimedTime } from '../../utils/dateUtil'
import { datePropsToNumber, nullPropsToUndefined, numberPropsToDate } from '../../utils/objectUtil'
import { setFormErrors } from '../../utils/reactUtil'
import { blankToNull, splitRemoveBlank } from '../../utils/stringUtil'
import {
  getAges,
  getAreas,
  getFacilityCategories,
  getProjects,
  getSearchFlags,
  getWebReservations,
  getPurposeOfUses,
} from '../common/codeMaster'
import { facilitySearchResultUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import {
  clearFacilitySearchResult,
  selectFacilitySearchCodeMaster,
  selectFacilitySearchCondition,
  setFacilitySearchCodeMaster,
  setFacilitySearchCondition,
} from '../common/store/slices/facilitySearch'
import { selectSystemControl } from '../common/store/slices/systemControl'

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}

export interface Inputs {
  /** 地域(カンマ区切り) */
  areas: string
  /** 年齢(カンマ区切り) */
  ages: string
  /** フリーワード */
  freeWord: string
  /** Web受付可否 */
  reservationAcceptFlag: string
  /** 事業ID(カンマ区切り) */
  projectIds: string
  /** 施設種別(カンマ区切り) */
  facilityCategories: string
  /** その他詳細条件(カンマ区切り) */
  otherDetailConditions: string
  /** 利用日 */
  usageDate: Date | null
  /** 利用用途(カンマ区切り) */
  purposeOfUses: string
}
type InputTypePropName = keyof Inputs

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const history = useHistory<LocationState | undefined>()
  const searchCondition = useSelector(selectFacilitySearchCondition)
  const codeMaster = useSelector(selectFacilitySearchCodeMaster)
  const sysCtrl = useSelector(selectSystemControl)

  const locationState = history.location.state

  const dispatch = useDispatch()
  const formMethods = useForm<Inputs>({
    ...(locationState?.isKeep && {
      defaultValues: numberPropsToDate(
        {
          areas: searchCondition?.areas?.join(','),
          ages: searchCondition?.ages?.join(','),
          freeWord: searchCondition?.freeWord,
          reservationAcceptFlag: searchCondition?.reservationAcceptFlag?.join(','),
          projectIds: searchCondition?.projectIds?.join(','),
          facilityCategories: searchCondition?.facilityCategories?.join(','),
          otherDetailConditions: searchCondition?.otherDetailConditions?.join(','),
          usageDate: searchCondition?.usageDate,
          purposeOfUses: searchCondition?.purposeOfUses?.join(','),
        },
        ['usageDate']
      ),
    }),
  })

  // ちらつき防止のためにuseLayoutEffect使用
  useLayoutEffect(() => {
    if (codeMaster.areas == null || !locationState?.isKeep) {
      const initialize = async () => {
        /** 初期表示情報の取得処理 */
        const resultDto = await getFacilityConditionInfo(sysCtrl.projectSearchFlag, sysCtrl.facilityCategorySearchFlag)
        dispatch(setFacilitySearchCodeMaster(resultDto))
      }
      dispatch(showLoading(errorHandle(initialize)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 項目間などの入力値妥当性検査
   * @param data 入力値
   * @returns エラーが存在する場合true
   */
  const validateItems = useCallback(
    (data: Inputs) => {
      const errors: { name: InputTypePropName; message: string }[] = []

      if (data.usageDate) {
        const { usageDate } = data
        const minDate = getNowTrimedTime()
        const maxDate = addMonths(minDate, 1)
        if (isBefore(usageDate, minDate) || isAfter(usageDate, maxDate)) {
          errors.push({ name: 'usageDate', message: translate('facilitySearchCondition.error.usageDateRange') })
        }
      }

      setFormErrors(formMethods, errors)

      return !!errors.length
    },
    [formMethods]
  )

  const onSubmit = useCallback(
    (data: Inputs) => {
      if (validateItems(data)) {
        return
      }

      dispatch(
        setFacilitySearchCondition(
          nullPropsToUndefined(
            datePropsToNumber({
              areas: splitRemoveBlank(',', data.areas),
              ages: splitRemoveBlank(',', data.ages),
              projectIds: splitRemoveBlank(',', data.projectIds),
              facilityCategories: splitRemoveBlank(',', data.facilityCategories),
              freeWord: blankToNull(data.freeWord),
              reservationAcceptFlag: splitRemoveBlank(',', data.reservationAcceptFlag),
              otherDetailConditions: splitRemoveBlank(',', data.otherDetailConditions),
              usageDate: data.usageDate,
              purposeOfUses: splitRemoveBlank(',', data.purposeOfUses),
            })
          )
        )
      )

      dispatch(clearFacilitySearchResult())
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      history.replace({ ...history.location, state: { isKeep: true } })
      history.push(facilitySearchResultUrl.url())
    },
    [dispatch, history, validateItems]
  )

  return {
    codeMaster,
    onSubmit,
    formMethods,
  }
}

async function getFacilityConditionInfo(projectSearchFlag: string, facilityCategorySearchFlag: string) {
  const [areas, projects, facilityCategories, searchFlags, ages, reservationAcceptFlag, purposeOfUses] =
    await Promise.all([
      /** 非同期実行 */
      /** APIの実行（地域一覧取得）*/
      getAreas(),

      /** APIの実行（事業取得）*/
      projectSearchFlag === yesNo.yes ? getProjects() : [],

      /** APIの実行（施設種別マスタ取得）*/
      facilityCategorySearchFlag === yesNo.yes ? getFacilityCategories() : [],

      /** APIの実行（検索フラグマスタ取得）*/
      getSearchFlags(),

      /** 定数値の取得（年齢）*/
      getAges(),

      /** 定数値の取得（Web予約可否）*/
      getWebReservations(),

      /** 定数値の取得（利用目的）*/
      getPurposeOfUses(),
    ])

  return {
    areas,
    projects,
    facilityCategories,
    otherDetailConditions: searchFlags,
    ages,
    reservationAcceptFlag,
    purposeOfUses,
  }
}
