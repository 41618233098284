// 文字列操作の内汎用的な処理を行うユーティリティ関数
//

type StringToNumber<T> = T extends string ? number : T
/**
 * 文字列を数値に変換する
 * @param value 文字列
 * @returns 数値
 */
export const toInt = <T extends string | undefined | null>(value: T): StringToNumber<T> => {
  let result: number | undefined
  if (value != null) {
    result = parseInt(value, 10)
  }
  return result as StringToNumber<T>
}

/**
 * 文字列の配列を数値の配列に変換する
 * @param values 文字列の配列
 * @returns 数値の配列
 */
export const toInts = (values?: string[]) => {
  return values?.map(toInt)
}

type StringFromNumber<T> = T extends number ? string : T
/**
 * 数値から文字列に変換する
 * @param value 数値
 * @returns 文字列
 */
export const fromNumber = <T extends number | undefined | null>(value: T): StringFromNumber<T> => {
  let result: string | undefined
  if (value != null) {
    result = value.toString(10)
  }
  return result as StringFromNumber<T>
}

/**
 * 数値の配列から文字列の配列に変換する
 * @param values 数値の配列
 * @returns 文字列の配列
 */
export const fromNumbers = (values?: number[]) => {
  return values?.map(fromNumber)
}

/**
 * 空文字列をnullに変換する。空文字列以外は、そのまま返却する
 * 渡された値が、null,undefinedの場合は、nullを返却する
 *
 * @param value 対象文字列
 * @returns 空文字列,null,undefinedの場合はnull、それ以外は渡された値
 */
export const blankToNull = (value?: string | null) => {
  return value ? value : null
}

/**
 * 文字列を指定した区切り文字列で分割し、配列で返却する。
 * ただし、配列の要素の内、空文字は除去する
 * また、対象文字列がundefinedの場合は、空配列を返却する
 *
 * @param value 対象文字列
 * @param separator 区切り文字列
 * @returns 分割した文字列配列
 */
export const splitRemoveBlank = (separator: string, value?: string) => {
  return value?.split(separator).filter((v) => v) ?? []
}

/**
 * 数値を3桁カンマ区切りの文字列にフォーマットする。
 *
 * @param value 数値
 * @returns 3桁カンマ区切りの文字列
 */
export const formatNumberComma = (value: number) => {
  return new Intl.NumberFormat().format(value)
}

/**
 * 数値を￥3桁カンマ区切りの文字列にフォーマットする。
 *
 * @param value 数値
 * @returns ￥3桁カンマ区切りの文字列
 */
export const formatNumberYen = (value: number) => {
  return `￥${formatNumberComma(value)}`
}

/**
 * 数値を「[3桁カンマ区切りの文字列]円」にフォーマット
 *
 * @param number v
 * @return string
 */
export const formatNumberYenString = (v: number) => {
  return `${formatNumberComma(v)}円`
}
