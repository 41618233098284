import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { availabilityStatus, yesNo } from '../../containers/common/constant/classification'
import {
  clearFacilityReserve,
  FacilityReserveEntry,
  selectFacilityReserveEntry,
  selectReserveModifyUpdateDatetime,
} from '../../containers/common/store/slices/facilityReserve'
import { executePostReservation, executePutReservation } from '../../dataAccess/webApi/dao/reservationsDao'
import { toApiYmd, toApiYmdHms } from '../../utils/dateUtil'
import { undefinedPropsToNull } from '../../utils/objectUtil'
import { blankToNull } from '../../utils/stringUtil'
import { castNonNullable } from '../../utils/typeUtil'
import { facilityReservationCompletionUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { dispMode } from '../facilityReservationCompletion/facilityReservationCompletionService'
import { getLatestAddOperationDatetime, useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const reserveEntry = useSelector(selectFacilityReserveEntry)
  const updateDatetime = useSelector(selectReserveModifyUpdateDatetime)

  const reservationReference = useMemo(() => {
    if (reserveEntry?.facility && reserveEntry?.parentChild && reserveEntry?.input) {
      const { facility, parentChild, input } = reserveEntry
      return {
        ...parentChild,
        ...input,
        ...facility,
      }
    }
  }, [reserveEntry])

  const [reservationResultCode, setReservationResultCode] = useState<number>()

  // 初期表示
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 上記の内容で申し込み
  const decide = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000026 })
    const operationLogDatetime = castNonNullable(getLatestAddOperationDatetime())

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          if (reserveEntry == null) {
            // 前画面情報無しの場合はエラーなので何もしない
            return
          }
          if (reserveEntry.reservationNo == null) {
            const response = await postReservation(reserveEntry, operationLogDatetime)
            if (response.resultCode) {
              // 登録失敗時
              setReservationResultCode(response.resultCode)
            } else {
              // 登録成功時
              const displayMode = getDisplayMode(reserveEntry)
              history.push(
                facilityReservationCompletionUrl.url(reserveEntry.facilityId, reserveEntry.childId, displayMode)
              )
              dispatch(clearFacilityReserve())
            }
          } else {
            if (updateDatetime) {
              const response = await putReservation(reserveEntry, updateDatetime, operationLogDatetime)
              if (response.resultCode) {
                // 登録失敗時
                setReservationResultCode(response.resultCode)
              } else {
                // 登録成功時
                const displayMode = getDisplayMode(reserveEntry)
                history.push(
                  facilityReservationCompletionUrl.url(reserveEntry.facilityId, reserveEntry.childId, displayMode)
                )
                dispatch(clearFacilityReserve())
              }
            }
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [reserveEntry, updateDatetime, addOperationLog, dispatch, errorHandle, history])

  return {
    reserveEntry,
    reservationReference,
    reservationResultCode,
    decide,
  }
}

const getRegistDataCommon = (facilityReserveEntry: FacilityReserveEntry) => {
  const entryInput = castNonNullable(facilityReserveEntry.input)
  const entryParentChild = castNonNullable(facilityReserveEntry.parentChild)
  return {
    /* 施設ID */
    facilityId: facilityReserveEntry.facilityId,
    /* お子様ID */
    childId: facilityReserveEntry.childId,
    /* キャンセル待ち予約フラグ */
    cancelWaitFlag: facilityReserveEntry.usageDatetimes[0].status === availabilityStatus.wait ? yesNo.yes : yesNo.no,
    /* 利用理由 市民画面で必須入力なのでnonNull */
    useReasonCategory: castNonNullable(entryInput.useReasonCategory),
    /* 昼食有無 */
    lunchFlag: entryInput.lunchFlag,
    /* おやつ有無 */
    snackFlag: entryInput.snackFlag,
    /* 見送り時のキャンセル待ち可否 */
    postponeCancelWaitFlag: entryInput.postponeCancelWaitFlag,
    /* 備考 */
    citizenNote: blankToNull(entryInput.citizenNote),
    /* 在住種別 */
    residenceCategory: castNonNullable(entryParentChild.residenceCategory),
  }
}

const postReservation = async (facilityReserveEntry: FacilityReserveEntry, operationLogDatetime: string) => {
  return executePostReservation(
    undefinedPropsToNull({
      ...getRegistDataCommon(facilityReserveEntry),
      usageDatetimes: facilityReserveEntry.usageDatetimes.map((v) => ({
        /* 利用日 */
        usageDate: toApiYmd(v.usageDate),
        /* 利用開始日時 */
        useFromDatetime: toApiYmdHms(v.useFromDatetime),
        /* 利用終了日時 */
        useToDatetime: toApiYmdHms(v.useToDatetime),
      })),
      operationLogDatetime,
    })
  )
}

const putReservation = async (
  facilityReserveEntry: FacilityReserveEntry,
  updateDatetime: string,
  operationLogDatetime: string
) => {
  return executePutReservation(
    castNonNullable(facilityReserveEntry.reservationNo),
    undefinedPropsToNull({
      ...getRegistDataCommon(facilityReserveEntry),
      /* 利用日 */
      usageDate: toApiYmd(facilityReserveEntry.usageDatetimes[0].usageDate),
      /* 利用開始日時 */
      useFromDatetime: toApiYmdHms(facilityReserveEntry.usageDatetimes[0].useFromDatetime),
      /* 利用終了日時 */
      useToDatetime: toApiYmdHms(facilityReserveEntry.usageDatetimes[0].useToDatetime),
      /* 更新日時 */
      updateDatetime: updateDatetime,
      operationLogDatetime,
    })
  )
}

const getDisplayMode = (reserveEntry: FacilityReserveEntry) => {
  let mode

  const facility = castNonNullable(reserveEntry.facility)

  // 新規登録
  if (reserveEntry.reservationNo == null) {
    // キャンセル待ち予約
    if (reserveEntry.usageDatetimes[0].status === availabilityStatus.wait) {
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        // 即時予約
        mode = dispMode.insertRealtimeCanselWait
        // 承認予約
      } else {
        mode = dispMode.insertApprovalCanselWait
      }
    } else {
      // 即時予約
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.insertRealtime
        // 承認予約
      } else {
        mode = dispMode.insertApproval
      }
    }
    // 変更
  } else {
    // キャンセル待ち予約
    if (reserveEntry.usageDatetimes[0].status === availabilityStatus.wait) {
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.updateRealtimeCanselWait
      } else {
        mode = dispMode.updateApprovalCanselWait
      }
    } else {
      // 即時予約
      if (facility.immediatelyReservationFlag === yesNo.yes) {
        mode = dispMode.updateRealtime
        // 承認予約
      } else {
        mode = dispMode.updateApproval
      }
    }
  }

  return mode
}
