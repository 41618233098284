// 空き状況カレンダーフォームコントロール
//

import { Stack, styled, Theme } from '@mui/material'
import { addDays, addMinutes, addWeeks, differenceInMinutes, isSameDay } from 'date-fns'
import { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import { availabilityStatus, receptionTimePattern } from '../../../../../containers/common/constant/classification'
import { translate } from '../../../../../i18n'
import {
  DateRange,
  formatDate,
  formatHmOver,
  formatLocaleYm,
  formatYmdHmToHmOverWeek,
  fromApiYmd,
  fromApiYmdHms,
  isIncludeDate,
  trimTime,
} from '../../../../../utils/dateUtil'
import { castNonNullable, castNullable, tuple } from '../../../../../utils/typeUtil'
import { ButtonS } from '../../buttons/buttonS'
import { CenterMiddleGItem, GContainer, GItem, LeftGItem, RightGItem } from '../../grids'
import { Link } from '../../link'
import { TitleLabel } from '../../titleLabel'
import { ArrayInputValue, AvailabilityMap, InputValue, PreviousRange } from './common'
import { SelectedRangeTextBox, SelectedRangeTextHandler } from './selectedRangeTextBox'

/**
 * 空き状況１日分の型
 * ※API返却型に合わせている
 */
interface Availability {
  /** 日付(Api日付形式:yyyy-MM-dd) */
  day: string
  /**
   * 各予約枠のステータス情報。
   * 受付時間単位が１日で非営業日の場合は、開始・終了時間がnullになる
   */
  statuses: (
    | {
        /** 開始時間(Api日時形式:yyyy-MM-dd HH:mm) */
        startTime: string
        /** 終了時間(Api日時形式:yyyy-MM-dd HH:mm) */
        endTime: string
        /** 空き状況ステータス */
        status: string
      }
    | {
        /** 開始時間 */
        startTime: null
        /** 終了時間 */
        endTime: null
        /** 空き状況ステータス */
        status: string
      }
  )[]
}

/** 変更制限 */
export const calendarChangeRestriction = {
  /** 日付も時間も変更不可 */
  none: 'none',
  /** 同一日で時間のみ変更可能 */
  time: 'time',
  /** 日付も時間も変更不可 */
  datetime: 'datetime',
} as const
export type CalendarChangeRestrictionType = typeof calendarChangeRestriction[keyof typeof calendarChangeRestriction]

/**
 * 空き状況カレンダーコントロールのプロパティ
 */
interface AvailabilityCalendarProps {
  /** 入力値を紐づける(バインドする)インプットオブジェクトのプロパティ名  */
  name: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 入力必須とする場合true */
  required?: boolean
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>

  /** 基準日 */
  baseDate: Date
  /** 受付時間単位パターン */
  receptionTimePattern: string
  /** 空き状況 */
  availabilities: Availability[]
  /** 変更前の範囲 */
  previousRange?: PreviousRange
  /**
   * 変更制限。
   * 変更前から変更可能な対象を指定
   * 変更前の範囲が設定されていない場合は無視される
   *
   * 指定しなかった場合は、datetime 指定時と同じ動作
   */
  changeRestriction?: CalendarChangeRestrictionType

  /**
   * 初期表示時バリデーションを行う場合に必要な空き状況。
   * エラーで利用日時選択まで戻った時、複数選択では全ての選択が
   * 表示する1週間に収まらない。
   * よって、選択日の空き状況別途取得してこちらに設定した上でバリデーションを実行する
   */
  initialValidationAvailabilities?: Availability[]

  /** 複数選択可能にする場合true */
  isMultiple?: boolean

  /** 基準日変更時イベントハンドラー */
  onChangeBaseDate: (baseDate: Date) => void

  /** 表示のみ（カレンダーの枠選択や選択内容の入力域を出さない）にする場合true */
  isDisplayOnly?: boolean
}

/**
 * 予約枠の表示、処理の制御に必要な情報
 */
interface SlotStatus {
  /** 予約枠キー日時。空き状況が存在しない日付では時間軸の値を使用する */
  keyDate: Date
  /**
   * 空き状況。
   * 予約枠の空き状況情報が存在しない場合は、値が設定されない
   */
  availability?: InputValue
  /** 全ての選択範囲 */
  allRange: DateRange[]
  /** 現在の選択範囲 */
  activeRange?: DateRange
  /** 変更前の選択範囲 */
  previousRange?: PreviousRange
  /** 枠を非活性(選択不可)にする場合true */
  isDisabled: boolean
  /** 枠をクリック可能にする場合true */
  isClickable: boolean
}

/**
 * 当カレンダーコントロール処理用に変換した空き状況１日分の型
 */
interface CalAvailability {
  baseDateOfTime: Date
  statuses: {
    range?: {
      from: Date
      to: Date
    }
    status: string
  }[]
}

/** getDay()の日曜日 */
const daysSunday = 0
/** getDay()の土曜日 */
const daysSaturday = 6

/** 枠状況のラベル */
const statusLabels: Record<string, string> = {
  [availabilityStatus.vacant]: '○',
  [availabilityStatus.wait]: '△',
  [availabilityStatus.noSpace]: '×',
  [availabilityStatus.outside]: '－',
  [availabilityStatus.doneFixed]: '☆',
  [availabilityStatus.doneNotFixed]: '★',
  [availabilityStatus.doneWait]: '★',
} as const

/**
 * @param theme テーマ
 * @param days Date.prototype.getDay()の値
 * @returns 日付軸のカラー
 */
const getDateColor = (theme: Theme, days: number) => {
  let palette = theme.palette.white
  if (days === daysSunday) {
    palette = theme.palette.error
  } else if (days === daysSaturday) {
    palette = theme.palette.primary
  }
  if (palette) {
    return {
      color: palette.contrastText,
      backgroundColor: palette.main,
    }
  }
}

/**
 * @param theme テーマ
 * @param status 空き状況ステータス
 * @returns 予約枠のカラー
 */
const getSlotCellColor = (theme: Theme, status: SlotStatus) => {
  const { activeRange, previousRange } = status
  let color = theme.palette.text.primary
  let backgroundColor
  if (activeRange && isIncludeDate(status.keyDate, activeRange, { isExcludingTo: true })) {
    color = theme.palette.primary.main
    backgroundColor = theme.palette.info.main
  } else if (status.allRange.some((range) => isIncludeDate(status.keyDate, range, { isExcludingTo: true }))) {
    color = theme.palette.greenPale.contrastText
    backgroundColor = theme.palette.greenPale.main
  } else if (
    previousRange &&
    previousRange.isWaiting &&
    isIncludeDate(status.keyDate, previousRange.range, { isExcludingTo: true })
  ) {
    backgroundColor = theme.palette.errorPale.main
  } else if (status.isDisabled) {
    backgroundColor = theme.palette.grayLight.main
  }

  return {
    color,
    backgroundColor,
  }
}

const AvailabilityTableBox = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}))
const EdgeCellBox = styled('div')(({ theme }) => ({
  flex: '1',
  borderLeft: '1px solid transparent',
  borderRight: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.white.main,
}))
const DateRowBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '1',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  position: 'sticky',
  top: theme.mixins.toolbar.minHeight,
}))
const DateCellBox = styled('div', { shouldForwardProp: (prop) => prop !== 'day' })<{
  day: number
}>(({ theme, day }) => ({
  flex: '1',
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  borderRight: `1px solid ${theme.palette.secondary.main}`,
  fontSize: theme.typography.font.sizeM,
  padding: '5px 0',
  ...getDateColor(theme, day),
}))

const SlotRowBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '1',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
}))
const SlotTimeCellBox = styled('div', { shouldForwardProp: (prop) => prop !== 'isSingle' })<{ isSingle: boolean }>(
  ({ theme, isSingle }) => ({
    flex: '1',
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
    borderRight: `1px solid ${theme.palette.secondary.main}`,
    fontSize: theme.typography.font.sizeS,
    padding: `${isSingle ? 8 : 1}px 0`,
    '& div': {
      textAlign: 'center',
    },
  })
)
const SlotCellBox = styled('div', { shouldForwardProp: (prop) => prop !== 'slotStatus' })<{
  slotStatus: SlotStatus
}>(({ theme, slotStatus }) => ({
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
  borderRight: `1px solid ${theme.palette.secondary.main}`,
  ...(slotStatus.isClickable && {
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  ...getSlotCellColor(theme, slotStatus),
}))

/**
 * 前・次週リンクのプロパティ
 */
interface ChangeBaseDateButtonProps {
  children: ReactNode
  baseDate: Date
  onChangeBaseDate: (baseDate: Date) => void
}
/**
 * 前・次週リンク
 */
const ChangeBaseDateButton = (props: ChangeBaseDateButtonProps) => {
  return <Link onClick={() => props.onChangeBaseDate(props.baseDate)}>{props.children}</Link>
}

const rangeMap = (from: Date, to: Date, stepCallback: (value: Date) => Date) => {
  let current = from
  const values = []
  while (current <= to) {
    values.push(current)
    current = stepCallback(current)
  }
  return values
}

/** 時間軸ラベル文言(x時間単位) */
const formatTimeLabelsByTimeRange = (baseDate: Date, range: DateRange) => [
  formatHmOver(range.from, baseDate),
  formatHmOver(range.to, baseDate),
]
/** 時間軸ラベル文言(AMPM単位) */
const formatTimeLabelsByAmPm = (baseDate: Date, range: DateRange, index: number) => [index % 2 === 0 ? 'AM' : 'PM']
/** 時間軸ラベル文言(1日単位) */
const formatTimeLabelsByAllDay = () => [translate('system.availabilityCalendar.allDay')]

/** 空き状況マップのキー値を返す */
const getAvailabilityMapKey = (date: Date, timeIndex: number) => date.getTime() + timeIndex

/** 変更不可にするか否かを返す */
const isReadonly = (previousRange?: PreviousRange, changeRestriction?: CalendarChangeRestrictionType) =>
  previousRange && changeRestriction === calendarChangeRestriction.none

/**
 * 変更選択可能か否かを返す。
 * 変更前の選択範囲、変更制限の指定が無ければ常にtrue
 *
 * @param baseDateOfTime 時間の基準となる日付
 * @param keyDate 予約枠キー日時
 * @param previousRange 変更前の選択範囲
 * @param changeRestriction 変更制限
 * @returns 変更選択可能ならtrue
 */
const isChangeSelectable = (
  baseDateOfTime: Date,
  keyDate: Date,
  previousRange?: PreviousRange,
  changeRestriction?: CalendarChangeRestrictionType
) => {
  if (previousRange == null || changeRestriction == null) {
    return true
  }
  switch (changeRestriction) {
    case calendarChangeRestriction.none:
      return isIncludeDate(keyDate, previousRange.range, { isExcludingTo: true })
    case calendarChangeRestriction.time:
      return isSameDay(baseDateOfTime, previousRange.baseDateOfTime)
    case calendarChangeRestriction.datetime:
      return true
  }
}

/**
 * 対象時間帯の各日(1週間分)について
 * 予約枠の表示、処理の制御に必要な情報を返す。
 *
 * @param availabilityMap 空き状況のマップ
 * @param timeIndex 時間帯インデックス
 * @param minutesFromBaseDate 利用日0時から時間軸の対象時間帯fromまでの時間(分)
 * @param weekDates 対象日(1週間分)
 * @param allRange 全ての選択範囲
 * @param activeRange 現在の選択範囲
 * @param previousRange 変更前の選択範囲
 * @param changeRestriction 変更制限
 * @param isDisplayOnly 表示のみ
 * @returns 予約枠の表示、処理の制御に必要な情報の配列
 */
const getSlotStatuses = (
  availabilityMap: AvailabilityMap,
  timeIndex: number,
  minutesFromBaseDate: number,
  weekDates: Date[],
  allRange: DateRange[],
  activeRange?: DateRange,
  previousRange?: PreviousRange,
  changeRestriction?: CalendarChangeRestrictionType,
  isDisplayOnly?: boolean
): SlotStatus[] => {
  return weekDates.map((date) => {
    const mapKey = getAvailabilityMapKey(date, timeIndex)
    const availability = castNullable(availabilityMap[mapKey])

    /**
     * 予約枠キー日時。空き状況が存在しない日付では時間軸の値を使用する
     */
    const keyDate = availability?.range.from ?? addMinutes(date, minutesFromBaseDate)

    const status = availability?.status
    const disableStatuses: string[] = [availabilityStatus.noSpace, availabilityStatus.outside]
    const isDisabled =
      status == null ||
      disableStatuses.includes(status) ||
      !isChangeSelectable(date, keyDate, previousRange, changeRestriction)
    return {
      keyDate,
      availability,
      isDisabled,
      isClickable: !isDisplayOnly && !isDisabled && !isReadonly(previousRange, changeRestriction),
      allRange,
      activeRange,
      previousRange,
    }
  })
}

const toCalAvailabilities = (availabilities: Availability[]): CalAvailability[] =>
  availabilities.map(({ day, statuses }) => ({
    baseDateOfTime: fromApiYmd(day),
    statuses: statuses.map((statusEntry) => ({
      ...(statusEntry.startTime && {
        range: {
          from: fromApiYmdHms(statusEntry.startTime),
          to: fromApiYmdHms(statusEntry.endTime),
        },
      }),
      status: statusEntry.status,
    })),
  }))

const toAvailabilityMap = (availabilities: CalAvailability[]) =>
  Object.fromEntries(
    availabilities
      .map(({ baseDateOfTime, statuses }) =>
        statuses.map(({ range, status }, index) =>
          tuple(getAvailabilityMapKey(baseDateOfTime, index), { baseDateOfTime, range, status })
        )
      )
      .flat()
      // 受付時間単位が１日で非営業日の場合は、開始・終了時間がnullになるので該当データを除外する
      .filter((v): v is [number, InputValue] => !!v[1].range)
  )

/**
 * 空き状況カレンダーフォームコントロール
 */
export const AvailabilityCalendar = memo(function AvailabilityCalendar(props: AvailabilityCalendarProps) {
  const baseDate = trimTime(props.baseDate)
  const weekDates = rangeMap(baseDate, addDays(addWeeks(baseDate, 1), -1), (date) => addDays(date, 1))

  /** 前週クリックで変更する基準日 */
  const previousBaseDate = addWeeks(baseDate, -1)
  /**
   * 翌週クリックで変更する基準日。
   * 単純に現在基準日+1週間とはしない。
   *
   * 理由：
   * 空き状況APIは、年度変わりなどで週の途中で受付時間単位や開始時間・終了時間などの設定が変更になっている場合、
   * 変更される日までの空き状況を返す。※受付時間単位60分とAMPMを混在させて表示することが困難な為
   */
  const nextBaseDate = addDays(fromApiYmd(props.availabilities.slice(-1)[0].day), 1)

  /** 時間軸のラベル文言取得関数 */
  let formatTimeLabels: (baseDate: Date, range: DateRange, index: number) => string[]
  switch (props.receptionTimePattern) {
    case receptionTimePattern.amPm:
      formatTimeLabels = formatTimeLabelsByAmPm
      break
    case receptionTimePattern.allDay:
      formatTimeLabels = formatTimeLabelsByAllDay
      break
    default:
      formatTimeLabels = formatTimeLabelsByTimeRange
      break
  }

  const { timeRanges, availabilityMap } = useMemo(() => {
    const availabilities = toCalAvailabilities(props.availabilities)
    const firstAvailability = availabilities[0]
    return {
      /** テーブルの時間軸に関する情報 */
      timeRanges: firstAvailability.statuses.map(({ range }, index) => {
        // 受付時間単位が１日で非営業日の場合は、開始・終了時間がnullになるので範囲にダミー値を設定
        const targetRange = range ?? { from: firstAvailability.baseDateOfTime, to: firstAvailability.baseDateOfTime }
        return {
          labels: formatTimeLabels(firstAvailability.baseDateOfTime, targetRange, index),
          timeIndex: index,
          minutesFromBaseDate: differenceInMinutes(targetRange.from, firstAvailability.baseDateOfTime),
        }
      }),
      /** 各予約枠情報のマップ */
      availabilityMap: toAvailabilityMap(availabilities),
    }
  }, [props.availabilities, formatTimeLabels])
  /** 初期表示時バリデーションを行う場合に必要な空き状況Map */
  const initialValidationAvailabilityMap = useMemo(() => {
    const availabilities = props.initialValidationAvailabilities
      ? toCalAvailabilities(props.initialValidationAvailabilities)
      : []
    return toAvailabilityMap(availabilities)
  }, [props.initialValidationAvailabilities])

  const { fields, append, remove } = useFieldArray({
    name: props.name,
    control: props.control,
  })

  // useWatchは初期表示後の変更はウォッチできるが初期表示時の値は取得できない為
  // 各選択範囲コントロールから初期値を集める
  const [initialInputs, setInitialInputs] = useState<ArrayInputValue[]>([])
  const putInitialInputs = useCallback(
    (index: number, input: InputValue | null) => {
      const newInputs = [...initialInputs]
      newInputs[index] = { value: input }
      setInitialInputs(newInputs)
    },
    [initialInputs]
  )
  const watchedArrayInputs: ArrayInputValue[] = useWatch({ name: props.name, control: props.control })
  const currentArrayInputs = watchedArrayInputs ?? initialInputs

  /** すべての選択日時範囲 */
  const allRanges = useMemo(
    () => currentArrayInputs?.map((v) => v.value?.range).filter((v): v is DateRange => !!v) ?? [],
    [currentArrayInputs]
  )

  const [activeIndex, setActiveIndex] = useState<number>(fields.length > 0 ? fields.length - 1 : 0)
  const activeRef = useRef<SelectedRangeTextHandler>()
  let activeInput: InputValue | null = null
  if (currentArrayInputs.length > activeIndex) {
    activeInput = currentArrayInputs[activeIndex].value
  }

  const appendInput = useCallback((fieldsLength: number) => {
    append({ value: null })
    setActiveIndex(fieldsLength)
  }, [])
  const removeInput = useCallback(
    (index: number) => {
      remove(index)
      if (index <= activeIndex) {
        setActiveIndex(Math.max(activeIndex - 1, 0))
      }
    },
    [activeIndex]
  )
  const editInput = useCallback(
    (index: number) => {
      setActiveIndex(index)
      const input = currentArrayInputs[index]
      if (
        input?.value &&
        !isIncludeDate(input.value.baseDateOfTime, { from: baseDate, to: nextBaseDate }, { isExcludingTo: true })
      ) {
        props.onChangeBaseDate(input.value.baseDateOfTime)
      }
    },
    [currentArrayInputs, baseDate, nextBaseDate, props.onChangeBaseDate]
  )

  useEffect(() => {
    if (fields.length === 0) {
      // 最低一つの入力欄は残す
      appendInput(0)
    }
  }, [fields])

  const getSlotCellClickHandler = useCallback(
    (availability: InputValue) => {
      return () => {
        activeRef.current?.setValue(availability)
      }
    },
    [availabilityMap]
  )

  const previousButton = (
    <ChangeBaseDateButton baseDate={previousBaseDate} onChangeBaseDate={props.onChangeBaseDate}>
      {`<${translate('system.availabilityCalendar.previousWeek')}`}
    </ChangeBaseDateButton>
  )

  const nextButton = (
    <ChangeBaseDateButton baseDate={nextBaseDate} onChangeBaseDate={props.onChangeBaseDate}>
      {`${translate('system.availabilityCalendar.nextWeek')}>`}
    </ChangeBaseDateButton>
  )

  return (
    <GContainer rowSpacing={1}>
      <LeftGItem xs={4}>{previousButton}</LeftGItem>
      <CenterMiddleGItem xs={4}>
        <TitleLabel>{formatLocaleYm(baseDate)}</TitleLabel>
      </CenterMiddleGItem>
      <RightGItem xs={4}>{nextButton}</RightGItem>

      <GItem xs={12}>
        <AvailabilityTableBox>
          {/* 日付行 */}
          <DateRowBox>
            <EdgeCellBox></EdgeCellBox>
            {weekDates.map((date) => (
              <DateCellBox key={date.getTime()} day={date.getDay()}>
                <div>{formatDate(date, 'd')}</div>
                <div>({formatDate(date, 'E')})</div>
              </DateCellBox>
            ))}
          </DateRowBox>
          {/* 時間行 */}
          {timeRanges.map(({ labels, timeIndex, minutesFromBaseDate }) => (
            <SlotRowBox key={timeIndex}>
              <SlotTimeCellBox isSingle={labels.length === 1}>
                {labels.map((label, index) => (
                  <div key={index}>{label}</div>
                ))}
              </SlotTimeCellBox>
              {getSlotStatuses(
                availabilityMap,
                timeIndex,
                minutesFromBaseDate,
                weekDates,
                allRanges,
                activeInput?.range,
                props.previousRange,
                props.changeRestriction,
                props.isDisplayOnly
              ).map((slotStatus) => (
                <SlotCellBox
                  key={slotStatus.keyDate.getTime()}
                  slotStatus={slotStatus}
                  {...(slotStatus.isClickable && {
                    // isClickableがtrueならavailabilityはnot null
                    onClick: getSlotCellClickHandler(castNonNullable(slotStatus.availability)),
                  })}
                >
                  {statusLabels[slotStatus.availability?.status ?? availabilityStatus.outside]}
                </SlotCellBox>
              ))}
            </SlotRowBox>
          ))}
        </AvailabilityTableBox>
      </GItem>

      <LeftGItem xs={4}>{previousButton}</LeftGItem>
      <GItem xs={4}></GItem>
      <RightGItem xs={4}>{nextButton}</RightGItem>

      {!props.isDisplayOnly && (
        <>
          <GItem xs={12}>
            <Stack spacing={1}>
              {props.previousRange && (
                <Stack>
                  <div>
                    {translate(
                      props.previousRange.isWaiting
                        ? 'system.availabilityCalendar.waitDatetime'
                        : 'system.availabilityCalendar.previousDatetime'
                    )}
                  </div>
                  <div>{formatYmdHmToHmOverWeek(props.previousRange.range, props.previousRange.baseDateOfTime)}</div>
                </Stack>
              )}

              <Stack>
                <div>{translate('system.availabilityCalendar.selectionDatetime')}</div>
                <Stack spacing={1}>
                  {fields.map((field, index) => (
                    <SelectedRangeTextBox
                      {...(index === activeIndex && {
                        ref: activeRef,
                      })}
                      isActived={index === activeIndex}
                      key={field.id}
                      arrayName={props.name}
                      index={index}
                      fieldName="value"
                      label={props.label}
                      required={props.required}
                      control={props.control}
                      availabilityMap={availabilityMap}
                      allInputs={currentArrayInputs}
                      previousRange={props.previousRange}
                      isReadonly={isReadonly(props.previousRange, props.changeRestriction)}
                      initialValidationAvailabilityMap={initialValidationAvailabilityMap}
                      onClickEdit={editInput}
                      onClickRemove={removeInput}
                      putInitialInputs={putInitialInputs}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </GItem>

          {props.isMultiple && (
            <CenterMiddleGItem xs={12}>
              <ButtonS onClick={() => appendInput(fields.length)}>{translate('system.button.add')}</ButtonS>
            </CenterMiddleGItem>
          )}
        </>
      )}
    </GContainer>
  )
})
