import {
  executeGet,
  executeGetNoResultError,
  executePost,
  executePut,
  executeDelete,
} from '../common/apiCaller'
import {
  GetChildDto,
  GetChildrenDto,
  GetChildrenInputDto,
  PostChildInputDto,
  PutChildInputDto,
} from '../dto/childrenDto'

const apiName = 'citizen'
const apiPath = '/children'

export const executeGetChildren = (input?: GetChildrenInputDto) => {
  return executeGet<GetChildrenDto[]>(apiName, apiPath, input)
}

export const executeGetChild = (id: string) => {
  return executeGetNoResultError<GetChildDto>(apiName, `${apiPath}/${id}`)
}

export const executePutChild = (id: string, input: PutChildInputDto) => {
  return executePut<null>(apiName, `${apiPath}/${id}`, input)
}

export const executePostChild = (input: PostChildInputDto) => {
  return executePost<null>(apiName, apiPath, input)
}

export const executeDeleteChild = (id: string) => {
  return executeDelete<null>(apiName, `${apiPath}/${id}`)
}
