// 位置情報、住所に関連する共通処理
//

import { getLanguage } from '../../i18n'
import { googleMapApiKey } from './constant/processEnv'
import { isValidPostalCode } from './validator'

/**
 * geolocation.getCurrentPositionのtimeout値(ミリ秒)
 * ※端末が位置を返すために掛けることができる最大時間
 */
const geolocationTimeout = 2 * 1000
/**
 * geolocation.getCurrentPosition処理の強制timeout値(ミリ秒)
 * iOS safariで位置情報サービスをオフで端末再起動後、safariキャッシュクリア状態にて初回アクセス時
 * getCurrentPositionがsuccessとerrorのどちらもコールしない問題が発生する
 * その結果、永久にローディング状態となる為、強制的にerrorに処理を流す
 */
const geolocationForceTimeout = 5 * 1000

/**
 * 端末の現在位置を取得。
 * ブラウザのGeolocationAPIを使用して取得する。
 *
 * @returns 位置情報
 */
export const getCurrentPosition = () => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    // 強制的にerrorへ流す処理。getCurrentPositionがsuccessとerrorをコールすればタイマーはクリアする
    const timeoutId = setTimeout(() => reject(), geolocationForceTimeout)

    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        clearTimeout(timeoutId)
        resolve(position)
      },
      (positionError: GeolocationPositionError) => {
        clearTimeout(timeoutId)
        reject(positionError)
      },
      {
        timeout: geolocationTimeout,
      }
    )
  })
}

/**
 * GeocodeAPIのレスポンス
 * GeocodeAPI仕様
 * https://developers.google.com/maps/documentation/geocoding/requests-geocoding
 */
interface GoogleGeocodeResponse {
  status: string
  results: {
    address_components: AddressComponent[]
    types: string[]
  }[]
}
interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

/**
 * GoogleGeocodeResponseにマッチするかを判定する型ガード関数
 * @param body レスポンスボディ
 * @returns GoogleGeocodeResponseならtrue
 */
const isGoogleGeocodeResponse = (body: any): body is GoogleGeocodeResponse => {
  if (body == null) {
    return false
  }
  const { status, results } = body
  if (typeof status === 'string' && Array.isArray(results)) {
    if (results.length === 0) {
      return true
    }
    const { address_components, types } = results[0]
    if (Array.isArray(address_components) && Array.isArray(types)) {
      return address_components.length === 0 || Array.isArray(address_components[0].types)
    }
  }
  return false
}

/**
 * 住所の各成分
 */
interface Address {
  country?: string
  administrativeAreaLevels: string[]
  locality?: string
  sublocalityLevels: string[]
  postalCode?: string
}

const getSublocalityLevelsSetter = (index: number) => (address: Address, value: string) => {
  address.sublocalityLevels[index] = value
}
const getAdministrativeAreaLevelsSetter = (index: number) => (address: Address, value: string) => {
  address.administrativeAreaLevels[index] = value
}

const typeToAddressPropertyMap: Record<string, (address: Address, value: string) => void> = {
  country: (address: Address, value: string) => {
    address.country = value
  },
  administrative_area_level_1: getAdministrativeAreaLevelsSetter(0),
  administrative_area_level_2: getAdministrativeAreaLevelsSetter(1),
  administrative_area_level_3: getAdministrativeAreaLevelsSetter(2),
  administrative_area_level_4: getAdministrativeAreaLevelsSetter(3),
  administrative_area_level_5: getAdministrativeAreaLevelsSetter(4),
  locality: (address: Address, value: string) => {
    address.locality = value
  },
  sublocality_level_1: getSublocalityLevelsSetter(0),
  sublocality_level_2: getSublocalityLevelsSetter(1),
  sublocality_level_3: getSublocalityLevelsSetter(2),
  sublocality_level_4: getSublocalityLevelsSetter(3),
  sublocality_level_5: getSublocalityLevelsSetter(4),
  postal_code: (address: Address, value: string) => {
    address.postalCode = value
  },
}

/**
 * Google Geocoding APIで発生したエラーを保持する例外クラス
 */
export class GeocodeError extends Error {
  statusCode: string
  constructor(statusCode: string, message?: string) {
    super(message)
    this.name = new.target.name
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype)

    this.statusCode = statusCode
  }
}

/**
 * 郵便番号から住所(各成分を連結した文字列)を取得。
 *
 * @param postalCode 郵便番号(000-0000形式)
 * @returns 郵便番号から取得した住所(各成分を連結した文字列)。郵便番号が000-0000形式ではない or GoogleAPIKey未設定時は、undefined
 * @throws {GeocodeError} GoogleAPIレスポンスのStatus codeが"OK","ZERO_RESULTS"以外
 * https://developers.google.com/maps/documentation/geocoding/overview#StatusCodes
 */
export const getAddressStringByPostalCode = async (postalCode: string) => {
  const address = await getAddressByPostalCode(postalCode)
  if (address) {
    const sep = getLanguage() === 'ja' ? '' : ' '
    return [...address.administrativeAreaLevels, address.locality, ...address.sublocalityLevels]
      .filter((v) => v)
      .join(sep)
  }
}

/**
 * 郵便番号から住所を取得。
 *
 * @param postalCode 郵便番号(000-0000形式)
 * @returns 郵便番号から取得した住所。郵便番号が000-0000形式ではない or GoogleAPIKey未設定時は、undefined
 * @throws {GeocodeError} GoogleAPIレスポンスのStatus codeが"OK","ZERO_RESULTS"以外
 * https://developers.google.com/maps/documentation/geocoding/overview#StatusCodes
 */
export const getAddressByPostalCode = async (postalCode: string) => {
  const searchParamAddress = postalCode.trim()
  if (!googleMapApiKey || !isValidPostalCode(postalCode)) {
    return
  }
  const response = await fetch(
    'https://maps.googleapis.com/maps/api/geocode/json?' +
      new URLSearchParams({
        key: googleMapApiKey,
        address: searchParamAddress,
      })
  )
  const body = await response.json()
  if (isGoogleGeocodeResponse(body)) {
    if (body.status === 'ZERO_RESULTS') {
      return
    } else if (body.status !== 'OK') {
      throw new GeocodeError(body.status, `postalCode=${searchParamAddress}`)
    }
  } else {
    throw TypeError(JSON.stringify(body))
  }

  return parseAddress(body.results[0].address_components)
}

const parseAddress = (addressComponents: AddressComponent[]) => {
  let address: Address = { administrativeAreaLevels: [], sublocalityLevels: [] }
  address = addressComponents.reduce((previousValue: Address, currentValue) => {
    for (const [typeKey, propertySetter] of Object.entries(typeToAddressPropertyMap)) {
      if (currentValue.types.indexOf(typeKey) !== -1) {
        propertySetter(previousValue, currentValue.long_name)
        break
      }
    }
    return previousValue
  }, address)
  return address
}
