import { I18n } from 'aws-amplify'
import React, { Fragment, ReactNode } from 'react'

function flatKey(obj: string | { [key: string]: any }, keyStack: string[], result: { [key: string]: string }) {
  if (typeof obj === 'string') {
    result[keyStack.join('.')] = obj
    return
  }

  for (const [key, value] of Object.entries(obj)) {
    flatKey(value, [...keyStack, key], result)
  }
  return result
}

type Result<T> = T extends string ? string : JSX.Element
const format = <T extends string | ReactNode>(value: string, ...args: T[]): Result<T> => {
  const exp = /{(\d+)}/g
  const formatedValues: T[] = []
  let expResult
  let previousIndex = 0
  while ((expResult = exp.exec(value)) != null) {
    const placeIndex = parseInt(expResult[1], 10)
    formatedValues.push(value.slice(previousIndex, expResult.index) as T)
    formatedValues.push(args[placeIndex])

    previousIndex = expResult.index + expResult[0].length
  }
  formatedValues.push(value.slice(previousIndex) as T)

  if (args.every((arg) => typeof arg === 'string')) {
    return formatedValues.join('') as Result<T>
  } else {
    return (
      <>
        {formatedValues.map((value, index) => (
          <Fragment key={index}>{value}</Fragment>
        ))}
      </>
    ) as Result<T>
  }
}

const getFromI18n = (key: string): string => I18n.get(key)

const getFixedLocaleCode = (localeCode: string) => {
  if (localeCode === 'en') {
    return 'en-US'
  } else if (localeCode === 'ja-JP') {
    return 'ja'
  }
  return localeCode
}

const yyyymmddMasks: Record<string, string> = {
  ja: '____/__/__',
  en: '__/__/____',
}

const yyyymmddFormats: Record<string, string> = {
  ja: 'yyyy/MM/dd',
  en: 'dd/MM/yyyy',
}

/** 判定した結果の言語 */
let determinedLanguage: string
/** 判定した結果のロケール */
let determinedLocale: Locale

const getLanguageVocabulariesLocale = async (localeCode: string) => {
  const language = localeCode.split(/-/)[0]
  const langVocabularies: { [key: string]: any } = await import(`./langs/${language}`)
  const locale: Locale = (await import(`date-fns/locale/${localeCode}`)).default
  return {
    language,
    langVocabularies,
    locale,
  }
}

export const initializeI18n = async () => {
  // ブラウザ優先言語取得の確認
  let localeCode = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language
  localeCode = getFixedLocaleCode(localeCode)
  let language: string
  let langVocabularies: { [key: string]: any }
  let locale: Locale
  try {
    ;({ language, langVocabularies, locale } = await getLanguageVocabulariesLocale(localeCode))
  } catch {
    // 対象言語が存在しない場合
    localeCode = 'en-US'
    ;({ language, langVocabularies, locale } = await getLanguageVocabulariesLocale(localeCode))
  }

  const vocabularies = { [language]: flatKey(langVocabularies.default, [], {}) }

  I18n.setLanguage(language)
  I18n.putVocabularies(vocabularies)
  determinedLanguage = language
  determinedLocale = locale
}

export const getLanguage = () => {
  return determinedLanguage
}

export const getLocale = () => {
  return determinedLocale
}

export const translate = (key: string, ...args: string[]) => {
  return format(getFromI18n(key), ...args)
}

export const translateNode = (key: string, ...args: ReactNode[]) => {
  return format(getFromI18n(key), ...args)
}

export const getClassificationValueLabels = (classificationKey: string, classificationValues: string[]) => {
  return classificationValues.map((value) => ({ value, label: getClassificationLabel(classificationKey, value) }))
}

export const getClassificationLabel = (classificationKey: string, classificationValue: string) => {
  return getFromI18n(`${classificationKey}.${classificationValue}`)
}

export const getYyyymmddFormat = () => {
  return yyyymmddFormats[determinedLanguage]
}

export const getYyyymmddMask = () => {
  return yyyymmddMasks[determinedLanguage]
}
