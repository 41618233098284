import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePostAccount, executePutAccount } from '../../dataAccess/webApi/dao/accountDao'
import { translate } from '../../i18n'
import { getSessionAccessToken } from '../../utils/authUtil'
import { getNow } from '../../utils/dateUtil'
import { undefinedPropsToNull } from '../../utils/objectUtil'
import { accountUrl, errorUrls } from '../common/constant/appUrl'
import { accountChangeVerificationCategory, httpStatusCode, yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { clearAccountChange, selectAccountChangeEntry } from '../common/store/slices/accountChange'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { detectLoginStatus } from '../common/store/slices/authority'

interface Inputs {
  oneTimePassword: string
}

const completionMessageKey = {
  [accountChangeVerificationCategory.email]: 'accountChangeVerification.success.completionMessageEmail',
  [accountChangeVerificationCategory.tel]: 'accountChangeVerification.success.completionMessageTel',
  [accountChangeVerificationCategory.password]: 'accountChangeVerification.success.completionMessagePassword',
} as const

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()

  const changeEntry = useSelector(selectAccountChangeEntry)

  const [identifier, setIdentifier] = useState<Date>(getNow())

  const formMethods = useForm<Inputs>()

  const resend = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000051 })

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          if (changeEntry == null) {
            // 変更画面以外から遷移した場合はエラーにするので有り得ない
            return
          }
          let tel
          let email
          let password
          let newPassword
          switch (changeEntry.category) {
            case accountChangeVerificationCategory.tel:
              tel = changeEntry.tel
              break
            case accountChangeVerificationCategory.password:
              password = changeEntry.password
              newPassword = changeEntry.newPassword
              break
          }
          const response = await executePutAccount(
            undefinedPropsToNull({
              accessToken: await getSessionAccessToken(),
              tel,
              email,
              password,
              newPassword,
            })
          )
          if (response.resultCode) {
            // 再送信失敗時 ※通常のオペレーションでの発生は考え難いエラーなのでシステムエラーにする
            history.replace(errorUrls[httpStatusCode.internalServerError].url())
          } else {
            // 再送信成功時
            dispatch(notifyMessage(translate('system.success.resendOneTimePassword')))
            setIdentifier(getNow())
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [addOperationLog])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000050, accessData: [{ userIdRegFlag: yesNo.yes }] })

      dispatch(
        showLoading({
          process: errorHandle(async () => {
            if (changeEntry == null) {
              // 変更画面以外から遷移した場合はエラーにするので有り得ない
              return
            }
            const response = await executePostAccount({
              accessToken: await getSessionAccessToken(),
              category: changeEntry.category,
              passcode: data.oneTimePassword,
            })
            if (response.resultCode) {
              // 検証失敗時
              // ローディング表示中はsetErrorでメッセージ表示が反映されないので遅延させる
              setTimeout(() => {
                formMethods.setError(
                  'oneTimePassword',
                  {
                    message: translate(`accountChangeVerification.error.verificationResultCode${response.resultCode}`),
                  },
                  { shouldFocus: true }
                )
              }, 0)
            } else {
              // 検証成功時
              await dispatch(detectLoginStatus())
              dispatch(notifyMessage(translate(completionMessageKey[changeEntry.category])))
              history.push(accountUrl.url())
              dispatch(clearAccountChange())
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [addOperationLog]
  )

  return {
    changeEntry,
    formMethods,
    identifier,
    resend,
    onSubmit,
  }
}
