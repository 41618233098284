import { styled } from '@mui/material'
import { ButtonM } from './buttonM'

/**
 * primaryカラーのボタンコントロールLサイズ。
 */
export const SsoLoginButton = styled(ButtonM)(({ theme }) => ({
  borderRadius: 0,
  width: '260px',
  marginLeft: '10px',
  [theme.breakpoints.up('sm')]: {
    width: '340px',
  },
}))
