import { memo } from 'react'
import { Amplify } from '@aws-amplify/core'
import { Stack, Toolbar } from '@mui/material'
import { Analytics, Auth } from 'aws-amplify'
import { Route, Switch } from 'react-router'
import awsmobile from './aws-exports'
import { useAction } from './containers/app/appService'
import {
  accountChangeVerificationUrl,
  accountConfirmationlUrl,
  accountCreateCompletionUrl,
  accountCreateConfirmationUrl,
  accountCreateFormUrl,
  accountEmailChangeUrl,
  accountPasswordChangeUrl,
  accountTelChangeUrl,
  accountUnsubscribeUrl,
  accountUrl,
  contactUrl,
  couponUrl,
  errorUrls,
  facilityDetailAvailabilityUrl,
  facilityDetailUrl,
  facilityReservationCompletionUrl,
  facilityReservationConfirmationUrl,
  facilityReservationDeleteConfirmationUrl,
  facilityReservationDeleteUrl,
  facilityReservationDetailUrl,
  facilityReservationFormUrl,
  facilityReservationSelectionCreateUrl,
  facilityReservationSelectionUpdateUrl,
  facilitySearchConditionUrl,
  facilitySearchResultUrl,
  faqUrl,
  homeUrl,
  identityEmailVerificationUrl,
  identityVerificationUrl,
  informationDetailUrl,
  informationUrl,
  interviewReservationCompletionUrl,
  interviewReservationConfirmationUrl,
  interviewReservationDetailUrl,
  interviewReservationFormUrl,
  loginUrl,
  manualUrl,
  memberAddChildUrl,
  memberAddConfirmationChildUrl,
  memberModifyChildUrl,
  memberModifyConfirmationChildUrl,
  memberModifyConfirmationUserUrl,
  memberModifyUserUrl,
  memberUrl,
  myFacilityUrl,
  myPageUrl,
  reservationStatusListUrl,
  rootUrl,
  termsOfServiceUrl,
  usageHistoryDetailUrl,
  usageHistoryUrl,
  webAccessibilityPolicyUrl,
  childUseEndUrl,
  ssoUrl,
} from './containers/common/constant/appUrl'
import { httpStatusCode } from './containers/common/constant/classification'
import { AppFooter } from './views/components/common/appFooter'
import { AppHeader } from './views/components/common/appHeader'
import { AppMain } from './views/components/common/appMain'
import { AuthenticatedGuard } from './views/components/common/authenticatedGuard'
import { LoadingProgress } from './views/components/common/loadingProgress'
import { ToastNotification } from './views/components/common/toastNotification'
import { Account } from './views/pages/account'
import { AccountChangeVerification } from './views/pages/accountChangeVerification'
import { AccountConfirmation } from './views/pages/accountConfirmation'
import { AccountCreateCompletion } from './views/pages/accountCreateCompletion'
import { AccountCreateConfirmation } from './views/pages/accountCreateConfirmation'
import { AccountCreateForm } from './views/pages/accountCreateForm'
import { AccountEmailChange } from './views/pages/accountEmailChange'
import { AccountPasswordChange } from './views/pages/accountPasswordChange'
import { AccountTelChange } from './views/pages/accountTelChange'
import { AccountUnsubscribe } from './views/pages/accountUnsubscribe'
import { Contact } from './views/pages/contact'
import { Coupon } from './views/pages/coupon'
import { FacilityDetail } from './views/pages/facilityDetail'
import { FacilityDetailAvailability } from './views/pages/facilityDetailAvailability'
import { FacilityReservationCompletion } from './views/pages/facilityReservationCompletion'
import { FacilityReservationConfirmation } from './views/pages/facilityReservationConfirmation'
import { FacilityReservationDelete } from './views/pages/facilityReservationDelete'
import { FacilityReservationDeleteConfirmation } from './views/pages/facilityReservationDeleteConfirmation'
import { FacilityReservationDetail } from './views/pages/facilityReservationDetail'
import { FacilityReservationForm } from './views/pages/facilityReservationForm'
import { FacilityReservationSelection } from './views/pages/facilityReservationSelection'
import { FacilitySearchCondition } from './views/pages/facilitySearchCondition'
import { FacilitySearchResult } from './views/pages/facilitySearchResult'
import { Faq } from './views/pages/faq'
import { Home } from './views/pages/home'
import { IdentityEmailVerification } from './views/pages/identityEmailVerification'
import { IdentityVerification } from './views/pages/identityVerification'
import { Information } from './views/pages/information'
import { InformationDetail } from './views/pages/informationDetail'
import { InterviewReservationCompletion } from './views/pages/interviewReservationCompletion'
import { InterviewReservationConfirmation } from './views/pages/interviewReservationConfirmation'
import { InterviewReservationDetail } from './views/pages/interviewReservationDetail'
import { InterviewReservationForm } from './views/pages/interviewReservationForm'
import { Login } from './views/pages/login'
import { Manual } from './views/pages/manual'
import { Member } from './views/pages/member'
import { MemberAddChild } from './views/pages/memberAddChild'
import { MemberAddConfirmationChild } from './views/pages/memberAddConfirmationChild'
import { MemberModifyChild } from './views/pages/memberModifyChild'
import { MemberModifyConfirmationChild } from './views/pages/memberModifyConfirmationChild'
import { MemberModifyConfirmationUser } from './views/pages/memberModifyConfirmationUser'
import { MemberModifyUser } from './views/pages/memberModifyUser'
import { MyFacility } from './views/pages/myFacility'
import { Mypage } from './views/pages/mypage'
import { ReservationStatusList } from './views/pages/reservationStatusList'
import { SystemError } from './views/pages/systemError'
import { TermsOfService } from './views/pages/termsOfService'
import { TransitionInconsistency } from './views/pages/transitionInconsistency'
import { UnderMaintenance } from './views/pages/underMaintenance'
import { UsageHistory } from './views/pages/usageHistory'
import { UsageHistoryDetail } from './views/pages/usageHistoryDetail'
import { WebAccessibilityPolicy } from './views/pages/webAccessibilityPolicy'
import { ChildUseEnd } from './views/pages/childUseEnd'
import { Sso } from './views/pages/sso'
import {
  amplifyConfOauthDomain,
  amplifyConfOauthSignOut,
  amplifyConfOauthSignIn,
} from './containers/common/constant/processEnv'

Amplify.configure(awsmobile)
// Amplify.configure側で設定すると oauth2/token が２回発生し１回エラー、２回ともエラーになる
// 同事象について以下サイトに記載があり、Auth.configureでoauth設定
// 参考:
// ・https://comytom.com/anarticle/c1ef3f1a-5197-4080-bfc2-17f2fc5a7074
// ・https://github.com/aws-amplify/amplify-js/issues/11012
if (amplifyConfOauthDomain && amplifyConfOauthSignIn && amplifyConfOauthSignOut) {
  Auth.configure({
    ...awsmobile,
    oauth: {
      domain: amplifyConfOauthDomain,
      scope: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: amplifyConfOauthSignIn,
      redirectSignOut: amplifyConfOauthSignOut,
      responseType: 'code',
    },
  })
}

Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA',
  /**
   * Page View Trackingで登録するurl文字列。
   * URLPathParamが異なるだけなら同じ画面と扱う。
   * よって、params部分は除去した文字列を返す。
   *
   * @returns url文字列
   */
  getUrl: () => {
    const locationPath = window.location.pathname
    return locationPath.split('/', 2).join('/')
  },
})

export const App = () => {
  const { isInitializedLoggedIn, loginRouteMatch, underMaintenanceRouteMatch } = useAction()
  const isLoginRoute = loginRouteMatch?.isExact
  const isUnderMaintenanceRoute = underMaintenanceRouteMatch?.isExact

  return (
    <AppContent
      isInitializedLoggedIn={isInitializedLoggedIn}
      isLoginRoute={!!isLoginRoute}
      isUnderMaintenanceRoute={!!isUnderMaintenanceRoute}
    />
  )
}
interface AppContentProps {
  isInitializedLoggedIn: boolean
  isLoginRoute: boolean
  isUnderMaintenanceRoute: boolean
}
const AppContent = memo(function AppContent(props: AppContentProps) {
  const {
    isInitializedLoggedIn,
    isLoginRoute,
    isUnderMaintenanceRoute,
  } = props
  
  return isInitializedLoggedIn ? (
    <Stack>
      <LoadingProgress />
      <ToastNotification />
      {!isLoginRoute && <AppHeader isHiddenButton={!!isUnderMaintenanceRoute} />}
      <AppMain>
        {!isLoginRoute && <Toolbar />}
        <Switch>
          <Route path={informationDetailUrl.routerPath} exact>
            <InformationDetail />
          </Route>
          <Route path={accountConfirmationlUrl.routerPath} exact>
            <AuthenticatedGuard>
              <AccountConfirmation />
            </AuthenticatedGuard>
          </Route>
          <Route path={contactUrl.routerPath} exact>
            <Contact />
          </Route>
          <Route path={termsOfServiceUrl.routerPath} exact>
            <TermsOfService />
          </Route>
          <Route path={homeUrl.routerPath} exact>
            <Home />
          </Route>
          <Route path={facilitySearchConditionUrl.routerPath} exact>
            <FacilitySearchCondition />
          </Route>
          <Route path={facilitySearchResultUrl.routerPath} exact>
            <FacilitySearchResult />
          </Route>
          <Route path={facilityDetailUrl.routerPath} exact>
            <FacilityDetail />
          </Route>
          <Route path={facilityDetailAvailabilityUrl.routerPath} exact>
            <FacilityDetailAvailability />
          </Route>
          <Route path={interviewReservationFormUrl.routerPath} exact>
            <AuthenticatedGuard>
              <InterviewReservationForm />
            </AuthenticatedGuard>
          </Route>
          <Route path={interviewReservationConfirmationUrl.routerPath} exact>
            <AuthenticatedGuard>
              <InterviewReservationConfirmation />
            </AuthenticatedGuard>
          </Route>
          <Route path={interviewReservationCompletionUrl.routerPath} exact>
            <AuthenticatedGuard>
              <InterviewReservationCompletion />
            </AuthenticatedGuard>
          </Route>
          <Route path={interviewReservationDetailUrl.routerPath} exact>
            <AuthenticatedGuard>
              <InterviewReservationDetail />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationSelectionCreateUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationSelection />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationSelectionUpdateUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationSelection />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationFormUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationForm />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationConfirmationUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationConfirmation />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationCompletionUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationCompletion />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationDetailUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationDetail />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationDeleteUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationDelete />
            </AuthenticatedGuard>
          </Route>
          <Route path={facilityReservationDeleteConfirmationUrl.routerPath} exact>
            <AuthenticatedGuard>
              <FacilityReservationDeleteConfirmation />
            </AuthenticatedGuard>
          </Route>
          <Route path={couponUrl.routerPath} exact>
            <AuthenticatedGuard>
              <Coupon />
            </AuthenticatedGuard>
          </Route>
          <Route path={myPageUrl.routerPath} exact>
            <AuthenticatedGuard isIgnoreVerifiedTel isIgnoreVerifiedEmail>
              <Mypage />
            </AuthenticatedGuard>
          </Route>
          <Route path={accountCreateFormUrl.routerPath} exact>
            <AccountCreateForm />
          </Route>
          <Route path={accountCreateConfirmationUrl.routerPath} exact>
            <AccountCreateConfirmation />
          </Route>
          <Route path={accountCreateCompletionUrl.routerPath} exact>
            <AccountCreateCompletion />
          </Route>
          <Route path={accountUrl.routerPath} exact>
            <AuthenticatedGuard>
              <Account />
            </AuthenticatedGuard>
          </Route>
          <Route path={accountEmailChangeUrl.routerPath} exact>
            <AuthenticatedGuard>
              <AccountEmailChange />
            </AuthenticatedGuard>
          </Route>
          <Route path={accountTelChangeUrl.routerPath} exact>
            <AuthenticatedGuard isIgnoreVerifiedTel isIgnoreVerifiedEmail>
              <AccountTelChange />
            </AuthenticatedGuard>
          </Route>
          <Route path={accountPasswordChangeUrl.routerPath} exact>
            <AuthenticatedGuard>
              <AccountPasswordChange />
            </AuthenticatedGuard>
          </Route>
          <Route path={accountChangeVerificationUrl.routerPath} exact>
            <AuthenticatedGuard isIgnoreVerifiedTel isIgnoreVerifiedEmail>
              <AccountChangeVerification />
            </AuthenticatedGuard>
          </Route>
          <Route path={identityVerificationUrl.routerPath} exact>
            <AuthenticatedGuard isIgnoreVerifiedTel>
              <IdentityVerification />
            </AuthenticatedGuard>
          </Route>
          <Route path={identityEmailVerificationUrl.routerPath} exact>
            <AuthenticatedGuard isIgnoreVerifiedTel isIgnoreVerifiedEmail>
              <IdentityEmailVerification />
            </AuthenticatedGuard>
          </Route>

          <Route path={memberUrl.routerPath} exact>
            <AuthenticatedGuard isIgnoreVerifiedTel isIgnoreVerifiedEmail>
              <Member />
            </AuthenticatedGuard>
          </Route>
          <Route path={memberModifyUserUrl.routerPath} exact>
            <AuthenticatedGuard>
              <MemberModifyUser />
            </AuthenticatedGuard>
          </Route>
          <Route path={memberModifyChildUrl.routerPath} exact>
            <AuthenticatedGuard>
              <MemberModifyChild />
            </AuthenticatedGuard>
          </Route>
          <Route path={memberModifyConfirmationUserUrl.routerPath} exact>
            <AuthenticatedGuard>
              <MemberModifyConfirmationUser />
            </AuthenticatedGuard>
          </Route>
          <Route path={memberModifyConfirmationChildUrl.routerPath} exact>
            <AuthenticatedGuard>
              <MemberModifyConfirmationChild />
            </AuthenticatedGuard>
          </Route>
          <Route path={memberAddChildUrl.routerPath} exact>
            <AuthenticatedGuard>
              <MemberAddChild />
            </AuthenticatedGuard>
          </Route>
          <Route path={memberAddConfirmationChildUrl.routerPath} exact>
            <AuthenticatedGuard>
              <MemberAddConfirmationChild />
            </AuthenticatedGuard>
          </Route>
          <Route path={accountUnsubscribeUrl.routerPath} exact>
            <AuthenticatedGuard>
              <AccountUnsubscribe />
            </AuthenticatedGuard>
          </Route>
          <Route path={childUseEndUrl.routerPath} exact>
            <AuthenticatedGuard>
              <ChildUseEnd />
            </AuthenticatedGuard>
          </Route>
          <Route path={usageHistoryUrl.routerPath} exact>
            <AuthenticatedGuard>
              <UsageHistory />
            </AuthenticatedGuard>
          </Route>
          <Route path={usageHistoryDetailUrl.routerPath} exact>
            <AuthenticatedGuard>
              <UsageHistoryDetail />
            </AuthenticatedGuard>
          </Route>
          <Route path={reservationStatusListUrl.routerPath} exact>
            <AuthenticatedGuard>
              <ReservationStatusList />
            </AuthenticatedGuard>
          </Route>
          <Route path={myFacilityUrl.routerPath} exact>
            <AuthenticatedGuard>
              <MyFacility />
            </AuthenticatedGuard>
          </Route>
          <Route path={informationUrl.routerPath} exact>
            <Information />
          </Route>
          <Route path={faqUrl.routerPath} exact>
            <Faq />
          </Route>
          <Route path={manualUrl.routerPath} exact>
            <Manual />
          </Route>
          <Route path={webAccessibilityPolicyUrl.routerPath} exact>
            <WebAccessibilityPolicy />
          </Route>
          <Route path={loginUrl.routerPath} exact>
            <Login />
          </Route>
          <Route path={ssoUrl.routerPath} exact>
            <Sso />
          </Route>
          <Route path={rootUrl.routerPath} exact>
            <Home />
          </Route>
          <Route path={errorUrls[httpStatusCode.badRequest].routerPath} exact>
            <SystemError statusCode={httpStatusCode.badRequest} />
          </Route>
          <Route path={errorUrls[httpStatusCode.unauthorized].routerPath} exact>
            <SystemError statusCode={httpStatusCode.unauthorized} />
          </Route>
          <Route path={errorUrls[httpStatusCode.forbidden].routerPath} exact>
            <SystemError statusCode={httpStatusCode.forbidden} />
          </Route>
          <Route path={errorUrls[httpStatusCode.methodNotAllowed].routerPath} exact>
            <SystemError statusCode={httpStatusCode.methodNotAllowed} />
          </Route>
          <Route path={errorUrls[httpStatusCode.requestTimeout].routerPath} exact>
            <SystemError statusCode={httpStatusCode.requestTimeout} />
          </Route>
          <Route path={errorUrls[httpStatusCode.conflict].routerPath} exact>
            <SystemError statusCode={httpStatusCode.conflict} />
          </Route>
          <Route path={errorUrls[httpStatusCode.internalServerError].routerPath} exact>
            <SystemError statusCode={httpStatusCode.internalServerError} />
          </Route>
          <Route path={errorUrls[httpStatusCode.serviceUnavailable].routerPath} exact>
            <UnderMaintenance />
          </Route>
          <Route path={errorUrls.unknown.routerPath} exact>
            <SystemError />
          </Route>
          <Route path={errorUrls.transitionInconsistency.routerPath} exact>
            <TransitionInconsistency />
          </Route>
          <Route>
            <SystemError statusCode={httpStatusCode.notFound} />
          </Route>
        </Switch>
      </AppMain>
      {!isLoginRoute && !isUnderMaintenanceRoute && <AppFooter />}
    </Stack>
  ) : (
    <></>
  )
})