import { Grid, Stack } from '@mui/material'
import React, { ReactNode } from 'react'
import { getUsageRecordApiStatusLabel } from '../../../../containers/common/codeMaster'
import { usageHistoryDetailUrl } from '../../../../containers/common/constant/appUrl'
import { translate } from '../../../../i18n'
import { formatHmtoHmOver, formatYmdWeek } from '../../../../utils/dateUtil'
import { Card } from '../card'
import { LabelGItemBase, RightGItem, ValueGItemBase } from '../grids'
import { Link } from '../link'

interface UsageHistoryCardProps {
  reservationNo: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  facilityName: string
  childName: string
  status: string
  usageMinute?: string
  operationId?: string
}

const LabelItem = (props: { children: ReactNode }) => <LabelGItemBase xs={3}>{props.children}</LabelGItemBase>
const ValueItem = (props: { children: ReactNode }) => <ValueGItemBase xs={9}>{props.children}</ValueGItemBase>

export const UsageHistoryCard = (props: UsageHistoryCardProps) => {
  let usageMinute
  if (props.usageMinute) {
    usageMinute = (
      <>
        <LabelItem>{translate('system.usageHistoryCard.label.couponUsageMinute')}</LabelItem>
        <ValueItem>{props.usageMinute}</ValueItem>
      </>
    )
  }
  return (
    <Card>
      <Grid container>
        <LabelItem>{translate('system.usageHistoryCard.label.usageDatetime')}</LabelItem>
        <ValueItem>
          <Stack>
            <div>{formatYmdWeek(props.usageDate)}</div>
            <div>{formatHmtoHmOver({ from: props.useFromDatetime, to: props.useToDatetime }, props.usageDate)}</div>
          </Stack>
        </ValueItem>
        <LabelItem>{translate('system.usageHistoryCard.label.facilityName')}</LabelItem>
        <ValueItem>{props.facilityName}</ValueItem>
        <LabelItem>{translate('system.usageHistoryCard.label.childName')}</LabelItem>
        <ValueItem>{props.childName}</ValueItem>
        <LabelItem>{translate('system.usageHistoryCard.label.status')}</LabelItem>
        <ValueItem>{getUsageRecordApiStatusLabel(props.status)}</ValueItem>
        {usageMinute}
        <RightGItem xs={12}>
          {props.operationId ? (
            <Link
              to={usageHistoryDetailUrl.url(props.reservationNo)}
              operationLog={{ operationId: props.operationId }}
            >{`${translate('system.button.detail')}>`}</Link>
          ) : (
            <Link to={usageHistoryDetailUrl.url(props.reservationNo)}>{`${translate('system.button.detail')}>`}</Link>
          )}
        </RightGItem>
      </Grid>
    </Card>
  )
}
