import { Box, Grid, Stack, styled } from '@mui/material'
import React from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { translate } from '../../i18n'
import { Link } from '../components/common/link'
import { PageTitle } from '../components/common/pageTitle'

const ErrorContentItem = styled(Grid)({
  whiteSpace: 'pre-wrap',
})

export const TransitionInconsistency = () => {
  return (
    <Stack spacing={2}>
      <PageTitle title={translate('transitionInconsistency.title.transitionInconsistency')} />
      <Box>
        <Grid container spacing={2}>
          <ErrorContentItem item xs={12}>
            {translate('transitionInconsistency.text.content')}
          </ErrorContentItem>
          <Grid item xs={12}>
            <Link to={rootUrl.url()}>{translate('system.text.backToHome')}</Link>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}
