import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { Grid, styled } from '@mui/material'
import { Redirect } from 'react-router'

import {
  accountCreateFormUrl,
  identityEmailVerificationUrl,
  identityVerificationUrl,
  rootUrl,
  ssoUrl,
  loginUrl,
} from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/login/loginService'
import { IconLinkButton } from '../components/common/buttons/iconLinkButton'
import { CenterGItem, GContainer, GItem, RightGItem, RowSpacerGItem } from '../components/common/grids'
import { Link } from '../components/common/link'

import { translate } from '../../i18n'
import { IdentityRedirect } from '../components/common/identityRedirect'
import { SystemLogo } from '../components/common/systemLogo'
import { SsoLoginButton } from '../components/common/buttons/ssoLoginButton'
import { yesNo } from '../../containers/common/constant/classification'

const Root = styled(Grid)({
  marginTop: 60,
})
const LogoItem = styled(CenterGItem)(({ theme }) => ({
  height: 100,
  color: theme.palette.primary.main,
}))
const Logo = styled(SystemLogo)(({}) => ({
  width: 230,
}))
const CreateLinkGContainer = styled(GContainer)({
  justifyContent: 'center',
  paddingRight: 8,
  visibility: 'hidden',
  'amplify-authenticator.hydrated + &': {
    visibility: 'visible',
  },
})
const CreateLinkGItem = styled(RightGItem)(({ theme }) => ({
  // index.css amplify-authenticator の 幅に合わせる
  width: 300,
  [theme.breakpoints.up(672)]: {
    width: 380,
  },
}))

export const Login = () => {
  const { isLoggedIn, isVerifiedEmail, isVerifiedTel, from, ssoUseFlag, ssoUseName} = useAction()

  if (isLoggedIn) {
    if (isVerifiedEmail && isVerifiedTel) {
      return <Redirect to={from ? from : rootUrl.url()} />
    } else if (!isVerifiedEmail) {
      return <IdentityRedirect from={from} toPathname={identityEmailVerificationUrl.url()} />
    } else {
      return <IdentityRedirect from={from} toPathname={identityVerificationUrl.url()} />
    }
  } else {
    return (
      <Root container>
        <LogoItem xs={12}>
          <IconLinkButton to={rootUrl.url()}>
            <Logo />
          </IconLinkButton>
        </LogoItem>

        <GItem xs={12}>
          <AmplifyAuthenticator usernameAlias="email">
            <AmplifySignIn
              slot="sign-in"
              hideSignUp
              usernameAlias="email"
              formFields={[
                { type: 'email', required: true },
                { type: 'password', required: true },
              ]}
            >
              <div slot="federated-buttons"></div>
            </AmplifySignIn>
          </AmplifyAuthenticator>
          <CreateLinkGContainer>
            { ssoUseFlag === yesNo.yes && (
              <>
                <SsoLoginButton to={`${ssoUrl.url()}?loginRedirectTo=${loginUrl.url()}`} >
                  {translate('login.button.ssoLink', ssoUseName ?? '')}
                </SsoLoginButton>
                <RowSpacerGItem />
              </>
            )}
            <CreateLinkGItem>
              <Link to={accountCreateFormUrl.url()}>{translate('login.text.accountCreate')}</Link>
            </CreateLinkGItem>
          </CreateLinkGContainer>
        </GItem>
      </Root>
    )
  }
}
