import { styled } from '@mui/material'
import { ButtonContainedBase } from './buttonContainedBase'

/**
 * primaryカラーのボタンコントロールLサイズ。
 */
export const ButtonL = styled(ButtonContainedBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
  padding: '18px 18px',
}))
