import { styled } from '@mui/material'
import { ButtonContainedBase } from './buttonContainedBase'

/**
 * primaryカラーのボタンコントロールLサイズ。
 */
export const ButtonM = styled(ButtonContainedBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeM,
  padding: '10px 10px',
}))
