import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { setFormErrors } from '../../utils/reactUtil'
import { translate } from '../../i18n'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorHandle } from '../common/error/errorHandler'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { executePutAccountIdentityVerifyResendEmail } from '../../dataAccess/webApi/dao/accountDao'
import { getSessionAccessToken } from '../../utils/authUtil'
import { selectIsVerifiedEmail } from '../common/store/slices/authority'

interface Inputs {
  email: string
  emailConfirmation: string
}
type InputTypePropName = keyof Inputs

export const useAction = () => {
  const dispatch = useDispatch()
  const errorHandle = useErrorHandle()

  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)

  const formMethods = useForm<Inputs>()

  const [resendEmailResultCode, setResendEmailResultCode] = useState<number>()

  /**
   * 項目間などの入力値妥当性検査
   * @param data 入力値
   * @returns エラーが存在する場合true
   */
  const validateItems = useCallback(
    (data: Inputs) => {
      const errors: { name: InputTypePropName; message: string }[] = []

      if (data.email !== data.emailConfirmation) {
        errors.push({
          name: 'emailConfirmation',
          message: translate('system.error.notSameEmail'),
        })
      }

      setFormErrors(formMethods, errors)

      return !!errors.length
    },
    [formMethods]
  )

  const onSubmit = useCallback(
    (data: Inputs) => {
      setResendEmailResultCode(undefined)
      if (validateItems(data)) {
        return
      }

      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const resp = await executePutAccountIdentityVerifyResendEmail({
              accessToken: await getSessionAccessToken(),
              email: data.email,
            })
            if (resp.resultCode) {
              // 送信失敗時
              setResendEmailResultCode(resp.resultCode)
            } else {
              // 送信成功時
              dispatch(notifyMessage(translate('accountCreateCompletion.success.resendEmail')))
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [dispatch, errorHandle, validateItems]
  )

  return { isVerifiedEmail, formMethods, resendEmailResultCode, onSubmit }
}
