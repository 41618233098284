// テキストコントロールの共通スタイル定義
//

import { styled, TextField } from '@mui/material'

/** テキストコントロールで共通適用するスタイル */
export const TextBoxBaseStyled = styled(TextField, { shouldForwardProp: (prop) => prop !== 'maxLength' })<{
  maxLength?: number
  fullWidth?: boolean
}>(({ maxLength, fullWidth }) => ({
  ...(maxLength &&
    !fullWidth && {
      width: `${maxLength * 1.5}rem`,
      maxWidth: '100%',
    }),
  '& .MuiInputBase-root': {
    padding: 0,
  },
  '& .MuiInputBase-input': {
    padding: '4px 5px',
  },
  '& .MuiInputBase-root.MuiInputBase-adornedStart': {
    paddingLeft: 4,
  },
  '& .MuiInputAdornment-positionStart': {
    marginRight: 0,
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: 0,
  },
}))
