import { executeGet } from '../common/apiCaller'
import {
  GetFacilityReservationsWeekPublicDto,
  GetFacilityReservationsWeekPublicInputDto,
} from '../dto/facilityReservationsWeekPublicDto'

const apiName = 'public'
const apiPath = '/facilities'

export const executeGetFacilityReservationsWeekPublic = (
  id: string,
  input: GetFacilityReservationsWeekPublicInputDto
) => {
  return executeGet<GetFacilityReservationsWeekPublicDto[]>(apiName, `${apiPath}/${id}/reservations/week`, input)
}
