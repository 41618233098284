import { executeGet, executeGetNoResultError } from '../common/apiCaller'
import { GetInformationDto, GetInformationsDto } from '../dto/informationsDto'

const apiNamePublic = 'public'
const apiNameCitizen = 'citizen'
const apiPath = '/informations'

export const executeGetInformations = (isCitizen: boolean) => {
  return executeGet<GetInformationsDto[]>(isCitizen ? apiNameCitizen : apiNamePublic, apiPath)
}

export const executeGetInformation = (isCitizen: boolean, id: string) => {
  return executeGetNoResultError<GetInformationDto>(isCitizen ? apiNameCitizen : apiNamePublic, `${apiPath}/${id}`)
}
