// import { executePost, KANRI_API_NAME } from '../common/apiCaller'
import { executePost } from '../common/apiCaller'
import { ContactData } from '../dto/contactDto'
// import { GuideTextDto, GuideTextData } from '../dto/guideTextDto'

// const apiName = KANRI_API_NAME
const apiName = 'public'
const apiPath = '/contact'

// /**
// * 当日分を全件取得
// */
// export const executeGetContacts = () => {
//   return executeGet<ContactData[]>(apiName, apiPath)
// }

/**
 * 全件取得
 */
export const executePostContacts = () => {
  return executePost<ContactData[]>(apiName, apiPath)
}

// /**
// * 案内文を取得
// */
// export const executePostGuideText = () => {
//   return executePost<GuideTextData[]>(apiName, '/guide-text-mst')
// }

 
