// 利用予約情報に関する共通処理を定義
//

import { executeGetReservation } from '../../dataAccess/webApi/dao/reservationsDao'
import { fromApiYmd, fromApiYmdHms, endOfDay, getNow } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { yesNo } from './constant/classification'

/**
 * 指定した受付Noの利用予約情報を返す。
 *
 * @param reservationNo 受付No
 * @returns 利用予約情報
 */
export const getReservation = async (reservationNo: string) => {
  const response = await executeGetReservation(reservationNo)
  const {
    usageDate,
    useFromDatetime,
    useToDatetime,
    childBirth,
    canUpdate,
    canCancel,
    reservationDatetime,
    reservationChangeDatetime,
    ...other
  } = response.result
  
  return nullPropsToUndefined({
    ...other,
    reservationNo,
    usageDate: fromApiYmd(usageDate),
    useFromDatetime: fromApiYmdHms(useFromDatetime),
    useToDatetime: fromApiYmdHms(useToDatetime),
    childBirth: fromApiYmd(childBirth),
    canUpdate: canUpdate === yesNo.yes,
    canCancel: canCancel === yesNo.yes,
    canCancelWait: getNow().getTime() <= endOfDay(usageDate).getTime(),
    reservationDatetime: fromApiYmdHms(reservationDatetime),
    reservationChangeDatetime: fromApiYmdHms(reservationChangeDatetime),
  })
}

/** 利用予約情報の型 */
export type Reservation = ReturnType<typeof getReservation> extends Promise<infer T> ? T : never
