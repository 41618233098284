import { SelectChangeEvent } from '@mui/material'
import { addMonths, startOfMonth, subMonths } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeGetUsageRecords } from '../../dataAccess/webApi/dao/usageRecordsDao'
import { fromApiYmd, fromApiYmdHms, getNow, toApiYm } from '../../utils/dateUtil'
import { undefinedPropsToNull } from '../../utils/objectUtil'
import { formatLocaleTimeAmountHm } from '../../utils/timeUtil'
import { ChildrenChoice, getChildrenChoices } from '../common/child'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { yesNo, usageHistoryStatusSearchCondition } from '../common/constant/classification'

interface LocationState {
  /** アクティブな年月の値 */
  yyyymm?: Date
  /** アクティブなお子さまID */
  childId?: string
  /** アクティブな利用予約の検索条件 */
  statusSearchCondition?: string
}

interface UsageHistory {
  reservationNo: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  facilityName: string
  childName: string
  status: string
  usageMinute?: string
}

interface PageState {
  activeYyyymm: Date
  activeChildId?: string
  activeStatusSearchCondition: string
  usageHistories?: UsageHistory[]
  childs: ChildrenChoice[]
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const { addOperationLog } = useOperationLog()
  const history = useHistory<LocationState | undefined>()

  const locationState = history.location.state

  const [state, setState] = useState<PageState>({
    activeYyyymm: locationState?.yyyymm ?? startOfMonth(getNow()),
    activeChildId: locationState?.childId,
    activeStatusSearchCondition: locationState?.statusSearchCondition ?? usageHistoryStatusSearchCondition.usedOnly,
    childs: [],
  })

  const load = useCallback(
    (
      isInitialize: boolean,
      activeYyyymm: Date,
      loadedChilds: { value: string; label: string }[],
      activeStatusSearchCondition: string,
      activeChildId?: string
    ) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const [childs, usageHistories] = await Promise.all([
              isInitialize ? getChildrenChoices() : loadedChilds,
              getUsageHistories(activeYyyymm, activeStatusSearchCondition, activeChildId),
            ])
            setState({
              activeChildId,
              activeYyyymm,
              usageHistories,
              childs,
              activeStatusSearchCondition,
            })
          }),
          isHiddenMain: isInitialize,
        })
      )
    },
    []
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    load(true, state.activeYyyymm, state.childs, state.activeStatusSearchCondition, state.activeChildId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 戻るで表示した際にアクティブな選択を復元する為に履歴に保管
    history.replace({
      ...history.location,
      state: { yyyymm: state.activeYyyymm, childId: state.activeChildId, statusSearchCondition: state.activeStatusSearchCondition }
    })
  }, [state.activeChildId, state.activeYyyymm, state.activeStatusSearchCondition])

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      load(false, state.activeYyyymm, state.childs, state.activeStatusSearchCondition, event.target.value)
    },
    [state.activeYyyymm, state.childs, state.activeStatusSearchCondition, addOperationLog]
  )

  const changeMonth = useCallback(
    (value: Date) => {
      load(false, value, state.childs, state.activeStatusSearchCondition, state.activeChildId)
    },
    [state]
  )

  const previousMonth = useCallback(() => {
    changeMonth(subMonths(state.activeYyyymm, 1))
  }, [state])

  const nextMonth = useCallback(() => {
    changeMonth(addMonths(state.activeYyyymm, 1))
  }, [state])
  
  const statusSearchConditionChangeHandle = useCallback(
    (event: SelectChangeEvent<string>) => {
      load(false, state.activeYyyymm, state.childs, event.target.value, state.activeChildId)
    },
    [state]
  )

  return {
    ...state,
    changeChild,
    previousMonth,
    nextMonth,
    statusSearchConditionChangeHandle,
  }
}

const getUsageHistories = async (yyyymm: Date, activeStatusSearchCondition: string, childId?: string) => {
  const response = await executeGetUsageRecords({
    ...undefinedPropsToNull({ yyyymm: toApiYm(yyyymm), childId }),
    ...(activeStatusSearchCondition === usageHistoryStatusSearchCondition.usedOnly && {usedOnlyGetFlag: yesNo.yes})
  })

  return response.result.map((usage) => {
    const { usageDate, useFromDatetime, useToDatetime, usageMinute, ...other } = usage
    return nullPropsToUndefined({
      ...other,
      usageDate: fromApiYmd(usageDate),
      useFromDatetime: fromApiYmdHms(useFromDatetime),
      useToDatetime: fromApiYmdHms(useToDatetime),
      usageMinute: formatLocaleTimeAmountHm(usageMinute),
    })
  })
}
