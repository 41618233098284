// 地図の数字入りアイコンに関する定義
//

import React from 'react'
import { createSvgIcon, Theme, useTheme } from '@mui/material'
import { IconProps } from './iconBase'

interface NumberPinIconProps extends IconProps {
  serialNumber: number
}

/** ピンSVGのViewBox */
export const numberPinSvgViewBox = '0 0 35.776 52.288'

const getFontScale = (serialNumber: number) => (serialNumber >= 1000 ? 0.5 : serialNumber >= 100 ? 0.7 : 1)

/**
 * GoogleMapのマーカーとしてデータUrl指定するためにSVGコンテンツ部分を返す
 *
 * @param theme テーマ
 * @param textFontSize 数字のフォントサイズ
 * @param serialNumber ピン内に表示する数字。省略時は数字の表示なし
 * @returns SVGコンテンツ部分
 */
export const createNumberPinSvg = (theme: Theme, textFontSize: string, serialNumber?: number) => {
  return (
    <>
      <path
        d="M1202,782.673s-11.2-17.922-14.557-26.042a19.871,19.871,0,0,1-1.466-7.292c-.1-4.337,1.989-7.714,4.366-10.349,3.43-3.8,7.768-5.688,11.658-5.6a16.947,16.947,0,0,1,12.326,5.6,16.384,16.384,0,0,1,4.415,10.349,17.76,17.76,0,0,1-1.629,7.292C1214.053,763.551,1202,782.673,1202,782.673Z"
        transform="translate(-1184.473 -731.885)"
        fill="#fff55b"
        stroke="#006dcc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      {serialNumber != null && (
        <text
          x="50%"
          y="38%"
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: `calc(${textFontSize} * ${getFontScale(serialNumber)})`,
            fontWeight: theme.typography.fontWeightBold,
            fill: theme.palette.primary.main,
          }}
        >
          {serialNumber}
        </text>
      )}
    </>
  )
}

/** 数字ピンアイコン */
export const NumberPinIcon = (props: NumberPinIconProps) => {
  const theme = useTheme()
  const NumberPin = createSvgIcon(createNumberPinSvg(theme, '2rem', props.serialNumber), 'NumberPin')
  return <NumberPin fontSize={props.fontSize} viewBox={numberPinSvgViewBox} />
}
