import { styled } from '@mui/material'
import { SizeLabelBase } from './sizeLabelBase'

/**
 * 色の指定は敢えてしていない。もしエラー色にしたい場合は、<AttentionLabel>などで囲う
 */
const Root = styled(SizeLabelBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeS,
}))

/** 注釈などの小さく表示する際に使うラベルコントロール */
export const AnnotationLabel = Root
