import { styled } from '@mui/material'
import { translate } from '../../../i18n'
import { BottomGItem, GContainer, MiddleGItem } from './grids'
import { SizeLabelBase } from './sizeLabelBase'

const Label = styled(SizeLabelBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeLL1,
}))

interface TimeAmountContentProps {
  value?: number
  subValue?: number
}
const splitAmount = (value?: number) => {
  if (value == null) {
    return {
      hour: 0,
      minute: 0,
    }
  }
  const hour = Math.floor(value / 60)
  const minute = value % 60
  return {
    hour: hour,
    minute: minute,
  }
}
const Content = styled('div')(({ theme }) => ({
  fontSize: theme.typography.font.sizeL2,
  paddingBottom: '5px',
}))
export const TimeAmountContent = (props: TimeAmountContentProps) => {
  const { value, subValue } = props
  const { hour, minute } = splitAmount(value)
  const subHour = splitAmount(subValue).hour
  return (
    <GContainer columnSpacing={1}>
      <MiddleGItem>
        <Label>{hour}</Label>
      </MiddleGItem>
      <MiddleGItem>
        <Label>{translate('system.label.hours')}</Label>
      </MiddleGItem>
      <MiddleGItem>
        <Label>{minute}</Label>
      </MiddleGItem>
      <MiddleGItem>
        <Label>{translate('system.label.minutes')}</Label>
      </MiddleGItem>
      <BottomGItem>
        <Content>
          /{subHour}
          {translate('system.label.hours')}
        </Content>
      </BottomGItem>
    </GContainer>
  )
}
