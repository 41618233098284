// 各アプリ画面のURLパス定数を定義
//

import { OptionalTupleMin, tuple } from '../../../utils/typeUtil'
import { httpStatusCode } from './classification'

/**
 * アプリ画面URL情報定数用の不変クラス。
 * URLのベースパスと必須URLパラメータ名配列、任意URLパラメータ名配列を保持し、
 * react-routerのpathに使用する文字列と画面遷移で使用する文字列を取得が行える。
 */
class AppUrl<Params extends string[], OptionParams extends string[]> {
  routerPath: string
  /**
   * コンストラクタ
   * @param baseUrl ページを識別する基本URL。トラッキングの為、"/" を含めることを禁止
   * @param option 必須、任意のURLパスパラメータ
   */
  constructor(private baseUrl: string, option: { params: Params; optionParams: OptionParams }) {
    this.routerPath = [
      baseUrl,
      ...option.params.map((v) => ':' + v),
      ...option.optionParams.map((v) => ':' + v + '?'),
    ].join('/')
  }

  url(...params: OptionalTupleMin<Params, OptionParams>) {
    if (params.length === 0) {
      return this.baseUrl
    }
    const m = /^(\/)?(.*)/.exec(this.baseUrl) ?? []
    return m[1] + [m[2], ...(params ?? [])].filter((v) => v).join('/')
  }
}

const emptyOption = { params: tuple(), optionParams: tuple() } as const

export const errorUrls = {
  [httpStatusCode.badRequest]: new AppUrl('/error_400', emptyOption),
  [httpStatusCode.unauthorized]: new AppUrl('/error_401', emptyOption),
  [httpStatusCode.forbidden]: new AppUrl('/error_403', emptyOption),
  [httpStatusCode.notFound]: new AppUrl('/error_404', emptyOption),
  [httpStatusCode.methodNotAllowed]: new AppUrl('/error_405', emptyOption),
  [httpStatusCode.requestTimeout]: new AppUrl('/error_408', emptyOption),
  [httpStatusCode.conflict]: new AppUrl('/error_409', emptyOption),
  [httpStatusCode.internalServerError]: new AppUrl('/error_500', emptyOption),
  [httpStatusCode.serviceUnavailable]: new AppUrl('/error_503', emptyOption),
  unknown: new AppUrl('/error_unknown', emptyOption),
  transitionInconsistency: new AppUrl('/error_transition_inconsistency', emptyOption),
} as const

export const rootUrl = new AppUrl('/', emptyOption)
export const homeUrl = new AppUrl('/home', emptyOption)
export const loginUrl = new AppUrl('/login', emptyOption)
export const accountCreateFormUrl = new AppUrl('/account_create_form', emptyOption)
export const accountCreateConfirmationUrl = new AppUrl('/account_create_confirmation', emptyOption)
export const accountCreateCompletionUrl = new AppUrl('/account_create_completion', emptyOption)

export const accountUrl = new AppUrl('/account', emptyOption)
export const accountTelChangeUrl = new AppUrl('/account_tel_change', emptyOption)
export const accountEmailChangeUrl = new AppUrl('/account_email_change', emptyOption)
export const accountPasswordChangeUrl = new AppUrl('/account_password_change', emptyOption)
export const accountChangeVerificationUrl = new AppUrl('/account_change_verification', emptyOption)
export const identityVerificationUrl = new AppUrl('/identity_verification', emptyOption)
export const identityEmailVerificationUrl = new AppUrl('/identity_email_verification', emptyOption)

export const memberUrl = new AppUrl('/member', emptyOption)
export const memberModifyUserUrl = new AppUrl('/member_modify_user', emptyOption)
export const memberModifyChildUrl = new AppUrl('/member_modify_child', {
  ...emptyOption,
  params: tuple<[string]>('childId'),
})
export const memberModifyConfirmationUserUrl = new AppUrl('/member_modify_confirmation_user', emptyOption)
export const memberModifyConfirmationChildUrl = new AppUrl('/member_modify_confirmation_child', emptyOption)
export const memberAddChildUrl = new AppUrl('/member_add_child', emptyOption)
export const memberAddConfirmationChildUrl = new AppUrl('/member_add_confirmation_child', emptyOption)

export const accountUnsubscribeUrl = new AppUrl('/account_unsubscribe', emptyOption)
export const childUseEndUrl = new AppUrl('/child_use_end', {
  ...emptyOption,
  params: tuple<[string]>('childId'),
})

export const myPageUrl = new AppUrl('/my_page', emptyOption)
export const informationUrl = new AppUrl('/information', emptyOption)
export const informationDetailUrl = new AppUrl('/information_detail', {
  ...emptyOption,
  params: tuple<[string]>('informaionNo'),
})

export const facilitySearchConditionUrl = new AppUrl('/facility_search_condition', emptyOption)
export const facilitySearchResultUrl = new AppUrl('/facility_search_result', emptyOption)
export const facilityDetailUrl = new AppUrl('/facility_detail', {
  ...emptyOption,
  params: tuple<[string]>('facilityId'),
})
export const facilityDetailAvailabilityUrl = new AppUrl('/facility_detail_availability', {
  params: tuple<[string]>('facilityId'),
  optionParams: tuple<[string]>('childId'),
})
export const accountConfirmationlUrl = new AppUrl('/account_confirmation', {
  ...emptyOption,
  params: tuple<[string]>('facilityId'),
})

export const interviewReservationFormUrl = new AppUrl('/interview_reservation_form', {
  ...emptyOption,
  params: tuple<[string]>('facilityId'),
})
export const interviewReservationConfirmationUrl = new AppUrl('/interview_reservation_confirmation', emptyOption)
export const interviewReservationCompletionUrl = new AppUrl('/interview_reservation_completion', emptyOption)
export const interviewReservationDetailUrl = new AppUrl('/interview_reservation_detail', {
  ...emptyOption,
  params: tuple<[string]>('interviewNo'),
})

export const facilityReservationSelectionCreateUrl = new AppUrl('/facility_reservation_selection', {
  ...emptyOption,
  params: tuple<[string, string]>('facilityId', 'childId'),
})
export const facilityReservationSelectionUpdateUrl = new AppUrl('/facility_reservation_selection', {
  ...emptyOption,
  params: tuple<[string]>('reservationNo'),
})
export const facilityReservationFormUrl = new AppUrl('/facility_reservation_form', emptyOption)
export const facilityReservationConfirmationUrl = new AppUrl('/facility_reservation_confirmation', emptyOption)
export const facilityReservationCompletionUrl = new AppUrl('/facility_reservation_completion', {
  ...emptyOption,
  params: tuple<[string, string, string]>('facilityId', 'childId', 'displayMode'),
})

export const facilityReservationDetailUrl = new AppUrl('/facility_reservation_detail', {
  ...emptyOption,
  params: tuple<[string]>('reservationNo'),
})
export const facilityReservationDeleteUrl = new AppUrl('/facility_reservation_delete', {
  ...emptyOption,
  params: tuple<[string]>('reservationNo'),
})
export const facilityReservationDeleteConfirmationUrl = new AppUrl(
  '/facility_reservation_delete_confirmation',
  emptyOption
)
export const couponUrl = new AppUrl('/coupon', emptyOption)
export const myFacilityUrl = new AppUrl('/my_facility', emptyOption)
export const reservationStatusListUrl = new AppUrl('/reservation_status_list', emptyOption)
export const usageHistoryUrl = new AppUrl('/usage_history', emptyOption)
export const usageHistoryDetailUrl = new AppUrl('/usage_history_detail', {
  ...emptyOption,
  params: tuple<[string]>('reservationNo'),
})

export const contactUrl = new AppUrl('/contact', emptyOption)
export const termsOfServiceUrl = new AppUrl('/terms_of_service', emptyOption)
export const manualUrl = new AppUrl('/manual', emptyOption)
export const faqUrl = new AppUrl('/faq', emptyOption)
export const webAccessibilityPolicyUrl = new AppUrl('/web_accessibility_policy', emptyOption)

export const ssoUrl = new AppUrl('/sso', emptyOption)
