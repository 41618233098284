import React, { ReactNode } from 'react'
import { Box, styled } from '@mui/material'

interface SearchConditionTagProps {
  children: ReactNode
}

const Root = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  fontSize: theme.typography.font.sizeM,
  color: theme.palette.primary.main,
  borderRadius: 24,
  backgroundColor: theme.palette.warning.main,
  border: `3px solid ${theme.palette.primary.main}`,
  padding: '4px 12px',
  lineHeight: 'normal',
  maxWidth: '13rem', // 10文字程度の幅を設定
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

export const SearchConditionTag = (props: SearchConditionTagProps) => {
  return <Root>{props.children}</Root>
}
