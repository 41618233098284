import { Link as MuiLink, styled } from '@mui/material'
import { LocationDescriptor } from 'history'
import { MouseEvent, MouseEventHandler, ReactNode, useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { OperationLogInput, useOperationLog } from '../../../containers/common/operationLog'

interface LinkProps {
  children: ReactNode
  className?: string
  underline?: 'none' | 'hover' | 'always'
  target?: '_blank'
  onClick?: MouseEventHandler<HTMLAnchorElement>
  to?: LocationDescriptor
  replace?: boolean
  href?: string
  disabled?: boolean
  download?: string
  operationLog?: OperationLogInput
}

const Root = (props: LinkProps) => {
  const { children, to, replace, onClick, href, operationLog, ...linkAttrs } = props
  const { getOnClickAddOperationLog } = useOperationLog()

  let rel
  if (linkAttrs.target === '_blank') {
    rel = 'noreferrer'
  }
  const onClickOpWrapped = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (operationLog == null) {
        return
      }
      getOnClickAddOperationLog(operationLog)()
      if (onClick != null) {
        return onClick(event)
      }
    },
    [onClick, operationLog, getOnClickAddOperationLog]
  )

  let onClickAttrs = {
    onClick,
  }
  if (operationLog) {
    onClickAttrs = {
      onClick: onClickOpWrapped,
    }
  }

  return to ? (
    <MuiLink component={RouterLink} {...linkAttrs} rel={rel} to={to} replace={replace} {...onClickAttrs}>
      {children}
    </MuiLink>
  ) : (
    <MuiLink {...linkAttrs} rel={rel} href={href} {...onClickAttrs}>
      {children}
    </MuiLink>
  )
}

export const Link = styled(Root, { shouldForwardProp: (prop) => prop !== 'disabled' })<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    '&:hover': {
      cursor: 'pointer',
    },
    ...(disabled && {
      pointerEvents: 'none',
      color: theme.palette.text.disabled,
      textDecoration: 'none',
    }),
  })
)
