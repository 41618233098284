import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { ApiError, NoResultError } from '../../../dataAccess/webApi/common/apiCaller'
import { isKeyOf } from '../../../utils/typeUtil'
import { errorUrls } from '../constant/appUrl'
import { httpStatusCode } from '../constant/classification'

/**
 * useEffect、onClickなどのイベントハンドラー内の処理で
 * 例外ハンドリングを行う関数を生成するフック関数。
 * この関数でラップしなければ、処理内で例外が発生してもエラー画面へ遷移させる処理が行われず
 * 例外は握りつぶされることになる。
 *
 * @returns 例外ハンドリング関数
 */
export const useErrorHandle = () => {
  const history = useHistory()

  const errorHandle = useCallback((e: unknown) => {
    if (e instanceof NoResultError) {
      // APIレスポンスのresult=nullなどで発生した例外の場合
      history.replace(errorUrls[httpStatusCode.notFound].url())
    } else if (e instanceof ApiError) {
      // その他のAPI呼び出しで発生した例外(httpstatus!=200)
      if (e.statusCode == null) {
        // statusCodeが無い場合、メンテナンス中用に設定した
        // WAFのレスポンスである可能性がある為、一度503ページへ遷移
        history.replace(errorUrls[503].url())
      } else if (isKeyOf(e.statusCode, errorUrls)) {
        history.replace(errorUrls[e.statusCode].url())
      } else {
        history.replace(errorUrls.unknown.url())
      }
    } else {
      // 想定されないエラー。開発中のバグなど
      console.error('想定されないエラー', e)
      history.replace(errorUrls.unknown.url())
    }
  }, [])

  const errorHandler = useCallback(<R, T extends (...args: never[]) => R>(process: T) => {
    const wrapedProcess = (...args: never[]) => {
      try {
        const returnValue = process(...args)
        if (returnValue instanceof Promise) {
          return returnValue.catch(errorHandle)
        } else {
          return returnValue
        }
      } catch (e) {
        errorHandle(e)
      }
    }
    return wrapedProcess as T
  }, [])

  return errorHandler
}
