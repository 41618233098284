import React from 'react'
import { styled } from '@mui/material'

interface ImageProps {
  className?: string
  imageUrl?: string
}

const Root = styled('img')({
  width: '100%',
})

export const Image = (props: ImageProps) => {
  const imgUrlFileName = props.imageUrl ? props.imageUrl.split('/').reverse()[0] : ''
  return props.imageUrl ? (
    <Root
      src={props.imageUrl.substring(0, props.imageUrl.lastIndexOf('/') + 1) + encodeURIComponent(imgUrlFileName)}
      className={props.className}
      loading="lazy"
    />
  ) : null
}
