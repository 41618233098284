import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePostUserAccount } from '../../dataAccess/webApi/dao/userAccountDao'
import { signIn, verifyEmail, verifyTel } from '../../utils/authUtil'
import { toApiYmd } from '../../utils/dateUtil'
import { blankToNull } from '../../utils/stringUtil'
import { translate } from '../../i18n'
import { accountCreateCompletionUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { AccountCreateEntry, clearAccountCreate, selectAccountCreateEntry } from '../common/store/slices/accountCreate'
import { notifyMessage } from '../common/store/slices/application'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { showLoading } from '../common/store/slices/application'
import { detectLoginStatus } from '../common/store/slices/authority'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const accountEntry = useSelector(selectAccountCreateEntry)
  const sysCtrl = useSelector(selectSystemControl)

  const [userAccountResultCode, setUserAccountResultCode] = useState<number>()

  const register = useCallback(() => {
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          if (accountEntry == null) {
            // 入力値なしで表示不可。型ガードの為の分岐
            return
          }
          const isSsoLink = accountEntry.email === accountEntry.ssoLinkedParams?.email
          const resp = await postUserAccount(accountEntry, isSsoLink)
          if (resp.resultCode) {
            // 登録失敗時
            setUserAccountResultCode(resp.resultCode)
          } else {
            // 登録成功時
            
            // ログイン
            await signIn(accountEntry.email, accountEntry.password)
            if (isSsoLink && sysCtrl.ssoEmailCertificationFlag === yesNo.no) {
              if (sysCtrl.phoneCertificationFlag  === yesNo.no) {
                dispatch(notifyMessage(translate('accountCreateConfirmation.success.accountRegist')))

              } else {
                // 電話番号認証開始
                await verifyTel()
                dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
              }
            } else {
              // メールアドレス認証開始
              await verifyEmail()
            }

            await dispatch(detectLoginStatus())

            dispatch(clearAccountCreate())
            history.push(accountCreateCompletionUrl.url())
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [dispatch, errorHandle, accountEntry, history, sysCtrl])

  return {
    accountEntry,
    userAccountResultCode,
    register,
  }
}

const postUserAccount = (entry: AccountCreateEntry, isSsoLink: boolean) => {
  return executePostUserAccount({
    name: entry.name,
    kana: entry.kana,
    tel: entry.tel,
    email: entry.email,
    ...(isSsoLink && entry.ssoLinkedParams?.id && { ssoUserId: entry.ssoLinkedParams.id }),
    password: entry.password,
    postalCode: entry.postalCode,
    address1: entry.address1,
    address2: entry.address2,
    buildingNameRoomNumber: blankToNull(entry.buildingNameRoomNumber),
    residenceCategory: entry.residenceCategory,
    relationship: blankToNull(entry.relationship),
    children: entry.children.map((child) => {
      return {
        name: child.name,
        kana: child.kana,
        birthday: toApiYmd(child.birthday),
        gender: child.gender,
        maternalHandbookNo: child.maternalHandbookNo,
      }
    }),
  })
}
