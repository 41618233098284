import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { signOut } from '../../utils/authUtil'
import { loginUrl } from './constant/appUrl'
import { useErrorHandle } from './error/errorHandler'
import { reset, showLoading } from './store/slices/application'
import { useOperationLog } from './operationLog'
import { OperationId } from './constant/operationLog'

export const useLogoutActions = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const errorHandle = useErrorHandle()
  const { addOperationLog } = useOperationLog()

  /**
   * ログアウトしてログイン画面へ移動。
   * 未ログイン状態でsignOutを呼び出しても特に何もしない為
   * ログイン・ログアウト共通で利用
   */
  const logout = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000024 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          await signOut()
          dispatch(reset({ isKeepAuthority: false }))
          history.push(loginUrl.url())
        })
      )
    )
  }, [dispatch, history, errorHandle, addOperationLog])

  return { logout }
}
