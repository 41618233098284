import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getNow } from '../../utils/dateUtil'
import { errorUrls, rootUrl } from '../common/constant/appUrl'
import { maintenanceMessagePath } from '../common/constant/processEnv'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'

interface LocationState {
  /** メンテナンスメッセージが無ければHOMEへ遷移 */
  isHomeTransition?: boolean
}

interface MaintenanceMessage {
  title?: string
  maintenanceDatetimeTitle?: string
  maintenanceDatetime: string
  message?: string
}

/** メンテナンスメッセージ再取得間隔(ms) */
const pollingTime = 10 * 60 * 1000

const isMaintenanceMessage = (obj: any): obj is MaintenanceMessage =>
  obj != null && 'maintenanceDatetime' in obj && typeof obj.maintenanceDatetime === 'string'

export const useAction = () => {
  const history = useHistory<LocationState | undefined>()
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()

  const [identifier, setIdentifier] = useState<Date>(getNow())
  const [maintenanceMessage, setmaintenanceMessage] = useState<MaintenanceMessage>()

  const isHomeTransition = !!history.location.state?.isHomeTransition

  const goUnknownError = useCallback(() => {
    history.replace(errorUrls.unknown.url())
  }, [])
  const goHome = useCallback(() => {
    // クライアントコードを最新化させるためにリロードを伴った遷移をさせる
    window.location.href = rootUrl.url()
  }, [])

  const loadMessage = useCallback(async (messageUrl: string, isHomeTransition: boolean) => {
    try {
      // キャッシュするとS3を変更しても取得しにいかない為 no-cache を付ける
      const response = await fetch(messageUrl, { cache: 'no-cache' })
      const messageObj = await response.json()
      if (isMaintenanceMessage(messageObj)) {
        setmaintenanceMessage(messageObj)
      }
    } catch (e) {
      // メンテナンスメッセージファイルが配置されていない場合は、
      // メンテナンス開始されていない状態とみなす
      console.error('メンテナンスメッセージ取得失敗', { e, isHomeTransition })
      if (isHomeTransition) {
        // メンテナンスメッセージ再取得で
        // メンテナンスメッセージファイルが無ければメンテナンス解除とし
        // HOMEへ遷移させる
        goHome()
      } else {
        // メンテナンスメッセージ再取得以外で
        // メンテナンスメッセージファイルが無ければ予期せぬエラーなので
        // システムエラーページへ遷移させる
        goUnknownError()
      }
    }
  }, [])

  useEffect(() => {
    const messageUrl = maintenanceMessagePath
    if (!messageUrl) {
      console.error('メンテナンスメッセージパス設定無し')
      goUnknownError()
      return
    }
    let timeoutId: number | undefined
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          if (!messageUrl) {
            // 型ガード
            return
          }
          await loadMessage(messageUrl, isHomeTransition)
          timeoutId = window.setTimeout(() => setIdentifier(getNow()), pollingTime)
        }),
        isHiddenMain: false,
      })
    )
    return () => {
      clearTimeout(timeoutId)
    }
  }, [identifier])

  useEffect(() => {
    history.replace({ ...history.location, state: { isHomeTransition: true } })
  }, [])

  return {
    maintenanceMessage,
  }
}
