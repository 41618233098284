import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePutUser } from '../../dataAccess/webApi/dao/userDao'
import { translate } from '../../i18n'
import { blankToNull } from '../../utils/stringUtil'
import { memberUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import {
  MemberModifyUserEntry,
  clearMemberModify,
  selectMemberModifyUpdateDatetime,
  selectMemberModifyUserEntry,
} from '../common/store/slices/memberModify'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addOperationLog } = useOperationLog()
  const [usersResultCode, setUsersResultCode] = useState<number>()
  // ログイン必須の画面なので必ずNonNullable
  const modifyEntry = useSelector(selectMemberModifyUserEntry)
  const updateDatetime = useSelector(selectMemberModifyUpdateDatetime)

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const decide = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000037, accessData: [{ userIdRegFlag: yesNo.yes }] })

    dispatch(
      showLoading({
        process: errorHandle(async () => {
          // entry, updateDatetimeは必ず設定されている
          if (modifyEntry && updateDatetime) {
            const response = await putUser(modifyEntry, updateDatetime)
            if (response.resultCode) {
              // 登録失敗時
              setUsersResultCode(response.resultCode)
            } else {
              dispatch(notifyMessage(translate('memberModifyConfirmationUser.success.completionMessage')))
              history.push(memberUrl.url())
              dispatch(clearMemberModify())
            }
          }
        }),
        isHiddenMain: false,
      })
    )
  }, [addOperationLog, dispatch, errorHandle, history, modifyEntry, updateDatetime])

  return {
    user: modifyEntry && toUser(modifyEntry),
    usersResultCode,
    decide,
  }
}

const toUser = (entry: MemberModifyUserEntry) => {
  const emergencyContacts = [
    {
      name: entry.emergencyContactName1,
      kana: entry.emergencyContactKana1,
      relationship: entry.emergencyContactRelationship1,
      company: entry.emergencyContactCompany1,
      tel: entry.emergencyContactTel1,
      email: entry.emergencyContactEmail1,
    },
    {
      name: entry.emergencyContactName2,
      kana: entry.emergencyContactKana2,
      relationship: entry.emergencyContactRelationship2,
      company: entry.emergencyContactCompany2,
      tel: entry.emergencyContactTel2,
      email: entry.emergencyContactEmail2,
    },
    {
      name: entry.emergencyContactName3,
      kana: entry.emergencyContactKana3,
      relationship: entry.emergencyContactRelationship3,
      company: entry.emergencyContactCompany3,
      tel: entry.emergencyContactTel3,
      email: entry.emergencyContactEmail3,
    },
  ]

  const { name, kana, postalCode, address1, address2, buildingNameRoomNumber, relationship, residenceCategory } = entry
  return {
    name,
    kana,
    postalCode,
    address1,
    address2,
    buildingNameRoomNumber,
    residenceCategory,
    relationship,

    emergencyContacts,
  }
}

const putUser = (entry: MemberModifyUserEntry, updateDatetime: string) => {
  return executePutUser({
    name: entry.name,
    kana: entry.kana,
    postalCode: entry.postalCode,
    address1: entry.address1,
    address2: entry.address2,
    buildingNameRoomNumber: blankToNull(entry.buildingNameRoomNumber),
    residenceCategory: entry.residenceCategory,
    relationship: blankToNull(entry.relationship),

    emergencyContactName1: blankToNull(entry.emergencyContactName1),
    emergencyContactKana1: blankToNull(entry.emergencyContactKana1),
    emergencyContactRelationship1: blankToNull(entry.emergencyContactRelationship1),
    emergencyContactCompany1: blankToNull(entry.emergencyContactCompany1),
    emergencyContactTel1: blankToNull(entry.emergencyContactTel1),
    emergencyContactEmail1: blankToNull(entry.emergencyContactEmail1),

    emergencyContactName2: blankToNull(entry.emergencyContactName2),
    emergencyContactKana2: blankToNull(entry.emergencyContactKana2),
    emergencyContactRelationship2: blankToNull(entry.emergencyContactRelationship2),
    emergencyContactCompany2: blankToNull(entry.emergencyContactCompany2),
    emergencyContactTel2: blankToNull(entry.emergencyContactTel2),
    emergencyContactEmail2: blankToNull(entry.emergencyContactEmail2),

    emergencyContactName3: blankToNull(entry.emergencyContactName3),
    emergencyContactKana3: blankToNull(entry.emergencyContactKana3),
    emergencyContactRelationship3: blankToNull(entry.emergencyContactRelationship3),
    emergencyContactCompany3: blankToNull(entry.emergencyContactCompany3),
    emergencyContactTel3: blankToNull(entry.emergencyContactTel3),
    emergencyContactEmail3: blankToNull(entry.emergencyContactEmail3),

    updateDatetime,
  })
}
