import { CardActionArea, styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface CardProps {
  /** カードのコンテンツ(カードの内に表示する内容) */
  children: ReactNode
  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string
  /** カードをクリックしたときに呼び出される関数 */
  onClick?: () => void
}

const Root = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.font.sizeM,
  fontWeight: theme.typography.fontWeightBold,
  borderRadius: 4,
  backgroundColor: theme.palette.white.main,
  border: `1px solid ${theme.palette.secondary.main}`,
  width: '100%',
  padding: '15px',
}))

/** カード型枠を表示するコントロール */
export const Card = (props: CardProps) => {
  const content = <Root className={props.className}>{props.children}</Root>
  return props.onClick ? <CardActionArea onClick={props.onClick}>{content}</CardActionArea> : <>{content}</>
}
