import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { getAuthenticatedUser } from '../../../../utils/authUtil'

interface Userinformation {
  loginId: string
  verifications: {
    tel: boolean
    email: boolean
  }
}

interface AuthorityState {
  isLoggedIn: boolean
  user?: Userinformation
}

const initialState: AuthorityState = {
  isLoggedIn: false,
}

const sliceName = 'authority'

export const detectLoginStatus = createAsyncThunk(`${sliceName}/detectLoginStatus`, async (arg, thunkAPI) => {
  try {
    const user = await getAuthenticatedUser(true)
    thunkAPI.dispatch(
      setLogin({
        loginId: user.attributes.email,
        verifications: { tel: !!user.attributes.phone_number_verified, email: !!user.attributes.email_verified },
      })
    )
  } catch (e) {
    console.error(e)
    thunkAPI.dispatch(setLogin())
  }
})

export const authoritySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<Userinformation | undefined>) => {
      state.isLoggedIn = !!action.payload
      state.user = action.payload
    },
  },
})

export const { setLogin } = authoritySlice.actions
export const selectIsLoggedIn = (state: RootState) => state.authority.isLoggedIn
export const selectIsVerifiedTel = (state: RootState) => !!state.authority.user?.verifications.tel
export const selectIsVerifiedEmail = (state: RootState) => !!state.authority.user?.verifications.email
export const selectIsVerifiedAll = (state: RootState) =>
  !!state.authority.user?.verifications.tel && !!state.authority.user?.verifications.email
export const selectLoginUser = (state: RootState) => state.authority.user
export default authoritySlice.reducer
