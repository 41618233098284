import { Grid, Stack } from '@mui/material'
import { ReactNode } from 'react'
import { getGenderLabel, getYesNoLabel, getResidenceCategoryLabel } from '../../containers/common/codeMaster'
import { interviewReservationStep } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/interviewReservationConfirmation/interviewReservationConfirmationService'
import { translate } from '../../i18n'
import { formatYmd } from '../../utils/dateUtil'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { ElevatedCard } from '../components/common/elevatedCard'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { GContainer, GItem, LabelGItemBase, RowSpacerGItem, ValueGItemBase } from '../components/common/grids'
import { StepBar } from '../components/common/stepBar'
import { TransitionCheck } from '../components/common/transitionCheck'

const LabelGItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={5}>{props.children}</LabelGItemBase>
const ValueGItem = (props: { children: ReactNode }) => <ValueGItemBase xs={7}>{props.children}</ValueGItemBase>

export const InterviewReservationConfirmation = () => {
  const { reserveEntry, facilityName, steps, interviewResultCode, decide } = useAction()

  if (reserveEntry == null) {
    return <TransitionCheck check={false} />
  }
  if (facilityName == null) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <div>
              <StepBar activeStepValue={interviewReservationStep.step2} stepValueLabels={steps} />
            </div>
            <Stack spacing={2}>
              <GContainer>
                <LabelGItem>{translate('interviewReservationConfirmation.label.facilityName')}</LabelGItem>
                <ValueGItem>{facilityName}</ValueGItem>
                <RowSpacerGItem />

                <LabelGItem>{translate('interviewReservationConfirmation.label.parentName')}</LabelGItem>
                <ValueGItem>{reserveEntry.parentName}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.parentKana')}</LabelGItem>
                <ValueGItem>{reserveEntry.parentKana}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.postalCode')}</LabelGItem>
                <ValueGItem>{reserveEntry.postalCode}</ValueGItem>
                <LabelGItem>
                  {translate('interviewReservationConfirmation.label.prefecturesAndMunicipalities')}
                </LabelGItem>
                <ValueGItem>{reserveEntry.address1}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.address')}</LabelGItem>
                <ValueGItem>{reserveEntry.address2}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.buildingNameRoomNumber')}</LabelGItem>
                <ValueGItem>{reserveEntry.buildingNameRoomNumber}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.residenceCategory')}</LabelGItem>
                <ValueGItem>{getResidenceCategoryLabel(reserveEntry.residenceCategory)}</ValueGItem>
                <LabelGItem>{translate('interviewReservationConfirmation.label.relationship')}</LabelGItem>
                <ValueGItem>{reserveEntry.relationship}</ValueGItem>
              </GContainer>

              <GContainer rowSpacing={1}>
                <GItem xs={12}>
                  <ElevatedCard title={translate('interviewReservationConfirmation.title.child')}>
                    <GContainer>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.childName')}</LabelGItem>
                      <ValueGItem>{reserveEntry.childName}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.childKana')}</LabelGItem>
                      <ValueGItem>{reserveEntry.childKana}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.childBirthday')}</LabelGItem>
                      <ValueGItem>{formatYmd(reserveEntry.childBirthday)}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.childGender')}</LabelGItem>
                      <ValueGItem>{getGenderLabel(reserveEntry.childGender)}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.childAllergyFlag')}</LabelGItem>
                      <ValueGItem>{getYesNoLabel(reserveEntry.childAllergyFlag)}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.childAllergyContent')}</LabelGItem>
                      <ValueGItem>{reserveEntry.childAllergy}</ValueGItem>
                      <LabelGItem>
                        {translate('interviewReservationConfirmation.label.childMedicalHistoryFlag')}
                      </LabelGItem>
                      <ValueGItem>{getYesNoLabel(reserveEntry.childMedicalHistoryFlag)}</ValueGItem>
                      <LabelGItem>
                        {translate('interviewReservationConfirmation.label.childMedicalHistoryContent')}
                      </LabelGItem>
                      <ValueGItem>{reserveEntry.childMedicalHistory}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.maternalHandbookNo')}</LabelGItem>
                      <ValueGItem>{reserveEntry.maternalHandbookNo}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.note')}</LabelGItem>
                      <ValueGItem>{reserveEntry.note}</ValueGItem>
                      <LabelGItem>{translate('interviewReservationConfirmation.label.facilityNumber')}</LabelGItem>
                      <ValueGItem>{reserveEntry.facilityNumber}</ValueGItem>
                    </GContainer>
                  </ElevatedCard>
                </GItem>
              </GContainer>

              {!!interviewResultCode && (
                <ErrorMessageCard
                  messages={[
                    translate(`interviewReservationConfirmation.error.interviewResultCode${interviewResultCode}`),
                  ]}
                />
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedWarningL fullWidth onClick={decide}>
          {translate('interviewReservationConfirmation.button.decide')}
        </ButtonOutlinedWarningL>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
