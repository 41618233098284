// 画面共通のフッター部
//

import { styled } from '@mui/material'
import { memo, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  contactUrl,
  faqUrl,
  manualUrl,
  termsOfServiceUrl,
  webAccessibilityPolicyUrl,
} from '../../../containers/common/constant/appUrl'
import { handingOfPersonalDataUrl } from '../../../containers/common/constant/externalUrl'
import { setIsIntersectingFooter } from '../../../containers/common/store/slices/application'
import { translate } from '../../../i18n'
import { Link } from './link'
import { SystemDatetimeLabel } from './systemDatetimeLabel'

const Root = styled('footer')(({ theme }) => ({
  position: 'relative',
  fontSize: theme.typography.font.sizeS,
  padding: '20px 30px',
  height: theme.typography.pxToRem(theme.appFooter.height('xs')),
  [theme.breakpoints.up('sm')]: {
    padding: '16px 30px',
    height: theme.typography.pxToRem(theme.appFooter.height('sm')),
  },
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}))
const LinkBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  flexWrap: 'wrap',
  height: '100%',
  '& .MuiLink-root': {
    color: theme.palette.primary.contrastText,
    whiteSpace: 'nowrap',
    padding: '3px 0',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    '& .MuiLink-root': {
      padding: '0 15px',
    },
  },
}))
const Separater = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.white.main}`,
}))
const SysDateInfo = styled(SystemDatetimeLabel)({
  position: 'absolute',
  left: 0,
  top: 0,
})

/** 画面共通フッター */
export const AppFooter = memo(function AppFooter() {
  const dispatch = useDispatch()
  const footerRef = useRef<HTMLDivElement>(null)
  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    const currentOvserver = new IntersectionObserver((entries) => {
      const target = entries[0]
      dispatch(setIsIntersectingFooter(target.isIntersecting))
    })
    observer.current = currentOvserver
    return () => currentOvserver.disconnect()
  }, [])
  useEffect(() => {
    const currentOvserver = observer.current
    const footerElement = footerRef.current
    if (footerElement && currentOvserver) {
      currentOvserver.observe(footerElement)
    }
  }, [])

  return (
    <Root ref={footerRef}>
      <LinkBox>
        <Link to={termsOfServiceUrl.url()} underline="none">
          {translate('system.appFooter.termsOfService')}
        </Link>
        <Separater />
        <Link href={handingOfPersonalDataUrl} underline="none" target="_blank">
          {translate('system.appFooter.handingOfPersonalData')}
        </Link>
        <Separater />
        <Link to={manualUrl.url()} underline="none">
          {translate('system.appFooter.manual')}
        </Link>
        <Separater />
        <Link to={faqUrl.url()} underline="none">
          {translate('system.appFooter.faq')}
        </Link>
        <Separater />
        <Link to={contactUrl.url()} underline="none">
          {translate('system.appFooter.contact')}
        </Link>
        <Separater />
        <Link to={webAccessibilityPolicyUrl.url()} underline="none">
          {translate('system.appFooter.webAccessibility')}
        </Link>
      </LinkBox>
      <SysDateInfo />
    </Root>
  )
})
