import { executeGet } from '../common/apiCaller'
import {
  GetFacilityReservationsWeekCitizenDto,
  GetFacilityReservationsWeekCitizenInputDto,
} from '../dto/facilityReservationsWeekCitizenDto'

const apiName = 'citizen'
const apiPath = '/facilities'

export const executeGetFacilityReservationsWeekCitizen = (
  id: string,
  input?: GetFacilityReservationsWeekCitizenInputDto
) => {
  return executeGet<GetFacilityReservationsWeekCitizenDto[]>(apiName, `${apiPath}/${id}/reservations/week`, input)
}
