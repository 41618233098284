import { styled } from '@mui/system'
import { ButtonOutlinedBase } from './buttonOutlinedBase'

/**
 * whiteカラーのボタンコントロールSサイズ。
 */
export const ButtonOutlinedS = styled(ButtonOutlinedBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeM,
  padding: '0 10px',
  borderWidth: 3,
  borderRadius: 8,
}))
