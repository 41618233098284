import { Stack, styled } from '@mui/material'
import React, { ReactNode } from 'react'
import { BottomActionContainer } from './bottomActionContainer'
import { FlexboxCenter } from './flexboxes'

interface BottomButtonProps {
  children: ReactNode
}

const ButtonBox = styled(FlexboxCenter)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '19rem',
  },
}))

/**
 * メインコンテンツエリア下部に表示する「登録」「戻る」などのアクションコントロールを
 * 画面幅に応じて適切に配置するコントロール。
 * PC以外のレイアウトでは縦
 * PCレイアウトでは横かつ逆順 ※左側に「戻る」「中止」を配置したいため
 */
export const BottomButtonStack = (props: BottomButtonProps) => {
  /** 配列のコピーを作成。要素以外は除去 */
  const buttons = (Array.isArray(props.children) ? [...props.children] : [props.children]).filter((v) =>
    React.isValidElement(v)
  )
  return (
    <Stack spacing={{ xs: 1, md: 2 }} direction={{ xs: 'column', md: 'row-reverse' }} justifyContent="center">
      {buttons.map((button, index) => (
        <ButtonBox key={index}>{button}</ButtonBox>
      ))}
    </Stack>
  )
}

/**
 * メインコンテンツエリア下部に表示するコントロール。
 * BottomButtonStack含む
 */
export const BottomButtonGroup = (props: BottomButtonProps) => {
  return (
    <BottomActionContainer>
      <BottomButtonStack>{props.children}</BottomButtonStack>
    </BottomActionContainer>
  )
}
