import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { executeGetInterview } from '../../dataAccess/webApi/dao/interviewsDao'
import { fromApiYmd, fromApiYmdHms } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'

interface UrlParams {
  interviewNo: string
}

interface InterviewDetail {
  facilityName: string
  interviewDate?: Date
  interviewStartDatetime?: Date
  interviewEndDatetime?: Date
  status: string
  parentName: string
  parentKana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber?: string
  residenceCategory: string
  relationship?: string
  tel: string
  email: string
  childName: string
  childKana: string
  childGender: string
  childBirthday: Date
  childMedicalHistoryFlag: string
  childMedicalHistory?: string
  childAllergyFlag: string
  childAllergy?: string
  maternalHandbookNo?: string
  note?: string
  reservationDatetime: Date
  reservationChangeDatetime?: Date
  reservationCancelDatetime?: Date
  permitFlag: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const { interviewNo } = useParams<UrlParams>()
  const { addOperationLog, attachAccessData } = useOperationLog()

  const [interviewDetail, setInterviewDetail] = useState<InterviewDetail>()

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          const result = await getInterviewDetail(interviewNo)
          attachAccessData({ accessData: [{ userIdRegFlag: yesNo.yes, childId: result.childId, interviewNo }] })

          setInterviewDetail(result)
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    interviewDetail,
  }
}

const getInterviewDetail = async (interviewNo: string) => {
  const interview = await executeGetInterview(interviewNo)
  const {
    interviewDate,
    interviewStartDatetime,
    interviewEndDatetime,
    childBirthday,
    reservationDatetime,
    reservationChangeDatetime,
    reservationCancelDatetime,
    ...through
  } = interview.result
  return nullPropsToUndefined({
    ...through,
    interviewDate: fromApiYmd(interviewDate),
    interviewStartDatetime: fromApiYmdHms(interviewStartDatetime),
    interviewEndDatetime: fromApiYmdHms(interviewEndDatetime),
    childBirthday: fromApiYmd(childBirthday),
    reservationDatetime: fromApiYmdHms(reservationDatetime),
    reservationChangeDatetime: fromApiYmdHms(reservationChangeDatetime),
    reservationCancelDatetime: fromApiYmdHms(reservationCancelDatetime),
  })
}
