import { executeGetNoResultError } from '../common/apiCaller'
import { GetControlDto, GetGuideTextDto } from '../dto/controlDto'

const apiName = 'public'
const apiPath = '/control'

export const executeGetControl = () => {
  return executeGetNoResultError<GetControlDto>(apiName, apiPath)
}

export const executeGetGuideText = (id: number) => {
  return executeGetNoResultError<GetGuideTextDto>(apiName, `${apiPath}/guide-text/${id}`)
}
