import { styled } from '@mui/material'
import React, { CSSProperties, forwardRef, memo, ReactNode, Ref, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import {
  clearActiveFacility,
  Facility,
  selectResultActiveFacilityId,
} from '../../../containers/common/store/slices/facilitySearch'
import { FacilityCard } from '../common/facilityCard'
import { GContainer, GItem } from '../common/grids'
import { NumberPinIcon } from '../common/icons/numberPin'

interface ResultBoxProps {
  isShownPin: boolean
  facilities: Facility[]
  onClickFacilityName: (facilityId?: string) => void
  mapMinHeight: number
}

const VirtuosoGContainer = styled(GContainer)({
  // Virtuosoコントロール配下でマイナスマージンを行うとその分下に隙間が出来る。
  // 対応として、Containerのマイナスマージンで1行目のパディングを相殺するのではなく
  // 1行目のパディングを０にする
  marginTop: 0,
  '& > .MuiGrid-item:first-of-type': {
    paddingTop: 0,
  },
})

const scrollToIndex = (virtuoso: VirtuosoHandle, index: number) =>
  virtuoso.scrollToIndex({
    index: index,
    align: 'center',
  })

const VirtuosoComponents = {
  List: forwardRef(function List(
    { style, children }: { style?: CSSProperties; children?: ReactNode },
    listRef: Ref<HTMLDivElement>
  ) {
    return (
      <div style={style}>
        <VirtuosoGContainer ref={listRef} rowSpacing={1}>
          {children}
        </VirtuosoGContainer>
      </div>
    )
  }),

  Item: ({ children, ...props }: { children?: ReactNode }) => {
    return (
      <GItem xs={12} {...props}>
        {children}
      </GItem>
    )
  },
}
interface ContentProps {
  isShownPin: boolean
  facilities: Facility[]
  onClickFacilityName: (facilityId?: string) => void
  mapMinHeight: number
  initialIndex?: number
}
const Content = forwardRef(function Content(
  { facilities, isShownPin, onClickFacilityName, mapMinHeight, initialIndex }: ContentProps,
  ref: Ref<VirtuosoHandle>
) {
  return (
    <Virtuoso
      ref={ref}
      components={VirtuosoComponents}
      useWindowScroll
      // スマホ画面で地図表示から非表示にタブ切り替えをすると地図の高さ分を余分に読み込む必要がある
      // そのため、increaseViewportByで下方向を余分にレンダリング対象とする
      increaseViewportBy={{ bottom: mapMinHeight, top: 5 }}
      {...(initialIndex && {
        initialTopMostItemIndex: {
          index: initialIndex,
          align: 'center',
        },
      })}
      data={facilities}
      itemContent={(index, facility) => {
        return (
          <FacilityCard
            {...(isShownPin && { titleIcon: <NumberPinIcon fontSize="inherit" serialNumber={index + 1} /> })}
            {...facility}
            onClickFacilityName={onClickFacilityName}
          />
        )
      }}
    />
  )
})
const MemoContent = memo(Content)

export const ResultBox = memo(function ResultBox(props: ResultBoxProps) {
  const dispatch = useDispatch()
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const activeFacilityId = useSelector(selectResultActiveFacilityId)
  // activeFacilityId変更でContentが再レンダリングされないように表示時のインデックスを保持
  const [initialIndex] = useState(props.facilities.findIndex((facility) => facility.facilityId === activeFacilityId))

  useEffect(() => {
    if (activeFacilityId) {
      const virtuoso = virtuosoRef.current
      const activeIndex = props.facilities.findIndex((facility) => facility.facilityId === activeFacilityId)
      if (virtuoso) {
        scrollToIndex(virtuoso, activeIndex)
        // 表示対象の要素がレンダリングされて高さが確定すると位置が少しずれるので再度スクロールさせる
        setTimeout(() => scrollToIndex(virtuoso, activeIndex), 0)
        dispatch(clearActiveFacility())
      }
    }
  }, [activeFacilityId])
  return <MemoContent {...props} {...(initialIndex !== -1 && { initialIndex })} ref={virtuosoRef} />
})
