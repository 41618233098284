import { Grid, GridSize, styled } from '@mui/material'
import React, { ReactNode } from 'react'
import { ColonSeparator, InlineFlexbox } from './flexboxes'

/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const FlexItem = styled(Grid)({
  display: 'flex',
})

/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const LeftItem = styled(FlexItem)({
  justifyContent: 'start',
})
/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const CenterItem = styled(FlexItem)({
  justifyContent: 'center',
})
/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const RightItem = styled(FlexItem)({
  justifyContent: 'flex-end',
})

/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const TopItem = styled(FlexItem)({
  alignItems: 'start',
})
/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const MiddleItem = styled(FlexItem)({
  alignItems: 'center',
})
/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const BottomItem = styled(FlexItem)({
  alignItems: 'flex-end',
})

/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const CenterMiddleItem = styled(CenterItem)({
  alignItems: 'center',
})

const WrapValue = styled('div')({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
})

interface LabelValueItemProps {
  children: ReactNode
  xs?: boolean | GridSize
  sm?: boolean | GridSize
  md?: boolean | GridSize
}

/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const LabelItemBase = (props: LabelValueItemProps) => {
  const { children, ...through } = props
  return (
    <Grid item {...through}>
      {children}
    </Grid>
  )
}

/** @deprecated grids.tsx に定義されたコントロールへ移行してください */
export const ValueItemBase = (props: LabelValueItemProps) => {
  const { children, ...through } = props
  return (
    <Grid item {...through}>
      <InlineFlexbox>
        <ColonSeparator />
        <WrapValue>{children}</WrapValue>
      </InlineFlexbox>
    </Grid>
  )
}
