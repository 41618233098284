// 施設情報に関する共通処理を定義
//

import { executeGetFacility } from '../../dataAccess/webApi/dao/facilitiesDao'
import { ElapsedMillisecond, toApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'

/**
 * 指定した施設IDの施設情報を返す。
 * 対象日を省略した場合は、サーバーの現在日で適用期間のマッチが行われる。
 *
 * @param facilityId 施設ID
 * @param targetDate 適用期間のマッチに使用する日付
 * @returns 施設情報
 */
export const getFacility = async (facilityId: string, targetDate?: Date | ElapsedMillisecond) => {
  const apiResponse = await executeGetFacility(
    facilityId,
    ...(targetDate ? [{ targetDate: toApiYmd(targetDate) }] : [])
  )
  const result = nullPropsToUndefined(apiResponse.result)
  // 画像URLは穴あきで設定される可能性があるため
  result.imageUrls = result.imageUrls.filter((url) => url)
  return result
}
