import { Stack, styled } from '@mui/material'
import React, { Fragment, ReactNode, useCallback } from 'react'
import { getReductionsStatusLabel } from '../../../containers/common/codeMaster'
import { translate } from '../../../i18n'
import { formatYmd } from '../../../utils/dateUtil'
import { ButtonOutlinedS } from '../common/buttons/buttonOutlinedS'
import { Dialog } from '../common/dialog'
import { GContainer, LabelGItemBase, ValueGItemBase } from '../common/grids'
import { SubTitle } from '../common/subTitle'

interface ReductionsDialogProps {
  isOpen: boolean
  onClose: () => void
  reductionName: string
  reductionInfos?: {
    status: string
    reductionStartDate?: Date
    reductionEndDate?: Date
  }[]
}

const LabeGlItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={3}>{props.children}</LabelGItemBase>
const ValueGItem = (props: { children: ReactNode }) => <ValueGItemBase xs={9}>{props.children}</ValueGItemBase>

const AttributeGContainer = styled(GContainer)({
  padding: '0 0 0 2rem',
})

export const ReductionsDialog = (props: ReductionsDialogProps) => {
  const close = useCallback(() => {
    props.onClose()
  }, [])

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={close}
      buttons={<ButtonOutlinedS onClick={close}>{translate('system.button.close')}</ButtonOutlinedS>}
      maxWidth="xs"
      fullWidth
    >
      <Stack spacing={2}>
        <SubTitle title={props.reductionName} />
        <AttributeGContainer>
          {props.reductionInfos &&
            props.reductionInfos.map((v, index) => {
              return (
                <Fragment key={index}>
                  <LabeGlItem>{getReductionsStatusLabel(v.status)}</LabeGlItem>
                  <ValueGItem>
                    {(v.reductionStartDate || v.reductionEndDate) && (
                      <>
                        {v.reductionStartDate && formatYmd(v.reductionStartDate)}～
                        {v.reductionEndDate && formatYmd(v.reductionEndDate)}
                      </>
                    )}
                  </ValueGItem>
                </Fragment>
              )
            })}
        </AttributeGContainer>
      </Stack>
    </Dialog>
  )
}
