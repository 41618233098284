import { SelectChangeEvent } from '@mui/material'
import { differenceInMinutes } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { reservationStatus, reservableUnitPatterMinutes, yesNo } from '../../containers/common/constant/classification'
import {
  FacilityReserveEntry,
  selectFacilityReserveEntry,
  setFacilityReserve,
  setReserveModifyUpdateDatetime,
} from '../../containers/common/store/slices/facilityReserve'
import { NoResultError } from '../../dataAccess/webApi/common/apiCaller'
import { executeGetChildren } from '../../dataAccess/webApi/dao/childrenDao'
import { executeGetFacilityReservationsDays } from '../../dataAccess/webApi/dao/facilityReservationsDaysDao'
import { executeGetFacilityReservationsWeekCitizen } from '../../dataAccess/webApi/dao/facilityReservationsWeekCitizenDao'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetFacilityReservationsDaysDto } from '../../dataAccess/webApi/dto/facilityReservationsDaysDto'
import { GetFacilityReservationsWeekCitizenDto } from '../../dataAccess/webApi/dto/facilityReservationsWeekCitizenDto'
import { translate } from '../../i18n'
import { DateRange, ElapsedMillisecond, getNowTrimedTime, isValidDate, toApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { promiseAllConcurrency } from '../../utils/promiseUtil'
import { fromNumber } from '../../utils/stringUtil'
import { castNonNullable, NullPropsToUndefinedType } from '../../utils/typeUtil'
import { facilityReservationFormUrl } from '../common/constant/appUrl'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getReservation, Reservation } from '../common/reservation'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'

interface InsertUrlParams {
  facilityId: string
  childId: string
}
interface UpdateUrlParams {
  reservationNo: string
}
type UrlParams = InsertUrlParams | UpdateUrlParams

interface Inputs {
  /** 利用希望日時 */
  usageDesiredDatetimes: {
    value: {
      /** 時間の基準となる日付 */
      baseDateOfTime: Date
      /** 日時範囲 */
      range: DateRange
      /** 空き状況ステータス */
      status: string
    }
  }[]
}

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep?: boolean
  activeChildId?: string
  activeDate: ElapsedMillisecond
}

interface PageState {
  activeChildId?: string
  activeDate: Date
  facilityId?: string
  /** 施設情報 */
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  /** 変更前予約情報 */
  reservation?: Reservation

  /** 選択対象お子さまリスト */
  childs: { value: string; label: string }[]
  /** 空き状況（１週間分） */
  availabilities?: GetFacilityReservationsWeekCitizenDto[]
  /** 空き状況（選択した利用日分） */
  initialValidationAvailabilities?: GetFacilityReservationsDaysDto[]
}

/** 最大並列実行数 */
const MAX_WORKER_COUNT = 4

const isInsertParams = (urlParams: UrlParams): urlParams is InsertUrlParams => {
  return 'facilityId' in urlParams
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const urlParams = useParams<UrlParams>()
  const { addOperationLog, attachAccessData } = useOperationLog()

  const locationState = history.location.state
  const reserveEntry = useSelector(selectFacilityReserveEntry)

  const [state, setState] = useState<PageState>({
    ...(locationState?.isKeep
      ? {
          activeChildId: locationState.activeChildId,
          activeDate: new Date(locationState.activeDate),
        }
      : {
          activeDate: locationState?.activeDate ? new Date(locationState.activeDate) : getNowTrimedTime(),
        }),
    childs: [],
  })

  /** 変更前の日時 */
  const previousRange = useMemo(() => {
    if (state.reservation) {
      return {
        range: {
          from: state.reservation.useFromDatetime,
          to: state.reservation.useToDatetime,
        },
        baseDateOfTime: state.reservation.usageDate,
        isWaiting: state.reservation.status === reservationStatus.wait,
      }
    }
  }, [state.reservation])

  const notifyDateFetchErrorMessage = useCallback(
    () => dispatch(notifyMessage(translate('facilityReservationSelection.error.noReservationReceptionSetting'))),
    []
  )

  const formMethods = useForm<Inputs>({
    defaultValues: {
      ...(locationState?.isKeep &&
        reserveEntry && {
          usageDesiredDatetimes: reserveEntry.usageDatetimes.map((v) => ({
            value: {
              /** 時間の基準となる日付 */
              baseDateOfTime: new Date(v.usageDate),
              /** 日時範囲 */
              range: { from: new Date(v.useFromDatetime), to: new Date(v.useToDatetime) },
              /** 空き状況ステータス */
              status: v.status,
            },
          })),
        }),
    },
  })

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          if (isInsertParams(urlParams)) {
            // 新規予約時
            const searchChildId = state.activeChildId ?? urlParams.childId
            attachAccessData({
              accessData: [{ userIdRegFlag: yesNo.yes, childId: searchChildId }],
            })

            const initialSelectedUsageApiYmds = getInitialSelectedUsageApiYmds(locationState?.isKeep, reserveEntry)
            const [facilityWithChilds, initialValidationAvailabilities] = await Promise.all([
              getFacilityAvailabilitiesWithChilds(urlParams.facilityId, searchChildId, state.activeDate, null),
              getAvailabilitiesByDays(urlParams.facilityId, searchChildId, initialSelectedUsageApiYmds, null),
            ])
            if (facilityWithChilds) {
              /** 正常 */
              setState({ ...facilityWithChilds, initialValidationAvailabilities })
              if (initialValidationAvailabilities?.length) {
                // 初期表示時バリデーション実行
                formMethods.trigger(undefined, { shouldFocus: true })
              }
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          } else {
            // 予約変更時
            const reservation = await getReservation(urlParams.reservationNo)
            attachAccessData({
              accessData: [
                {
                  userIdRegFlag: yesNo.yes,
                  childId: reservation.childId,
                  usageDate: toApiYmd(reservation.usageDate),
                  reservationNo: reservation.reservationNo,
                },
              ],
            })

            const searchDate = locationState?.isKeep ? state.activeDate : reservation.usageDate

            const initialSelectedUsageApiYmds = getInitialSelectedUsageApiYmds(locationState?.isKeep, reserveEntry)
            const availabilitiesReservationNo = getAvailabilitiesReservationNo(reservation)
            const [facilityWithChilds, initialValidationAvailabilities] = await Promise.all([
              getFacilityAvailabilitiesWithChilds(
                reservation.facilityId,
                reservation.childId,
                searchDate,
                availabilitiesReservationNo
              ),
              getAvailabilitiesByDays(
                reservation.facilityId,
                reservation.childId,
                initialSelectedUsageApiYmds,
                availabilitiesReservationNo
              ),
            ])

            if (facilityWithChilds) {
              /** 正常 */
              if (!locationState?.isKeep) {
                // 戻る以外の初期表示時のみ変更前範囲を初期選択
                formMethods.reset({
                  usageDesiredDatetimes: [
                    {
                      value: {
                        /** 時間の基準となる日付 */
                        baseDateOfTime: reservation.usageDate,
                        /** 日時範囲 */
                        range: {
                          from: reservation.useFromDatetime,
                          to: reservation.useToDatetime,
                        },
                        // 空き状況ステータスは、空き状況カレンダーコントロールの初期設定に任せる
                      },
                    },
                  ],
                })
              }
              setState({
                ...facilityWithChilds,
                initialValidationAvailabilities,
                /** 予約変更時は、子どもが確定しているため、こども一覧から対象の子どものみにする */
                childs: facilityWithChilds.childs.filter(({ value }) => value === reservation.childId),
                reservation: reservation,
              })
              if (initialValidationAvailabilities?.length) {
                // 初期表示時バリデーション実行
                formMethods.trigger(undefined, { shouldFocus: true })
              }
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }
        })
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onNextPrevExec = useCallback(
    (baseDate: Date) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const facilityAvailabilities = await getFacilityAvailabilities(
              castNonNullable(state.facilityId),
              castNonNullable(state.activeChildId),
              baseDate,
              getAvailabilitiesReservationNo(state.reservation)
            )
            if (facilityAvailabilities) {
              /** 正常 */
              setState((old) => ({ ...old, ...facilityAvailabilities }))
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [state.facilityId, state.activeChildId, state.reservation]
  )

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const searchChildId = event.target.value
            const availabilities = await getAvailabilities(
              castNonNullable(state.facilityId),
              searchChildId,
              state.activeDate,
              getAvailabilitiesReservationNo(state.reservation)
            )
            if (availabilities) {
              /** 正常 */
              setState((old) => ({ ...old, ...availabilities }))
              /** 画面項目に値をセット */
              formMethods.reset({
                ...formMethods.getValues(),
                usageDesiredDatetimes: [],
              })
            } else {
              /** 異常 */
              notifyDateFetchErrorMessage()
            }
          }),
          isHiddenMain: false,
        })
      )
    },
    [state.facilityId, state.activeDate, state.reservation, addOperationLog]
  )

  const changeDate = useCallback(
    (date: Date | null) => {
      addOperationLog({ operationId: OperationId.OP_00000029 })

      if (isValidDate(date)) {
        onNextPrevExec(date)
      }
    },
    [onNextPrevExec, addOperationLog]
  )

  /**
   * 妥当性検査
   * @param data 入力値
   * @param facilityId 施設ID
   * @returns エラーが存在する場合true
   */
  const validate = useCallback(async (data: Inputs, facilityId: string) => {
    const facilityMap = await getFacilityMapByUsageDateElapsed(
      facilityId,
      new Set(data.usageDesiredDatetimes.map((v) => v.value.baseDateOfTime.getTime()))
    )

    let isError = false
    data.usageDesiredDatetimes.forEach(({ value: { baseDateOfTime, range } }, idx) => {
      const facility = facilityMap.get(baseDateOfTime.getTime())
      const minReservationMinute = facility?.minReservationMinute
      const reservableUnitPatternMinute = facility && reservableUnitPatterMinutes[facility?.reservableUnitPattern]
      if (minReservationMinute != null) {
        // 最低予約時間が設定されていれる場合
        const minute = differenceInMinutes(range.to, range.from)
        if (minute < minReservationMinute) {
          formMethods.setError(`usageDesiredDatetimes.${idx}.value`, {
            message: translate(
              'facilityReservationSelection.error.minReservationMinute',
              fromNumber(minReservationMinute)
            ),
          })
          isError = true
        }
      }
      if (reservableUnitPatternMinute != null) {
        // 予約可能単位が設定されていれる場合
        const minute = differenceInMinutes(range.to, range.from)
        if (minute % reservableUnitPatternMinute !== 0) {
          formMethods.setError(`usageDesiredDatetimes.${idx}.value`, {
            message: translate(
              'facilityReservationSelection.error.reservableUnitPattern',
              fromNumber(reservableUnitPatternMinute)
            ),
          })
          isError = true
        }
      }
    })

    return isError
  }, [])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000030 })

      const facilityId = castNonNullable(state.facilityId)
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            // 妥当性検査
            const isValidateError = await validate(data, facilityId)
            if (isValidateError) {
              return
            }

            dispatch(
              setFacilityReserve({
                facilityId: castNonNullable(state.facilityId),
                childId: castNonNullable(state.activeChildId),
                reservationNo: state.reservation?.reservationNo,

                // 確認しやすくするために利用開始日でソートしておく
                usageDatetimes: data.usageDesiredDatetimes
                  .map((v) => ({
                    usageDate: v.value.baseDateOfTime.getTime(),
                    useFromDatetime: v.value.range.from.getTime(),
                    useToDatetime: v.value.range.to.getTime(),
                    status: v.value.status,
                  }))
                  .sort((a, b) => a.useFromDatetime - b.useFromDatetime),

                ...(state.reservation && {
                  input: nullPropsToUndefined({
                    useReasonCategory: state.reservation.useReasonCategory,
                    lunchFlag: state.reservation.lunchFlag,
                    snackFlag: state.reservation.snackFlag,
                    postponeCancelWaitFlag: state.reservation.postponeCancelWaitFlag,
                    citizenNote: state.reservation.citizenNote,
                  }),
                }),
              })
            )
            if (state.reservation) {
              dispatch(setReserveModifyUpdateDatetime(state.reservation.updateDatetime))
            }

            /** 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管 */
            history.replace({
              ...history.location,
              state: {
                isKeep: true,
                activeChildId: castNonNullable(state.activeChildId),
                activeDate: state.activeDate.getTime(),
              },
            })
            history.push(facilityReservationFormUrl.url())
          }),
          isHiddenMain: false,
        })
      )
    },
    [
      reserveEntry,
      state.facilityId,
      state.activeChildId,
      state.activeDate,
      state.reservation,
      validate,
      addOperationLog,
    ]
  )

  return {
    facilityId: state.facilityId,
    facility: state.facility,
    childs: state.childs,
    activeChildId: state.activeChildId,
    activeDate: state.activeDate,
    availabilities: state.availabilities,
    initialValidationAvailabilities: state.initialValidationAvailabilities,
    previousRange,
    formMethods,
    onSubmit,
    onNextPrevExec,
    changeChild,
    changeDate,
  }
}

const getFacilityMapByUsageDateElapsed = async (facilityId: string, usageDateElapsedSet: Set<ElapsedMillisecond>) => {
  const facilities = await promiseAllConcurrency(
    Array.from(usageDateElapsedSet).map((usageDateElapsed) => async () => {
      const facility = await getFacility(facilityId, new Date(usageDateElapsed))
      return [usageDateElapsed, facility] as const
    }),
    MAX_WORKER_COUNT
  )
  return new Map(facilities)
}

/**
 * 空き状況取得用の受付No取得
 * キャンセル待ち予約の変更時は受付Noを指定して空き状況を取得する為
 * @param reservation
 * @returns
 */
const getAvailabilitiesReservationNo = (reservation: Reservation | undefined) =>
  reservation?.status === reservationStatus.wait ? reservation.reservationNo : null

/**
 * 空き情報を取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @returns 空き情報
 */
const getAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  reservationNo: string | null
) => {
  const availabilitiesResponse = await executeGetFacilityReservationsWeekCitizen(facilityId, {
    childId,
    targetDate: toApiYmd(targetDate),
    reservationNo,
  })
  if (availabilitiesResponse.result.length) {
    return {
      activeChildId: childId,
      activeDate: targetDate,
      facilityId,
      availabilities: availabilitiesResponse.result,
    }
  } else {
    return null
  }
}

/**
 * 施設情報、空き情報を取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @returns 施設情報、空き情報
 */
const getFacilityAvailabilities = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  reservationNo: string | null
) => {
  try {
    const [facility, availabilities] = await Promise.all([
      getFacility(facilityId, targetDate),
      getAvailabilities(facilityId, childId, targetDate, reservationNo),
    ])
    if (availabilities) {
      return {
        ...availabilities,
        facility,
      }
    } else {
      return null
    }
  } catch (e) {
    if (e instanceof NoResultError) {
      return null
    } else {
      throw e
    }
  }
}

/**
 * 施設情報、空き情報、お子さまリストを取得
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetDate 取得基準日
 * @param reservationNo 受付No。空き状況取得でキャンセル待ち予約を変更する際に設定する
 * @returns 施設情報、空き情報、お子さまリスト
 */
const getFacilityAvailabilitiesWithChilds = async (
  facilityId: string,
  childId: string,
  targetDate: Date,
  reservationNo: string | null
) => {
  const [facilityAvailabilities, childrenResponse] = await Promise.all([
    getFacilityAvailabilities(facilityId, childId, targetDate, reservationNo),
    executeGetChildren({ facilityId }),
  ])
  if (facilityAvailabilities && childrenResponse.result.length) {
    return {
      ...facilityAvailabilities,
      childs: childrenResponse.result.map((child) => ({ value: child.childId, label: child.name })),
    }
  } else {
    return null
  }
}

/**
 * 初期選択の年月日を取得する
 *
 * @param isKeep 戻るで初期表示してreduxから状態を復元する際はtrue
 * @param reserveEntry 保存された状態
 * @returns 初期選択の利用日時から取得した年月日(yyyy-MM-dd)配列。※重複除去
 */
const getInitialSelectedUsageApiYmds = (isKeep?: boolean, reserveEntry?: FacilityReserveEntry) => {
  return isKeep && reserveEntry
    ? Array.from(new Set(reserveEntry.usageDatetimes.map((v) => toApiYmd(new Date(v.usageDate)))))
    : []
}

/**
 * 指定日分の空き状況を取得する
 *
 * @param facilityId 施設ID
 * @param childId お子さまID
 * @param targetApiYmds 取得対象年月日(yyyy-MM-ddの配列)
 * @param reservationNo キャンセル待ち予約変更時は対象予約No。それ以外はnull
 * @returns 空き状況
 */
const getAvailabilitiesByDays = async (
  facilityId: string,
  childId: string,
  targetApiYmds: string[],
  reservationNo: string | null
) => {
  let result
  if (targetApiYmds.length) {
    const resp = await executeGetFacilityReservationsDays(facilityId, {
      childId: childId,
      targetDates: targetApiYmds,
      reservationNo,
    })
    result = resp.result
  }
  return result
}
