import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ValueType } from '../../utils/typeUtil'
import { getHttpStatusCodeLabel } from '../common/codeMaster'
import { httpStatusCode } from '../common/constant/classification'
import { reset } from '../common/store/slices/application'
import { detectLoginStatus } from '../common/store/slices/authority'

export const useAction = (statusCode?: ValueType<typeof httpStatusCode>) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(reset({ isKeepAuthority: true }))
    dispatch(detectLoginStatus())
  }, [])
  const statusLabel = getHttpStatusCodeLabel(statusCode)
  const subTitleContent = [statusCode, statusLabel].filter((v) => v).join(' ')

  return {
    subTitleContent,
  }
}
