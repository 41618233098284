import { Chip, Stack, styled, Grid } from '@mui/material'

import { ReactNode } from 'react'

import { useAction } from '../../containers/account/accountService'
import {
  accountEmailChangeUrl,
  accountTelChangeUrl,
  accountUnsubscribeUrl,
  myPageUrl,
  ssoUrl,
  accountUrl,
} from '../../containers/common/constant/appUrl'
import { OperationId } from '../../containers/common/constant/operationLog'

import { yesNo } from '../../containers/common/constant/classification'
import { translate, translateNode } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { GContainer, GItem, GItemContainer, LabelGItemBase, ValueGItemBase } from '../components/common/grids'
import { Link } from '../components/common/link'
import { SubTitle } from '../components/common/subTitle'
import { Dialog } from '../components/common/dialog'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'

const LinkItemBase = styled(GItem)({
  paddingLeft: 4,
})
const VerifiedStatusChipBase = styled(Chip)({ marginLeft: 12 })
const VerifiedStatusChip = (props: { isVerified: boolean }) => {
  const chipProps = props.isVerified
    ? ({
        label: translate('account.text.authenticated'),
        color: 'success',
      } as const)
    : ({
        label: translate('account.text.unauthenticated'),
        color: 'error',
      } as const)

  return <VerifiedStatusChipBase {...chipProps} size="small" />
}

const LinkedStatusChipBase = styled(Chip)({ marginLeft: 12 })
const LinkedStatusChip = (props: { isLinked: boolean }) => {
  const chipProps = props.isLinked
    ? ({
        label: translate('account.text.linked'),
        color: 'success',
      } as const)
    : ({
        label: translate('account.text.unlinked'),
        color: 'error',
      } as const)
  return <LinkedStatusChipBase {...chipProps} size="small" />
}

const LabelGItem = (props: { children?: ReactNode }) => <LabelGItemBase xs={4}>{props.children}</LabelGItemBase>
const ValueGItem = (props: { children: ReactNode }) => <ValueGItemBase xs={6}>{props.children}</ValueGItemBase>
const LinkGItem = (props: { children?: ReactNode }) => <LinkItemBase xs={2}>{props.children}</LinkItemBase>

const DialogContentText = styled('div')({
  whiteSpace: 'pre-line',
  textAlign: 'center',
})

export const Account = () => {
  const { sysCtrl, isVerifiedTel, isVerifiedEmail, isVerifiedAll, account, logout, ssoUseFlag, ssoUseName, unlink, dialog } = useAction()

  if (account == null) {
    return null
  }
  
  return (
    <Stack spacing={4}>
      <Dialog
        isOpen={dialog.isOpen}
        onClose={dialog.onClose}
        buttons={
          <ButtonOutlinedS onClick={dialog.onClose}>{translate('system.button.close')}</ButtonOutlinedS>
        }
        maxWidth={'xs'}
        fullWidth
      >
        <DialogContentText>
          {dialog.message}
        </DialogContentText>
      </Dialog>
      
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <SubTitle title={translate('mypage.button.account')} />
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <GContainer rowSpacing={3}>
            <GItemContainer xs={12}>
              <LabelGItem>{translate('account.label.email')}</LabelGItem>
              <ValueGItem>{account.email}</ValueGItem>
              <LinkGItem>
                {isVerifiedAll && (
                  <Link
                    to={accountEmailChangeUrl.url()}
                    operationLog={{ operationId: OperationId.OP_00000048 }}
                  >{`${translate('account.text.change')}>`}</Link>
                )}
              </LinkGItem>
              <GItem xs={4}></GItem>
              <GItem xs={8}>
                <VerifiedStatusChip isVerified={isVerifiedEmail} />
              </GItem>
            </GItemContainer>
            
            <GItemContainer xs={12}>
              <LabelGItem>{translate('account.label.telMobile')}</LabelGItem>
              <ValueGItem>{account.tel}</ValueGItem>
              <LinkGItem>
                <Link
                  to={accountTelChangeUrl.url()}
                  operationLog={{ operationId: OperationId.OP_00000047 }}
                >{`${translate('account.text.change')}>`}</Link>
              </LinkGItem>
              <GItem xs={4}></GItem>
              {sysCtrl.phoneCertificationFlag === yesNo.yes &&
                <GItem xs={8}>
                  <VerifiedStatusChip isVerified={isVerifiedTel} />
                </GItem>
              }
            </GItemContainer>

            <LabelGItem>{translate('account.label.password')}</LabelGItem>
            <ValueGItem>{translate('account.text.passwordChangeInformation')}</ValueGItem>
            <LinkGItem>
              {isVerifiedAll && (
                <Link onClick={logout} operationLog={{ operationId: OperationId.OP_00000049 }}>{`${translate(
                  'account.text.change'
                )}>`}</Link>
              )}
            </LinkGItem>

            {sysCtrl.unsubscribeAcceptFlag === yesNo.yes && (
              <>
                <LabelGItem>{translate('account.label.unsubscribe')}</LabelGItem>
                <ValueGItem>
                  {translateNode(
                    'account.text.unsubscribe',
                    <Link to={accountUnsubscribeUrl.url()} operationLog={{ operationId: OperationId.OP_00000155 }}>
                      {translate('account.text.here')}
                    </Link>
                  )}
                </ValueGItem>
                <LinkGItem></LinkGItem>
              </>
            )}
            
            { ssoUseFlag === yesNo.yes && (
              <>
                <GItemContainer xs={12}>
                  <LabelGItem>{ssoUseName}{translate('account.label.linkage')}</LabelGItem>
  
                  { account.ssoUserId != null ? (
                    <>
                      <ValueGItem>
                        {translateNode(
                          'account.text.unlinkage',
                          <Link 
                            onClick={unlink} 
                            operationLog={{ operationId: OperationId.OP_00000167 }}
                          >
                            {translate('account.text.here')}
                          </Link>
                        )}
                        <LinkedStatusChip isLinked={account.ssoUserId != null} />
                      </ValueGItem>
                    </>
                  ):(
                    <>
                      <ValueGItem>
                        {translateNode(
                          'account.text.linkage',
                          <Link 
                            to={`${ssoUrl.url()}?linkRedirectTo=${accountUrl.url()}`} 
                            operationLog={{ operationId: OperationId.OP_00000162 }}
                          >
                            {translate('account.text.here')}
                          </Link>
                        )}
                        <LinkedStatusChip isLinked={account.ssoUserId != null} />
                      </ValueGItem>
                      <LinkGItem></LinkGItem>
                    </>
                  )}
                </GItemContainer>
 
              </>
            )}
            
          </GContainer>
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={myPageUrl.url()}>
          {translate('account.button.backToMypage')}
        </ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
