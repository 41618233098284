// 電話番号フォームコントロール
//

import { FormControl, FormHelperText } from '@mui/material'
import React, { ChangeEvent, forwardRef, Ref, useCallback, useMemo } from 'react'
import { Control, useController, Validate } from 'react-hook-form'
import { requiredValidator, telValidator } from '../../../../containers/common/validator'
import { FlexboxMiddle, HyphenSeparator } from '../flexboxes'
import { TextBoxBaseStyled } from './textBoxBaseStyled'

interface TelTextBoxProps {
  /** 入力値を紐づける(バインドする)インプットオブジェクトのプロパティ名  */
  name: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 入力必須とする場合true */
  required?: boolean
  /** width: 100% にする場合true */
  fullWidth?: boolean
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
  /** autocomplete属性 */
  autoComplete?: string
}

type PartPosition = 0 | 1 | 2

const splitTel = (value: string) => value.split('-').concat(['', '', '']).slice(0, 3)

const spliceTel = (currentValue: string, newValue: string, position: PartPosition) => {
  if (/[^0-9]/.test(newValue)) {
    // 数値以外は無視する
    return
  }

  const splittedNumbers = splitTel(currentValue)
  splittedNumbers[position] = newValue
  return splittedNumbers.join('-').replace(/-+$/, '')
}

const getValidators = (label: string, required?: boolean) => {
  const validators: Record<string, Validate<any>> = {}
  if (required) {
    const validator = requiredValidator(label)
    validators.required = (value: string) => {
      if (!required) {
        return true
      }
      for (const part of splitTel(value)) {
        const result = validator(part)
        if (typeof result === 'string') {
          return result
        }
      }
      return true
    }
  }
  validators.tel = telValidator()
  return validators
}

interface PartTextBoxProps {
  fullWidth?: boolean
  error: boolean
  value: string
  position: PartPosition
  onChange: (...event: any[]) => void
  onBlur: (...event: any[]) => void
  autoComplete?: string
}
const PartTextBox = forwardRef(function PartTextBox(props: PartTextBoxProps, ref: Ref<HTMLInputElement>) {
  const { onChange, value, position, ...through } = props
  const maxLength = 4

  const onChangeFormat = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newValue = spliceTel(value, event.target.value, position)
      if (newValue != null) {
        onChange(newValue)
      }
    },
    [onChange, value, position]
  )

  return (
    <TextBoxBaseStyled
      {...through}
      inputRef={ref}
      value={splitTel(value)[position]}
      type="tel"
      onChange={onChangeFormat}
      maxLength={maxLength}
      inputProps={{
        maxLength,
      }}
    />
  )
})

/** 電話番号フォームコントロール */
export const TelTextBox = (props: TelTextBoxProps) => {
  const { name, label, required, control, fullWidth, autoComplete } = props

  const validate = useMemo(() => getValidators(label, required), [label, required])
  const {
    field: { ref, value, onChange, onBlur },
    formState: { errors },
  } = useController({
    name,
    rules: {
      validate,
    },
    defaultValue: '',
    control,
  })

  const error = errors[props.name]
  const partProps = {
    error: !!error,
    fullWidth,
    onBlur,
    onChange,
    value,
    autoComplete,
  }
  return (
    <FormControl error={!!error} fullWidth={fullWidth}>
      <FlexboxMiddle>
        <PartTextBox {...partProps} position={0} ref={ref} />
        <HyphenSeparator />
        <PartTextBox {...partProps} position={1} />
        <HyphenSeparator />
        <PartTextBox {...partProps} position={2} />
      </FlexboxMiddle>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
