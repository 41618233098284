import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showLoading } from '../../containers/common/store/slices/application'
import { executeGetInformations } from '../../dataAccess/webApi/dao/informationsDao'
import { fromApiYmdHms } from '../../utils/dateUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { selectIsLoggedIn, selectIsVerifiedEmail, selectIsVerifiedTel } from '../common/store/slices/authority'

interface Information {
  informaionNo: string
  publicationStartDatetime: Date
  facilityName: string
  title: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const [informations, setInformations] = useState<Information[]>()

  useEffect(() => {
    const initialize = async () => {
      const result = await getInformations(isLoggedIn, isVerifiedEmail, isVerifiedTel)
      setInformations(result)
    }
    dispatch(showLoading(errorHandle(initialize)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    informations,
  }
}

const getInformations = async (isLoggedIn: boolean, isVerifiedEmail: boolean, isVerifiedTel: boolean) => {
  const response = await executeGetInformations(isLoggedIn && isVerifiedEmail && isVerifiedTel)
  return response.result.map((info) => {
    const { publicationStartDatetime, ...other } = info
    return {
      ...other,
      publicationStartDatetime: fromApiYmdHms(publicationStartDatetime),
    }
  })
}
