// Reduxのstoreを定義
// すべてのsliceを纏める部分
// 新しくsliceを追加する場合は、combineReducersに
// sliceのreducerを追加する必要がある。
//

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import accountChange from './slices/accountChange'
import accountCreate from './slices/accountCreate'
import application from './slices/application'
import authority from './slices/authority'
import facilityReserve from './slices/facilityReserve'
import facilityReserveDelete from './slices/facilityReserveDelete'
import facilitySearch from './slices/facilitySearch'
import interviewReserve from './slices/interviewReserve'
import memberAddChild from './slices/memberAddChild'
import memberModify from './slices/memberModify'
import systemControl from './slices/systemControl'

const combinedReducer = combineReducers({
  application,
  systemControl,
  authority,
  facilitySearch,
  facilityReserve,
  facilityReserveDelete,
  interviewReserve,
  memberModify,
  memberAddChild,
  accountChange,
  accountCreate,
})

/**
 * 基本的に各sliceのreducerへ処理を委譲するが、
 * 全sliceのstateを削除するリセット処理だけは、
 * stateを横断してアクセスするひつようがある為
 * ここに削除処理を実装する。
 * actionを作成するためにapplicationのsliceにreducer関数を空実装している。
 *
 * state,actionの型がanyを指定してるのは、全sliceのreducer呼び出しが
 * この関数を通るので型を指定できない為。
 *
 * @param state 状態
 * @param action アクション
 * @returns reducerの戻り値
 */
const rootReducer = (state: any, action: any) => {
  if (action.type === 'application/reset') {
    const application = state.application
    const systemControl = state.systemControl
    let authority
    if (action.payload.isKeepAuthority) {
      authority = state.authority
    }
    state = { application, systemControl, authority }
  }
  return combinedReducer(state, action)
}

/**
 * ReduxのStore
 */
export const store = configureStore({
  reducer: rootReducer,
})

/**
 * Storeに格納されたstateの型
 */
export type RootState = ReturnType<typeof store.getState>
