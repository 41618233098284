import { styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface AlertCardProps {
  children: ReactNode
  className?: string
}

const Root = styled('div')(({ theme }) => ({
  fontSize: theme.typography.font.sizeM,
  borderRadius: 4,
  color: theme.palette.error.main,
  backgroundColor: theme.palette.errorPale.main,
  width: '100%',
  padding: '20px',
}))

/** 注意喚起用カードコントロール */
export const AlertCard = (props: AlertCardProps) => {
  return <Root className={props.className}>{props.children}</Root>
}
