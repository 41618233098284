import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { castNonNullable, tuple } from '../../utils/typeUtil'

/** クエリパラメータオブジェクトの型 */
export type QueryParams<T> = {
  [key in keyof T]?: string
}

/**
 * ロケーションのクエリ文字列からクエリパラメータオブジェクトを生成して返すフック関数
 *
 * @returns クエリパラメータオブジェクト
 */
export const useQueryParams = <T extends QueryParams<T>>(): T => {
  const location = useLocation()
  const search = location.search

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const params: Record<string, unknown> = {}
    for (const key of Array.from(searchParams.keys())) {
      const value = searchParams.get(key)
      if (value) {
        params[key] = decodeURIComponent(value)
      }
    }
    return params as T
  }, [search])

  return queryParams
}

/**
 * クエリパラメータを付与したURLpathnameを返す
 * @param pathname URLpathname
 * @param params クエリパラメータオブジェクト
 * @returns クエリパラメータを付与したURLpathname
 */
export const getUrlPathname = <T extends QueryParams<T>>(pathname: string, params: T) => {
  const entries = Object.entries(params as Record<string, string>)
    .filter(([_key, value]) => value)
    .map(([key, value]) => {
      // filterでnullは除去されるのでnonNull
      return tuple(key, encodeURIComponent(castNonNullable(value)))
    })
  return entries.length ? pathname + '?' + new URLSearchParams(entries).toString() : pathname
}
