import { styled } from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'

interface LazyMountProps {
  children: ReactNode
  isShown: boolean
}

const Root = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isShown',
})<{ isShown: boolean }>(({ isShown }) => ({
  ...(!isShown && { display: 'none' }),
}))

export const LazyMount = (props: LazyMountProps) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (!isMounted && props.isShown) {
      setIsMounted(true)
    }
  }, [props.isShown])

  return isMounted ? <Root isShown={props.isShown}>{props.children}</Root> : null
}
