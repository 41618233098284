import { Grid, Stack, styled } from '@mui/material'
import React, { ReactNode } from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/mypage/mypageService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { InlineFlexbox } from '../components/common/flexboxes'
import { CenterItem, CenterMiddleItem, LabelItemBase, ValueItemBase } from '../components/common/gridItems'
import { AccountIcon } from '../components/common/icons/accountIcon'

const ItemIcon = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grayLight.main,
  borderRadius: 65,
  fontSize: '13rem',
}))

const UserContainer = styled(Grid)({
  width: 'auto',
})
const LabelItem = (props: { children?: ReactNode }) => <LabelItemBase xs={3}>{props.children}</LabelItemBase>
const ValueItem = (props: { children: ReactNode }) => <ValueItemBase xs={9}>{props.children}</ValueItemBase>

export const Mypage = () => {
  const { loginId, userName, goAccount, goMember, goMyFacility, goUsageHistory } = useAction()

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Grid container rowSpacing={2}>
            <CenterItem item xs={12} md={4}>
              <ItemIcon>
                <AccountIcon fontSize="inherit" />
              </ItemIcon>
            </CenterItem>
            <CenterMiddleItem item xs={12} md={8}>
              <UserContainer container>
                <LabelItem>{translate('mypage.label.loginId')}</LabelItem>
                <ValueItem>{loginId}</ValueItem>
                <LabelItem>{translate('mypage.label.fullName')}</LabelItem>
                <ValueItem>{userName}</ValueItem>
              </UserContainer>
            </CenterMiddleItem>
            <CenterItem item xs={12}>
              <ButtonL fullWidth onClick={goAccount}>
                {translate('mypage.button.account')}
              </ButtonL>
            </CenterItem>
            <CenterItem item xs={12}>
              <ButtonL fullWidth onClick={goMember}>
                {translate('mypage.button.member')}
              </ButtonL>
            </CenterItem>
            <CenterItem item xs={12}>
              <ButtonL fullWidth onClick={goMyFacility}>
                {translate('mypage.button.myFacility')}
              </ButtonL>
            </CenterItem>
            <CenterItem item xs={12}>
              <ButtonL fullWidth onClick={goUsageHistory}>
                {translate('mypage.button.usageHistory')}
              </ButtonL>
            </CenterItem>
          </Grid>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
