import { Grid, Stack } from '@mui/material'
import React from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/information/informationService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { CityFacilityInformationCard } from '../components/common/cityFacilityInformationCard'
import { SubTitle } from '../components/common/subTitle'

export const Information = () => {
  const { informations } = useAction()
  if (informations == null) {
    return null
  }
  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <SubTitle title={translate('home.text.informationsLabel')} />
            {!!informations.length ? (
              <div>
                <Grid container rowSpacing={1}>
                  {informations.map((info) => (
                    <Grid item xs={12} key={info.informaionNo}>
                      <CityFacilityInformationCard {...info} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : (
              <div>{translate('home.text.noInformation')}</div>
            )}
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>

      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
