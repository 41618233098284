import { useCallback, useEffect, useState } from 'react'
// import { useCallback, useEffect, useState, useRef } from 'react'
// import { useDispatch } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { showLoading } from '../../containers/common/store/slices/application'
import { executeGetCouponsIssues } from '../../dataAccess/webApi/dao/couponsDao'
import { executeGetInformations } from '../../dataAccess/webApi/dao/informationsDao'
// import { checkDto } from 'containers/common/commonFunction'
// import { executePostLinkSettings } from '../../dataAccess/webApi/dao/linkSettingsDao'
import { executeGetLinkSettings } from '../../dataAccess/webApi/dao/linkSettingsDao'
// import { LinkMasterData, LinkMasterDto } from 'dataAccess/webApi/dto/linkMasterDto'
import { PostLinkSettingsDto } from '../../dataAccess/webApi/dto/linkSettingsDto'
import { fromApiYmd, fromApiYmdHms } from '../../utils/dateUtil'
import { yesNo } from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { selectIsLoggedIn, selectIsVerifiedEmail, selectIsVerifiedTel } from '../common/store/slices/authority'
import { SystemControl, selectSystemControl } from '../common/store/slices/systemControl'
import { useHistory } from 'react-router-dom'
import { myFacilityUrl, reservationStatusListUrl, usageHistoryUrl, couponUrl } from '../common/constant/appUrl'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
// import { executeApi } from 'dataAccess/webApi/dao/commonDao'

interface Information {
  informaionNo: string
  publicationStartDatetime: Date
  facilityName: string
  title: string
}
// interface LinkSettings {
//   link_id: number
//   link_name: string
//   link_url: string
// }
export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const sysCtrl = useSelector(selectSystemControl)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isVerifiedEmail = useSelector(selectIsVerifiedEmail)
  const isVerifiedTel = useSelector(selectIsVerifiedTel)
  const history = useHistory()
  const { addOperationLog } = useOperationLog()
  
  const [informations, setInformations] = useState<Information[]>()
  // const [linkSettingsList, setLinkSettingsList] = useState<LinkSettings[]>()
  const [linkSettingsList, setLinkSettingsList] = useState<PostLinkSettingsDto[]>()
  const [showCouponFunctionFlag, setShowCouponFunctionFlag] = useState<boolean>(false)
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    const initialize = async () => {
      const result = await getInformations(isLoggedIn, isVerifiedEmail, isVerifiedTel)
      setInformations(result)
      if (isLoggedIn && isVerifiedEmail && isVerifiedTel) {
        // クーポン利用可能判定
        const isUsable = await canUseCouponFunction(sysCtrl)
        setShowCouponFunctionFlag(isUsable)
      }
      
      // 各種リンクを取得
      { 
        const linkSettingsList = await getLinkSettings()
        setLinkSettingsList(linkSettingsList)
        // console.log("linkSettingsList", linkSettingsList)
      }
      
    }
    dispatch(showLoading(errorHandle(initialize)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // My施設へ遷移
  const goMyFacility = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000019 })
    history.push(myFacilityUrl.url())
  }, [addOperationLog, history])

  // 予約状況へ遷移
  const goReservationStatusList = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000020 })
    history.push(reservationStatusListUrl.url())
  }, [addOperationLog, history])

  // 利用履歴へ遷移
  const goUsageHistory = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000021 })
    history.push(usageHistoryUrl.url())
  }, [addOperationLog, history])

  // はじめてのおあずかり券へ遷移
  const goCoupon = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000022 })
    history.push(couponUrl.url())
  }, [addOperationLog, history])

  return {
    informations,
    linkSettingsList,
    showCouponFunctionFlag,
    goMyFacility,
    goReservationStatusList,
    goUsageHistory,
    goCoupon,
  }
}

const getInformations = async (isLoggedIn: boolean, isVerifiedEmail: boolean, isVerifiedTel: boolean) => {
  const response = await executeGetInformations(isLoggedIn && isVerifiedEmail && isVerifiedTel)
  // HOMEに表示するお知らせは２件まで
  return response.result.slice(0, 2).map((info) => {
    const { publicationStartDatetime, ...other } = info
    return {
      ...other,
      publicationStartDatetime: fromApiYmdHms(publicationStartDatetime),
    }
  })
}
const getCouponsIssue = async () => {
  const response = await executeGetCouponsIssues()
  return response.result.map((coupon) => {
    const { issuedNo, limitUsageMinute, totalBalanceMinute, limitDate, childId, deleteFlag, ...other } = coupon
    return {
      ...other,
      issuedNo: issuedNo,
      limitUsageMinute: limitUsageMinute,
      remainingMinute: totalBalanceMinute,
      limitDate: fromApiYmd(limitDate),
      childId: childId,
      deleteFlag: deleteFlag,
    }
  })
}
/**
 * 各種リンクを一覧取得して返す
 * @return {Array<Object>} - linkSettingsのリスト
 */
const getLinkSettings = async () => {
  // const response = await executePostLinkSettings()
  const response = await executeGetLinkSettings()
  return response.result.map((entry) => {
    const { ...other } = entry
    return {
      ...other,
    }
  })
}

const canUseCouponFunction = async (sysCtrl: SystemControl) => {
  const isUsable = sysCtrl.useCouponFunctionFlag === yesNo.yes
  if (!isUsable) {
    // コントロール設定で利用不可
    return false
  }

  const result = await getCouponsIssue()
  // 利用可能かクーポンが存在すれば利用可
  return result.length >= 1
}
