import { Breakpoint, createTheme } from '@mui/material'

const defaultTheme = createTheme()

export default createTheme({
  palette: {
    // テーマカラー 1
    primary: {
      main: '#006dcc',
      dark: '#00439a',
      light: '#5c9bff',
    },
    // テーマカラー 2
    secondary: {
      main: '#2e2e2e',
      dark: '#030303',
      light: '#575757',
    },
    // テーマカラー 3
    info: {
      main: '#e5f8ff',
      dark: '#b3c5cc',
      light: '#ffffff',
      contrastText: '#000000',
    },
    // テーマカラー 4
    warning: {
      main: '#fff55b',
      dark: '#c9c323',
      light: '#ffff8e',
      contrastText: '#000000',
    },
    // テーマカラー 5
    error: {
      main: '#c53030',
      dark: '#8d0009',
      light: '#fe645a',
    },
    // テーマカラー 6
    white: {
      main: '#ffffff',
      dark: '#cccccc',
      light: '#ffffff',
      contrastText: '#000000',
    },
    // テーマカラー 7
    errorPale: {
      main: '#fee9e9',
      dark: '#cbb7b7',
      light: '#ffffff',
      contrastText: '#000000',
    },
    // テーマカラー 8
    grayLight: {
      main: '#dfdfdf',
      dark: '#adadad',
      light: '#ffffff',
      contrastText: '#000000',
    },
    // テーマカラー 9
    grayDark: {
      main: '#757575',
      dark: '#494949',
      light: '#a4a4a4',
    },
    // テーマカラー 10
    greenPale: {
      main: '#e7f4e9',
      dark: '#b5c1b7',
      light: '#ffffff',
    },
    text: {
      primary: '#2e2e2e',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: [
      '"Noto Sans JP"',
      '"Meiryo"',
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      '"Helvetica Neue"',
      '"Arial"',
      '"sans-serif"',
    ].join(','),
    body1: {
      fontSize: '1.2rem',
      fontWeight: defaultTheme.typography.fontWeightBold,
    },
    button: {
      fontWeight: defaultTheme.typography.fontWeightBold,
    },
    font: {
      sizeS: '1rem',
      sizeM: '1.2rem',
      sizeL1: '1.4rem',
      sizeL2: '1.8rem',
      sizeLL1: '3rem',
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      md: 1024,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 8 * 6,
    },
  },
  appMain: {
    padding: 8 * 3,
  },
  appFooter: {
    height: (breakpoint: Breakpoint): number => {
      switch (breakpoint) {
        case 'xs':
          return 8 * 13
        default:
          return 8 * 5
      }
    },
  },
})
