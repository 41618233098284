import { styled } from '@mui/system'
import { ButtonOutlinedInfoBase } from './buttonOutlinedInfoBase'

/**
 * infoカラーのボタンコントロールLサイズ。
 */
export const ButtonOutlinedInfoL = styled(ButtonOutlinedInfoBase)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
  padding: '14px 14px',
  borderWidth: 4,
}))
