import { SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeGetInterviews } from '../../dataAccess/webApi/dao/interviewsDao'
import { executeGetReservations } from '../../dataAccess/webApi/dao/reservationsDao'
import { fromApiYmd, fromApiYmdHms } from '../../utils/dateUtil'
import { nullPropsToUndefined, undefinedPropsToNull } from '../../utils/objectUtil'
import { ChildrenChoice, getChildrenChoices } from '../common/child'
import { useErrorHandle } from '../common/error/errorHandler'
import { showLoading } from '../common/store/slices/application'
import { useOperationLog } from '../common/operationLog'
import { OperationId } from '../common/constant/operationLog'
import { yesNo, reservationStatusSearchCondition } from '../common/constant/classification'

interface LocationState {
  /** アクティブなお子さまIDの値 */
  childId?: string
  /** アクティブなタブの値 */
  tabValue?: string
  /** アクティブな利用予約の検索条件 */
  statusSearchCondition?: string
}

interface Reservation {
  reservationNo: string
  usageDate: Date
  useFromDatetime: Date
  useToDatetime: Date
  facilityName: string
  childName: string
  status: string
}
interface Interview {
  interviewNo: string
  interviewDate?: Date
  interviewStartDatetime?: Date
  interviewEndDatetime?: Date
  facilityName: string
  childName: string
  status: string
  permitFlag: string
}

interface PageState {
  activeChildId?: string
  activeTabValue: string
  /** 利用予約の検索条件 */
  activeStatusSearchCondition: string
  /** 利用予約 */
  reservations?: Reservation[]
  /** 面談予約 */
  interviews?: Interview[]
  childs: ChildrenChoice[]
}

/** タブの選択値 */
export const tabValue = {
  reservation: '1',
  interview: '2',
} as const

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { addOperationLog } = useOperationLog()

  const locationState = history.location.state

  const [state, setState] = useState<PageState>({
    activeChildId: locationState?.childId,
    activeTabValue: locationState?.tabValue ?? tabValue.reservation,
    activeStatusSearchCondition: locationState?.statusSearchCondition ?? reservationStatusSearchCondition.confirmedOnly,
    childs: [],
  })

  const load = useCallback(
    (
      isInitialize: boolean,
      loadedChilds: { value: string; label: string }[],
      activeStatusSearchCondition: string,
      activeTabValue: string,
      activeChildId?: string
    ) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            let callGetChildren
            let callGetReservations
            let callGetInterviews
            if (isInitialize) {
              callGetChildren = getChildrenChoices()
            }
            if (activeTabValue === tabValue.reservation) {
              callGetReservations = getReservations(activeStatusSearchCondition, activeChildId)
            } else {
              callGetInterviews = getInterviews(activeChildId)
            }

            const [childs, reservations, interviews] = await Promise.all([
              callGetChildren ?? loadedChilds,
              callGetReservations,
              callGetInterviews,
            ])
            setState({
              activeChildId,
              activeTabValue,
              activeStatusSearchCondition,
              reservations,
              interviews,
              childs,
            })
          }),
          isHiddenMain: isInitialize,
        })
      )
    },
    []
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    load(true, state.childs, state.activeStatusSearchCondition, state.activeTabValue, state.activeChildId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 戻るで表示した際にアクティブなタブなどを復元する為に履歴に保管
    history.replace({
      ...history.location,
      state: { childId: state.activeChildId, tabValue: state.activeTabValue, statusSearchCondition: state.activeStatusSearchCondition }
    })
  }, [state.activeChildId, state.activeTabValue, state.activeStatusSearchCondition])

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      load(false, state.childs, state.activeStatusSearchCondition, state.activeTabValue, event.target.value)
    },
    [state, addOperationLog]
  )

  const changeTab = useCallback(
    (value: string) => {
      if (value === tabValue.reservation) {
        addOperationLog({ operationId: OperationId.OP_00000042 })
      } else if (value === tabValue.interview) {
        addOperationLog({ operationId: OperationId.OP_00000043 })
      }

      load(false, state.childs, state.activeStatusSearchCondition, value, state.activeChildId)
    },
    [state, addOperationLog]
  )
  
  const statusSearchConditionChangeHandle = useCallback(
    (event: SelectChangeEvent<string>) => {
      load(false, state.childs, event.target.value, state.activeTabValue, state.activeChildId)
    },
    [state]
  )

  return {
    ...state,
    changeChild,
    changeTab,
    statusSearchConditionChangeHandle,
  }
}

const getReservations = async (activeStatusSearchCondition: string, childId?: string) => {
  const response = await executeGetReservations({ 
    ...undefinedPropsToNull({childId}),
    ...(activeStatusSearchCondition === reservationStatusSearchCondition.confirmedOnly && {confirmedOnlyGetFlag: yesNo.yes})
  })
  return response.result.map((reservation) => {
    const { usageDate, useFromDatetime, useToDatetime, ...other } = reservation
    return nullPropsToUndefined({
      ...other,
      usageDate: fromApiYmd(usageDate),
      useFromDatetime: fromApiYmdHms(useFromDatetime),
      useToDatetime: fromApiYmdHms(useToDatetime),
    })
  })
}

const getInterviews = async (childId?: string) => {
  const response = await executeGetInterviews(undefinedPropsToNull({ childId }))
  return response.result.map((interview) => {
    const { interviewDate, interviewStartDatetime, interviewEndDatetime, ...other } = interview
    return nullPropsToUndefined({
      ...other,
      interviewDate: fromApiYmd(interviewDate),
      interviewStartDatetime: fromApiYmdHms(interviewStartDatetime),
      interviewEndDatetime: fromApiYmdHms(interviewEndDatetime),
    })
  })
}
