import React from 'react'
import { Box, styled } from '@mui/material'

interface PageTitleProps {
  title: string
  subTitle?: string
}

const Root = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL2,
  letterSpacing: '0.2em',
  color: theme.palette.secondary.main,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
}))
const SubTitle = styled('span')(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
}))

export const PageTitle = (props: PageTitleProps) => {
  return (
    <Root>
      {props.title}
      {props.subTitle && (
        <>
          {'-'}
          <SubTitle>{props.subTitle}</SubTitle>
        </>
      )}
    </Root>
  )
}
