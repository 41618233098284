import { MenuItem, Select as MuiSelect, SelectChangeEvent, SelectProps, styled } from '@mui/material'
import React, { ElementType, Ref, forwardRef } from 'react'

interface SelectNoBindProps {
  /** width: 100% にする場合true */
  fullWidth?: boolean
  /** 選択肢の値と表示文言配列 */
  valueLabels: { value: string; label: string }[]

  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string
  /**
   * 選択要素のコンポーネント。
   * 指定が無ければ {@link SelectItem} を使用する
   */
  itemComponent?: ElementType

  /** name属性 */
  name?: string
  /** 選択状態の選択肢の値 */
  value?: string
  /** エラー表示する場合true */
  error?: boolean
  /** 選択が変更された時に呼び出される関数 */
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
  /** フォーカスアウトした時に呼び出される関数 */
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  /**
   * 選択なしを示す項目を自動的に表示するための制御プロパティ。
   * multiple指定時：選択肢が複数あれば表示
   * always指定時：選択肢の数によらず常に表示
   */
  includeNone?: 'multiple' | 'always'
  /** 選択なしを示す項目の表示文言 */
  noneLable?: string
  /** 非活性にする場合true */
  disabled?: boolean
}

const RootBase: (props: SelectProps<string>) => JSX.Element = MuiSelect
const Root = styled(RootBase)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  '& .MuiSelect-select': {
    padding: '4px 8px',
  },
}))
export const SelectItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}))

/** セレクトコントロール */
export const SelectNoBind = forwardRef(function SelectNoBind(props: SelectNoBindProps, ref: Ref<HTMLSelectElement>) {
  const { valueLabels, onChange, includeNone, noneLable, value, itemComponent, ...through } = props
  const isShown = includeNone === 'always' || (includeNone === 'multiple' && valueLabels.length > 1)

  const CustomItem = itemComponent ?? SelectItem
  return (
    <Root
      {...through}
      inputRef={ref}
      value={value == null || valueLabels.length === 0 ? '' : value}
      onChange={onChange}
      displayEmpty
    >
      {isShown && <CustomItem value="">{noneLable ?? <>&nbsp;</>}</CustomItem>}
      {valueLabels.map((valueLabel) => (
        <CustomItem key={valueLabel.value} value={valueLabel.value}>
          {valueLabel.label}
        </CustomItem>
      ))}
    </Root>
  )
})
