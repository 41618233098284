import { Box, styled } from '@mui/material'
import React from 'react'

interface ErrorMessageCardProps {
  messages: (string | undefined | null)[]
}

const Root = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.errorPale.main,
  padding: theme.spacing(1),
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '& ul': {
    margin: 0,
    padding: 0,
  },
  '& li': {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'baseline',
    whiteSpace: 'pre-wrap',
    '&::before': {
      content: '"・"',
    },
  },
}))

/**
 * エラーメッセージ表示カードコントロール。
 * エラーメッセージ表示が一体となっていない入力コントロールで発生したエラーメッセージや
 * 入力項目間の関連チェック、サーバー側でのチェックでエラーが発生した場合に
 * その内容を表示する為に使用する
 */
export const ErrorMessageCard = (props: ErrorMessageCardProps) => {
  const messages = props.messages.filter((message) => message)
  return messages.length ? (
    <Root>
      <ul>
        {messages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    </Root>
  ) : null
}
