import React from 'react'
import { Grid, GridSize } from '@mui/material'
import { Control, useController } from 'react-hook-form'
import { ButtonOutlinedS } from '../buttons/buttonOutlinedS'
import { ButtonS } from '../buttons/buttonS'
import { translate } from '../../../../i18n'

type BreakpointColumns = 1 | 2 | 3 | 4 | 6 | 12

interface ToggleButtonGroupProps {
  /** 入力値を紐づける(バインドする)インプットオブジェクトのプロパティ名  */
  name: string
  /** 項目の名称。必須エラーなどのエラーメッセージで使用 */
  label: string
  /** 選択肢の値と表示文言配列 */
  buttonValueLabels: { value: string; label: string }[]
  /** 入力必須とする場合true */
  required?: boolean
  /** ブレイクポイントxsでの表示列数 */
  xsColumns?: BreakpointColumns
  /** ブレイクポイントsmでの表示列数 */
  smColumns?: BreakpointColumns
  /** ブレイクポイントmdでの表示列数 */
  mdColumns?: BreakpointColumns
  /** ブレイクポイントlgでの表示列数 */
  lgColumns?: BreakpointColumns
  /**
   * ReactHookFormのコントロールオブジェクト
   * 通常は省略する。
   * ただし、入力コントロールがFormタグの子孫にならない場合に指定する必要がある。
   */
  control?: Control<any, any>
}

const getGridNumber = (columns?: BreakpointColumns): boolean | GridSize =>
  columns == null ? false : ((12 / columns) as GridSize)

const toggle = (currentValue: string, newValue: string) => {
  const values = new Set(currentValue?.split(',').filter((x) => x) || [])
  if (values.has(newValue)) {
    values.delete(newValue)
  } else {
    values.add(newValue)
  }
  return Array.from(values.values()).join(',')
}

/** トグルボタングループフォームコントロール */
export const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  const { field } = useController({
    name: props.name,
    defaultValue: '',
    rules: {
      required: {
        value: !!props.required,
        message: translate('system.error.requiredSelectionMinimumOneMore', props.label),
      },
    },
    control: props.control,
  })
  return (
    <div>
      <Grid container spacing="2px">
        {props.buttonValueLabels.map(({ value, label }) => (
          <Grid
            item
            key={value}
            xs={getGridNumber(props.xsColumns)}
            sm={getGridNumber(props.smColumns)}
            md={getGridNumber(props.mdColumns)}
            lg={getGridNumber(props.lgColumns)}
          >
            {field.value?.split(',').some((x: string) => x === value) ? (
              <ButtonS fullWidth onClick={() => field.onChange(toggle(field.value, value))}>
                {label}
              </ButtonS>
            ) : (
              <ButtonOutlinedS fullWidth onClick={() => field.onChange(toggle(field.value, value))}>
                {label}
              </ButtonOutlinedS>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
