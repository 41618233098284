import { executePostKeepAlive as executePostKeepAlive } from '../common/apiCaller'
import { PostOperationLogAccessDataInputDto, PostOperationLogInputDto } from '../dto/operationLogDto'

const apiName = 'citizen'
const apiPath = '/operation-log'

export const executePostOperationLog = (input: PostOperationLogInputDto) => {
  return executePostKeepAlive<null>(apiName, apiPath, input)
}

export const executePostOperationLogAccessData = (input: PostOperationLogAccessDataInputDto) => {
  return executePostKeepAlive<null>(apiName, `${apiPath}/access-data`, input)
}
