import { Stack, styled } from '@mui/material'
import {
  getGenders,
  getInterviewReservationSteps,
  getYesNos,
  getResidenceCategorys,
} from '../../containers/common/codeMaster'
import { interviewReservationStep } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/interviewReservationForm/interviewReservationFormService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { ElevatedCard } from '../components/common/elevatedCard'
import { FlexboxRowTop, Separator } from '../components/common/flexboxes'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { DatePicker } from '../components/common/inputs/datePicker'
import { Form } from '../components/common/inputs/form'
import { RadioButtonGroup } from '../components/common/inputs/radioButtonGroup'
import { TextBox } from '../components/common/inputs/textBox'
import { StepBar } from '../components/common/stepBar'
import { ChildSelectDialog } from '../components/accountConfirmation/childSelectDialog'

const AnnotationList = styled('ul')({
  paddingLeft: '20px',
  margin: '0',
})
const NormalItem = styled('li')({
  padding: '2px 0',
})
const AttentionItem = styled(NormalItem)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.error.main,
}))

export const InterviewReservationForm = () => {
  const {
    formMethods,
    autoCompleteAddress,
    onSubmit,
    childBirthdayLimitDate,
    userInterviewPermitCount,
    childInterviewPermitCount,
    isOpenInterviewChildSelect,
    onCloseInterviewChildSelect,
    uninterviewedChilds,
    interviewedChildsAndStatus,
  } = useAction()

  let isDisabledBirthday = false
  if (childInterviewPermitCount > 0) {
    isDisabledBirthday = true
  }

  let isDisabledResidenceCategory = false
  if (userInterviewPermitCount > 0) {
    isDisabledResidenceCategory = true
  }
  return (
    <>
      <Stack spacing={2}>
        <ChildSelectDialog
          isOpen={isOpenInterviewChildSelect}
          onClose={onCloseInterviewChildSelect}
          childs={uninterviewedChilds}
          interviewedChildsAndStatus={interviewedChildsAndStatus}
          isShowToNextButton={!!uninterviewedChilds.length}
        />
      </Stack>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Stack spacing={2}>
          <GContainer>
            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <Stack spacing={2}>
                <div>
                  <StepBar
                    activeStepValue={interviewReservationStep.step1}
                    stepValueLabels={getInterviewReservationSteps()}
                  />
                  <InfoCard>
                    <AnnotationList>
                      <NormalItem>{translate('interviewReservationForm.card.formAnnotation')}</NormalItem>
                      <AttentionItem>{translate('interviewReservationForm.card.firstUseAnnotation')}</AttentionItem>
                      <AttentionItem>{translate('interviewReservationForm.card.childStateAnnotation')}</AttentionItem>
                      <AttentionItem>{translate('interviewReservationForm.card.telAnnotation')}</AttentionItem>
                      <AttentionItem>{translate('interviewReservationForm.card.useAnnotation')}</AttentionItem>
                      <NormalItem>{translate('interviewReservationForm.card.inputNameAnnotation')}</NormalItem>
                      <NormalItem>{translate('interviewReservationForm.card.inputEmojiAnnotation')}</NormalItem>
                    </AnnotationList>
                  </InfoCard>
                </div>
                <div>
                  <GContainer rowSpacing={3}>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.parentName')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="parentName"
                          label={translate('interviewReservationForm.label.parentName')}
                          maxLength={100}
                          required
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.parentKana')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="parentKana"
                          label={translate('interviewReservationForm.label.parentKana')}
                          maxLength={100}
                          textType="katakana"
                          required
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.postalCode')}
                      </GItem>
                      <GItem xs={12}>
                        <FlexboxRowTop>
                          <TextBox
                            name="postalCode"
                            label={translate('interviewReservationForm.label.postalCode')}
                            textType="postalCode"
                            maxLength={8}
                            required
                          />
                          <Separator />
                          <ButtonOutlinedS onClick={autoCompleteAddress}>
                            {translate('interviewReservationForm.button.autoComplete')}
                          </ButtonOutlinedS>
                        </FlexboxRowTop>
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.prefecturesAndMunicipalities')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="address1"
                          label={translate('interviewReservationForm.label.prefecturesAndMunicipalities')}
                          maxLength={100}
                          required
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.address')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="address2"
                          label={translate('interviewReservationForm.label.address')}
                          maxLength={100}
                          required
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>{translate('interviewReservationForm.label.buildingNameRoomNumber')}</GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="buildingNameRoomNumber"
                          label={translate('interviewReservationForm.label.buildingNameRoomNumber')}
                          maxLength={100}
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.residenceCategory')}
                      </GItem>
                      <GItem xs={12}>
                        <RadioButtonGroup
                          name="residenceCategory"
                          label={translate('interviewReservationForm.label.residenceCategory')}
                          required
                          row
                          buttonValueLabels={getResidenceCategorys()}
                          disabled={isDisabledResidenceCategory}
                        />
                      </GItem>
                    </GItemContainer>
  
                    <GItemContainer xs={12}>
                      <GItem xs={12}>{translate('interviewReservationForm.label.relationship')}</GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="relationship"
                          label={translate('interviewReservationForm.label.relationship')}
                          maxLength={50}
                        />
                      </GItem>
                    </GItemContainer>
  
                    <GItemContainer xs={12} rowSpacing={1}>
                      <GItem xs={12}>
                        <ElevatedCard title={translate('interviewReservationForm.title.child')}>
                          <GContainer rowSpacing={1}>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('interviewReservationForm.label.childName')}
                              </GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name="childName"
                                  label={translate('interviewReservationForm.label.childName')}
                                  maxLength={100}
                                  required
                                />
                              </GItem>
                            </GItemContainer>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('interviewReservationForm.label.childKana')}
                              </GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name="childKana"
                                  label={translate('interviewReservationForm.label.childKana')}
                                  maxLength={100}
                                  textType="katakana"
                                  required
                                />
                              </GItem>
                            </GItemContainer>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('interviewReservationForm.label.childBirthday')}
                              </GItem>
                              <GItem xs={12}>
                                <DatePicker
                                  name="childBirthday"
                                  label={translate('interviewReservationForm.label.childBirthday')}
                                  maxDate={childBirthdayLimitDate}
                                  disabled={isDisabledBirthday}
                                  required
                                />
                              </GItem>
                            </GItemContainer>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('interviewReservationForm.label.childGender')}
                              </GItem>
                              <GItem xs={12}>
                                <RadioButtonGroup
                                  name="childGender"
                                  label={translate('interviewReservationForm.label.childGender')}
                                  required
                                  row
                                  buttonValueLabels={getGenders()}
                                />
                              </GItem>
                            </GItemContainer>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('interviewReservationForm.label.childAllergy')}
                              </GItem>
                              <GItem xs={12}>
                                <RadioButtonGroup
                                  name="childAllergyFlag"
                                  label={translate('interviewReservationForm.label.childAllergyFlag')}
                                  required
                                  row
                                  buttonValueLabels={getYesNos().reverse()}
                                />
                              </GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name="childAllergy"
                                  label={translate('interviewReservationForm.label.childAllergyContent')}
                                  maxLength={200}
                                  multiline
                                  rows={4}
                                  fullWidth
                                />
                              </GItem>
                            </GItemContainer>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('interviewReservationForm.label.childMedicalHistory')}
                              </GItem>
                              <GItem xs={12}>
                                <RadioButtonGroup
                                  name="childMedicalHistoryFlag"
                                  label={translate('interviewReservationForm.label.childMedicalHistoryFlag')}
                                  required
                                  row
                                  buttonValueLabels={getYesNos().reverse()}
                                />
                              </GItem>
  
                              <GItem xs={12}>
                                <TextBox
                                  name="childMedicalHistory"
                                  label={translate('interviewReservationForm.label.childMedicalHistoryContent')}
                                  maxLength={200}
                                  multiline
                                  rows={4}
                                  fullWidth
                                />
                              </GItem>
                            </GItemContainer>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <AttentionLabel>*</AttentionLabel>
                                {translate('interviewReservationForm.label.maternalHandbookNo')}
                              </GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name="maternalHandbookNo"
                                  label={translate('interviewReservationForm.label.maternalHandbookNo')}
                                  maxLength={20}
                                  required
                                />
                              </GItem>
                            </GItemContainer>
  
                            <GItemContainer xs={12}>
                              <GItem xs={12}>{translate('interviewReservationForm.label.note')}</GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name="note"
                                  label={translate('interviewReservationForm.label.note')}
                                  maxLength={200}
                                  multiline
                                  rows={4}
                                  fullWidth
                                />
                              </GItem>
                            </GItemContainer>
                            <GItemContainer xs={12}>
                              <GItem xs={12}>
                                <div>{translate('interviewReservationForm.label.facilityNumber')}</div>
                                <div>{translate('interviewReservationForm.label.facilityNumberSupplement')}</div>
                              </GItem>
                              <GItem xs={12}>
                                <TextBox
                                  name="facilityNumber"
                                  label={translate('interviewReservationForm.label.facilityNumber')}
                                  maxLength={12}
                                  fullWidth
                                />
                              </GItem>
                            </GItemContainer>
                          </GContainer>
                        </ElevatedCard>
                      </GItem>
                    </GItemContainer>
                  </GContainer>
                </div>
              </Stack>
            </GItem>
            <GItem md={3}></GItem>
          </GContainer>
          <BottomButtonGroup>
            <ButtonL fullWidth type="submit">
              {translate('interviewReservationForm.button.confirmation')}
            </ButtonL>
            <BackButton />
          </BottomButtonGroup>
        </Stack>
      </Form>
    </>
  )
}
