import React, { MouseEventHandler, ReactNode } from 'react'
import { IconButton, IconButtonPropsColorOverrides } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

interface IconLinkButtonProps {
  /**
   * ボタンの中身。
   * ボタンの表示文言を設定。
   */
  children: ReactNode
  /** styled()でスタイル変更した際に設定されるclass属性 */
  className?: string
  /**
   * カラー指定
   * @default 'inherit'
   */
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | keyof IconButtonPropsColorOverrides

  /** ボタンクリック時に呼び出される関数 */
  onClick?: MouseEventHandler<HTMLButtonElement>
  /**
   * MaterialUIのIconButtonプロパティ(edge)
   * 参照：https://mui.com/api/icon-button/#props
   */
  edge?: 'start' | 'end' | false
  /**
   * 遷移先を示す情報。
   * 参照：https://v5.reactrouter.com/web/api/Link
   */
  to?: string
}

/**
 * アイコンのボタン
 */
export const IconLinkButton = (props: IconLinkButtonProps) => {
  const { children, to, onClick, color, ...buttonAttrs } = props
  const buttonColor = color ?? 'inherit'
  return to ? (
    <IconButton {...buttonAttrs} color={buttonColor} component={RouterLink} to={to}>
      {children}
    </IconButton>
  ) : (
    <IconButton {...buttonAttrs} color={buttonColor} onClick={onClick}>
      {children}
    </IconButton>
  )
}
