import { Grid, Stack, styled } from '@mui/material'
import { RowSpacerGItem } from '../components/common/grids'
import React from 'react'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { getReservationStatusSearchConditions } from '../../containers/common/codeMaster'
import { tabValue, useAction } from '../../containers/reservationStatusList/reservationStatusListService'
import { getClassificationValueLabels, translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { MiddleItem } from '../components/common/gridItems'
import { RadioButtonGroupNoBind } from '../components/common/inputs/radioButtonGroupNoBind'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { ReservationCard } from '../components/common/reservation/reservationCard'
import { TabBar } from '../components/common/tabBar'
import { InterviewCard } from '../components/reservationStatusList/interviewCard'

const NoResultMessageCard = styled('div')({
  padding: '20px 0',
  textAlign: 'center',
})

export const ReservationStatusList = () => {
  const {
    activeChildId,
    activeTabValue,
    activeStatusSearchCondition,
    reservations,
    interviews,
    childs,
    changeChild,
    changeTab,
    statusSearchConditionChangeHandle
  } = useAction()

  let contents
  if (reservations) {
    contents = reservations.map((reservation) => <ReservationCard key={reservation.reservationNo} {...reservation} />)
  } else if (interviews) {
    contents = interviews.map((interview) => <InterviewCard key={interview.interviewNo} {...interview} />)
  }
  if (contents == null) {
    return null
  }
  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Grid container rowSpacing={1}>
            <MiddleItem item>{translate('reservationStatusList.label.selectionChild')}　</MiddleItem>
            <Grid item xs>
              <SelectNoBind
                fullWidth
                includeNone="always"
                noneLable={translate('system.pulldown.allChildren')}
                valueLabels={childs}
                onChange={changeChild}
                value={activeChildId}
              />
            </Grid>

            <Grid item xs={12}>
              <TabBar
                value={activeTabValue}
                valueLabels={getClassificationValueLabels(
                  'reservationStatusList.classification.tabValue',
                  Object.values(tabValue)
                )}
                onChange={changeTab}
              />
            </Grid>
            {activeTabValue === tabValue.reservation && (
              <>
                <RowSpacerGItem />
                <RadioButtonGroupNoBind 
                  name={'statusSearchCondition'}
                  value={activeStatusSearchCondition}
                  onChange={statusSearchConditionChangeHandle}
                  buttonValueLabels={getReservationStatusSearchConditions()}
                  row
                />
              </>
            )}
            <Grid item xs={12}>
              {contents.length ? (
                <Stack spacing={1}>{contents}</Stack>
              ) : (
                <NoResultMessageCard>
                  {translate(
                    activeTabValue === tabValue.reservation
                      ? 'reservationStatusList.text.noReservation'
                      : 'reservationStatusList.text.noInterview'
                  )}
                </NoResultMessageCard>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <ButtonOutlinedResponsive to={rootUrl.url()}>{translate('system.button.backToHome')}</ButtonOutlinedResponsive>
      </BottomButtonGroup>
    </Stack>
  )
}
