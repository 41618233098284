import { Stack, styled } from '@mui/material'
import { getAvailableLabel } from '../../containers/common/codeMaster'
import { interviewReservationFormUrl, facilityDetailAvailabilityUrl } from '../../containers/common/constant/appUrl'
import { yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityDetail/facilityDetailService'
import { translate } from '../../i18n'
import { formatAcceptAge, formatAcceptAgeRange, formatAddress } from '../../utils/itemFormatUtil'
import { fromNumber } from '../../utils/stringUtil'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { InlineFlexbox } from '../components/common/flexboxes'
import { GContainer, GItem, TopGItem } from '../components/common/grids'
import { AcceptAgeIcon } from '../components/common/icons/acceptAgeIcon'
import { AcceptWebReservationIcon } from '../components/common/icons/acceptWebReservationIcon'
import { InquiryIcon } from '../components/common/icons/inquiryIcon'
import { LinkIcon } from '../components/common/icons/linkIcon'
import { LocationIcon } from '../components/common/icons/locationIcon'
import { MoneyIcon } from '../components/common/icons/moneyIcon'
import { PostalCodeIcon } from '../components/common/icons/postalCodeIcon'
import { ProjectIcon } from '../components/common/icons/projectIcon'
import { TelIcon } from '../components/common/icons/telIcon'
import { TimeIcon } from '../components/common/icons/timeIcon'
import { TrainIcon } from '../components/common/icons/trainIcon'
import { UsageIcon } from '../components/common/icons/usageIcon'
import { WalkIcon } from '../components/common/icons/walkIcon'
import { ImageCarousel } from '../components/common/imageCarousel'
import { Link } from '../components/common/link'
import { Tag } from '../components/common/tag'
import { TitleLabel } from '../components/common/titleLabel'
interface UsageListProps {
  usages: string[]
}
const Root = styled('div')(({}) => ({
  '& ul': {
    margin: 0,
    padding: 0,
  },
  '& li': {
    display: 'flex',
  },
}))
const DetailGItem = styled(GItem)(({ theme }) => ({
  color: theme.palette.primary.main,
}))
const AlignStartGItem = styled(GItem)({
  alignItems: 'start',
})
const ItemIcon = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 24,
  fontSize: '1.4rem',
  marginTop: '0.15rem',
  marginRight: '4px',
}))
const WrapTopGItem = styled(TopGItem)({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
})

const FeeTable = styled('table')({
  borderCollapse: 'collapse',
})
const FeeTitle = styled('td')({
  padding: 0,
})
const Fee = styled('td')({
  textAlign: 'end',
  padding: 0,
  paddingLeft: 5,
})
const MessageBox = styled('div')({
  whiteSpace: 'pre-wrap',
  marginLeft: '1rem',
})
const SearchFlagGItem = styled(GItem)({
  '& > *': {
    margin: '1px',
  },
})

const UpperUnder3Fee = (props: { title: string; upperFee?: number; underFee?: number }) => {
  return (
    <>
      <tr>
        <FeeTitle colSpan={2}>{props.title}</FeeTitle>
      </tr>
      <tr>
        <Fee>{props.upperFee != null && translate('facilityDetail.text.upper3Fee', fromNumber(props.upperFee))}</Fee>
        <Fee>{props.underFee != null && translate('facilityDetail.text.under3Fee', fromNumber(props.underFee))}</Fee>
      </tr>
    </>
  )
}
const UsageList = (props: UsageListProps) => {
  return props.usages.length ? (
    <Root>
      <ul>
        <li>{translate('facilityDetail.label.usage')}：</li>
        {props.usages.map((usage) => (
          <li key={usage}>{usage}</li>
        ))}
      </ul>
    </Root>
  ) : null
}
export const FacilityDetail = () => {
  const { facilityId, facility, availabilityBaseDate } = useAction()

  if (facility == null) {
    return null
  }
  const isPreinterviewNo = facility.preinterviewAcceptFlag === yesNo.no
  const isNotLoginCalendarDisplayFlag = facility.notLoginCalendarDisplayFlag === yesNo.yes
  const address = formatAddress(facility.address1, facility.address2, facility.buildingNameRoomNo)
  const acceptAgeFrom = formatAcceptAge(
    facility.acceptStartAge,
    facility.acceptStartMonth,
    facility.acceptStartDay,
    facility.acceptStartAgeGradeFlag
  )
  const acceptAgeTo = formatAcceptAge(
    facility.acceptEndAge,
    facility.acceptEndMonth,
    facility.acceptEndDay,
    facility.acceptEndAgeGradeFlag
  )
  const isExistFee = [
    facility.under3UsageFeePerDay,
    facility.upper3UsageFeePerDay,
    facility.under3UsageFeePerHour,
    facility.upper3UsageFeePerHour,
  ].some((fee) => fee != null)

  const usage = [
    facility.irregularFlag === yesNo.yes ? [translate('facilityDetail.text.irregular')] : [],
    facility.emergencyFlag === yesNo.yes ? [translate('facilityDetail.text.emergency')] : [],
    facility.refreshFlag === yesNo.yes ? [translate('facilityDetail.text.refresh')] : [],
  ].flat()
  return (
    <Stack spacing={2}>
      <div>
        <GContainer rowSpacing={1} columnSpacing={2}>
          <GItem md={2} sx={{ display: { xs: 'none', md: 'block' } }}></GItem>
          <DetailGItem xs={12} md={4}>
            <GContainer rowSpacing={1}>
              <GItem xs={12}>
                <TitleLabel>{facility.facilityName}</TitleLabel>
              </GItem>
              {facility.homePageUrl && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <LinkIcon fontSize="inherit" />
                  </ItemIcon>
                  <Link href={facility.homePageUrl} target="_blank">
                    {facility.homePageUrl}
                  </Link>
                </TopGItem>
              )}
              {facility.projectName && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <ProjectIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.projectName}
                </TopGItem>
              )}
              {facility.postalCode && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <PostalCodeIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.postalCode}
                </TopGItem>
              )}
              {address && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <LocationIcon fontSize="inherit" />
                  </ItemIcon>
                  {address}
                </TopGItem>
              )}
              {facility.nearestStation && (
                <WrapTopGItem xs={12}>
                  <ItemIcon>
                    <TrainIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.nearestStation}
                </WrapTopGItem>
              )}
              {facility.timeFromStation && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <WalkIcon fontSize="inherit" />
                  </ItemIcon>
                  {facility.timeFromStation}
                </TopGItem>
              )}
              <TopGItem xs={12}>
                <ItemIcon>
                  <TelIcon fontSize="inherit" />
                </ItemIcon>
                <Stack>
                  <div>
                    {translate('facilityDetail.label.tel')}：{facility.tel}
                  </div>
                  {facility.fax && (
                    <div>
                      {translate('facilityDetail.label.fax')}：{facility.fax}
                    </div>
                  )}
                </Stack>
              </TopGItem>
              {(facility.openTimeWeekday || facility.openTimeWeekend) && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <TimeIcon fontSize="inherit" />
                  </ItemIcon>
                  <Stack>
                    {facility.openTimeWeekday && (
                      <div>
                        {translate('facilityDetail.label.weekday')}：{facility.openTimeWeekday}
                      </div>
                    )}
                    {facility.openTimeWeekend && (
                      <div>
                        {translate('facilityDetail.label.weekend')}：{facility.openTimeWeekend}
                      </div>
                    )}
                  </Stack>
                </TopGItem>
              )}
              {facility.inquiryTime && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <InquiryIcon fontSize="inherit" />
                  </ItemIcon>
                  {translate('facilityDetail.label.inquiryTime')}：{facility.inquiryTime}
                </TopGItem>
              )}
              {acceptAgeFrom && acceptAgeTo && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <AcceptAgeIcon fontSize="inherit" />
                  </ItemIcon>
                  <Stack>
                    <div>{formatAcceptAgeRange(acceptAgeFrom, acceptAgeTo)}</div>
                    {facility.ageNote && <div>{`（${facility.ageNote}）`}</div>}
                  </Stack>
                </TopGItem>
              )}
              <TopGItem xs={12}>
                <ItemIcon>
                  <AcceptWebReservationIcon fontSize="inherit" />
                </ItemIcon>
                {translate('system.facilityCard.acceptWebReservation')}：
                {getAvailableLabel(facility.reservationAcceptFlag)}
              </TopGItem>
              {isExistFee && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <MoneyIcon fontSize="inherit" />
                  </ItemIcon>
                  <FeeTable>
                    <tbody>
                      {(facility.upper3UsageFeePerDay != null || facility.under3UsageFeePerDay != null) && (
                        <UpperUnder3Fee
                          title={translate('facilityDetail.label.usageFeePerDay')}
                          upperFee={facility.upper3UsageFeePerDay}
                          underFee={facility.under3UsageFeePerDay}
                        />
                      )}
                      {(facility.upper3UsageFeePerHour != null || facility.under3UsageFeePerHour != null) && (
                        <UpperUnder3Fee
                          title={translate('facilityDetail.label.usageFeePerHour')}
                          upperFee={facility.upper3UsageFeePerHour}
                          underFee={facility.under3UsageFeePerHour}
                        />
                      )}
                    </tbody>
                  </FeeTable>
                </TopGItem>
              )}
              {!!usage.length && (
                <TopGItem xs={12}>
                  <ItemIcon>
                    <UsageIcon fontSize="inherit" />
                  </ItemIcon>
                  <UsageList usages={usage} />
                </TopGItem>
              )}
              {facility.message && (
                <AlignStartGItem xs={12}>
                  <div>～{translate('facilityDetail.label.message')}～</div>
                  <MessageBox>{facility.message}</MessageBox>
                </AlignStartGItem>
              )}
              <SearchFlagGItem xs={12}>
                {facility.searchFlagNames.map((searchFlagName, index) => (
                  <Tag key={index}>{searchFlagName}</Tag>
                ))}
              </SearchFlagGItem>
            </GContainer>
          </DetailGItem>
          <GItem xs={12} md={4}>
            <div>
              <ImageCarousel imageUrls={facility.imageUrls} />
            </div>
          </GItem>
          <GItem md={2} sx={{ display: { xs: 'none', md: 'block' } }}></GItem>
        </GContainer>
      </div>
      <BottomButtonGroup>
        {isNotLoginCalendarDisplayFlag && (
          <ButtonL
            type="submit"
            to={{
              pathname: facilityDetailAvailabilityUrl.url(facilityId),
              state: { activeDate: availabilityBaseDate },
            }}
            fullWidth
          >
            {translate('facilityDetail.button.checkAvailability')}
          </ButtonL>
        )}
        {isPreinterviewNo || (
          <ButtonOutlinedWarningL fullWidth to={interviewReservationFormUrl.url(facilityId)}>
            {translate('facilityDetail.button.usageApplication')}
          </ButtonOutlinedWarningL>
        )}
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
