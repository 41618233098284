import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { fromApiHms, toApiYmd } from '../../utils/dateUtil'
import { datePropsToNumber, nullPropsToUndefined } from '../../utils/objectUtil'
import { facilityReservationDeleteConfirmationUrl, reservationStatusListUrl } from '../common/constant/appUrl'
import { reservationStatus, yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { useOperationLog } from '../common/operationLog'
import { getReservation } from '../common/reservation'
import { showLoading } from '../common/store/slices/application'
import {
  FacilityReserveDeleteEntry,
  selectFacilityReserveDeleteEntry,
  setFacilityReserveDeleteEntry,
  setFacilityReserveDeleteUpdateDatetime,
} from '../common/store/slices/facilityReserveDelete'

interface UrlParams {
  reservationNo: string
}
interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}

interface Inputs {
  cancelReson: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { reservationNo } = useParams<UrlParams>()
  const { addOperationLog } = useOperationLog()

  const locationState = history.location.state

  const deleteEntry = useSelector(selectFacilityReserveDeleteEntry)

  const formMethods = useForm<Inputs>({
    ...(locationState?.isKeep && {
      defaultValues: {
        cancelReson: deleteEntry?.cancelReason,
      },
    }),
  })
  const [reservationDetail, setReservationDetail] = useState<
    Omit<FacilityReserveDeleteEntry, 'cancelReason'> | undefined
  >(locationState?.isKeep ? deleteEntry : undefined)

  useEffect(() => {
    if (reservationDetail == null) {
      dispatch(
        showLoading(
          errorHandle(async () => {
            const { updateDatetime, ...detail } = await getReservationDetail(reservationNo)
            dispatch(setFacilityReserveDeleteUpdateDatetime(updateDatetime))
            if (detail.status === reservationStatus.wait) {
              // キャンセル待ちの場合、キャンセル理由の入力は不要なので確認画面まで即遷移
              dispatch(
                setFacilityReserveDeleteEntry({
                  ...detail,
                })
              )
              history.replace(facilityReservationDeleteConfirmationUrl.url())
            } else {
              addOperationLog({
                operationId: OperationId.OP_00000001,
                accessData: [
                  {
                    userIdRegFlag: yesNo.yes,
                    childId: detail.childId,
                    usageDate: toApiYmd(detail.usageDate),
                    reservationNo,
                  },
                ],
              })
              setReservationDetail(detail)
            }
          })
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goList = useCallback(() => history.push(reservationStatusListUrl.url()), [])

  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000036 })

      if (reservationDetail) {
        dispatch(
          setFacilityReserveDeleteEntry({
            ...reservationDetail,
            cancelReason: data.cancelReson,
          })
        )
        // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
        history.replace({ ...history.location, state: { isKeep: true } })
        history.push(facilityReservationDeleteConfirmationUrl.url())
      }
    },
    [reservationDetail, addOperationLog]
  )

  return {
    formMethods,
    reservationDetail,
    onSubmit,
    goList,
  }
}

const getReservationDetail = async (reservationNo: string) => {
  const reservation = await getReservation(reservationNo)
  const facility = await getFacility(reservation.facilityId, reservation.usageDate)

  return nullPropsToUndefined({
    ...datePropsToNumber(reservation),
    cancelLimit: facility.cancelLimit,
    cancelLimitTime: fromApiHms(facility.cancelLimitTime)?.getTime(),
    cancelNote: facility.cancelNote,
    cancelLimitHolidayOption: facility.cancelLimitHolidayOption,
    immediatelyReservationFlag: facility.immediatelyReservationFlag,
    lunchAcceptFlag: facility.lunchAcceptFlag,
    snackAcceptFlag: facility.snackAcceptFlag,
    postponeCancelWaitAcceptFlag: facility.postponeCancelWaitAcceptFlag,
  })
}
