import { styled, Theme, useMediaQuery } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Dialog } from './dialog'
import { Image } from './image'

interface ImageCarouselProps {
  imageUrls: string[]
}

interface DialogState {
  isOpen: boolean
  initialIndex?: number
}

const CarouselBase = styled(Carousel)({
  ul: {
    paddingInlineStart: 0,
  },
  '.thumb,.slide': {
    userSelect: 'none',
  },
})

const MainCarousel = styled(CarouselBase, { shouldForwardProp: (prop) => prop !== 'isClicableItem' })<{
  isClicableItem?: boolean
}>(({ isClicableItem }) => ({
  ...(isClicableItem && {
    '.slide': {
      cursor: 'pointer',
    },
  }),
}))
const DialogCarousel = styled(CarouselBase)({
  height: 'calc(100% - 10px)',
  ' .carousel-slider, .slider-wrapper, .slider, .slide': {
    height: '100%',
  },
})

const ContainImage = styled(Image)(({ theme }) => ({
  objectFit: 'contain',
  backgroundColor: theme.palette.secondary.main,
}))
const MainImage = styled(ContainImage)({
  height: 8 * 40,
})
const ThumbImage = styled(ContainImage)({
  height: 8 * 8,
})
const DialogImage = styled(ContainImage)({
  height: '100%',
})

export const ImageCarousel = (props: ImageCarouselProps) => {
  const isUpMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
  })
  const images = useMemo(
    () => props.imageUrls.map((url, index) => <MainImage key={index} imageUrl={url} />),
    [props.imageUrls]
  )
  const thumbImages = useMemo(
    () => props.imageUrls.map((url, index) => <ThumbImage key={index} imageUrl={url} />),
    [props.imageUrls]
  )
  const dialogImages = useMemo(
    () => props.imageUrls.map((url, index) => <DialogImage key={index} imageUrl={url} />),
    [props.imageUrls]
  )
  const onClickItem = useCallback((index: number) => setDialogState({ isOpen: true, initialIndex: index }), [])
  return (
    <>
      <MainCarousel
        showStatus={false}
        showThumbs={isUpMd}
        showIndicators={!isUpMd}
        infiniteLoop
        renderThumbs={() => thumbImages}
        {...(isUpMd && {
          isClicableItem: true,
          onClickItem,
        })}
      >
        {images}
      </MainCarousel>
      <Dialog
        maxWidth="md"
        isShownCloseButton
        fullHeight
        isOpen={dialogState.isOpen}
        onClose={() => setDialogState({ isOpen: false })}
      >
        <DialogCarousel showStatus={false} showThumbs={false} infiniteLoop selectedItem={dialogState.initialIndex}>
          {dialogImages}
        </DialogCarousel>
      </Dialog>
    </>
  )
}
