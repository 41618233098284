// 認証関連のユーティリティ関数を定義
//
import { SignOutOpts } from '@aws-amplify/auth/lib-esm/types'
import { Auth } from 'aws-amplify'

/** ログインする */
export const signIn = (username: string, password: string) => Auth.signIn(username, password)

/** ログアウトする */
export const signOut = (options?: SignOutOpts) => Auth.signOut(options)

/** フェデレーション(saml)でログイン */
export const federatedSignIn = (providerName: string) => Auth.federatedSignIn({ customProvider: providerName })

/**
 * 現在ログインしているcognitoユーザー情報を取得。
 * ログインしていない場合は例外がスローされる
 *
 * @param bypassCache キャッシュから取得を行わない場合true
 * @returns cognitoユーザー情報
 */
export const getAuthenticatedUser = (bypassCache?: boolean) =>
  Auth.currentAuthenticatedUser({ bypassCache: !!bypassCache })

/**
 * cognitoの電話番号属性に対し検証行う。SMSでワンタイムパスワードの送信が実施される
 */
export const verifyTel = () => Auth.verifyCurrentUserAttribute('phone_number')
/**
 * cognitoの電話番号属性に対し検証行った際に送信されたワンタイムパスワードのチェックを行う。
 * 正しいワンタイムパスワードであれば、電話番号検証済みとなる
 *
 * @param code ワンタイムパスワード
 * @throws {AmplifyAuthError}
 */
export const verifyTelSubmit = (code: string) => Auth.verifyCurrentUserAttributeSubmit('phone_number', code)

/**
 * cognitoのEメール属性に対し検証行う。メールで認証用メールの送信が実施される
 */
export const verifyEmail = () => Auth.verifyCurrentUserAttribute('email')

/**
 * @returns ユーザープールに格納されている情報を確認するのに必要なJWTトークン
 */
export const getSessionIdToken = async () => (await Auth.currentSession()).getIdToken().getJwtToken()
/**
 * @returns ユーザープールの情報の更新のために必要なJWTトークン
 */
export const getSessionAccessToken = async () => (await Auth.currentSession()).getAccessToken().getJwtToken()

/**
 * AuthAPIで発生したエラーインスタンスの内、ハンドリングに必要なプロパティを定義したインターフェース
 *
 * Cognito のエラーコードまとめ
 * https://qiita.com/kugyo/items/b3c1d706a85d5c772670
 */
interface AmplifyAuthError {
  /** エラーの種類を識別するコード */
  code: string
  /** エラーメッセージ(英語) */
  message: string
}

/**
 * AmplifyのAuthAPIで発生する例外のハンドリングする際、
 * エラーインスタンスの型ガード関数
 * @param e AuthAPIで発生したエラーインスタンス
 * @returns 渡されたインスタンスがAmplifyAuthErrorにマッチすればtrue
 */
export const isAmplifyAuthError = (e: any): e is AmplifyAuthError => {
  return e && typeof e.code === 'string' && typeof e.message === 'string'
}
