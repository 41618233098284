import { Box, Stack, styled } from '@mui/material'
import { interviewReservationFormUrl, facilityDetailUrl } from '../../containers/common/constant/appUrl'
import { loginUrl } from '../../containers/common/constant/appUrl'
import { yesNo } from '../../containers/common/constant/classification'
import { useAction } from '../../containers/facilityDetailAvailability/facilityDetailAvailabilityService'
import { translate, translateNode } from '../../i18n'
import { AnnotationLabel } from '../components/common/annotationLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { GContainer, GItem, MiddleGItem, RowSpacerGItem } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { AvailabilityCalendar } from '../components/common/inputs/availabilityCalendar'
import { DatePickerNoBind } from '../components/common/inputs/datePickerNoBind'
import { Form } from '../components/common/inputs/form'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { Link } from '../components/common/link'
import { TitleLabel } from '../components/common/titleLabel'

const FacilityNoteCard = styled(InfoCard)({
  whiteSpace: 'pre-wrap',
})

export const FacilityDetailAvailability = () => {
  const {
    facilityId,
    facility,
    childs,
    activeChildId,
    activeDate,
    availabilities,
    formMethods,
    isExistPermitChilds,
    currentLocation,
    isLoggedIn,
    onNextPrevExec,
    changeChild,
    changeDate,
    callTel,
  } = useAction()

  if (facility == null) {
    return null
  }

  const isPreinterviewNo = facility.preinterviewAcceptFlag === yesNo.no
  const isLoggedInDisp = isExistPermitChilds

  let childAgeStr: string
  if (isLoggedInDisp) {
    childAgeStr = 'facilityDetailAvailability.label.selectionChild'
  } else {
    childAgeStr = 'facilityDetailAvailability.label.ageClass'
  }

  let usageGuideStr: string
  if (facility.immediatelyReservationFlag === yesNo.yes) {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      if (isLoggedInDisp) {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.1'
      } else {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.1'
      }
    } else {
      if (isLoggedInDisp) {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.2'
      } else {
        usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.2'
      }
    }
  } else {
    if (facility.cancelWaitAcceptFlag === yesNo.yes) {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        if (isLoggedInDisp) {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.3'
        } else {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.1'
        }
      } else {
        if (isLoggedInDisp) {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.5'
        } else {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.1'
        }
      }
    } else {
      if (facility.postponeCancelWaitAcceptFlag === yesNo.yes) {
        if (isLoggedInDisp) {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.4'
        } else {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.2'
        }
      } else {
        if (isLoggedInDisp) {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.LoggedIn.6'
        } else {
          usageGuideStr = 'facilityDetailAvailability.label.reserveUsageGuide.NotLoggedIn.2'
        }
      }
    }
  }

  return (
    <Stack spacing={2}>
      <GContainer>
        <GItem md={3}></GItem>
        <GItem xs={12} md={6}>
          <Box>
            <GContainer rowSpacing={1}>
              <GItem xs={12}>
                <Link to={facilityDetailUrl.url(facilityId)}>
                  <TitleLabel>{facility.facilityName}</TitleLabel>
                </Link>
              </GItem>

              <GItem xs={12}>
                <GContainer>
                  <MiddleGItem xs={5} md={4}>
                    {translate(childAgeStr)}
                  </MiddleGItem>

                  <MiddleGItem xs={7} md={8}>
                    <SelectNoBind fullWidth onChange={changeChild} valueLabels={childs} value={activeChildId} />
                  </MiddleGItem>
                </GContainer>

                <GContainer>
                  <MiddleGItem xs={5} md={4}>
                    {translate('facilityDetailAvailability.label.selectTheDesiredDateOfUse')}
                  </MiddleGItem>
                  <MiddleGItem xs={7} md={8}>
                    <DatePickerNoBind fullWidth value={activeDate} onChange={changeDate} />
                  </MiddleGItem>
                  <RowSpacerGItem />
                </GContainer>
              </GItem>

              <GItem xs={12}>
                <Stack spacing={2}>
                  <AnnotationLabel>{translate(usageGuideStr)}</AnnotationLabel>

                  <AvailabilityCalendar
                    name="usageDesiredDatetimes"
                    label={translate('facilityDetailAvailability.label.availabilityCalendar')}
                    baseDate={activeDate}
                    receptionTimePattern={facility.receptionTimePattern}
                    availabilities={availabilities ?? []}
                    onChangeBaseDate={onNextPrevExec}
                    control={formMethods.control}
                    isDisplayOnly={true}
                  />
                </Stack>
              </GItem>

              <GItem xs={12}>
                <FacilityNoteCard>{facility?.note}</FacilityNoteCard>
              </GItem>
            </GContainer>
          </Box>
          <div>{translate('facilityDetailAvailability.label.annotation')}</div>
          {!isLoggedIn && (
            <div>
              {translateNode(
                'facilityDetailAvailability.text.loginAnnotation',
                <Link
                  to={{
                    pathname: loginUrl.url(),
                    state: { from: currentLocation },
                  }}
                  replace
                >
                  {translate('facilityDetailAvailability.text.loginHere')}
                </Link>
              )}
            </div>
          )}
        </GItem>
        <GItem md={3}></GItem>
      </GContainer>

      <Form formMethods={formMethods}>
        <BottomButtonGroup>
          {isLoggedInDisp &&
            facility.reservationAcceptFlag === yesNo.no &&
            facility.notLoginCalendarDisplayFlag === yesNo.yes && (
              <ButtonOutlinedWarningL fullWidth onClick={() => callTel(facility.tel)}>
                {translate('facilityDetailAvailability.button.telReservation')}
              </ButtonOutlinedWarningL>
            )}
          {isPreinterviewNo || (
            <ButtonOutlinedWarningL fullWidth to={interviewReservationFormUrl.url(facilityId)}>
              {translate('facilityDetailAvailability.button.usageApplication')}
            </ButtonOutlinedWarningL>
          )}
          <BackButton />
        </BottomButtonGroup>
      </Form>
    </Stack>
  )
}
