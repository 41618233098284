import { SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executeGetReductions } from '../../dataAccess/webApi/dao/reductionsDao'
import { fromApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined } from '../../utils/objectUtil'
import { Child, ChildrenChoice, getChild, getChildrenChoices } from '../common/child'
import { memberAddChildUrl, memberModifyChildUrl, memberModifyUserUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import { selectIsVerifiedAll } from '../common/store/slices/authority'
import { User, getUser } from '../common/user'
import { selectSystemControl } from '../common/store/slices/systemControl'

interface LocationState {
  /** アクティブなタブの値 */
  tabValue?: string
  /** アクティブなお子さまID */
  childId?: string
}

interface PageState {
  activeTabValue: string
  activeChildId?: string
  user?: User
  child?: Child
  childs?: ChildrenChoice[]
}
interface DialogState {
  reductionName: string
  reductionInfos: {
    status: string
    reductionStartDate?: Date
    reductionEndDate?: Date
  }[]
}

/** タブの選択値 */
export const tabValue = {
  user: '1',
  child: '2',
} as const

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { addOperationLog } = useOperationLog()
  const sysCtrl = useSelector(selectSystemControl)

  const isVerifiedAll = useSelector(selectIsVerifiedAll)

  const locationState = history.location.state
  const [isOpenReductionsHistory, setIsOpenReductionsHistory] = useState(false)
  const [state, setState] = useState<PageState>({
    activeTabValue: locationState?.tabValue ?? tabValue.user,
    activeChildId: locationState?.childId,
  })
  const [reductionHistoryState, setReductionHistoryState] = useState<DialogState>()
  const load = useCallback(
    (
      isInitialize: boolean,
      activeTabValue: string,
      loadedChilds?: { value: string; label: string }[],
      activeChildId?: string
    ) => {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            let childs
            let childId
            let user
            let child
            if (activeTabValue === tabValue.user) {
              childs = loadedChilds
              childId = activeChildId
              user = await getUser()
            } else {
              childs = loadedChilds == null ? await getChildrenChoices() : loadedChilds
              childId = activeChildId ?? childs[0].value
              child = await getChild(childId)
            }

            setState({
              activeTabValue,
              activeChildId: childId,
              user,
              child,
              childs,
            })
          }),
          isHiddenMain: isInitialize,
        })
      )
    },
    []
  )

  // ちらつき防止のためにuseLayoutEffect使用
  useLayoutEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes }] })

    load(true, state.activeTabValue, state.childs, state.activeChildId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 戻るで表示した際にアクティブな選択を復元する為に履歴に保管
    history.replace({ ...history.location, state: { tabValue: state.activeTabValue, childId: state.activeChildId } })
  }, [state.activeTabValue, state.activeChildId])

  const changeTab = useCallback(
    (value: string) => {
      if (value === tabValue.user) {
        addOperationLog({ operationId: OperationId.OP_00000052 })
      } else if (value === tabValue.child) {
        addOperationLog({ operationId: OperationId.OP_00000053 })
      }

      load(false, value, state.childs, state.activeChildId)
    },
    [state.childs, state.activeChildId, addOperationLog]
  )

  const changeChild = useCallback(
    (event: SelectChangeEvent<string>) => {
      addOperationLog({ operationId: OperationId.OP_00000028 })

      load(false, state.activeTabValue, state.childs, event.target.value)
    },
    [state.activeTabValue, state.childs, addOperationLog]
  )

  const goModify = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000057 })

    if (state.activeTabValue === tabValue.user) {
      history.push(memberModifyUserUrl.url())
    } else if (state.activeChildId) {
      // 型ガードの為にnullチェックをしている。※ここを通る際はactiveChildIdは必ず設定済み
      history.push(memberModifyChildUrl.url(state.activeChildId))
    }
  }, [state.activeTabValue, state.activeChildId, addOperationLog])

  const goAddChild = useCallback(() => {
    history.push(memberAddChildUrl.url())
  }, [history])

  const onClickReductionHistories = useCallback(
    (id: string) => {
      addOperationLog({ operationId: OperationId.OP_00000054 })

      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const result = await getReductionHistories(id)
            setReductionHistoryState(result)
            setIsOpenReductionsHistory(true)
          }),
          isHiddenMain: false,
        })
      )
    },
    [addOperationLog]
  )

  const onCloseReductionsHistory = useCallback(() => {
    addOperationLog({ operationId: OperationId.OP_00000055 })

    setIsOpenReductionsHistory(false)
  }, [addOperationLog])

  const getReductionHistories = async (id: string) => {
    const response = await executeGetReductions(id)
    const reductionInfos = response.result.reductionInfos.map((v) => {
      return nullPropsToUndefined({
        status: v.status,
        reductionStartDate: fromApiYmd(v.reductionStartDate),
        reductionEndDate: fromApiYmd(v.reductionEndDate),
      })
    })

    return nullPropsToUndefined({
      reductionName: response.result.reductionName,
      reductionInfos: reductionInfos,
    })
  }
  return {
    isVerifiedAll,
    activeTabValue: state.activeTabValue,
    activeChildId: state.activeChildId,
    childs: state.childs,
    user: state.user,
    child: state.child,
    isOpenReductionsHistory,
    reductionHistoryState,
    changeTab,
    changeChild,
    goModify,
    goAddChild,
    onClickReductionHistories,
    onCloseReductionsHistory,
    sysCtrl,
  }
}
