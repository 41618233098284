// GoogleMap上に各施設を数字入りのピンでマークするコントロール
//

import { IconButton, styled, Theme, ThemeProvider, useTheme } from '@mui/material'
import { GoogleMap, InfoWindow, LoadScript, Marker, useGoogleMap } from '@react-google-maps/api'
import React, { memo, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import { googleMapApiKey } from '../../../containers/common/constant/processEnv'
import { getLanguage, translate } from '../../../i18n'
import { ErrorMessageCard } from './errorMessageCard'
import { CurrentLocationIcon } from './icons/currentLocationIcon'
import { createNumberPinSvg, numberPinSvgViewBox } from './icons/numberPin'

interface Facility {
  facilityId: string
  facilityName: string
  latitude: number
  longitude: number
}

interface MapSize {
  pinIconSize: google.maps.Size
  infoOffset: google.maps.Size
}
interface FacilityMapProps {
  /** 地図中央の緯度経度 */
  center?: { latitude: number; longitude: number }
  /**
   * 地図のズームレベル。
   * 公式ではないが以下サイトでどの程度の拡大か確認できる
   * https://qiita.com/SnowMonkey/items/795779913be692c12a0b
   */
  zoom?: number
  /** マップに表示したい施設情報の配列 */
  facilities?: Facility[]
  /** 現在地表示の機能を無効化する場合true */
  isDisabledCurrentLocation?: boolean
  /**
   * 現在地表示のボタンを非表示にする場合true
   * 施設詳細での施設1件表示を考慮して作成したもの
   */
  isHideCurrentLocation?: boolean
  /**
   * 現在地表示のボタンを非表示にする場合true
   * 施設詳細での施設1件表示を考慮して作成したもの
   */
  isHidePinNumber?: boolean
  /**
   * 現在位置表示のボタンをクリックしたときに呼び出される関数
   */
  onClickCurrentLocation?: () => void
  /**
   * 施設の位置を示す数字ピンをクリックしたときに呼び出される関数
   */
  onClickFacilityPin?: (facilityId: string) => void
}

const pinIconSize = {
  height: 35,
  width: 35,
}
const infoOffset = -45

const mapContainerStyle = {
  width: '100%',
  height: '100%',
}

/** デフォルトのマップ表示位置・ズーム */
const defaultMapPan = {
  center: { latitude: 0, longitude: 0 },
  zoom: 13,
}

const CurrentLocationButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  width: '40px',
  height: '40px',
  marginRight: '10px',
  borderRadius: 4,
  boxShadow: theme.shadows[2],
  '&:hover': {
    backgroundColor: theme.palette.white.dark,
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: theme.palette.white.main,
    },
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.white.main,
  },
}))

function jsxToHtml(jsx: React.ReactElement): string {
  return ReactDOMServer.renderToStaticMarkup(jsx)
}
function getPinIconUrl(theme: Theme, serialNumber?: number) {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={pinIconSize.width}
      height={pinIconSize.height}
      viewBox={numberPinSvgViewBox}
    >
      {createNumberPinSvg(theme, '1rem', serialNumber)}
    </svg>
  )
  return 'data:image/svg+xml,' + encodeURIComponent(jsxToHtml(icon))
}

interface MapPanningProps {
  center?: { latitude: number; longitude: number }
  zoom?: number
}
const MapPanning = (props: MapPanningProps) => {
  const map = useGoogleMap()
  React.useEffect(() => {
    if (map) {
      const center = props.center ?? defaultMapPan.center
      const zoom = props.zoom ?? defaultMapPan.zoom
      map.panTo({ lat: center.latitude, lng: center.longitude })
      map.setZoom(zoom)
    }
  }, [map, props.center, props.zoom])

  return null
}
interface MapMarkerProps {
  onClickMarker: (facility: Facility) => void
  facilities?: Facility[]
  isHidePinNumber?: boolean
  mapSize?: MapSize
}
const MapMarker = memo(function MapMarker({ onClickMarker, facilities, isHidePinNumber, mapSize }: MapMarkerProps) {
  const theme = useTheme()
  return (
    <>
      {facilities?.map((facility, index) => {
        return (
          <Marker
            key={facility.facilityId}
            position={{
              lat: facility.latitude,
              lng: facility.longitude,
            }}
            icon={{
              url: isHidePinNumber ? getPinIconUrl(theme) : getPinIconUrl(theme, index + 1),
              scaledSize: mapSize?.pinIconSize,
            }}
            onClick={() => onClickMarker(facility)}
          />
        )
      })}
    </>
  )
})

/** GoogleMap上に各施設を数字入りのピンでマークするコントロール */
export const FacilityMap = memo(function FacilityMap(props: FacilityMapProps) {
  const theme = useTheme()

  const [mapSize, setMapSize] = useState<MapSize>()
  const [controlContainer, setControlContainer] = useState<HTMLDivElement>()
  const [infoFacility, setInfoFacility] = useState<Facility>()

  const language = getLanguage()

  const renderControlContainer = useCallback(
    (container: HTMLDivElement) => {
      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <CurrentLocationButton disabled={props.isDisabledCurrentLocation} onClick={props.onClickCurrentLocation}>
            <CurrentLocationIcon />
          </CurrentLocationButton>
        </ThemeProvider>,
        container
      )
    },
    [props.isDisabledCurrentLocation, props.onClickCurrentLocation]
  )

  const onLoadScript = useCallback(() => {
    setMapSize({
      pinIconSize: new google.maps.Size(pinIconSize.width, pinIconSize.height),
      infoOffset: new google.maps.Size(0, infoOffset),
    })
  }, [mapSize])

  const onLoadMap = useCallback((map: google.maps.Map) => {
    if (!props.isHideCurrentLocation) {
      const container = document.createElement('div')
      renderControlContainer(container)
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(container)
      setControlContainer(container)
    }
  }, [])

  const onClickMarker = useCallback(
    (facility: Facility) => {
      props.onClickFacilityPin?.(facility.facilityId)
      setInfoFacility(facility)
    },
    [props.onClickFacilityPin]
  )

  useEffect(() => {
    if (controlContainer) {
      renderControlContainer(controlContainer)
    }
  }, [props.isDisabledCurrentLocation, controlContainer])

  return googleMapApiKey ? (
    <LoadScript googleMapsApiKey={googleMapApiKey} language={language} onLoad={onLoadScript}>
      {mapSize && (
        <GoogleMap onLoad={onLoadMap} mapContainerStyle={mapContainerStyle}>
          <MapPanning center={props.center} zoom={props.zoom} />
          <MapMarker
            onClickMarker={onClickMarker}
            facilities={props.facilities}
            isHidePinNumber={props.isHidePinNumber}
            mapSize={mapSize}
          />
          {infoFacility && (
            <InfoWindow
              position={{
                lat: infoFacility.latitude,
                lng: infoFacility.longitude,
              }}
              options={{ pixelOffset: mapSize?.infoOffset }}
              onCloseClick={() => setInfoFacility(undefined)}
            >
              <ThemeProvider theme={theme}>
                <div>{infoFacility.facilityName}</div>
              </ThemeProvider>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </LoadScript>
  ) : (
    <ErrorMessageCard messages={[translate('system.facilityMap.notSetApiKey')]} />
  )
})
