// 施設情報(概要)をカード型で表示するコントロール
//

import { Stack, styled } from '@mui/material'
import { ReactNode, useCallback } from 'react'
import { getAvailableLabel } from '../../../containers/common/codeMaster'
import { translate } from '../../../i18n'
import { formatAcceptAge, formatAcceptAgeRange, formatAddress } from '../../../utils/itemFormatUtil'
import { BreakpointSwitch } from './breakpointSwitch'
import { InlineFlexbox } from './flexboxes'
import { CenterGItem, FlexGItem, GContainer, GItem, GItemContainer, RowSpacerGItem, TopGItem } from './grids'
import { AcceptAgeIcon } from './icons/acceptAgeIcon'
import { AcceptWebReservationIcon } from './icons/acceptWebReservationIcon'
import { LocationIcon } from './icons/locationIcon'
import { ProjectIcon } from './icons/projectIcon'
import { TimeIcon } from './icons/timeIcon'
import { TrainIcon } from './icons/trainIcon'
import { Image } from './image'
import { Link } from './link'

interface CardProps {
  /**
   * カード下部に配置するコンテンツ
   * ボタン類を配置する想定
   */
  children?: ReactNode
  /**
   * 施設名の左側に配置するアイコン。
   * 施設検索での数字ピンアイコンの配置を想定
   */
  titleIcon?: ReactNode

  facilityName: string
  projectName?: string
  address1?: string
  address2?: string
  buildingNameRoomNo?: string
  nearestStation?: string
  openTimeWeekday?: string
  openTimeWeekend?: string
  imageUrl?: string
  acceptStartAge?: number
  acceptStartMonth?: number
  acceptStartDay?: number
  acceptStartAgeGradeFlag?: string
  acceptEndAge?: number
  acceptEndMonth?: number
  acceptEndDay?: number
  acceptEndAgeGradeFlag?: string
  reservationAcceptFlag: string

  /** 施設画像(imageUrl)を表示しない場合true */
  isDisabledImage?: boolean
  /** 施設名をクリック可能にした際、引数として渡される施設ID */
  facilityId?: string
  /**
   * 施設名をクリックした時に呼び出される関数。
   * 未設定なら施設名はクリック可能にならない
   */
  onClickFacilityName?: (facilityId?: string) => void
}

const Root = styled('div')(({ theme }) => ({
  fontSize: theme.typography.font.sizeM,
  color: theme.palette.primary.main,
  borderRadius: 8,
  backgroundColor: theme.palette.white.main,
  border: `3px solid ${theme.palette.primary.main}`,
  width: '100%',
  padding: '10px',
}))
const TitleIconGItem = styled(GItem)({
  fontSize: '3.5rem',
})
const TitleGItem = styled(GItem)(({ theme }) => ({
  fontSize: theme.typography.font.sizeL1,
}))
const ItemIconBox = styled(InlineFlexbox)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 24,
  fontSize: '1.4rem',
  marginTop: '0.15rem',
  marginRight: '4px',
}))
const ItemLabel = styled('span')(({ theme }) => ({
  fontSize: theme.typography.font.sizeM,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
}))
const FacilityImage = styled(Image)(({ theme }) => ({
  maxHeight: 120,
  objectFit: 'contain',
  backgroundColor: theme.palette.secondary.main,
}))

/**
 * 施設情報(概要)をカード型で表示するコントロール。
 * 施設検索結果、My施設で共有するためにcommonに配置している
 */
export const FacilityCard = (props: CardProps) => {
  const { onClickFacilityName, facilityId } = props

  const handleClickFacilityName = useCallback(
    () => onClickFacilityName?.(facilityId),
    [onClickFacilityName, facilityId]
  )

  const address = formatAddress(props.address1, props.address2, props.buildingNameRoomNo)
  const acceptAgeFrom = formatAcceptAge(
    props.acceptStartAge,
    props.acceptStartMonth,
    props.acceptStartDay,
    props.acceptStartAgeGradeFlag
  )
  const acceptAgeTo = formatAcceptAge(
    props.acceptEndAge,
    props.acceptEndMonth,
    props.acceptEndDay,
    props.acceptEndAgeGradeFlag
  )

  return (
    <Root>
      <GContainer rowSpacing={1}>
        <GItemContainer xs={12} md={props.isDisabledImage ? 12 : 10}>
          <TitleIconGItem xs="auto">{props.titleIcon}</TitleIconGItem>

          <GItemContainer xs columnSpacing={1}>
            <TitleGItem xs={12}>
              {props.onClickFacilityName ? (
                <Link onClick={handleClickFacilityName}>{props.facilityName}</Link>
              ) : (
                props.facilityName
              )}
            </TitleGItem>
            <RowSpacerGItem />

            <FlexGItem xs={12} md={6}>
              <GContainer rowSpacing={{ xs: 0, md: 1 }}>
                <TopGItem xs={12}>
                  <ItemIconBox>
                    <LocationIcon fontSize="inherit" />
                  </ItemIconBox>
                  <ItemLabel>{address}</ItemLabel>
                </TopGItem>
                {props.nearestStation && (
                  <TopGItem xs={12}>
                    <ItemIconBox>
                      <TrainIcon fontSize="inherit" />
                    </ItemIconBox>
                    <ItemLabel>{props.nearestStation}</ItemLabel>
                  </TopGItem>
                )}
                {(props.openTimeWeekday || props.openTimeWeekend) && (
                  <TopGItem xs={12}>
                    <ItemIconBox>
                      <TimeIcon fontSize="inherit" />
                    </ItemIconBox>
                    <Stack>
                      {props.openTimeWeekday && (
                        <div>
                          <ItemLabel>
                            {translate('system.facilityCard.weekday')}：{props.openTimeWeekday}
                          </ItemLabel>
                        </div>
                      )}
                      {props.openTimeWeekend && (
                        <div>
                          <ItemLabel>
                            {translate('system.facilityCard.weekend')}：{props.openTimeWeekend}
                          </ItemLabel>
                        </div>
                      )}
                    </Stack>
                  </TopGItem>
                )}
              </GContainer>
            </FlexGItem>
            <FlexGItem xs={12} md={6}>
              <GContainer rowSpacing={{ xs: 0, md: 1 }}>
                {acceptAgeFrom && acceptAgeTo && (
                  <TopGItem xs={12}>
                    <ItemIconBox>
                      <AcceptAgeIcon fontSize="inherit" />
                    </ItemIconBox>
                    <div>{formatAcceptAgeRange(acceptAgeFrom, acceptAgeTo)}</div>
                  </TopGItem>
                )}
                {props.projectName && (
                  <TopGItem xs={12}>
                    <ItemIconBox>
                      <ProjectIcon fontSize="inherit" />
                    </ItemIconBox>
                    <ItemLabel>{props.projectName}</ItemLabel>
                  </TopGItem>
                )}
                <TopGItem xs={12}>
                  <ItemIconBox>
                    <AcceptWebReservationIcon fontSize="inherit" />
                  </ItemIconBox>
                  <ItemLabel>
                    {translate('system.facilityCard.acceptWebReservation')}：
                    {getAvailableLabel(props.reservationAcceptFlag)}
                  </ItemLabel>
                </TopGItem>
              </GContainer>
            </FlexGItem>
          </GItemContainer>
        </GItemContainer>
        {!props.isDisabledImage && (
          <BreakpointSwitch
            md={
              <GItem md>
                <FacilityImage imageUrl={props.imageUrl} />
              </GItem>
            }
          />
        )}
        {props.children && <CenterGItem xs={12}>{props.children}</CenterGItem>}
      </GContainer>
    </Root>
  )
}
