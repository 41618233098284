import { styled } from '@mui/material'
import { rootUrl } from '../../containers/common/constant/appUrl'
import { useAction } from '../../containers/underMaintenance/underMaintenanceService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { CenterGItem, GContainer, GItemContainer } from '../components/common/grids'
import { Link } from '../components/common/link'
import { TitleLabel } from '../components/common/titleLabel'

const MessageItem = styled(CenterGItem)({
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
})

export const UnderMaintenance = () => {
  const { maintenanceMessage } = useAction()
  if (maintenanceMessage == null) {
    return null
  }
  return (
    <GContainer rowSpacing={3}>
      <CenterGItem xs={12}>
        <TitleLabel>{maintenanceMessage.title ?? translate('underMaintenance.title.underMaintenance')}</TitleLabel>
      </CenterGItem>
      <GItemContainer xs={12} spacing={1}>
        <CenterGItem xs={12}>
          {maintenanceMessage.maintenanceDatetimeTitle ?? translate('underMaintenance.title.maintenanceDatetime')}
        </CenterGItem>
        <CenterGItem xs={12}>
          <AttentionLabel>{maintenanceMessage.maintenanceDatetime}</AttentionLabel>
        </CenterGItem>
      </GItemContainer>
      <MessageItem xs={12}>{maintenanceMessage.message ?? translate('underMaintenance.text.message')}</MessageItem>
      <CenterGItem xs={12}>
        {/* クライアントコードを最新化させるためにリロードを伴った遷移をさせる */}
        <Link href={rootUrl.url()}>{translate('system.text.backToHome')}</Link>
      </CenterGItem>
    </GContainer>
  )
}
