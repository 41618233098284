import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { translate } from '../../../../i18n'
import { ButtonOutlinedResponsive } from './buttonOutlinedResponsive'

interface BackButtonProps {
  className?: string
}

/**
 * 戻るボタン。
 * 画面下部に配置する戻るボタンには、基本的にこのコントロールを使用する
 */
export const BackButton = (props: BackButtonProps) => {
  const history = useHistory()
  const goBack = useCallback(() => history.goBack(), [])

  return (
    <ButtonOutlinedResponsive className={props.className} onClick={goBack}>
      {translate('system.button.back')}
    </ButtonOutlinedResponsive>
  )
}
