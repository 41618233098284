import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useAction } from '../../containers/accountEmailChange/accountEmailChangeService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonOutlinedWarningL } from '../components/common/buttons/buttonOutlinedWarningL'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'

export const AccountEmailChange = () => {
  const { formMethods, onSubmit } = useAction()
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <Stack spacing={4}>
        <Grid container>
          <Grid item md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <AttentionLabel>*</AttentionLabel>
                  {translate('accountEmailChange.label.newEmail')}
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    name="newEmail"
                    label={translate('accountEmailChange.label.newEmail')}
                    textType="email"
                    maxLength={320}
                    required
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <AttentionLabel>*</AttentionLabel>
                  {translate('accountEmailChange.label.newEmailConfirmation')}
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    name="newEmailConfirmation"
                    label={translate('accountEmailChange.label.newEmailConfirmation')}
                    textType="email"
                    maxLength={320}
                    required
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>

        <BottomButtonGroup>
          <ButtonOutlinedWarningL fullWidth type="submit">
            {translate('accountEmailChange.button.change')}
          </ButtonOutlinedWarningL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
