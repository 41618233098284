import React, { ReactNode } from 'react'
import { styled, Box } from '@mui/material'

interface TagProps {
  children: ReactNode
}

const Root = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  fontSize: theme.typography.font.sizeM,
  color: theme.palette.primary.contrastText,
  borderRadius: 24,
  backgroundColor: theme.palette.primary.main,
  padding: '7px 15px',
  lineHeight: 'normal',
}))

export const Tag = (props: TagProps) => {
  return <Root>{props.children}</Root>
}
