import { Stack, styled } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ChildrenChoice } from '../../../containers/common/child'
import { translate } from '../../../i18n'
import { ButtonOutlinedS } from '../common/buttons/buttonOutlinedS'
import { ButtonS } from '../common/buttons/buttonS'
import { Dialog } from '../common/dialog'
import { GContainer, GItem } from '../common/grids'
import { RadioButtonGroup } from '../common/inputs/radioButtonGroup'
import { Link } from '../common/link'
import { useHistory } from 'react-router'
import { memberAddChildUrl } from '../../../containers/common/constant/appUrl'

interface ChildSelectDialogProps {
  isOpen: boolean
  onClose: (isCancel: boolean, childId?: string) => void
  childs: ChildrenChoice[]
  interviewedChildsAndStatus: ChildrenChoice[]
  isShowToNextButton?: boolean
}

interface Inputs {
  activeChildId: string
}

const MessageBox = styled('div')({
  whiteSpace: 'pre-wrap',
})

const InterviewedChildsAndStatusItem = styled(GItem)({
  marginBottom: '5px',
  marginLeft: '3.1rem',
})

export const ChildSelectDialog = (props: ChildSelectDialogProps) => {
  const history = useHistory()
  const formMethods = useForm<Inputs>()

  useEffect(() => {
    formMethods.reset({ activeChildId: props.childs[0]?.value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen])

  const close = useCallback(
    (isCancel: boolean, childId?: string) => {
      props.onClose(isCancel, childId)
    },
    [props]
  )
  const cancel = useCallback(() => {
    close(true)
  }, [close])

  const onSubmit = useCallback(
    (data: Inputs) => {
      close(false, data.activeChildId)
    },
    [close]
  )

  const goAddChild = useCallback(() => {
    history.push(memberAddChildUrl.url(), { completionTo: history.location })
  }, [history])

  const interviewedChildsAndStatusConstents = props.interviewedChildsAndStatus.map((v) => (
    <InterviewedChildsAndStatusItem key={v.value} xs={12}>
      {v.label}
    </InterviewedChildsAndStatusItem>
  ))

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={cancel}
      formMethods={formMethods}
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonOutlinedS onClick={cancel}>{translate('system.button.back')}</ButtonOutlinedS>
          {props.isShowToNextButton && (
            <ButtonS type="submit">{translate('accountConfirmation.button.toNext')}</ButtonS>
          )}
        </>
      }
    >
      <Stack spacing={2}>
        <MessageBox>{translate('accountConfirmation.text.childSelectDialogMessage')}</MessageBox>
        <GContainer>
          {interviewedChildsAndStatusConstents}
          <GItem>
            <RadioButtonGroup name="activeChildId" label="お子さま" required buttonValueLabels={props.childs} />
          </GItem>
        </GContainer>
        <GContainer>
          <GItem>
            <Link onClick={goAddChild} underline="none">
              ＋ {translate('accountConfirmation.text.addChild')}
            </Link>
          </GItem>
        </GContainer>
      </Stack>
    </Dialog>
  )
}
