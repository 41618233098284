import React from 'react'
import { Control, useController } from 'react-hook-form'
import { isAfter } from 'date-fns'
import { translate } from '../../../../i18n'
import { isValidDate, formatYmd } from '../../../../utils/dateUtil'
import { DatePickerNoBind } from './datePickerNoBind'
import { getValueByNameStr } from '../../../../utils/objectUtil'

interface DatePickerProps {
  name: string
  label: string
  required?: boolean
  fullWidth?: boolean
  control?: Control<any, any>
  maxDate?: Date
  disabled?: boolean
}

/** 日付フォームコントロール */
export const DatePicker = (props: DatePickerProps) => {
  const { name, label, required, control, disabled, ...through } = props
  const {
    field: { ref, value, onChange, onBlur },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: { value: !!required, message: translate('system.error.requiredInput', label) },
      validate: {
        invalidDate: (value?: Date) =>
          value == null || isValidDate(value) || translate('system.error.correctInput', label),
        // maxDateが指定されているときのみ
        invalidDateLimitByMaxDate: (value?: Date) => {
          if (props.maxDate && isValidDate(value) && isAfter(value, props.maxDate)) {
            return translate('system.error.limitOverDate', label, formatYmd(props.maxDate))
          }
          return true
        },
      },
    },
    defaultValue: null,
    control,
  })

  const error = getValueByNameStr(errors, name)
  return (
    <DatePickerNoBind
      {...through}
      ref={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={!!error}
      errorMessage={error?.message}
      disabled={disabled}
    />
  )
}
