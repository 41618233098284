import { Box, Stack, styled } from '@mui/material'
import { useAction } from '../../containers/facilitySearchCondition/facilitySearchConditionService'
import { translate } from '../../i18n'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { GContainer, GItem, MiddleGItem } from '../components/common/grids'
import { SearchGlassIcon } from '../components/common/icons/searchGlassIcon'
import { DatePicker } from '../components/common/inputs/datePicker'
import { Form } from '../components/common/inputs/form'
import { TextBox } from '../components/common/inputs/textBox'
import { ToggleButtonGroup } from '../components/common/inputs/toggleButtonGroup'
import { RequiredLabel } from '../components/common/requiredLabel'
import { SubTitle } from '../components/common/subTitle'

const UsageDateGItem = styled(GItem)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '13rem',
  },
}))

export const FacilitySearchCondition = () => {
  const { codeMaster, onSubmit, formMethods } = useAction()
  
  return (
    <Form onSubmit={onSubmit} formMethods={formMethods}>
      <Stack spacing={2}>
        <Box>
          <GContainer rowSpacing={2}>
            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <Stack spacing={2}>
                <SubTitle
                  title={
                    <>
                      {translate('facilitySearchCondition.title.age')}
                      <RequiredLabel size="sizeM" />
                    </>
                  }
                />
                <ToggleButtonGroup
                  required
                  name="ages"
                  label={translate('facilitySearchCondition.button.age')}
                  buttonValueLabels={codeMaster.ages ?? []}
                  xsColumns={3}
                  mdColumns={3}
                />
              </Stack>
            </GItem>
            <GItem md={2}></GItem>

            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <Stack spacing={2}>
                <SubTitle title={translate('facilitySearchCondition.title.area')} />
                <ToggleButtonGroup
                  name="areas"
                  label={translate('facilitySearchCondition.button.area')}
                  buttonValueLabels={codeMaster.areas ?? []}
                  xsColumns={3}
                  mdColumns={6}
                />
              </Stack>
            </GItem>
            <GItem md={2}></GItem>

            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <GContainer rowSpacing={2} columnSpacing={1}>
                <GItem xs={12}>
                  <SubTitle title={translate('facilitySearchCondition.title.acceptWebReservation')} />
                </GItem>
                <GItem>
                  <ToggleButtonGroup
                    name="reservationAcceptFlag"
                    label={translate('facilitySearchCondition.button.acceptWebReservation')}
                    buttonValueLabels={codeMaster.reservationAcceptFlag ?? []}
                  />
                </GItem>
                <MiddleGItem>{translate('facilitySearchCondition.label.usageDate')}</MiddleGItem>
                <UsageDateGItem>
                  <DatePicker name="usageDate" label={translate('facilitySearchCondition.textbox.usageDate')} />
                </UsageDateGItem>
              </GContainer>
            </GItem>
            <GItem md={2}></GItem>

            {!!codeMaster.projects?.length && (
              <>
                <GItem md={3}></GItem>
                <GItem xs={12} md={6}>
                  <Stack spacing={2}>
                    <SubTitle title={translate('facilitySearchCondition.title.project')} />
                    <ToggleButtonGroup
                      name="projectIds"
                      label={translate('facilitySearchCondition.button.project')}
                      buttonValueLabels={codeMaster.projects}
                      xsColumns={2}
                      mdColumns={3}
                    />
                  </Stack>
                </GItem>
                <GItem md={2}></GItem>
              </>
            )}

            {!!codeMaster.facilityCategories?.length && (
              <>
                <GItem md={3}></GItem>
                <GItem xs={12} md={6}>
                  <Stack spacing={2}>
                    <SubTitle title={translate('facilitySearchCondition.title.facilityCategory')} />
                    <ToggleButtonGroup
                      name="facilityCategories"
                      label={translate('facilitySearchCondition.button.facilityCategory')}
                      buttonValueLabels={codeMaster.facilityCategories}
                      xsColumns={2}
                      mdColumns={3}
                    />
                  </Stack>
                </GItem>
                <GItem md={2}></GItem>
              </>
            )}

            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <Stack spacing={2}>
                <SubTitle title={translate('facilitySearchCondition.title.otherDetailCondition')} />
                <ToggleButtonGroup
                  name="otherDetailConditions"
                  label={translate('facilitySearchCondition.button.otherDetailCondition')}
                  buttonValueLabels={
                    // 空文字（or半角スペースのみの文字列）の要素を除外
                    codeMaster.otherDetailConditions?.filter( (map:any) => {return (map?.label?.match(/^[ ]*$/) == null)} )
                    ?? []
                  }
                  xsColumns={2}
                  mdColumns={3}
                />
              </Stack>
            </GItem>
            <GItem md={2}></GItem>

            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <Stack spacing={2}>
                <SubTitle title={translate('facilitySearchCondition.title.purposeOfUse')} />
                <ToggleButtonGroup
                  name="purposeOfUses"
                  label={translate('facilitySearchCondition.button.purposeOfUse')}
                  buttonValueLabels={codeMaster.purposeOfUses ?? []}
                  xsColumns={2}
                  mdColumns={3}
                />
              </Stack>
            </GItem>
            <GItem md={2}></GItem>

            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <Stack spacing={2}>
                <SubTitle title={translate('facilitySearchCondition.title.freeWord')} />
                <TextBox
                  name="freeWord"
                  label={translate('facilitySearchCondition.textbox.freeWord')}
                  placeholder={translate('facilitySearchCondition.textbox.placeholder')}
                  maxLength={50}
                  fullWidth
                  startIcon={<SearchGlassIcon />}
                />
              </Stack>
            </GItem>
            <GItem md={3}></GItem>

            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <ErrorMessageCard messages={[formMethods.formState.errors.ages?.message]} />
            </GItem>
            <GItem md={2}></GItem>
          </GContainer>
        </Box>

        <BottomButtonGroup>
          <ButtonL type="submit" fullWidth>
            {translate('facilitySearchCondition.button.search')}
          </ButtonL>
          <BackButton />
        </BottomButtonGroup>
      </Stack>
    </Form>
  )
}
