import { useCallback } from 'react'
import { translate } from '../../../../i18n'
import { ButtonOutlinedResponsive } from './buttonOutlinedResponsive'

interface WindowCloseButtonProps {
  className?: string
}

/**
 * ウィンドウを閉じるボタン
 */
export const WindowCloseButton = (props: WindowCloseButtonProps) => {
  const closeWindow = useCallback(() => {
    window.close()
  }, [])

  return (
    <ButtonOutlinedResponsive className={props.className} onClick={closeWindow}>
      {translate('system.button.close')}
    </ButtonOutlinedResponsive>
  )
}
