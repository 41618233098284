import { Stack, styled } from '@mui/material'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackOrWindowCloseButton } from '../components/common/buttons/backOrWindowCloseButton'
import { SubTitle } from '../components/common/subTitle'

const Content = styled('div')({
  whiteSpace: 'pre-wrap',
})

export const TermsOfService = () => {
  return (
    <Stack spacing={1}>
      <SubTitle title="利用規約" />
      <Content>{`
第1条 本規約について
この利用規約（以下「本規約」という）は、横浜市一時預かりＷＥＢ予約システム（以下、「本システム」という）を利用するにあたり、必要な条件を定めることを目的とします。
横浜市は、本規約に基づき本システムを提供します。
本システムを利用する者（以下「利用者」という）は、本規約に従い本システムを利用するものとします。

第2条 用語の定義
本規約において使用する用語の意義は、次のとおりとします。
（1）本システム
横浜市が所管する一時預かり保育サービスの予約を受付処理するサービスをいいます。
（2）個人情報
本システムにおいて取り扱う個人に関する情報（当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるものをいう。）で、
横浜市が管理する文書、図画、写真、フィルム又は電磁的記録（電子的方式、磁気的方式、その他人の知覚によっては認識することができない方式で作られた記録をいう。）
に記録されたものを含みます。ただし、法人その他の団体に関して記録された情報に含まれる当該法人その他の団体の役員に関する情報及び事業を営む個人の該当事業に関する情報を除きます。
（3）利用者ID
利用者に対して本システムが付与する識別符号をいいます。
（4）ログイン認証
利用者IDとパスワードにより本システムの正当な利用者であることを証明することをいいます。
（5）利用者情報
利用者が、利用者ID、パスワードその他本システムを利用するために登録する利用者の情報をいいます。
（6）パスワード
利用者を特定する際のセキュリティを目的として、利用者が管理する暗証符号をいいます。

第3条 サービス提供事業者
本システムの提供は、横浜市より株式会社電算システム（以下「サービス提供事業者」という）に委託しています。

第4条 利用者の責任
（1）利用者は、自己の判断と責任に基づき本システムを利用するものとします。本システムの利用に伴い生じる情報についても利用者が管理し、横浜市に対して、いかなる責任も負担させないものとします。
（2）利用者は、本システムを利用するために必要なパソコンその他のあらゆる機器、ソフトウェア、通信手段を自己の負担において準備するものとします。
また、機器の整備、通信回線の利用及びそれらに必要な手続きおよび費用は、利用者が自己の責任と負担において行うものとします。
（3）利用者は、自己の利用環境に応じて、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を講じるものとします。

第5条 個人情報の取扱い
（1）利用者より提供を受けた個人情報は、横浜市の定める個人情報保護関連条例、個人情報保護関連法令・例規等に基づき、適切に取り扱います。利用者より提出された資料は、必要に応じて本システムに登録し、横浜市に提出するものとします。
また、横浜市の定める個人情報保護関連条例、個人情報保護関連法令・例規等で定める場合を除き、横浜市は、個人情報の目的外利用、及びサービス提供事業者以外の第三者へ個人情報を提供することは一切ありません。
（2）サービス提供事業者は、横浜市の定める個人情報保護関連条例、個人情報保護関連法令・例規等、及び横浜市との委託契約において定める個人情報についての取り扱い等を遵守し、本システムの運用を行います。
（3）本システムを利用して利用者と横浜市との間で送受信する個人情報は、データの暗号化によって保護されます。
（4）横浜市及びサービス提供事業者において、個人情報を取り扱う範囲は必要最小限とし、横浜市の定める個人情報保護関連条例、個人情報保護関連法令・例規等の規定に違反して保有する個人情報を漏洩した者には、当該法令等に基づく罰則を適用します。

第6条　システム提供時間
（1）本システムの提供時間は、原則として24時間365日とします。
（2）上記時間帯においても、横浜市は、次に掲げる場合には、利用者へ事前の通知を行うことなく、本システムの利用を停止又は制限できるものとします。
（ア）本システムに重大な不具合が生じた場合
（イ）本システムの利用が著しく集中した場合
（ウ）天災、事変など、非常事態が発生した場合
（エ）その他、システムの保守・点検等によりサービス停止が必要となる場合
（オ）その他やむを得ない理由が生じた場合
（3）運用の都合により本システムの利用を停止又は制限する場合には、本システムのトップページでお知らせいたします。

第7条 禁止事項及び利用者の利用停止
本システムの利用に当たっては、次に掲げる行為を禁止します。
利用者がその責めに帰すべき理由により、第三者又は横浜市に対し、損害を与えた場合、その責を問われる場合があります。
（1）虚偽の申告を行うこと。
（2）他人を装って不正にアクセスすること。
（3）本規約に違反すること。
（4）横浜市が規定している条例及び条例規則に違反すること。
（5）本システムの管理及び運営を故意に妨害又は破壊すること。
（6）本システムの全部又は一部を第三者に開示、送信その他の方法で提供すること。
（7）本システムに改変を加えること。
（8）本システムの改変又は解析を試みること。
（9）本システムに含まれる著作権およびその他の知的財産権を侵害する行為。
（10）本システムに含まれるコンテンツの修正、複製、改ざん、販売等をすること。
（11）本システムを本来の目的以外で利用すること。
（12）その他法令若しくは公序良俗に違反する行為又はそのおそれのある行為。
また、横浜市は、本規約に定める禁止事項のいずれかに該当する行為が明らかな場合、又は該当する行為があると疑うに足りる相当な理由がある場合は、利用者へ事前の通知を行うことなく、利用者から収集した情報の抹消、利用者の本システム利用停止等必要な措置を講ずることができるものとします。

第8条 利用者IDの取得とパスワードの設定
（1）利用者は、本システムにおいてログイン認証するためには、事前に利用者IDを取得し、パスワードを設定する必要があります。
（2）ログイン認証が必要な手続きを申請する場合、利用者は、保護者氏名、お子さま氏名、電子メールアドレス等を、利用希望する施設に対し、あらかじめ本システム上から届け出るものとします。
（3）（2）の届出に対し、横浜市は、利用者ID及びパスワード設定用URLを通知するものとします。なお、利用者IDはお子さまと保護者１名を一組として登録されるため、他の保護者等による複数の利用者IDの取得は禁止事項となっています。複数の利用者IDを取得し利用していることが判明した場合は、利用者の許可なく、横浜市にて利用者IDを削除する場合があります。
（4）利用者は、通知されたパスワード設定用URLにアクセスし、速やかにパスワードを設定するとともに、（2）により届け出た事項に変更があった場合は、速やかに変更の届出を行うものとします。

第9条 利用者ID及びパスワードの管理
（1）利用者は、本システムを利用するための利用者ID及び利用者本人が登録したパスワード等の管理責任をいかなる場合においても自己の責任として負うものとします。
（2）利用者は、利用者IDを亡失した場合には、改めて新規の利用者IDを取得するものとします。なお、その場合、亡失した利用者IDと同じ利用者IDを取得することはできません。
（3）利用者は、パスワードを亡失した場合には、改めて新規のパスワードを取得するものとします。
（4）横浜市は、登録されている利用者情報の内容、又は既に発行された利用者IDの亡失等に関する問合せには一切お答えできません。

第10条 退会
（1）利用者が、本予約システムの退会を希望する場合、本システム上で退会手続きを行うものとします。
（2）（1）の退会手続きを行った利用者には、電子メールにより退会通知をし、会員資格を喪失するものとします。なお、電子メールによる通知がない場合は、次の事項を記載の上、横浜市こども青少年局保育・教育運営課（kd-ichijiyoyaku@city.yokohama.jp）にお問い合わせください。
　　（ア）保護者氏名、お子さま氏名
　　（イ）登録のメールアドレス、電話番号
（3）本システムに登録されていた情報は、下記目的のために、（1）の退会手続きやお子さまの利用終了手続きを行った日より一定期間、保持されます。
　　（ア）施設より市へ提出する利用実績報告書の作成のため【退会手続きを行った日の翌々月末日】
　　（イ）はじめてのおあずかり券の再発行や重複発行の防止のため【はじめてのおあずかり券の発行対象児童が満２歳になった月の翌々月末日】
　　　　　 ただし、上記以外の目的には使用されません。一定期間経過後、情報は完全に削除されます。
（4）（3）（イ）に記載の理由により、退会やお子さまの利用終了手続き後、再登録が制限される場合があります。誤って退会した場合や、やむを得ない理由で再登録をされる場合は、次の事項を記載の上、横浜市こども青少年局保育・教育運営課（kd-ichijiyoyaku@city.yokohama.jp）にお問い合わせください。
　　（ア）保護者氏名、お子さま氏名
　　（イ）登録のメールアドレス、電話番号


第11条 免責事項
（1）横浜市は、利用者が本システムを利用したことにより発生した、いかなる損害について、その損害が直接的又は間接的かを問わず、一切の責任を負いません。
（2）横浜市は、本システム運用の停止、中止、中断等により発生した、利用者の損害について、一切の責任を負いません。

第12条 利用規約の変更
（1）横浜市の判断により、利用者へ事前の承諾を得ることなく、本規約を変更することができるものとします。
（2）横浜市は、本規約の変更を行った場合には、遅滞なく本システム上に掲載するものとします。
（3）本規約の変更後に、本システムを利用することにより、利用者は変更後の利用規約に同意したものとみなされます。

第13条 著作権
本システムが利用者に対し提供するコンテンツは、日本国の著作権関連法令によって保護されています。

第14条 合意管轄裁判所
本規約に関する準拠法は日本法とし、本システム利用に関して横浜市と利用者間に生じるすべての訴訟については、横浜地方裁判所を専属的管轄裁判所とします。

附則
この規約は、令和4年3月31日から施行します。
令和5年10月25日改定
`}</Content>
      <BottomButtonGroup>
        <BackOrWindowCloseButton />
      </BottomButtonGroup>
    </Stack>
  )
}
